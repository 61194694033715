import { Button, Typography } from "@mui/material";
import ReactDOM from "react-dom";
import { colors } from "../themes/main";

export default function customConfirmAlert(yesCallBack = () => {}, noCallBack = () => {}, type = "LEAVE") {
    let result = false;
    _createModalComponent(yesCallBack, noCallBack, type);
    return result;
}

function _createModalComponent(yesCallBack, noCallBack, type) {
    if (document.getElementById("customConfirmAlertDiv")) {
        document.getElementById("customConfirmAlertDiv").remove();
    }

    const divTarget = document.createElement("div");
    divTarget.id = "customConfirmAlertDiv";
    document.body.appendChild(divTarget);

    // document.getElementById("root").style.filter = "blur(5px)";
    // document.getElementsByClassName("MuiModal-root")[0].style.filter = "blur(2px) brightness(80%)";

    ReactDOM.render(
        <ConfirmComponent
            type={type}
            on
            onYes={() => {
                yesCallBack();
                unmountModal(divTarget);
            }}
            onNo={() => {
                noCallBack();
                unmountModal(divTarget);
            }}
        />,
        divTarget
    );
}

function unmountModal(target) {
    ReactDOM.unmountComponentAtNode(target);
    document.getElementById("customConfirmAlertDiv").remove();
    // document.getElementById("root").style.filter = "blur(0px)";
    // document.getElementsByClassName("MuiModal-root")[0].style.filter = "blur(0px)";
}

function ConfirmComponent({ onYes = () => {}, onNo = () => {}, type }) {
    return (
        <div
            style={{
                boxShadow: "0px 2px 20px -5px rgba(0,0,0,0.25)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 5,
                zIndex: 9999,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: "30vw",
                minHeight: "20vh",
                backgroundColor: "#FFFF",
            }}
        >
            <div
                style={{ display: "flex", alignItems: "center", padding: 20, borderRadius: 5, height: 60, width: "100%", backgroundColor: colors.main.primary }}
            >
                <Typography variant="h6" color="#FFF" sx={{ cursor: "default" }}>
                    Are you sure?
                </Typography>
            </div>

            <div
                style={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "calc(30vh - 60px)" }}
            >
                <Typography variant="h6">{type == "LEAVE" ? "Leave without saving?" : "Are you sure?"}</Typography>

                <div style={{ padding: 20, width: "100%", display: "flex", justifyContent: "center", gap: 10 }}>
                    <Button variant="outlined" onClick={() => onYes()}>
                        Yes
                    </Button>
                    <Button variant="outlined" onClick={() => onNo()}>
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
}
