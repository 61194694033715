import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageNotFoundImage from "../../assets/fatal-error.gif";

export default function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <div style={{ width: "100vw", height: "100vh", gap: 20, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <img src={PageNotFoundImage} />
            <Typography>This page isn't available. Sorry about that.</Typography>

            <Typography color="blue" sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                Try going back
            </Typography>
        </div>
    );
}
