import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import usLocale from "date-fns/locale/en-US";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Typography } from "@mui/material";

export default function BasicDatePicker({
    helperText = "",
    error = false,
    PickerProps = {},
    InputProps = {},
    onChange = (date: any) => {},
    value = "",
    onClick = () => {},
    isDisabled = false,
    isDateCorrect = false,
    placeholder = "",
}) {
    const dateValue = isDateCorrect ? value : fixDate(value);
    const [currentValue, setCurrentValue] = useState<any>(value == "" || value == null || value == undefined ? null : dateValue);

    const handleChangeDate = (date: Date, keyboardInputValue?: string) => {
        setCurrentValue(keyboardInputValue ?? date);
        onChange(keyboardInputValue ?? date);
    };

    return (
        <LocalizationProvider locale={usLocale} dateAdapter={AdapterDateFns}>
            <div onClick={() => onClick()}>
                <DatePicker
                    value={currentValue}
                    {...PickerProps}
                    onChange={(date, keyboardInputValue) => handleChangeDate(date, keyboardInputValue)}
                    renderInput={(params) => (
                        <TextField error={error} {...params} {...InputProps} inputProps={{ ...params.inputProps, placeholder: placeholder }} />
                    )}
                    disabled={isDisabled}
                />
                {error && (
                    <Typography variant="caption" color="red">
                        {helperText}
                    </Typography>
                )}
            </div>
        </LocalizationProvider>
    );
}

function fixDate(date: string) {
    if (typeof date === "string") {
        const dateArr = date.split("-").map((dateStr) => parseInt(dateStr));
        dateArr[1] = dateArr[1] < 1 ? dateArr[1] : dateArr[1] - 1;

        return new Date(dateArr[0], dateArr[1], dateArr[2]);
    }
}
