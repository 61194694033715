import { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField, Select, MenuItem } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { DateHelper } from "../../helpers/dateHelper";
import CustomModal from "../../components/CustomModal/CustomModal";
import BasicDatePicker from "../../components/DatePicker";

export default function AddTrainingModal({
    responderId,
    open,
    onClose,
    trainings,
    onInspectionAdded = () => {},
    onInspectionAddError = () => {},
    organizationId,
    locationId,
}: any) {
    function handleOnClose(bool: boolean) {
        onClose(bool);
        clearCamps();
    }

    const [trainingExpirationDate, setTrainingExpirationDate] = useState<any>("");
    const [trainingNotes, setTrainingNotes] = useState<any>("");
    const [classTypes, setClassTypes] = useState<any>([]);

    const [currentTrainings, setCurrentTrainings] = useState(trainings || []);
    const [currentClassType, setCurrentClassType] = useState<any>(null);

    async function getClassTypes() {
        const classTypesInfo = await apiRequest.get("/classtype").then((res) => res.data);
        setClassTypes([...classTypesInfo]);
        setCurrentClassType(classTypesInfo[0]);
    }

    async function updateTraining() {
        const promises = currentTrainings.map((training: any) => {
            return apiRequest.put("/responder/update-training/", {
                responderId: responderId,
                trainingId: training.id,
                classTypeId: currentClassType.id,
                expirationDate: DateHelper.toLocalISOString(new Date(trainingExpirationDate)).slice(0, 10),
                notes: trainingNotes,
            });
        });

        Promise.all(promises)
            .then((res) => {
                notify("Training updated", "success");
                onClose(true);
                onInspectionAdded();
            })
            .catch((err) => {
                console.error(err.response);
                onInspectionAddError(err.response.data.error || `${err.response.data.errors[0].field || ""} ${err.response.data.errors[0].message}`);
            });
    }

    function clearCamps() {
        setCurrentTrainings([]);
    }

    useEffect(() => {
        if (Array.isArray(trainings) && open) {
            setCurrentTrainings([...trainings]);
        }
    }, [trainings, open]);

    useEffect(() => {
        getClassTypes();
    }, []);

    useEffect(() => {
        setTrainingExpirationDate(trainings[0].expirationDate);
        setTrainingNotes(trainings[0].notes);
        const selectedClassType: any = classTypes.filter((classtype: any) => {
            return classtype?.type == trainings[0]?.type;
        });
        setCurrentClassType(selectedClassType[0]);
    }, [open, trainings]);

    return (
        <CustomModal padding={2} open={open} title="Edit Training" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Edit Training</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        MenuProps={{
                            sx: { maxHeight: 250 },
                        }}
                        value={currentClassType}
                        label="Class Type"
                        onChange={(evt) => setCurrentClassType(evt.target.value)}
                    >
                        {classTypes.map((classType: any) => (
                            <MenuItem key={classType.id} value={classType}>
                                {classType.type}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                    <BasicDatePicker
                        PickerProps={{ label: "Expiration Date" }}
                        value={trainingExpirationDate}
                        onChange={(date: any) => {
                            setTrainingExpirationDate(date);
                        }}
                        InputProps={{ fullWidth: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField focused label="Notes" value={trainingNotes} fullWidth onChange={(evt) => setTrainingNotes(evt.target.value)} />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => updateTraining()} fullWidth color="success">
                        {"SAVE"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
