import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { Button, Grid, Typography, Box, TableRow, TableCell, Table, TableHead, TableBody } from "@mui/material";
import { motion } from "framer-motion";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { AuthContext } from "../../context/AuthContext";
import AddUser from "./addUser";
import EditUser from "./editUser";
import ChangePassword from "./changePassword";
import Roles from "./roles";
import { Profile } from "../../entities/profile";
import DashboardLayout from "../../layouts/DashboardLayout";
import MuiTable from "../../components/MuiTable";
import ConfirmDialog from "../../components/ConfirmDialog";
import Breadcrumbs from "../../components/Breadcrumbs";
import SearchFilter from "../../components/SearchFilter";
import CustomModal from "../../components/CustomModal/CustomModal";

export default function Home() {
    const { user } = useContext<any>(AuthContext);

    const navigate = useNavigate();

    const columns = [
        {
            name: "firstName",
            label: "Name",
            options: {
                display: false,
            },
        },
        {
            name: "lastName",
            label: "Name",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (
                    <span>
                        {tableMeta?.rowData[0]} {tableMeta?.rowData[1]}
                    </span>
                ),
            },
        },
        {
            label: "Phone",
            name: "fone",
        },
        {
            label: "Email",
            name: "email",
        },
        {
            label: "Organization",
            name: "organization_name",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => navigate(`/organization/${tableMeta.tableData[tableMeta.rowIndex][5]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "370px",
                    },
                }),
            },
        },
        {
            label: "OrganizationId",
            name: "organization_id",
            options: {
                display: false,
            },
        },
        {
            label: "profiles",
            name: "profiles",
            options: {
                display: false,
            },
        },
        {
            label: "Status",
            name: "userStatus",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (value ? "Active" : "Inactive"),
            },
        },
        {
            name: "notificationProfiles",
            options: {
                display: false,
            },
        },
    ];

    const [contentLoaded, setContentLoaded] = useState(false);
    const [contentUpdated, setContentUpdated] = useState(true);

    async function getUsersInfo() {
        setContentLoaded(false);
        setContentLoaded(true);
    }

    useEffect(() => {
        getUsersInfo();
    }, []);

    const [ModalOpen, setModalOpen] = useState(false);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [userToEdit, setUserToEdit] = useState<any>(false);
    const [userDelete, setUserDelete] = useState(false);
    const [editUserModal, setEditUserModal] = useState(false);

    function disableUser(id: any) {
        setUserDelete(false);
        setConfirmOpen(true);
        setUserToDelete(id);
        setEditUserModal(false);
    }

    async function disableUserRequest() {
        apiRequest
            .put(`/user/${userToDelete}/disable/`)
            .then((res) => {
                if (res.status == 200) {
                    setConfirmOpen(false);
                    notify("User disabled", "success");
                    getUsersInfo();
                }
            })
            .catch((err) => {
                notify("Error disabling user", "error");
                console.log(err);
            });
    }

    async function deleteUserRequest() {
        await apiRequest
            .delete(`/user/${userToEdit?.id}/`)
            .then((res) => {
                if (res.status == 200) {
                    setConfirmOpen(false);
                    notify("User deleted", "success");
                    getUsersInfo();
                }
            })
            .catch((err) => {
                notify("Error deleting user", "error");
                console.log(err);
            });
    }

    const [searchFilter, setSearchFilter] = useState("");

    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [rolesModal, setRolesModal] = useState(false);

    useEffect(() => {
        if (userDelete) {
            setConfirmOpen(true);
        }
    }, [userDelete]);

    const [myUser, setMyUser] = useState(false);

    const redirectToLocation = (profile: Profile, organizationId: string | number) => {
        if (profile.profileType == "ROLE_SYSTEM_ADMIN") {
            return null;
        }

        const organizationProfiles = ["ROLE_PROGRAM_COORDINATOR", "ROLE_PROGRAM_STAFF", "ROLE_FRANCHISE"];
        if (organizationProfiles.indexOf(profile.profileType) !== -1) {
            return navigate(`/organization/${profile.placeId}`);
        } else if (profile.profileType == "ROLE_FRANCHISE_ADMIN") {
            return navigate(`/organization/${profile.placeId}`);
        } else {
            return navigate(`/organization/${organizationId}/location/${profile.placeId}`);
        }
    };

    const [isSendEmailModal, setIsSendEmailModal] = useState(false);

    function sendForgotPasswordEmail(email: string) {
        apiRequest
            .post("/auth/forgot-password", {
                email: email,
            })
            .then((res) => {
                setIsSendEmailModal(false);
                notify(res?.data?.message, "success");
            })
            .catch((err) => notify(err?.response.data?.errors[0]?.message, "error"));
    }

    function redirectToNotificationPlace(profile: string, placeId: number, organizationId: number) {
        const locationProfiles = ["ROLE_SITES_SUPERVISOR", "ROLE_SITE_COORDINATOR", "ROLE_SITE_STAFF"];

        if (locationProfiles.includes(profile)) {
            navigate(`/organization/${organizationId}/location/${placeId}`);
        } else {
            navigate("/organization/" + placeId);
        }
    }

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="Users" />

            <AddUser open={ModalOpen} onUserAdded={() => getUsersInfo()} onClose={() => setModalOpen(false)} />

            {userToEdit && (
                <EditUser
                    onDelete={() => setUserDelete(true)}
                    user={userToEdit}
                    open={editUserModal}
                    onUserAdded={() => getUsersInfo()}
                    onClose={() => setEditUserModal(false)}
                />
            )}
            {userToEdit && (
                <ChangePassword
                    self={myUser}
                    user={userToEdit}
                    open={changePasswordModal}
                    onUserAdded={() => getUsersInfo()}
                    onClose={() => setChangePasswordModal(false)}
                />
            )}
            {userToEdit && (
                <Roles
                    user={userToEdit}
                    open={rolesModal}
                    onUpdate={() => {
                        setContentUpdated(false);
                        setTimeout(() => {
                            setContentUpdated(true);
                        }, 50);
                    }}
                    onClose={() => {
                        setRolesModal(false);
                    }}
                />
            )}

            <ConfirmDialog
                open={confirmOpen}
                onConfirm={() => (userDelete ? deleteUserRequest() : disableUserRequest())}
                onClose={() => setConfirmOpen(false)}
            />

            <CustomModal
                open={isSendEmailModal}
                title="Send forget password Email"
                onClose={() => {
                    setUserToEdit(false);
                    setIsSendEmailModal(false);
                }}
                width="60vw"
            >
                <Typography
                    variant="h6"
                    component="p"
                    align="center"
                >{`Are you sure you want to send an email to reset ${userToEdit?.firstName}'s password?`}</Typography>
                <Grid xs={12} sx={{ display: "flex", justifyContent: "center", gap: 2, marginTop: "24px" }}>
                    <Button onClick={() => sendForgotPasswordEmail(userToEdit?.email)} variant="contained">
                        YES
                    </Button>
                    <Button onClick={() => setIsSendEmailModal(false)} variant="outlined">
                        NO
                    </Button>
                </Grid>
            </CustomModal>

            {contentLoaded && (
                <motion.div key="usersThing" initial={{ scale: 0.97, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.97, opacity: 0 }}>
                    <Grid container padding={1} paddingTop={3} spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ cursor: "default" }} variant="caption" color="#2a5886">
                                Welcome,
                            </Typography>
                            <Typography variant="h4" sx={{ alignItems: "center", display: "flex", gap: 2 }} fontWeight="bold">
                                {user?.firstName} {user?.lastName}{" "}
                                <FaEdit
                                    onClick={() => {
                                        setEditUserModal(true);
                                        setUserToEdit(user);
                                    }}
                                    style={{ padding: 10, cursor: "pointer" }}
                                />{" "}
                            </Typography>

                            <Typography variant="body2">Phone: {user?.fone}</Typography>
                            <Typography variant="body2">Email: {user?.email}</Typography>
                            <Typography variant="body2">Organization: {user?.organization?.name}</Typography>

                            <Box sx={{ gap: 1, display: "flex", mt: 1 }}>
                                <Button
                                    onClick={() => {
                                        setUserToEdit(user);
                                        setRolesModal(true);
                                    }}
                                    sx={{ height: 20, width: 110, fontSize: 12, padding: 0, margin: 0, fontWeight: 500 }}
                                    variant="outlined"
                                >
                                    Roles
                                </Button>
                                <Button
                                    onClick={() => {
                                        setUserToEdit(user);
                                        setChangePasswordModal(true);
                                        setMyUser(true);
                                    }}
                                    sx={{ height: 20, width: 110, fontSize: 12, padding: 0, margin: 0, fontWeight: 500 }}
                                    variant="outlined"
                                >
                                    Set Password
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item marginTop={5} xs={12}>
                            <Typography variant="h6" color="#5a5c69" fontWeight="600">
                                Users
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", paddingBottom: 10 }}>
                                <SearchFilter onChangeFilter={(filter) => setSearchFilter(filter)} />

                                <Button
                                    sx={{ height: 40, width: 150, marginLeft: 2 }}
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                    variant="contained"
                                    color="success"
                                >
                                    ADD NEW
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <MuiTable
                                forceReload={!contentUpdated}
                                aditionalOptions={{
                                    expandableRows: true,
                                    expandableRowsHeader: true,
                                    expandableRowsOnClick: false,
                                    isRowExpandable: (dataIndex: any, expandedRows: any) => {
                                        return true;
                                    },
                                    renderExpandableRow: (rowData: any, rowMeta: any) => {
                                        const colSpan = rowData.length + 1;
                                        const profileList = rowData[6];
                                        const notificationProfileList = rowData[8];

                                        return (
                                            <TableRow sx={{ backgroundColor: "rgba(244,244,244,0.5)" }}>
                                                <TableCell colSpan={colSpan}>
                                                    <Table sx={{ border: "none" }}>
                                                        <TableHead sx={{ backgroundColor: "transparent", padding: 0 }}>
                                                            <TableCell sx={{ fontWeight: "bold", padding: 1 }}>Profile</TableCell>
                                                            <TableCell sx={{ fontWeight: "bold", padding: 1 }}>Organization / Location</TableCell>
                                                        </TableHead>
                                                        <TableBody sx={{ marginLeft: -2 }}>
                                                            {profileList.map((profile: Profile, index: number) => (
                                                                <TableRow key={index} sx={{ padding: 0 }}>
                                                                    <TableCell sx={{ padding: 1 }}>
                                                                        {profile?.profileType?.replace("ROLE_", "")?.replaceAll("_", " ")}
                                                                    </TableCell>
                                                                    <TableCell sx={{ padding: 1 }} onClick={() => redirectToLocation(profile, rowData[5])}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            sx={{
                                                                                marginRight: "16px",
                                                                                "&:hover": {
                                                                                    cursor: profile.profileType == "ROLE_SYSTEM_ADMIN" ? "default" : "pointer",
                                                                                    fontWeight: profile.profileType == "ROLE_SYSTEM_ADMIN" ? "normal" : "bold",
                                                                                    marginRight: 0,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {profile?.placeName}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                            {notificationProfileList.length > 0 && (
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: "bold", padding: 1 }}>Notification Profile</TableCell>
                                                                    <TableCell sx={{ fontWeight: "bold", padding: 1 }}>Organization / Location</TableCell>
                                                                </TableRow>
                                                            )}

                                                            {notificationProfileList.map((notification: any, index: number) => (
                                                                <TableRow key={index + "b"} sx={{ padding: 0 }}>
                                                                    <TableCell sx={{ padding: 1 }}>
                                                                        {notification?.profile?.replace("ROLE_", "")?.replaceAll("_", " ")}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        sx={{ padding: 1 }}
                                                                        onClick={() =>
                                                                            redirectToNotificationPlace(
                                                                                notification?.profile,
                                                                                notification?.placeId,
                                                                                rowData[5]
                                                                            )
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body2"
                                                                            sx={{
                                                                                width: "500px",
                                                                                "&:hover": {
                                                                                    cursor: notification.profile == "ROLE_SYSTEM_ADMIN" ? "default" : "pointer",
                                                                                    fontWeight: notification.profile == "ROLE_SYSTEM_ADMIN" ? "normal" : "bold",
                                                                                },
                                                                            }}
                                                                        >
                                                                            {notification?.placeName}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    },
                                    selectableRows: "none",
                                }}
                                onDelete={(row: any) => disableUser(row.id)}
                                onEdit={(row: any) => {
                                    setEditUserModal(true);
                                    setUserToEdit(row);
                                }}
                                pagination
                                noView
                                onAditionalAction={(details: any) => {
                                    setUserToEdit(details);
                                    setIsSendEmailModal(true);
                                }}
                                onSecondAditionalAction={(details: any) => {
                                    setUserToEdit(details);
                                    setRolesModal(true);
                                }}
                                secondAditionalAction={
                                    <Button sx={{ height: 20, width: 110, fontSize: 12, padding: 0, margin: 0, fontWeight: 500 }} variant="outlined">
                                        Roles
                                    </Button>
                                }
                                aditionalAction={
                                    user?.userProfiles.systemAdmin ? (
                                        <Button sx={{ height: 20, width: 232, fontSize: 12, padding: 0, margin: 0, fontWeight: 500 }} variant="outlined">
                                            Send forget password Email
                                        </Button>
                                    ) : (
                                        <></>
                                    )
                                }
                                paginationURL="/user/"
                                search={searchFilter}
                                columns={columns}
                            />
                        </Grid>
                    </Grid>
                </motion.div>
            )}
        </DashboardLayout>
    );
}
