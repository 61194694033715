import { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorProps } from "../../helpers/errorHelper";
import customConfirmAlert from "../../components/confirmAlert";
import CustomModal from "../../components/CustomModal/CustomModal";

export default function RespondersModal({
    onResponderAdded = () => {},
    onResponderAddError = () => {},
    open,
    onClose,
    createMode,
    locations = [],
    locationId,
    responder,
    onAEDUpdate = () => null,
    organizationId,
}: any) {
    const [updated, setUpdated] = useState(true);

    function handleOnClose(bool: boolean) {
        onClose(bool);
        setUpdated(false);
        clearCamps();
    }

    // const [selectedResponder, setSelectedResponder] = useState<any>(responder || null);
    const [responderName, setResponderName] = useState(responder?.name || "");

    const [errors, setErrors] = useState<any>([]);

    async function addResponder() {
        await apiRequest
            .post("/responder", {
                locationId: locationId,
                name: responderName,
            })
            .then(async (res) => {
                setUpdated(true);
                onResponderAdded();
                handleOnClose(true);
                notify("Responder added", "success");
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors([...err.response.data.errors]);
                } else {
                    notify(err?.response?.data?.error, "error");
                }
            });
    }

    async function updateResponder() {
        await apiRequest
            .put("/responder/" + responder?.id, {
                name: responderName,
                locationId: locationId,
            })
            .then(async (res) => {
                setUpdated(true);
                onResponderAdded();
                handleOnClose(true);
                notify("Responder added", "success");
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors([...err.response.data.errors]);
                } else {
                    notify(err?.response?.data?.error, "error");
                }
            });
    }

    function clearCamps() {
        // setSelectedResponder("");
        setResponderName("");
        setErrors([]);
    }

    function editHandler() {
        setResponderName(responder?.name);
    }

    useEffect(() => {
        createMode && clearCamps();
        !createMode && editHandler();
    }, [responder, open, createMode]);

    return (
        <CustomModal
            padding={2}
            open={open}
            title="Responders"
            onClose={() => {
                if (!updated) {
                    customConfirmAlert(() => {
                        handleOnClose(true);
                    });
                } else {
                    handleOnClose(true);
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">{createMode ? "Add " : "Edit "} responder</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="caption">Info</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        {...errorProps(errors, "name")}
                        label="Name"
                        value={responderName}
                        fullWidth
                        onChange={(evt) => {
                            if (evt.target.value != "") {
                                setUpdated(false);
                            }
                            setResponderName(evt.target.value);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (createMode) {
                                addResponder();
                            } else {
                                updateResponder();
                            }
                        }}
                        fullWidth
                        color="success"
                    >
                        {createMode ? "ADD" : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
