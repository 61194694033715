export function DateFormat(dateStr) {
    const dateValidation = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/; // YYYY-MM-DD

    if (!dateStr || !dateValidation.test(dateStr)) {
        return "";
    }

    const dateArr = dateStr.split("-");
    const newDateArr = [dateArr[1], dateArr[2], dateArr[0]]; // MM-DD-YYYY

    return newDateArr.join("/");
}
