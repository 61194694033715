import { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import BasicDatePicker from "../../DatePicker";
import CustomModal from "../../CustomModal/CustomModal";

export default function RemovePrimaryAccessory({ title, isOpen, onClose, component, componentData, asyncAction }) {
    const [info, setInfo] = useState(null);

    const handleOnAction = () => {
        asyncAction(info);
        setInfo(null);
    };

    const handleOnClose = () => {
        onClose();
        setInfo(null);
    };

    return (
        <CustomModal title={title} open={isOpen} onClose={handleOnClose} width={"45vw"}>
            <Grid container>
                {component === "BACKUP_ID" && (
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: 1, padding: 1 }}>
                        {componentData.map((data) => (
                            <Button
                                variant={data?.id == info ? "contained" : "outlined"}
                                key={data?.id}
                                onClick={() => setInfo(data?.id)}
                            >{`${data?.type} (${data?.expiration})`}</Button>
                        ))}
                    </Grid>
                )}
                {component === "INSTALLATION_DATE" && (
                    <Grid item xs={12} sx={{ padding: 1 }}>
                        <Typography>Installation Date</Typography>
                        <BasicDatePicker
                            value={""}
                            onChange={(date) => setInfo(date)}
                            PickerProps={{
                                fullWidth: true,
                                center: true,
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ display: "flex", gap: 1, justifyContent: "center", marginTop: 3 }}>
                    <Button variant="outlined" disabled={!info} onClick={handleOnAction}>
                        Confirm
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleOnClose}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
