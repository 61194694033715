import { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button, Grid, MenuItem, Select, TableCell, TableRow, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { format, parse } from "date-fns";
import useAccessory from "../../../hooks/useAccessory";
import apiRequest from "../../../helpers/apiRequest";
import { notify } from "../../../helpers/notify";
import { errorHandler } from "../../../helpers/errorHelper";
import { DateHelper } from "../../../helpers/dateHelper";
import BasicDatePicker from "../../DatePicker";
import RemovePrimaryAccessory from "../modal/removePrimaryAccessory";

export default function AccessoriesList({
    accessories,
    onUpdate = (updatedAed) => {},
    editMode = false,
    aedId,
    aedModelId,
    isFixedBatteryExpirationDate = false,
}) {
    const [accessoriesList, setAccessoriesList] = useState(accessories);
    const [expandedRows, setExpandedRows] = useState([]);

    const [accessoryId, setAccessoryId] = useState(null);
    const [accessoryType, setAccessoryType] = useState(null);
    const [accessoryOperationLevel, setAccessoryOperationLevel] = useState(null);
    const [accessoryExpiration, setAccessoryExpiration] = useState("");

    const { removePrimary } = useAccessory();

    const [asyncModal, setAsyncModal] = useState({
        title: "",
        isOpen: false,
        onClose: () => {},
        component: "",
        componentData: {},
        asyncAction: () => {},
    });

    const clearModalData = () => {
        setAsyncModal(() => ({
            title: "",
            isOpen: false,
            onClose: () => {},
            component: "",
            componentData: {},
            asyncAction: () => {},
        }));
    };

    async function editAccessory() {
        if (editMode) {
            await apiRequest
                .put(`/aed/${aedId}/accessory/${accessoryId}`, {
                    type: accessoryType,
                    operationLevel: accessoryOperationLevel,
                    expiration: accessoryExpiration ? DateHelper.stringToGMT(accessoryExpiration) : null,
                })
                .then((res) => {
                    setExpandedRows([]);
                    setAccessoryId(null);
                    setAccessoryType(null);
                    setAccessoryOperationLevel(null);
                    setAccessoryExpiration("");

                    const accessoriesListCopy = accessoriesList.map((accessory) => {
                        if (accessory.id == accessoryId) {
                            accessory.type = res.data.type.replace("_", " ");
                            accessory.operationLevel = res.data.operationLevel;
                            accessory.expiration = res.data.expiration;
                            accessory.expirationLabel = res.data.expiration;
                            accessory.installationDate = res.data.installationDate;
                        }

                        return accessory;
                    });
                    setAccessoriesList([...accessoriesListCopy]);
                    onUpdate(accessoriesListCopy);
                    notify("Accessory updated", "success");
                })
                .catch((err) => {
                    const errorMessaage = err.response.data.errors[0]?.message ?? "Unexpected error, please try again soon";
                    notify(errorMessaage, "error");
                    errorHandler(err);
                });
        } else {
            const installationDate = isInstallationDate() ? await getInstallationDate() : false;

            const accessoriesListCopy = accessoriesList.map((accessory) => {
                if (accessory.id == accessoryId) {
                    const expirationDate = accessoryExpiration ? DateHelper.stringToGMT(accessoryExpiration) : null;

                    accessory.operationLevel = accessoryOperationLevel;
                    accessory.expiration = expirationDate;
                    accessory.expirationLabel = expirationDate;

                    if (installationDate) {
                        accessory.expiration = installationDate;
                        accessory.expirationLabel = installationDate;
                        accessory.installationDate = expirationDate;
                    }
                }

                return accessory;
            });

            setAccessoriesList([...accessoriesListCopy]);
            onUpdate(accessoriesListCopy);
            setExpandedRows([]);
        }
    }

    async function getInstallationDate() {
        return await apiRequest
            .post(`/aed/accessory-valid`, {
                aedModelId: aedModelId,
                type: accessoryType,
                operationLevel: accessoryOperationLevel,
                expiration: accessoryExpiration ? format(accessoryExpiration, "yyyy-MM-dd") : "",
            })
            .then((res) => res.data.expiration);
    }

    async function deleteAccessoryHandler(id) {
        const currentAccessory = accessories.find((accessory) => accessory.id == id);

        if (editMode) {
            if (currentAccessory.operationLevel === "BACKUP" || currentAccessory.type === "PEDIATRIC PAD") {
                await apiRequest
                    .delete(`/aed/${aedId}/accessory/${id}`)
                    .then((res) => {
                        notify("Accessory deleted", "success");
                        deleteAccessory(id);
                    })
                    .catch((err) => {
                        notify("Error deleting accessory: " + err.message, "error");
                    });

                return;
            }

            removePrimary(accessories, currentAccessory, aedId, isFixedBatteryExpirationDate, setAsyncModal)
                .then((res) => {
                    const updatedList = res.data?.accessories || [];
                    let backupAccessory = null;

                    for (const i in accessories) {
                        if (backupAccessory) break;

                        for (const j in updatedList) {
                            if (
                                accessories[i].id == updatedList[j].id &&
                                accessories[i].operationLevel == "BACKUP" &&
                                updatedList[j].operationLevel == "PRIMARY"
                            ) {
                                backupAccessory = accessories[i];
                                break;
                            }
                        }
                    }

                    setAccessoriesList(updatedList);
                    onUpdate(updatedList);
                    notify(
                        <span>
                            {"Accessory Removed."}
                            <br />
                            {`Backup ${backupAccessory.type.replaceAll("_", " ")} converted to primary`}
                        </span>,
                        "success"
                    );
                })
                .catch((err) => {
                    notify(err.response.data?.errors[0].message || "Unexpected error", "error");
                })
                .finally(clearModalData);
        } else {
            deleteAccessory(id);
        }
    }

    function deleteAccessory(id) {
        const accessoryIndex = accessoriesList.findIndex((accessory) => accessory.id == id);

        const newList = Object.assign([], accessoriesList);
        newList.splice(accessoryIndex, 1);

        setAccessoriesList([...newList]);
        onUpdate(newList);
    }

    useEffect(() => {
        setAccessoriesList([...accessories]);
    }, [accessories]);

    function isInstallationDate(accType = accessoryType) {
        return accType === "BATTERY" && isFixedBatteryExpirationDate === false;
    }

    return (
        <>
            <RemovePrimaryAccessory
                title={asyncModal.title}
                isOpen={asyncModal.isOpen}
                onClose={() => {
                    asyncModal.onClose();
                    clearModalData();
                }}
                component={asyncModal.component}
                componentData={asyncModal.componentData}
                asyncAction={asyncModal.asyncAction}
            />

            <MUIDataTable
                title={"Employee List"}
                data={accessoriesList}
                columns={[
                    {
                        name: "id",
                        label: "id",
                        options: { display: false },
                    },
                    {
                        name: "type",
                        label: "Type",
                    },
                    {
                        name: "operationLevel",
                        label: "Operation Level",
                    },
                    {
                        name: editMode ? "expiration" : "expirationLabel",
                        label: "Expiration",
                        options: {
                            customBodyRender: (value, tableMeta) =>
                                value ? <Typography>{format(parse(value, "yyyy-MM-dd", new Date()), "MM/dd/yyyy") ?? ""}</Typography> : "",
                        },
                    },
                    {
                        name: "id",
                        label: "Actions",
                        options: {
                            customBodyRender: (value, tableMeta) => (
                                <div>
                                    <FaEdit
                                        size={18}
                                        style={{ padding: 1, cursor: "pointer" }}
                                        onClick={() => {
                                            if (expandedRows.length > 0) {
                                                if (expandedRows[0] == tableMeta.rowIndex) {
                                                    setExpandedRows([]);
                                                    setAccessoryId(null);
                                                    setAccessoryType(null);
                                                    setAccessoryOperationLevel(null);
                                                    setAccessoryExpiration("");
                                                }
                                            } else {
                                                const accType = tableMeta.tableData[tableMeta.rowIndex][1]?.replaceAll(" ", "_").toUpperCase();
                                                const rowIndex = isInstallationDate(accType) ? 6 : 3;

                                                setExpandedRows([tableMeta?.rowIndex]);
                                                setAccessoryId(tableMeta.tableData[tableMeta.rowIndex][0]);
                                                setAccessoryType(accType);
                                                setAccessoryOperationLevel(tableMeta.tableData[tableMeta.rowIndex][2]);
                                                setAccessoryExpiration(tableMeta.tableData[tableMeta.rowIndex][rowIndex]);
                                            }
                                        }}
                                    />
                                    <FaTrash size={18} style={{ padding: 1, cursor: "pointer" }} onClick={() => deleteAccessoryHandler(value)} />
                                </div>
                            ),
                        },
                    },
                    {
                        name: "expirationLabel",
                        label: "Expiration",
                        options: {
                            display: false,
                        },
                    },
                    {
                        name: "installationDate",
                        label: "Installation Date",
                        options: {
                            display: false,
                        },
                    },
                ]}
                options={{
                    filterType: "checkbox",
                    elevation: 0,
                    expandableRows: true,
                    expandableRowsHeader: false,
                    expandableRowsOnClick: false,
                    selectableRowsHeader: false,
                    selectableRows: "none",
                    rowsExpanded: expandedRows,
                    rowsPerPageOptions: [10, 25, 50, 100, { label: "ALL", value: accessoriesList?.length }],
                    isRowExpandable: () => true,
                    renderExpandableRow: (rowData, rowMeta) => {
                        const colSpan = rowData.length + 1;
                        return (
                            <TableRow sx={{ backgroundColor: "rgba(244,244,244,0.5)" }}>
                                <TableCell colSpan={colSpan}>
                                    <Grid container sx={{ display: "flex", minHeight: 60, spacing: 2 }}>
                                        <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                                            <Typography>Accessory Type</Typography>
                                            <Select
                                                disabled
                                                sx={{ backgroundColor: "#FFF" }}
                                                value={accessoryType}
                                                fullWidth
                                                onChange={(e) => setAccessoryType(e.target.value)}
                                            >
                                                <MenuItem value="BATTERY">BATTERY</MenuItem>
                                                <MenuItem value="ADULT_PAD">ADULT PAD </MenuItem>
                                                <MenuItem value="PEDIATRIC_PAD">PEDIATRIC PAD </MenuItem>
                                                <MenuItem value="BATTERY_PAD_COMBO">BATTERY PAD COMBO</MenuItem>
                                                <MenuItem value="ADULT_PAD_ICPR">ADULT PAD iCPR</MenuItem>
                                            </Select>
                                        </Grid>

                                        <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                                            <Typography>Operation Level</Typography>
                                            <Select
                                                disabled={accessoryType === "PEDIATRIC_PAD"}
                                                sx={{ backgroundColor: "#FFF" }}
                                                value={accessoryOperationLevel}
                                                fullWidth
                                                onChange={(e) => setAccessoryOperationLevel(e.target.value)}
                                            >
                                                {accessoryType === "PEDIATRIC_PAD" && <MenuItem value="N/A">N/A</MenuItem>}
                                                <MenuItem value="PRIMARY">PRIMARY</MenuItem>
                                                <MenuItem value="BACKUP">BACKUP</MenuItem>
                                            </Select>
                                        </Grid>

                                        <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                                            <Typography>{isInstallationDate() ? "Installation Date" : "Expiration Date"}</Typography>
                                            <BasicDatePicker
                                                value={accessoryExpiration}
                                                onChange={(date) => setAccessoryExpiration(date)}
                                                PickerProps={{
                                                    fullWidth: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                                            <Typography>Confirm</Typography>
                                            <Button onClick={() => editAccessory()} fullWidth sx={{ height: 55 }} variant="contained">
                                                OK
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    },
                }}
                components={{
                    TableToolbarSelect: (props) => <></>,
                    TableToolbar: (props) => <div></div>,
                    ExpandButton: (props) => <></>,
                }}
            />
        </>
    );
}
