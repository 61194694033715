import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, TextField, Grid } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorHandler, errorProps } from "../../helpers/errorHelper";
import LogoHorizontal from "../../images/Logo_2.png";

export default function Login() {
    const passwordResetToken = useLocation().search.slice(3, useLocation().search.length);

    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    async function ResetPassword() {
        await apiRequest
            .post(`/auth/set-password?t=${passwordResetToken}`, {
                password: password,
                passwordConfirm: passwordConfirm,
            })
            .then((res) => {
                notify("Password changed!", "success");
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    const styleBody = {
        backgroundImage: "linear-gradient(to bottom, #22579b, #1f5190, #1d4b85, #1a457b, #183f70)",
        width: "100%",
        height: "100vh",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const styleBox = {
        backgroundColor: "#ffffff",
        width: "400px",
        p: 4,
        borderRadius: "0.3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const styleButton = {
        backgroundColor: "#5dc48f",
        mb: 5,
    };

    return (
        <Grid sx={styleBody}>
            <Grid sx={styleBox} container spacing={1}>
                <img src={LogoHorizontal} alt="" width={300} />

                <Grid item xs={12} mt={4}>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        type={"password"}
                        label="Password"
                        value={password}
                        {...errorProps(errors, "password")}
                        variant="outlined"
                        onChange={(evt) => setPassword(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} mt={4}>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        type={"password"}
                        {...errorProps(errors, "passwordConfirm")}
                        label="Confirm Password"
                        value={passwordConfirm}
                        variant="outlined"
                        onChange={(evt) => setPasswordConfirm(evt.target.value)}
                    />
                </Grid>

                <Grid marginTop={2} item xs={12} md={12}>
                    <Button sx={styleButton} onClick={() => ResetPassword()} variant="contained" size="large" fullWidth>
                        Change password
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
