import { Button, Grid, Typography, TextField, Select, FormControl, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiRequest from "../../../../helpers/apiRequest";
import { errorHandler, errorProps } from "../../../../helpers/errorHelper";
import { notify } from "../../../../helpers/notify";

export default function SetLocation({ aed, onClose = () => {}, onUpdate = () => {}, onUserAddError = () => {} }) {
    const { organizationId } = useParams();

    function handleOnClose() {
        onClose();
        onUpdate();
        clearCamps();
    }

    const [errors, setErrors] = useState([]);

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [placement, setPlacement] = useState("");

    const [locations, setLocations] = useState([]);
    async function getLocations() {
        const locationsInfo = await apiRequest.get(`/location/all/${organizationId}`).then((res) => res.data);
        setLocations([...locationsInfo]);
    }

    async function setLocationRequest() {
        setErrors([]);

        await apiRequest
            .post(`/aed/addlocation`, {
                aedId: aed.id,
                locationId: selectedLocation?.id,
                organizationId: organizationId,
                placement: placement,
            })
            .then((res) => {
                handleOnClose();
                notify("Aed added to location", "success");
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    function clearCamps() {
        setPlacement("");
        setSelectedLocation(null);
    }

    useEffect(() => {
        setPlacement(aed?.placement);
        getLocations();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item marginTop={2} xs={12}>
                <Typography variant="body1">Select a location</Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControl {...errorProps(errors, "locationId")} fullWidth>
                    <InputLabel id="demo-simple-select-label2">Location</InputLabel>
                    <Select
                        MenuProps={{
                            sx: {
                                maxHeight: 250,
                            },
                        }}
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select2"
                        value={selectedLocation}
                        onChange={(evt) => setSelectedLocation(evt.target.value)}
                        label="Location"
                    >
                        {locations.map((org) => (
                            <MenuItem value={org}>{org.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errorProps(errors, "locationId").helperText}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <TextField {...errorProps(errors, "placement")} value={placement} onChange={(e) => setPlacement(e.target.value)} fullWidth label="Placement" />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" onClick={() => setLocationRequest()} fullWidth color="success">
                    {"SET LOCATION"}
                </Button>
            </Grid>
        </Grid>
    );
}
