import CustomModal from "../../components/CustomModal/CustomModal";
import { Typography, Button, Box } from "@mui/material";

function ConfirmModal({ isOpen, sourceProfile, targetProfile, onAccept, onClose }) {
    const textAlert = isSingular(sourceProfile) ? `the ${sourceProfile} profile.` : `all ${sourceProfile} profiles.`;
    const textConfirm = isSingular(sourceProfile) ? `${sourceProfile} profile` : `all ${sourceProfile} profiles`;

    return (
        <CustomModal padding={2} open={isOpen} title="Are you sure?" onClose={() => onClose()}>
            <Typography variant="h6" align="center">{`To switch the user to ${targetProfile}, you must remove ${textAlert}`}</Typography>
            <Typography variant="h6" align="center">{`Are you sure you want to remove ${textConfirm} from the user?`}</Typography>
            <Box sx={{ display: "flex", width: "fit-content", margin: "24px auto", gap: 2 }}>
                <Button onClick={() => onAccept()} variant="contained">
                    YES
                </Button>
                <Button onClick={() => onClose()} variant="outlined">
                    NO
                </Button>
            </Box>
        </CustomModal>
    );
}

export function isSingular(profile) {
    const singularProfiles = ["SYSTEM ADMIN", "PROGRAM COORDINATOR", "PROGRAM STAFF"];

    for (const key in singularProfiles) {
        if (profile == singularProfiles[key]) return true;
    }

    return false;
}

export default ConfirmModal;
