import { ReactElement, useEffect, useState } from "react";
import CustomModal from "../components/CustomModal/CustomModal";

export type useModalProps = {
    title: string;
    scene: ReactElement | null;
};

export const useModal = ({ title = "Modal", scene = null }: useModalProps) => {
    const [_scene, setScene] = useState<ReactElement | null>(scene);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    const SceneRender = ({ children = null }) => {
        return (
            <CustomModal padding={2} open={isOpen} title={title} onClose={close}>
                {_scene ?? children}
            </CustomModal>
        );
    };

    useEffect(() => {
        if (scene !== scene) {
            setScene(scene);
        }
    }, [scene]);

    return {
        open,
        close,
        setScene,
        SceneRender,
    };
};
