import { toast, ToastContent } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export function notify(message: ToastContent, type: string) {
    const AUTO_CLOSE_DELAY = 3000;

    if (toast.isActive("error-id")) {
        return;
    }

    switch (type) {
        case "error":
            toast.error(message, {
                theme: "dark",
                autoClose: AUTO_CLOSE_DELAY,
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "error-id",
            });
            break;
        case "success":
            toast.success(message, {
                theme: "dark",
                autoClose: AUTO_CLOSE_DELAY,
                position: toast.POSITION.BOTTOM_CENTER,
            });
            break;
        case "alert":
            toast.warn(message, {
                theme: "dark",
                autoClose: AUTO_CLOSE_DELAY,
                position: toast.POSITION.BOTTOM_CENTER,
            });
            break;
        default:
            toast.info(message, {
                theme: "dark",
                autoClose: AUTO_CLOSE_DELAY,
            });
    }
}
