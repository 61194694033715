import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { PrivateRoute } from "./helpers/privateRoute";
import AuthProvider from "./context/AuthContext";
import Organization from "./pages/organization";
import Locations from "./pages/locations/index";
import Aed from "./pages/aed/index";
import Responder from "./pages/responder/index";
import Login from "./pages/login";
import ForgetPassword from "./pages/forget-password";
import ResetPassword from "./pages/reset-password";
import Home from "./pages/home";
import Users from "./pages/users";
import ClassTypes from "./pages/class-types";
import NewAeds from "./pages/newaeds";
import AedModels from "./pages/aed-models/index";
import AfterTheRescue from "./pages/after-the-rescue";
import Supervisor from "./pages/supervisor";
import ForecastPage from "./pages/forecast-sheet";
import ForecastSub from "./pages/forecast-subscription";
import SupportLogs from "./pages/support-logs";
import DocumentCenter from "./pages/document-center";
import NotFoundPage from "./pages/404";

export default function Router() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <PrivateRoute allowedRoles={["franchise", "systemAdmin"]}>
                                <Home />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/forget-password" element={<ForgetPassword />} />
                    <Route exact path="/reset-password" element={<ResetPassword />} />

                    <Route
                        exact
                        path="/organizations"
                        element={
                            <PrivateRoute allowedRoles={["franchise", "systemAdmin"]}>
                                <Home />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/forecast-sheet"
                        element={
                            <PrivateRoute allowedRoles={["franchise", "systemAdmin"]}>
                                <ForecastPage />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/forecast-subscription"
                        element={
                            <PrivateRoute allowedRoles={["franchise", "systemAdmin"]}>
                                <ForecastSub />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/home"
                        element={
                            <PrivateRoute allowedRoles={["franchise", "systemAdmin"]}>
                                <Home />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/class-types/"
                        element={
                            <PrivateRoute allowedRoles={["systemAdmin"]}>
                                <ClassTypes />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/aed-models/"
                        element={
                            <PrivateRoute allowedRoles={["systemAdmin"]}>
                                <AedModels />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/organization/:organizationId/after-the-rescue/"
                        element={
                            <PrivateRoute>
                                <AfterTheRescue />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/coordinator-view"
                        element={
                            <PrivateRoute allowedRoles={["systemAdmin", "siteCoordinator"]}>
                                <Supervisor />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/users"
                        element={
                            <PrivateRoute>
                                <Users />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/organization/:organizationId"
                        element={
                            <PrivateRoute>
                                <Organization />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        exact
                        path="/organization/:organizationId/new-aeds"
                        element={
                            <PrivateRoute allowedRoles={["franchise", "systemAdmin", "programCoordinator", "programStaff", "sitesSupervisor"]}>
                                <NewAeds />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/organization/:organizationId/location/:id"
                        element={
                            <PrivateRoute>
                                <Locations />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        exact
                        path="/organization/:organizationId/location/:id/aed/:aedId"
                        element={
                            <PrivateRoute>
                                <Aed />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        exact
                        path="/organization/:organizationId/location/:id/responder/:responderId"
                        element={
                            <PrivateRoute>
                                <Responder />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/support-logs"
                        element={
                            <PrivateRoute allowedRoles={["franchise", "systemAdmin"]}>
                                <SupportLogs />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        exact
                        path="/documents"
                        element={
                            <PrivateRoute>
                                <DocumentCenter />
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route exact path="/404" element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}
