import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import MuiTable from "../../components/MuiTable";
import { useParams } from "react-router-dom";
import SetLocation from "./setLocation";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function NewAeds({}) {
    const [newAeds, setNewAeds] = useState([]);
    const { organizationId } = useParams();

    const columns = [
        {
            name: "serial",
            label: "Serial number",
        },
        {
            label: "Model",
            name: "aedModel_model",
        },
        {
            label: "Placement",
            name: "placement",
        },
        {
            label: "Location",
            name: "ownerInfo_locationName",
        },
        {
            label: "Status",
            name: "status",
        },
        {
            label: "Warranty Expiration",
            name: "warrantyDate",
        },
    ];

    const [selectedAED, setSelectedAED] = useState(null);
    const [locationOpen, setLocationOpen] = useState(false);

    useEffect(() => {
        if (selectedAED) {
            setLocationOpen(true);
        }
    }, [selectedAED]);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true, organization: { org: true } }} currentPage="New AED" />
            <SetLocation aed={selectedAED} open={locationOpen} onClose={() => setLocationOpen(false)} />
            <motion.div key="new-aeds" initial={{ scale: 0.97, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.97, opacity: 0 }}>
                <Grid container padding={2} paddingTop={3} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="#5a5c69" fontWeight="600">
                            New Aeds
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <MuiTable
                            pagination
                            paginationURL={`/organization/${organizationId}/newaeds`}
                            onAditionalAction={(data) => setSelectedAED(data)}
                            aditionalAction={
                                <Button sx={{ height: 20, width: 110, fontSize: 12, padding: 0, margin: 0, fontWeight: 500 }} variant="contained">
                                    Set Location
                                </Button>
                            }
                            noView
                            noDelete
                            noEdit
                            noSelect
                            data={newAeds}
                            columns={columns}
                        />
                    </Grid>
                </Grid>
            </motion.div>
        </DashboardLayout>
    );
}
