import { useContext, useEffect, useState } from "react";
import { Button, TextField, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { EmailOutlined, HttpsOutlined, Login as LoginIcon } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import LogoHorizontal from "../../images/Logo_2.png";

export default function Login() {
    const isMobile = useMediaQuery("(max-width: 900px)");

    const { signIn, authRedirect, user } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    async function LogIn() {
        await signIn(email, password);
    }

    const handleOnKeyPress = (event) => {
        // charCode 13 == ENTER
        if (event.charCode == 13) {
            LogIn();
        }
    };

    useEffect(() => {
        if (user !== null) {
            authRedirect();
        }
    }, [user]);

    const styleBody = {
        backgroundImage: "linear-gradient(to bottom, #22579b, #1f5190, #1d4b85, #1a457b, #183f70)",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    };

    const styleBox = {
        backgroundColor: "#ffffff",
        maxWidth: "70vw",
        width: "400px",
        p: 5,
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
        margin: 2,
    };

    const styleButton = {
        backgroundColor: "#154C92",
        fontWeight: "500",
        mb: 1,
    };

    return (
        <Grid container>
            <Grid
                item
                md={6}
                sx={{
                    backgroundColor: "#FFFF",
                    padding: 5,
                    gap: 5,
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img src={LogoHorizontal} width={250} />
                <Typography textAlign="center">
                    TracAED has been rebranded to RescueManager. All the information and data has been transferred for you. If you know your credentials, input
                    them to the right. If you don’t have your access credentials, select “Forgot Password” and follow the instructions.
                    <br />
                    If you’re unable to login, please call us at (916) 939-2277
                </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={styleBody}>
                <Box sx={styleBox}>
                    {isMobile && <img src={LogoHorizontal} width={125} />}

                    <Typography mb={2}>Sign in to Rescue Manager</Typography>

                    <TextField
                        fullWidth
                        type={"email"}
                        label="Email"
                        value={email}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(evt) => setEmail(evt.target.value)}
                        onKeyPress={(event) => handleOnKeyPress(event)}
                    />
                    <TextField
                        fullWidth
                        value={password}
                        type={"password"}
                        label="Password"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HttpsOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(evt) => setPassword(evt.target.value)}
                        onKeyPress={(event) => handleOnKeyPress(event)}
                    />

                    <Button sx={styleButton} onClick={() => LogIn()} variant="contained" size="large" startIcon={<LoginIcon />} fullWidth>
                        Login
                    </Button>

                    <Typography variant="subtitle2">
                        <a href="/forget-password" style={{ textDecoration: "none", color: "#154C92" }}>
                            Forgot your password?
                        </a>
                    </Typography>
                </Box>
                {isMobile && (
                    <Box sx={{ display: "flex", width: "90%", justifyContent: "center", mt: 2 }}>
                        <Typography sx={{ display: { xs: "block", md: "none" }, color: "#FFFF", maxWidth: "75vw" }} textAlign="center" variant="caption">
                            TracAED has been rebranded to RescueManager. All the information and data has been transferred for you. If you know your
                            credentials, input them above. If you don’t have your access credentials, select “Forgot Password” and follow the instructions. If
                            you’re unable to login, please call us at (916) 939-2277.
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
