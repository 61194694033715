import { useState } from "react";
import { Button, Grid, Typography, TextField } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorFieldHandler, errorFieldMessage } from "../../helpers/errorHelper";
import CustomModal from "../../components/CustomModal/CustomModal";
import customConfirmAlert from "../../components/confirmAlert";

export default function ChangePassword({ self = false, user, open, onClose, onUserAdded = () => {}, onUserAddError = () => {} }) {
    const [passwordChanged, setPasswordChanged] = useState(true);

    function handleOnClose(bool) {
        clearCamps();
        onClose(bool);
    }

    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");

    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [errors, setErrors] = useState([]);

    async function updateUser() {
        await apiRequest
            .put(`/user/${user?.id}/changepassword`, {
                password: password,
                passwordConfirm: passwordConfirm,
                ...(self && {
                    oldPassword: oldPassword,
                }),
            })
            .then((res) => {
                setPasswordChanged(true);
                notify("User password updated", "success");
                handleOnClose(true);
            })
            .catch((err) => {
                if (err.response.data.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    notify("Error updating user password " + err.response.data.error, "error");
                }
                onUserAddError(err.response.data.error || err.response.data.errors[0].message);
            });
    }

    function clearCamps() {
        setPassword("");
        setPasswordConfirm("");
        setErrors([]);
    }

    return (
        <CustomModal
            padding={2}
            open={open}
            title="Change password"
            onClose={() => {
                if (!passwordChanged) {
                    customConfirmAlert(() => {
                        onClose(false);
                    });
                } else {
                    handleOnClose(false);
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Change user password</Typography>
                </Grid>

                {self && (
                    <Grid item xs={12}>
                        <TextField
                            helperText={errorFieldMessage(errors, "oldPassword")}
                            error={errorFieldHandler(errors, "oldPassword")}
                            type="password"
                            label="Old password"
                            value={oldPassword}
                            fullWidth
                            onChange={(evt) => setOldPassword(evt.target.value)}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "password")}
                        error={errorFieldHandler(errors, "password")}
                        type="password"
                        label="Password"
                        value={password}
                        fullWidth
                        onChange={(evt) => {
                            setPasswordChanged(false);
                            setPassword(evt.target.value);
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "passwordConfirm")}
                        error={errorFieldHandler(errors, "passwordConfirm")}
                        type="password"
                        label="Password Confirm"
                        value={passwordConfirm}
                        fullWidth
                        onChange={(evt) => {
                            setPasswordChanged(false);
                            setPasswordConfirm(evt.target.value);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => updateUser()} fullWidth color="success">
                        {"Save"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
