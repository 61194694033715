import { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField, Autocomplete } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorFieldHandler, errorFieldMessage } from "../../helpers/errorHelper";
import customConfirmAlert from "../../components/confirmAlert";
import CustomModal from "../../components/CustomModal/CustomModal";

export default function EditCompany({ open, onClose, company, onCompanyUpdate = () => null }: any) {
    const [updated, setUpdated] = useState(false);

    const [companyName, setCompanyName] = useState(company?.name);
    const [companyAddress, setCompanyAddress] = useState(company?.mainAddress?.address);
    const [companyCity, setCompanyCity] = useState(company?.mainAddress?.city);
    const [companyState, setCompanyState] = useState(company?.mainAddress?.state);
    const [companyCounty, setCompanyCounty] = useState(company?.mainAddress?.county);
    const [companyZipCode, setCompanyZipCode] = useState(company?.mainAddress?.zipCode);

    const [errors, setErrors] = useState<any>([]);

    const [states, setStates] = useState([]);
    async function getStates() {
        setStates(await apiRequest.get(`/information/states`).then((res) => res.data));
    }

    async function updateOrganizacao() {
        await apiRequest
            .put("/organization/" + company.id + "", {
                name: companyName,
                mainAddress: {
                    address: companyAddress,
                    city: companyCity,
                    state: companyState,
                    zipCode: companyZipCode,
                    county: companyCounty,
                },
            })
            .then((res) => {
                setUpdated(true);
                onCompanyUpdate();
                notify("Organization updated", "success");
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors([...err.response.data.errors]);
                } else {
                    notify("Error editing company " + err?.response?.data?.error, "error");
                }
            });
    }

    function handleOnClose(bool: boolean) {
        if (updated) {
            onClose(bool);
            setErrors([]);
        } else {
            customConfirmAlert(() => {
                onClose(bool);
            });
        }
    }

    useEffect(() => {
        getStates();
    }, []);

    return (
        <CustomModal open={open} title="Edit Company" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="caption">Info</Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        helperText={errorFieldMessage(errors, "name")}
                        error={errorFieldHandler(errors, "name")}
                        label="Name"
                        value={companyName}
                        onChange={(evt) => setCompanyName(evt.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">Address</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        helperText={errorFieldMessage(errors, "mainAddress.address")}
                        error={errorFieldHandler(errors, "mainAddress.address")}
                        label="Address"
                        value={companyAddress}
                        fullWidth
                        onChange={(evt) => setCompanyAddress(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "mainAddress.city")}
                        error={errorFieldHandler(errors, "mainAddress.city")}
                        label="City"
                        value={companyCity}
                        fullWidth
                        onChange={(evt) => setCompanyCity(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    {states.length > 0 && (
                        <Autocomplete
                            value={states.filter((stt: any) => stt?.postalAbbreviation == companyState)[0]}
                            isOptionEqualToValue={(opt, val) => opt?.postalAbbreviation === val}
                            onChange={(event: any, newValue: any) => {
                                setCompanyState(newValue?.postalAbbreviation);
                            }}
                            options={states}
                            getOptionLabel={(opt: any) => opt?.name}
                            renderInput={(params: any) => <TextField error={errorFieldHandler(errors, "mainAddress.state")} {...params} label="State" />}
                        />
                    )}
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "mainAddress.county")}
                        error={errorFieldHandler(errors, "mainAddress.county")}
                        label="County"
                        value={companyCounty}
                        fullWidth
                        onChange={(evt) => setCompanyCounty(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "mainAddress.zipCode")}
                        error={errorFieldHandler(errors, "mainAddress.zipCode")}
                        label="ZipCode"
                        value={companyZipCode}
                        fullWidth
                        onChange={(evt) => {
                            if (evt.target.value.length < 6) {
                                setCompanyZipCode(evt.target.value);
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => updateOrganizacao()} fullWidth style={{ height: 55 }} color="success">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
