import { useEffect, useState } from "react";
import {
    Chip,
    Button,
    Collapse,
    Grid,
    Typography,
    TextField,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormGroup,
} from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorFieldHandler } from "../../helpers/errorHelper";
import BasicDatePicker from "../../components/DatePicker";
import CustomModal from "../../components/CustomModal/CustomModal";

export default function DevicesModal({ open, onClose, aeds, onInspectionAdded = () => {}, onInspectionAddError = () => {}, organizationId, locationId }: any) {
    const [activitiesCheck, setActivitiesCheck] = useState(0);

    function manageActivities(status: boolean) {
        status ? setActivitiesCheck((prevState) => prevState + 1) : setActivitiesCheck((prevState) => prevState - 1);
    }

    function handleOnClose(bool: boolean) {
        onClose(bool);
        clearCamps();
        setActivitiesCheck(0);
    }

    const [inspectorName, setInspectorName] = useState<any>("");
    const [inspectionDate, setInspectionDate] = useState<any>("");
    const [inspectionNotes, setInspectionNotes] = useState<any>("");
    const [inspectionStatus, setInspectionStatus] = useState<any>("");
    const [statusNeedsAttetion, setStatusNeedsAttetion] = useState<any>("RESPONSE_KIT_MISSING");

    const [currentAeds, setCurrentAeds] = useState(aeds || []);

    const [errors, setErrors] = useState<any>([]);

    async function addInspection() {
        if (activitiesCheck !== 3) {
            return notify("Ensure all responsibilities are checked", "alert");
        }

        await apiRequest
            .post("/aed/inspection/", {
                aedIds: [...currentAeds.map((aed: any) => aed.id)],
                inspectorName: inspectorName,
                inspectionStatus: inspectionStatus,
                inspectionDate: new Date(inspectionDate).toISOString().slice(0, 10),
                notes: inspectionNotes,
                statusNeedsAttetion: inspectionStatus === "NEEDS_ATTENTION" ? statusNeedsAttetion : null,
            })
            .then((res) => {
                notify("Inspection added", "success");
                handleOnClose(true);
                onInspectionAdded();
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    notify("Error adding inspection!  " + err?.response?.data?.error, "error");
                }
            });
    }

    function clearCamps() {
        setCurrentAeds([]);
        setErrors([]);
        setInspectorName("");
        setInspectionDate("");
        setInspectionNotes("");
        setInspectionStatus("");
        setStatusNeedsAttetion("RESPONSE_KIT_MISSING");
    }

    function removeAedFromList(aed: any) {
        const newAeds = currentAeds;
        newAeds.splice(currentAeds.indexOf(aed), 1);
        setCurrentAeds([...newAeds]);
    }

    useEffect(() => {
        if (Array.isArray(aeds) && open) {
            setCurrentAeds([...aeds]);
        }
    }, [aeds, open]);

    return (
        <CustomModal padding={2} open={open} title="Add inspection" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Add Inspections</Typography>
                </Grid>

                <Grid item marginTop={2} xs={12}>
                    <Typography variant="caption">You're adding the ispection to the following aeds:</Typography>
                </Grid>

                <Grid item marginBottom={4} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }} xs={12}>
                    {currentAeds.map((aed: any) => (
                        <Chip label={`${aed?.aedModel?.model} | ${aed?.placement} | ${aed?.ownerInfo?.locationName}`} onDelete={() => removeAedFromList(aed)} />
                    ))}
                </Grid>

                <Grid item xs={12}>
                    <FormGroup sx={{ gap: 2 }}>
                        <FormControlLabel
                            control={<Checkbox onChange={(e) => manageActivities(e.target.checked)} />}
                            label={
                                <label>
                                    <strong>AED:</strong> Audible, visual and manipulative inspection completed.
                                </label>
                            }
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={(e) => manageActivities(e.target.checked)} />}
                            label={
                                <label>
                                    <strong>Supplies:</strong> Inventory complete including electrodes (primary and back-up), kit containing mouth barrier,
                                    gloves, razor, scissors, towelette/gauze (minimum list).
                                </label>
                            }
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={(e) => manageActivities(e.target.checked)} />}
                            label={
                                <label>
                                    <strong>Storage:</strong> If AED is in rack or cabinet, is it visible? If in a cabinet, does it operate as designed and do
                                    you know where the key is?
                                </label>
                            }
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        helperText={errorFieldHandler(errors, "inspectorName")}
                        error={errorFieldHandler(errors, "inspectorName")}
                        label="Inspector name"
                        value={inspectorName}
                        fullWidth
                        onChange={(evt) => setInspectorName(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Inspection Status</InputLabel>
                        <Select
                            inputProps={{ helperText: errorFieldHandler(errors, "inspectionStatus") }}
                            error={errorFieldHandler(errors, "inspectionStatus")}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={inspectionStatus}
                            onChange={(evt) => setInspectionStatus(evt.target.value)}
                            label="Inspection Status"
                        >
                            <MenuItem value="READY">READY</MenuItem>
                            <MenuItem value="NEEDS_ATTENTION">NEEDS ATTENTION</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <BasicDatePicker
                        PickerProps={{ label: "Inspection Date" }}
                        value={inspectionDate}
                        onChange={(date) => {
                            setInspectionDate(date);
                        }}
                        InputProps={{ fullWidth: true, error: errorFieldHandler(errors, "inspectionDate") }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Collapse in={inspectionStatus == "NEEDS_ATTENTION"}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status needs attetion</InputLabel>
                            <Select
                                error={errorFieldHandler(errors, "statusNeedsAttetion")}
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select2"
                                value={statusNeedsAttetion}
                                onChange={(evt) => setStatusNeedsAttetion(evt.target.value)}
                                label="Status needs attetion"
                                disabled={inspectionStatus == "READY"}
                            >
                                <MenuItem value="RESPONSE_KIT_MISSING">Response kit missing</MenuItem>
                                <MenuItem value="PRIMARY_PADS_EXPIRED">Primary pads expired</MenuItem>
                                <MenuItem value="PRIMARY_PADS_MISSING">Primary pads missing</MenuItem>
                                <MenuItem value="BATTERY_NEEDS_TO_BE_REPLACED">Battery needs to be replaced</MenuItem>
                                <MenuItem value="NEED_BACKUP_PADS">Need backup pads</MenuItem>
                                <MenuItem value="AUDIBLE_OR_VISUAL_ALERTS_FOR_UNKNOWN_REASON">Audible or visual alerts for unknown reason</MenuItem>
                                <MenuItem value="CABINET_OR_RACK_ISSUE">Cabinet or rack issue</MenuItem>
                                <MenuItem value="OTHER">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Collapse>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        helperText={errorFieldHandler(errors, "notes")}
                        error={errorFieldHandler(errors, "notes")}
                        label="Notes"
                        value={inspectionNotes}
                        fullWidth
                        onChange={(evt) => setInspectionNotes(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => addInspection()} fullWidth color="success">
                        {"ADD"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
