import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaEdit, FaCircle } from "react-icons/fa";
import { Button, Grid, Typography, Box, Tooltip } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { motion } from "framer-motion";
import apiRequest from "../../helpers/apiRequest";
import RespondersModal from "./responders";
import AddInspection from "./addInspection";
import AddTraining from "./addTraining";
import EditShipTo from "./editShipTo";
import useAuth from "../../hooks/useAuth";
import DashboardLayout from "../../layouts/DashboardLayout";
import AedsModal from "../../components/new-aeds/modal";
import AedsModal2 from "../../components/aeds/modal";
import RespondersTable from "../../components/RespondersTable";
import Breadcrumbs from "../../components/Breadcrumbs";
import AedsTable from "../../components/AedsTable";
import InfoCollapse from "../../components/InfoCollapse";

export default function Location() {
    const { user }: any = useAuth();

    const { organizationId, id } = useParams();

    const [contentLoaded, setContentLoaded] = useState(false);
    const [location, setLocation] = useState<any | null>(null);
    const [aeds, setAeds] = useState<any>([]);

    const [responders, setResponders] = useState<any>([]);
    const [respondersInfo, setRespondersInfo] = useState<any>([]);
    const [respondersTablePage, setRespondersTablePage] = useState<number>(0);
    const [respondersTableSize, setRespondersTableSize] = useState<number>(10);
    const [respondersSortColumn, setRespondersSortColumn] = useState("");
    const [respondersSortOrder, setRespondersSortOrder] = useState("asc");

    const [aedsInfo, setAedsInfo] = useState<any>([]);
    const [aedsTablePage, setAedsTablePage] = useState<number>(0);
    const [aedsTableSize, setAedsTableSize] = useState<number>(10);
    const [aedsSortColumn, setAedsSortColumn] = useState("");
    const [aedsSortOrder, setAedsSortOrder] = useState("asc");

    async function getOrganizationInfo() {
        const locationInfo = await apiRequest.get(`/location/${id}`).then((res) => res.data);
        setLocation(locationInfo);

        const aedsInfo = await apiRequest
            .get(`/aed/location/${id}?page=${aedsTablePage}&size=${aedsTableSize}&sort=${aedsSortColumn}${aedsSortColumn !== "" ? "," + aedsSortOrder : ""}`)
            .then((res) => res.data)
            .catch((err) => {
                return [];
            });
        setAeds(aedsInfo.content);
        setAedsInfo(aedsInfo);

        await getRespondersInfo();
    }

    async function getRespondersInfo(
        page: number = respondersTablePage,
        size: number = respondersTableSize,
        sortColumn: string = respondersSortColumn,
        sortOrder: string = respondersSortOrder
    ) {
        const respondersInfo = await apiRequest
            .get(`/responder?locationId=${id}&page=${page}&size=${size}&sort=${sortColumn}${sortColumn !== "" ? "," + sortOrder : ""}`)
            .then((res) => res.data)
            .catch((err) => {
                return [];
            });
        setResponders(respondersInfo.content);
        setRespondersInfo(respondersInfo);

        setContentLoaded(true);
    }

    const [DevicesModalOpen, setDevicesModalOpen] = useState(false);
    const [RespondersModalOpen, setRespondersModalOpen] = useState(false);
    const [AddInspectionModalOpen, setAddInspectionModalOpen] = useState(false);
    const [AddTrainingModalOpen, setAddTrainingModalOpen] = useState(false);

    const [createMode, setCreateMode] = useState(false);

    const [manageMode, setManageMode] = useState("devices");

    const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<any>([]);

    function openDevicesModal(mode: string) {
        mode == "create" && setCreateMode(true);
        setDevicesModalOpen(true);
    }

    function openRespondersModal(mode: string) {
        mode == "create" ? setCreateMode(true) : setCreateMode(false);
        setRespondersModalOpen(true);
    }

    const [currentDevice, setCurrentDevice] = useState(undefined);
    const [currentResponder, setCurrentResponder] = useState(undefined);

    const [selectedAeds, setCurrentSelectedAeds] = useState<any>([]);
    const [selectedResponders, setCurrentSelectedResponders] = useState<any>([]);

    function setSelectedAeds() {
        const currentSelectedAeds = aeds.filter((aed: any, index: number) => {
            if (selectedRowsIndexes.indexOf(index) !== -1) {
                return aed;
            }
        });

        setCurrentSelectedAeds([...currentSelectedAeds]);
    }

    useEffect(() => {
        setCurrentSelectedAeds([]);
        setCurrentSelectedResponders([]);
        setSelectedRowsIndexes([]);
    }, [manageMode]);

    useEffect(() => {
        (async () => getOrganizationInfo())();
    }, [aedsTablePage, aedsTableSize, aedsSortColumn, aedsSortOrder]);

    function setSelectedResponders() {
        const currentSelectedResponders = responders.filter((aed: any, index: number) => {
            if (selectedRowsIndexes.indexOf(index) !== -1) {
                return aed;
            }
        });
        setCurrentSelectedResponders([...currentSelectedResponders]);
    }

    useEffect(() => {
        setSelectedAeds();
        setSelectedResponders();
    }, [selectedRowsIndexes]);

    const [editShipToOpen, setEditShipToOpen] = useState(false);

    const [siteProfiles, setSiteProfiles] = useState<any>(null);

    const getSiteProfiles = () => {
        apiRequest.get(`/user/users-position-location/${id}`).then((res) => setSiteProfiles(res.data));
    };

    useEffect(() => {
        getOrganizationInfo();
        getSiteProfiles();
    }, [id]);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true, organization: { org: true } }} currentPage="Location" />
            {contentLoaded && (
                <motion.div key="Location" initial={{ scale: 0.97, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.97, opacity: 0 }}>
                    <Grid container padding={1} spacing={2}>
                        <AedsModal
                            location={location}
                            addMode
                            open={DevicesModalOpen}
                            onClose={() => {
                                getOrganizationInfo();
                                setDevicesModalOpen(false);
                            }}
                        />

                        <RespondersModal
                            onResponderAdded={() => {
                                getOrganizationInfo();
                            }}
                            onResponderAddError={(message: any) => getOrganizationInfo()}
                            createMode={createMode}
                            organizationId={location?.organization?.id}
                            locationId={id}
                            open={RespondersModalOpen}
                            onClose={() => setRespondersModalOpen(false)}
                            responder={currentResponder}
                        />

                        <AddInspection
                            onInspectionAdded={() => {
                                getOrganizationInfo();
                            }}
                            onInspectionAddError={(message: any) => {}}
                            aeds={selectedAeds}
                            open={AddInspectionModalOpen}
                            onClose={() => setAddInspectionModalOpen(false)}
                            locationId={location?.id}
                            organizationId={location?.organization?.id}
                        />

                        <AddTraining
                            onInspectionAdded={() => {
                                getOrganizationInfo();
                            }}
                            onInspectionAddError={(message: any) => {}}
                            responders={[...selectedResponders]}
                            open={AddTrainingModalOpen}
                            onClose={() => setAddTrainingModalOpen(false)}
                            locationId={location?.id}
                            organizationId={location?.organization?.id}
                        />

                        <Grid item xs={12}>
                            <Typography variant="h4" fontWeight="bold">
                                {location?.name}
                            </Typography>
                        </Grid>

                        {!user?.userProfiles?.siteStaff && (
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Address : {`${location.address.address} - ${location.address.city}, ${location.address.state} ${location.address.zipCode} `}
                                </Typography>
                                <Typography variant="body1">
                                    Ship To :{" "}
                                    {`${location.shipTo?.name} (${location?.shipTo?.fone})  |  ${location?.shipTo?.address?.address} - ${location?.shipTo?.address.city}, ${location?.shipTo?.address.state} ${location?.shipTo?.address.zipCode} `}{" "}
                                    <FaEdit onClick={() => setEditShipToOpen(true)} style={{ marginBottom: -2, marginLeft: 10, cursor: "pointer" }} />{" "}
                                </Typography>
                                {location && (
                                    <EditShipTo
                                        location={location}
                                        onEdit={() => {
                                            setEditShipToOpen(false);
                                            getOrganizationInfo();
                                        }}
                                        onClose={() => setEditShipToOpen(false)}
                                        open={editShipToOpen}
                                    />
                                )}
                            </Grid>
                        )}

                        <Grid item xs={12} marginTop={4}>
                            <Button
                                variant={manageMode == "devices" ? "contained" : "outlined"}
                                onClick={() => setManageMode("devices")}
                                color="secondary"
                                sx={{ marginRight: 1 }}
                            >
                                {" "}
                                Manage Devices
                            </Button>
                            <Button
                                variant={manageMode == "responders" ? "contained" : "outlined"}
                                onClick={() => setManageMode("responders")}
                                color="secondary"
                            >
                                Manage Responders
                            </Button>
                        </Grid>

                        <Grid item xs={12} marginTop={1}>
                            {manageMode == "devices" ? (
                                <Grid item xs={12} sx={{}}>
                                    {currentDevice && (
                                        <AedsModal2
                                            aed={currentDevice}
                                            open={DevicesModalOpen}
                                            closeAfterEdit={true}
                                            onClose={() => {
                                                setCurrentDevice(undefined);
                                                openDevicesModal("create");
                                                setDevicesModalOpen(false);
                                                getOrganizationInfo();
                                            }}
                                        />
                                    )}

                                    <AedsTable
                                        onRowSelectionChange={(rows) => setSelectedRowsIndexes(rows)}
                                        onAedEdit={(aed) => {
                                            setCurrentDevice(aed);
                                            openDevicesModal("edit");
                                        }}
                                        organizationId={organizationId}
                                        locationId={id}
                                        rows={[...aeds]}
                                        aedsInfo={aedsInfo}
                                        setAedsTablePage={setAedsTablePage}
                                        setAedsTableSize={setAedsTableSize}
                                        setSortColumn={setAedsSortColumn}
                                        setSortOrder={setAedsSortOrder}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} sx={{}}>
                                    <RespondersTable
                                        onRowSelectionChange={(rows) => setSelectedRowsIndexes(rows)}
                                        onAedEdit={(responder) => {
                                            setCurrentResponder(responder);
                                            openRespondersModal("edit");
                                        }}
                                        organizationId={organizationId}
                                        locationId={id}
                                        rows={[...responders]}
                                        respondersInfo={respondersInfo}
                                        setRespondersTablePage={setRespondersTablePage}
                                        setRespondersTableSize={setRespondersTableSize}
                                        setSortColumn={setRespondersSortColumn}
                                        setSortOrder={setRespondersSortOrder}
                                        getRespondersInfo={getRespondersInfo}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
                                {user?.userProfiles?.siteStaff ? (
                                    <>
                                        {manageMode === "responders" && (
                                            <Button variant="contained" onClick={() => openRespondersModal("create")} sx={{ height: 40, flexGrow: 1 }}>
                                                ADD NEW {manageMode.slice(0, manageMode.length - 1)}
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={() => (manageMode == "devices" ? openDevicesModal("create") : openRespondersModal("create"))}
                                        sx={{ height: 40, flexGrow: 1 }}
                                    >
                                        ADD NEW {manageMode.slice(0, manageMode.length - 1)}
                                    </Button>
                                )}

                                <Tooltip title={manageMode == "devices" ? "Select a device to add the inspection" : "Select a responder to add a training"}>
                                    <Button
                                        variant={selectedRowsIndexes.length > 0 ? "contained" : "outlined"}
                                        onClick={() => {
                                            if (selectedRowsIndexes.length > 0) {
                                                return manageMode == "devices" ? setAddInspectionModalOpen(true) : setAddTrainingModalOpen(true);
                                            }
                                        }}
                                        sx={{ height: 40, flexGrow: 1 }}
                                    >
                                        {manageMode == "devices" ? "ADD INSPECTION" : "ADD TRAINING"}
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <StatusLegend />
                        </Grid>

                        {siteProfiles?.sitesSupervisor?.length > 0 && SiteProfileInfo("Sites Supervisor", siteProfiles?.sitesSupervisor)}

                        {siteProfiles?.siteCoordinator?.length > 0 && SiteProfileInfo("Site Coordinator", siteProfiles?.siteCoordinator)}

                        {siteProfiles?.siteStaff?.length > 0 && SiteProfileInfo("Site Staff", siteProfiles?.siteStaff)}
                    </Grid>
                </motion.div>
            )}
        </DashboardLayout>
    );
}

function StatusLegend({}) {
    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 1,
                    border: "1px solid rgba(0,0,0,0.1)",
                    marginTop: 10,
                    flexWrap: "wrap",
                    padding: 0.5,
                }}
            >
                <>
                    <FaCircle color="#00BF41" />{" "}
                    <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                        Ok
                    </Typography>
                </>
                <>
                    <FaCircle color="#FFA800" />{" "}
                    <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                        Needs Attention
                    </Typography>
                </>
                <>
                    <FaCircle color="#2a5886" />{" "}
                    <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                        Due now
                    </Typography>
                </>
                <>
                    <FaCircle color="#FF0000" />{" "}
                    <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                        Overdue
                    </Typography>
                </>
                <>
                    <FaCircle color="#454545" />{" "}
                    <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                        Disabled/Inactive
                    </Typography>
                </>
            </Box>
        </Grid>
    );
}

function SiteProfileInfo(title: string, data: any) {
    return (
        <Grid item xs={12} md={4}>
            <InfoCollapse title={title}>
                {data?.map((userProfile: any, index: number) => (
                    <Grid container key={index} sx={{ padding: 2, width: "100%", flexDirection: "column" }}>
                        <Typography variant="body1" fontWeight="bold">
                            {userProfile?.name}
                            {userProfile?.notificationProfile && (
                                <NotificationsActiveOutlinedIcon color="primary" fontSize="small" style={{ marginLeft: 4, paddingTop: 2 }} />
                            )}
                        </Typography>
                        <Grid sx={{ display: "flex" }}>
                            <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                Phone:{" "}
                            </Typography>
                            <Typography variant="body2">{userProfile?.phone}</Typography>
                        </Grid>
                        <Grid sx={{ display: "flex" }}>
                            <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                Email:{" "}
                            </Typography>
                            <Typography variant="body2">{userProfile?.email}</Typography>
                        </Grid>
                    </Grid>
                ))}
            </InfoCollapse>
        </Grid>
    );
}
