import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { motion } from "framer-motion";
import apiRequest from "../../helpers/apiRequest";
import { AuthContext } from "../../context/AuthContext";
import DashboardLayout from "../../layouts/DashboardLayout";
import MuiTable from "../../components/MuiTable";
import InfoCollapse from "../../components/InfoCollapse";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function Supervisor() {
    const [contentLoaded, setContentLoaded] = useState(true);
    const { user }: any = useContext(AuthContext);

    const navigate = useNavigate();

    const columns = [
        {
            label: "Address",
            name: "address_address",
            options: { display: false },
        },
        {
            label: "Address",
            name: "address_city",
            options: { display: false },
        },
        {
            label: "Address",
            name: "address_state",
            options: { display: false },
        },
        {
            label: "Address",
            name: "address_zipCode",
            options: { display: false },
        },
        {
            label: "id",
            name: "id",
            options: { display: false },
        },
        {
            label: "Name",
            name: "name",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => navigate(`/organization/${user.organization.id}/location/${tableMeta.tableData[tableMeta.rowIndex][4]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
            },
        },
        {
            label: "Address",
            name: "address_address",
            options: {
                customBodyRender: (value: any, tableMeta: any, updatedValue: any) => (
                    <span>{`${tableMeta.tableData[tableMeta.rowIndex][0] || ""} - ${tableMeta.tableData[tableMeta.rowIndex][1] || ""}, ${
                        tableMeta.tableData[tableMeta.rowIndex][2] || ""
                    } ${tableMeta.tableData[tableMeta.rowIndex][3] || ""}`}</span>
                ),
            },
        },
        {
            label: "AEDs Quantity",
            name: "aedsQuantity",
        },
    ];

    const [staffList, setStaffList] = useState<any>(null);

    async function getSupervisorView() {
        await apiRequest.get("/information/site-coordinator-view").then((res) => {
            setStaffList(res.data);
        });
    }

    useEffect(() => {
        getSupervisorView();
    }, []);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="Coordinator View" />
            {contentLoaded && (
                <motion.div key="Organization" initial={{ scale: 0.97, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.97, opacity: 0 }}>
                    <Grid container padding={2} paddingTop={3} spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ cursor: "default" }} variant="caption" color="#2a5886">
                                Welcome,
                            </Typography>
                            <Typography variant="h4" fontWeight="bold">
                                {user?.firstName} {user?.lastName}.
                            </Typography>
                            <Typography sx={{ cursor: "default", fontWeight: "bold" }} variant="h6" color="primary">
                                Locations
                                <LocationOnIcon sx={{ height: 20, transform: "translate(-4px, 2px)" }} />
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <MuiTable
                                noActions
                                search={""}
                                noView
                                noEdit
                                pagination
                                paginationURL={`/location/by-user`}
                                noSelect
                                onView={(loc: any) => navigate("/organization/" + 3 + "/location/" + loc.id)}
                                columns={columns}
                            />
                        </Grid>

                        {staffList?.programCoordinator.length > 0 && (
                            <Grid item xs={12} md={6} lg={4}>
                                <InfoCollapse title="Program Cordinator">
                                    {staffList?.programCoordinator?.map((programCoordinator: any) => (
                                        <Grid container sx={{ padding: 2, width: "100%", flexDirection: "column" }}>
                                            <Typography variant="body1" fontWeight="bold">
                                                {programCoordinator?.fullName}
                                            </Typography>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Phone:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.fone}</Typography>
                                            </Grid>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Email:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.email}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </InfoCollapse>
                            </Grid>
                        )}

                        {staffList?.programStaff.length > 0 && (
                            <Grid item xs={12} md={6} lg={4}>
                                <InfoCollapse title="Program Staff">
                                    {staffList?.programStaff?.map((programCoordinator: any) => (
                                        <Grid container sx={{ padding: 2, width: "100%", flexDirection: "column" }}>
                                            <Typography variant="body1" fontWeight="bold">
                                                {programCoordinator?.fullName}
                                            </Typography>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Phone:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.fone}</Typography>
                                            </Grid>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Email:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.email}</Typography>
                                            </Grid>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Location:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.locationName}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </InfoCollapse>
                            </Grid>
                        )}

                        {staffList?.sitesSupervisor.length > 0 && (
                            <Grid item xs={12} md={6} lg={4}>
                                <InfoCollapse title="Sites Supervisor">
                                    {staffList?.sitesSupervisor?.map((programCoordinator: any) => (
                                        <Grid container sx={{ padding: 2, width: "100%", flexDirection: "column" }}>
                                            <Typography variant="body1" fontWeight="bold">
                                                {programCoordinator?.fullName}
                                            </Typography>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Phone:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.fone}</Typography>
                                            </Grid>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Email:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.email}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </InfoCollapse>
                            </Grid>
                        )}
                    </Grid>
                </motion.div>
            )}
        </DashboardLayout>
    );
}
