import { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Typography, Checkbox } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import { ForecastTable } from "./components/forecast-table";
import BasicDatePicker from "../../components/DatePicker";
import { useForecastContainer } from "./forecast-container";
import { DateHelper } from "../../helpers/dateHelper";
import { notify } from "../../helpers/notify";
import Breadcrumbs from "../../components/Breadcrumbs";

const ForecastPage = () => {
    const [forecastList, setForecastList] = useState<any>(null);
    const { getForecastList } = useForecastContainer();

    const onGetForecastClick = (start: string, end: string, isDefault: boolean) => getForecast(start.slice(0, 10), end.slice(0, 10), isDefault);

    const cleatForecastList = () => {
        setForecastList(null);
    };

    const getForecast = async (start?: string, end?: string, isDefault?: boolean) => {
        const forecastInfo = await getForecastList(start, end, isDefault);

        if (forecastInfo.errors) {
            forecastInfo.errors.forEach((error: any) => {
                notify(error.message, "error");
            });

            return;
        }

        cleatForecastList();
        setForecastList(forecastInfo);
    };

    useEffect(() => {
        getForecast();
    }, []);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="Forecast Subscription" />
            <Typography sx={{ marginTop: 2, marginBottom: 2, color: "rgba(0,0,0,0.7)" }} variant="h4" fontWeight="bold">
                Forecast Subscription
            </Typography>

            <ForecastDateSelector onGetForecastClick={onGetForecastClick} />

            {forecastList ? (
                <ForecastTable forecastList={forecastList} />
            ) : (
                <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size={30} color="primary" />
                </Box>
            )}
        </DashboardLayout>
    );
};

function ForecastDateSelector({ onGetForecastClick = (start: string, end: string, isDefault: boolean) => {} }) {
    const [startDate, setStartDate] = useState("2019-04-14");
    const [endDate, setEndDate] = useState(DateHelper.toLocalISOString(new Date()).slice(0, 10));
    const [isDefaultChecked, setIsDefaultCheck] = useState<boolean>(true);

    const onGetForecast = () => {
        onGetForecastClick(startDate, endDate, isDefaultChecked);
    };

    return (
        <Box sx={{ display: "flex", gap: 2 }}>
            <BasicDatePicker
                value={startDate}
                onChange={(date: any) => setStartDate(DateHelper.toLocalISOString(new Date(date)))}
                InputProps={{ size: "small" }}
                isDisabled={isDefaultChecked}
            />
            <BasicDatePicker
                value={endDate}
                onChange={(date: any) => setEndDate(DateHelper.toLocalISOString(new Date(date)))}
                InputProps={{ size: "small" }}
                isDisabled={isDefaultChecked}
            />
            <Box title={"Retrieves data with no date range"}>
                <Typography variant="overline">Default</Typography>
                <Checkbox checked={isDefaultChecked} onChange={() => setIsDefaultCheck((state) => !state)} />
            </Box>
            <Button variant="contained" onClick={onGetForecast}>
                GET FORECAST
            </Button>
        </Box>
    );
}

export default ForecastPage;
