import { useContext } from "react";
import { IoExitOutline } from "react-icons/io5";
import { Box, Grid, Typography } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";

import Logo from "../../images/Logo_2.png";

export default function Header({ style = {} }) {
    const { logOut, user } = useContext(AuthContext);

    return (
        <Grid
            style={{
                ...style,
                minHeight: 70,
                height: 70,
                boxShadow: "0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%)",
                padding: 10,
                backgroundColor: "#FFFF",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
            }}
        >
            <img src={Logo} width={175} height={55} style={{ objectFit: "contain", marginRight: 30, marginLeft: 15 }} />

            <Typography variant="subtitle1" fontWeight="bold" color="#000">
                AED Program Management
            </Typography>

            <Grid style={{ display: "flex", flexGrow: 1 }}></Grid>

            <Grid style={{ display: "flex", marginRight: 20 }}>
                <Typography
                    sx={{ border: "1px solid #0a4176", padding: 0.2, paddingX: 1.5, borderRadius: 20, cursor: "default" }}
                    variant="caption"
                    color="#000"
                >{`${user?.firstName} ${user?.lastName.slice(0, 1)}.  |  ${user?.organization?.name}`}</Typography>
            </Grid>

            <Box
                onClick={() => logOut()}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginRight: 2,
                    marginLeft: 1,
                    cursor: "pointer",
                }}
            >
                <IoExitOutline style={{ padding: 5, justifySelf: "flex-end" }} size={30} color="#000" />
                <Typography fontSize={10} variant="caption" sx={{ marginTop: -1 }} color="black">
                    Logout
                </Typography>
            </Box>
        </Grid>
    );
}
