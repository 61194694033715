export class DateHelper {
    public static toLocalISOString(dateObj: Date) {
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();

        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();

        return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}T${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
        }:00`;
    }

    public static dateToString(date: string) {
        let dateString = date.split("-");
        dateString[1] = dateString[1].padStart(2, "0"); //month
        dateString[2] = dateString[2].padStart(2, "0"); //day

        return dateString.join("-");
    }

    /**
     * Does not include timezone
     * @param date
     * @returns
     */
    public static stringToGMT(date: string): string {
        return new Date(date).toISOString().split("T")[0];
    }
}
