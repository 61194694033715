import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FaCheck, FaEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import apiRequest from "../../../../helpers/apiRequest";
import { DateFormat } from "../../../../helpers/DateFormat";
import { errorHandler, errorProps, errorFieldMessage } from "../../../../helpers/errorHelper";
import { notify } from "../../../../helpers/notify";
import BasicDatePicker from "../../../DatePicker";
import Accessories from "../../accessory";
import ModelSelect from "../modelSelect";
import ModelSelectList from "../modelSelectList";
import useAuth from "../../../../hooks/useAuth";
import { format } from "date-fns";

export default function EditAED({ aed, onEdit = () => {}, onSave = null }) {
    const { organizationId } = useParams();

    const [selectAEDModal, setSelectAedModal] = useState(false);

    const [anchorAED, setAnchorAED] = useState(aed);

    const [selectedAEDModel, setSelectedAEDModel] = useState(null);
    const [serial, setSerial] = useState(null);
    const [warrantyDate, setWarrantyDate] = useState(null);
    const [dateInService, setDateInService] = useState(null);
    const [placement, setPlacement] = useState(null);
    const [location, setLocation] = useState(null);
    const [accessories, setAccessories] = useState(null);

    const [locationsList, setLocationsList] = useState([]);
    async function getOrganizationLocations() {
        const locationsRes = await apiRequest.get(`/location/all/${organizationId}`).then((res) => res.data);
        setLocationsList(locationsRes);
    }

    async function aedUpdateHandler() {
        try {
            if (aed?.ownerInfo?.locationId !== location?.id && location !== null) {
                try {
                    await updateLocation();
                } catch (e) {
                    return null;
                }
            }

            if (placement !== anchorAED?.placement) {
                await updatePlacement();
            }

            if (serial !== anchorAED?.serial || dateInService !== anchorAED?.purchaseDate) {
                await updateAED();
            }

            await apiRequest.get("/aed/" + aed?.id).then((res) => {
                setAnchorAED(res.data);
            });

            return onEdit();
        } catch (e) {
            return null;
        }
    }

    const [errors, setErrors] = useState([]);

    async function updateLocation() {
        if (!placement) {
            notify("You need to set a placement in order to change the location", "error");
            throw new Error("No placement defined");
        }

        if (aed?.ownerInfo?.locationId !== null) {
            return await apiRequest
                .put("/aed/swipe-aed", {
                    aedId: aed.id,
                    originLocationId: aed?.ownerInfo?.locationId,
                    destinationLocationId: location?.id,
                })
                .then((res) => notify(`AED Moved to ${location?.name}`, "success"))
                .catch((err) => {
                    const errorMsg = err.response.data?.message ?? err.response.data?.errors[0]?.message;
                    setLocation({ id: aed?.ownerInfo?.locationId });
                    notify(errorMsg, "error");
                    throw new Error("Error changing aed location");
                });
        }

        await apiRequest
            .post("/aed/addlocation", {
                aedId: aed.id,
                locationId: location?.id,
                organizationId: organizationId,
                placement: placement,
            })
            .then((res) => {
                notify("Aed location set", "success");
            })
            .catch((err) => {
                setLocation({ id: aed?.ownerInfo?.locationId });
                notify("Error changing aed location", "error");
                throw new Error("Error changing aed location");
            });
    }

    async function updateAED() {
        await apiRequest
            .put(`/aed/${aed?.id}`, {
                aedModelId: selectedAEDModel.id,
                serial: serial,
            })
            .then((res) => {
                notify("AED info updated", "success");
            })
            .catch((err) => {
                setErrors(errorHandler(err));
                throw new Error("Error updating aed info");
            });
    }

    async function updatePlacement() {
        await apiRequest
            .put(`/aed/${aed?.id}/updateplacement`, {
                placement: placement,
            })
            .then((res) => {
                notify("Placement updated", "success");
            })
            .catch((err) => {
                setErrors(errorHandler(err));
                throw new Error("Error updating aed placement");
            });
    }

    async function updateWarranty() {
        await apiRequest
            .put(`/aed/${aed?.id}/updatewarranty`, {
                warrantyDate: warrantyDate ? new Date(warrantyDate).toISOString().slice(0, 10) : null,
            })
            .then((res) => {
                notify("Warranty updated", "success");
                setWarrantyDate(res?.data?.warrantyDate);
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    async function updateDateInService() {
        const purchaseDate = dateInService ? format(dateInService, "yyyy-MM") + "-01" : null;

        await apiRequest
            .put(`/aed/${aed?.id}/purchase-date`, {
                purchaseDate,
            })
            .then((res) => {
                notify("Purchase date updated", "success");
                setDateInService(purchaseDate);
                setWarrantyDate(res.data?.warrantyDate);
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    useEffect(() => {
        getOrganizationLocations();

        setSelectedAEDModel(aed?.aedModel);
        setSerial(aed?.serial);
        setWarrantyDate(aed?.warrantyDate);
        setDateInService(aed?.purchaseDate);
        setPlacement(aed?.placement);
        setLocation({ id: aed?.ownerInfo?.locationId });
        setAccessories(aed?.accessories);
    }, []);

    // Detects if user made any changes - if so, a confirm modal will popup if changes aren't saved
    const handleOnEdit = () => {
        if (onSave.updated) {
            onSave.setUpdated(false);
        }
    };

    const purchaseDateLabel = (date) => {
        if (date == null) return "";

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/; // yyyy-MM-dd
        const dateObj = new Date(date);

        if (dateRegex.test(date)) {
            const dateArr = date.split("-");
            const month = parseInt(dateArr[1]) - 1;

            dateObj.setFullYear(dateArr[0], month, 1);
        }

        return months[dateObj.getMonth()] + " " + dateObj.getFullYear();
    };

    return (
        <AnimatePresence>
            <Typography variant="h6">Edit AED</Typography>

            {!selectAEDModal && (
                <motion.div key="normal">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ModelSelect aed={selectedAEDModel} onClickToChange={() => setSelectAedModal(true)} isEdit={true} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3}>
                            <ValueToEdit label="Serial number" value={serial}>
                                <TextField
                                    fullWidth
                                    {...errorProps(errors, "serial")}
                                    value={serial}
                                    onChange={(e) => {
                                        setSerial(e.target.value);
                                        handleOnEdit();
                                    }}
                                    size="small"
                                    variant="outlined"
                                    label="Serial number"
                                />
                            </ValueToEdit>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3}>
                            <ValueToEditWithConfirm onConfirm={() => updateDateInService()} label="Purchase Date" value={purchaseDateLabel(dateInService)}>
                                <BasicDatePicker
                                    value={dateInService}
                                    onChange={(date) => {
                                        setDateInService(date);
                                        handleOnEdit();
                                    }}
                                    InputProps={{ size: "small", label: "Purchase Date" }}
                                    PickerProps={{
                                        views: ["month", "year"],
                                        inputFormat: "MM/yyyy",
                                    }}
                                    placeholder="Month/Year"
                                />
                            </ValueToEditWithConfirm>
                            <FormHelperText error>{errorFieldMessage(errors, "purchaseDate")}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3}>
                            <ValueToEditWithConfirm onConfirm={() => updateWarranty()} label="Warranty Expiration" value={DateFormat(warrantyDate)}>
                                <BasicDatePicker
                                    {...errorProps(errors, "warrantyDate")}
                                    value={warrantyDate}
                                    onChange={(date) => {
                                        setWarrantyDate(date);
                                        handleOnEdit();
                                    }}
                                    InputProps={{ size: "small", label: "Warranty Expiration" }}
                                />
                            </ValueToEditWithConfirm>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3}>
                            <ValueToEdit label="Placement" value={placement}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    {...errorProps(errors, "placement")}
                                    value={placement}
                                    onChange={(e) => {
                                        setPlacement(e.target.value);
                                        handleOnEdit();
                                    }}
                                    variant="outlined"
                                    label="Placement"
                                />
                            </ValueToEdit>
                        </Grid>

                        {accessories !== null && (
                            <Accessories
                                aedModel={aed?.aedModel}
                                {...errorProps(errors, "accessories")}
                                aedId={aed?.id}
                                editMode
                                accessories={accessories}
                                onAccessoriesListChange={(list) => {
                                    setAccessories(list);
                                    // handleOnEdit();
                                }}
                            />
                        )}

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                <Select
                                    defaultValue={aed?.ownerInfo?.locationId}
                                    MenuProps={{ sx: { maxHeight: 250 } }}
                                    label="Location"
                                    fullWidth
                                    value={location?.id}
                                    onChange={(e) => {
                                        setLocation(locationsList.find((loc) => loc.id == e.target.value));
                                        handleOnEdit();
                                    }}
                                >
                                    {locationsList?.map((loc) => (
                                        <MenuItem key={loc?.id} value={loc?.id}>
                                            {loc?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Button onClick={() => aedUpdateHandler()} variant="contained" fullWidth>
                                SAVE AED
                            </Button>
                        </Grid>
                    </Grid>
                </motion.div>
            )}

            {selectAEDModal && (
                <motion.div key="modelSelect" initial={{ x: -100 }} animate={{ x: 0 }} exit={{ x: 0 }}>
                    <ModelSelectList
                        onSelect={(aedModel) => {
                            setSelectAedModal(false);
                            setSelectedAEDModel(aedModel);
                            handleOnEdit();
                        }}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
}

function ValueToEdit({ children, value = "", label = "label" }) {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <>
            {isEditing ? (
                <div style={{ marginTop: 14, display: "flex" }}>{children}</div>
            ) : (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 1 }}>
                    <Box>
                        <Typography variant="caption">{label} </Typography>
                        <Typography sx={{ minHeight: 23 }}>{value}</Typography>
                    </Box>
                    <FaEdit onClick={() => setIsEditing(true)} size={20} style={{ padding: 3, cursor: "pointer" }} />
                </Box>
            )}
        </>
    );
}

function ValueToEditWithConfirm({ children, value = "", label = "label", onConfirm = () => {} }) {
    const [isEditing, setIsEditing] = useState(false);
    const { user } = useAuth();

    const handleConfirm = () => {
        onConfirm();
        setIsEditing(false);
    };

    return (
        <>
            {isEditing ? (
                <div style={{ marginTop: 14, display: "flex" }}>
                    {children ?? <></>}
                    <Button
                        onClick={() => handleConfirm()}
                        sx={{
                            backgroundColor: "#00A878",
                            borderTopRightRadius: 50,
                            borderBottomRightRadius: 50,
                            padding: 0,
                            marginLeft: -0.1,
                            "&:hover": { backgroundColor: "#00CC92" },
                        }}
                    >
                        <FaCheck color="white" />
                    </Button>
                </div>
            ) : (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 1 }}>
                    <Box>
                        <Typography variant="caption">{label} </Typography>
                        <Typography sx={{ minHeight: 23 }}>{value}</Typography>
                    </Box>
                    {(user.userProfiles.systemAdmin || user.userProfiles.franchise) && (
                        <FaEdit onClick={() => setIsEditing(true)} size={20} style={{ padding: 3, cursor: "pointer" }} />
                    )}
                </Box>
            )}
        </>
    );
}
