import { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField, Select, MenuItem } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { DateHelper } from "../../helpers/dateHelper";
import { errorHandler, errorProps } from "../../helpers/errorHelper";
import NewClassTypeOther from "../class-types/newClassTypeOther";
import CustomModal from "../../components/CustomModal/CustomModal";
import BasicDatePicker from "../../components/DatePicker";

export default function AddTrainingModal({
    open,
    onClose,
    responders,
    onInspectionAdded = () => {},
    onInspectionAddError = () => {},
    organizationId,
    locationId,
}: any) {
    function handleOnClose(bool: boolean) {
        onClose(bool);
        clearCamps();
    }

    const [trainingExpirationDate, setTrainingExpirationDate] = useState<any>("");
    const [trainingNotes, setTrainingNotes] = useState<any>("");
    const [classTypes, setClassTypes] = useState<any>([]);
    const [currentResponders, setCurrentResponders] = useState(responders || []);
    const [currentClassType, setCurrentClassType] = useState<any>(null);

    const [errors, setErrors] = useState<any>([]);

    async function getClassTypes(otherName: string | null = null) {
        const classTypesInfo = await apiRequest.get("/classtype").then((res) => res.data);
        setClassTypes([...classTypesInfo]);
        if (otherName) {
            setCurrentClassType(classTypesInfo.find((classType: any) => classType.type == otherName));
        } else {
            setCurrentClassType(classTypesInfo[0]);
        }
    }

    async function addTraining() {
        const promises = currentResponders.map((responder: any) => {
            return apiRequest.put("/responder/add-training/", {
                responderId: responder?.id,
                classTypeId: currentClassType?.id,
                expirationDate: DateHelper.toLocalISOString(new Date(trainingExpirationDate)).slice(0, 10),
                notes: trainingNotes,
            });
        });

        Promise.all(promises)
            .then((res) => {
                onInspectionAdded();
                handleOnClose(true);
                notify("Training added to the responder", "success");
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    function clearCamps() {
        setTrainingExpirationDate("");
        setTrainingNotes("");
        setCurrentClassType(classTypes[0]);
        setCurrentResponders([]);
        setErrors([]);
    }

    useEffect(() => {
        if (Array.isArray(responders) && open) {
            setCurrentResponders([...responders]);
        }
    }, [responders, open]);

    useEffect(() => {
        getClassTypes();
    }, []);

    const [newClassTypeOpen, setNewClassTypeOpen] = useState(false);

    return (
        <CustomModal padding={2} open={open} title="Add Training" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Add Training</Typography>
                </Grid>

                <NewClassTypeOther
                    onUserAdded={(classTypeName: string) => getClassTypes(classTypeName)}
                    open={newClassTypeOpen}
                    onClose={() => {
                        setNewClassTypeOpen(false);
                    }}
                />

                <Grid item xs={12} md={6}>
                    <Select
                        MenuProps={{
                            sx: { maxHeight: 250 },
                        }}
                        {...errorProps(errors, "classTypeId")}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={currentClassType}
                        label="Class Type"
                        onChange={(evt) => {
                            if (evt.target.value === "other") {
                                setNewClassTypeOpen(true);
                            } else {
                                setCurrentClassType(evt.target.value);
                            }
                        }}
                    >
                        {classTypes.map((classType: any) => (
                            <MenuItem key={classType.id} value={classType}>
                                {classType.type}
                            </MenuItem>
                        ))}

                        <MenuItem key={"other"} value={"other"}>
                            Other
                        </MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                    <BasicDatePicker
                        {...errorProps(errors, "expirationDate")}
                        PickerProps={{ label: "Expiration Date" }}
                        value={trainingExpirationDate}
                        onChange={(date) => {
                            setTrainingExpirationDate(date);
                        }}
                        InputProps={{ fullWidth: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Notes"
                        {...errorProps(errors, "notes")}
                        value={trainingNotes}
                        fullWidth
                        onChange={(evt) => setTrainingNotes(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => addTraining()} fullWidth color="success">
                        {"ADD"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
