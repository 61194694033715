import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Autocomplete,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import usLocale from "date-fns/locale/en-US";
import CustomModal from "../../components/CustomModal/CustomModal";
import BasicDatePicker from "../../components/DatePicker";
import customConfirmAlert from "../../components/confirmAlert";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { DateHelper } from "../../helpers/dateHelper";
import { errorFieldHandler, errorFieldMessage } from "../../helpers/errorHelper";

const AfterTheRescueModal = ({ open, onClose }) => {
    const { organizationId } = useParams();
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [aedOptions, setAedOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [hadAnyChanges, setHadAnyChanges] = useState(false);

    function handleChange(e) {
        !hadAnyChanges && setHadAnyChanges(true);

        const newForm = form;

        if (e.target.name.indexOf(".") !== -1) {
            const entities = e.target.name.split(".");

            if (entities.length == 2) {
                if (newForm[entities[0]] === undefined) {
                    newForm[entities[0]] = {};
                }
                newForm[entities[0]][entities[1]] = e.target.value;
            }
        } else {
            newForm[e.target.name] = e.target.value;
        }
        setForm({ ...newForm });
    }

    async function getAeds() {
        setAedOptions(await apiRequest.get(`/aed/location/${selectedLocation?.id}?size=999`).then((res) => res.data.content));
    }

    async function getLocations() {
        setLocationOptions(await apiRequest.get(`/location/all/${organizationId}`).then((res) => res.data));
    }

    const [states, setStates] = useState([]);
    async function getStates() {
        setStates(await apiRequest.get(`/information/states`).then((res) => res.data));
    }

    useEffect(() => {
        getLocations();
        getStates();
    }, []);

    useEffect(() => {
        selectedLocation !== null && getAeds();
    }, [selectedLocation]);

    async function sendRescueInformation() {
        if (
            form.question1 == null ||
            form.question2 == null ||
            form.question3 == null ||
            form.question4 == null ||
            form.question5 == null ||
            form.question6 == null ||
            form.question7 == null
        ) {
            return notify("You need to answer all the questions", "error");
        }

        const postData = Object.assign(
            {
                incidentDate: null,
                incidentTime: null,
                exactLocation: null,
                incidentAddress: {
                    address: null,
                    city: null,
                    state: null,
                    zipCode: null,
                    county: null,
                },
                aedId: null,
                locationId: null,
                organizationId: organizationId,
                operatorName: null,
                lastName: null,
                firstName: null,
                dateBirth: null,
                race: null,
                gender: null,
                question1: null,
                question2: null,
                question3: null,
                question4: null,
                question5: null,
                question6: null,
                question7: null,
                descriptionOfEvent: "Assim assim e assim blah blah blah",
                reportCompletedBy: "Kelvin Brito",
                dateFormCompleted: new Date().toISOString().slice(0, 10),
                contactPhone: null,
            },
            form
        );

        await apiRequest
            .post(`/aed/usage-event/`, postData)
            .then((res) => {
                onClose();
                setErrors([]);
                return notify("Usage report sent", "success");
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    notify("Error sending usage report. Check your form fields.  " + err?.response?.data?.error, "error");
                }
                notify("Error sending usage report. Check your form fields.", "error");
            });
    }

    const [value, setValue] = useState(null);

    const handleOnClose = () => {
        if (hadAnyChanges) {
            customConfirmAlert(() => {
                setHadAnyChanges(false);
                onClose();
            });

            return;
        }

        setHadAnyChanges(false);
        onClose();
    };

    return (
        <CustomModal open={open} onClose={() => handleOnClose()}>
            <Grid container spacing={2}>
                <Grid item xs={12} mt={4}>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            alignContent: "center",
                            paddingBottom: 10,
                        }}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            Incident Details
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography>Incident Date</Typography>
                    <BasicDatePicker
                        InputProps={{
                            fullWidth: true,
                            helperText: errorFieldMessage(errors, "incidentDate"),
                            error: errorFieldHandler(errors, "incidentDate"),
                        }}
                        onChange={(date) =>
                            handleChange({ target: { value: date ? DateHelper.toLocalISOString(date).slice(0, 10) : "", name: "incidentDate" } })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography>Incident Time</Typography>
                    <LocalizationProvider locale={usLocale} dateAdapter={AdapterDateFns}>
                        <TimePicker
                            name="incidentTime"
                            value={value}
                            onChange={(date) => {
                                setValue(date);
                                handleChange({ target: { value: date ? getDate(date).slice(11, 19) : "", name: "incidentTime" } });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    helperText={errorFieldMessage(errors, "incidentTime")}
                                    error={errorFieldHandler(errors, "incidentTime")}
                                    fullWidth
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                    <Typography>Incident Address</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        helperText={errorFieldMessage(errors, "address.address")}
                        error={errorFieldHandler(errors, "address.address")}
                        size="small"
                        label="Address"
                        fullWidth
                        name="incidentAddress.address"
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "address.city")}
                        error={errorFieldHandler(errors, "address.city")}
                        size="small"
                        label="City"
                        fullWidth
                        name="incidentAddress.city"
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Autocomplete
                        key="2"
                        id="2"
                        size="small"
                        value={states.filter((stt) => stt?.postalAbbreviation == form?.incidentAddress?.state)[0]}
                        onChange={(event, newValue) => {
                            handleChange({ target: { value: newValue?.postalAbbreviation, name: "incidentAddress.state" } });
                        }}
                        options={states}
                        getOptionLabel={(opt) => opt?.name}
                        renderInput={(params) => (
                            <TextField
                                helperText={errorFieldMessage(errors, "address.state")}
                                error={errorFieldHandler(errors, "address.state")}
                                {...params}
                                label="State"
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "address.county")}
                        error={errorFieldHandler(errors, "address.county")}
                        size="small"
                        label="County"
                        fullWidth
                        name="incidentAddress.county"
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "address.zipcode")}
                        error={errorFieldHandler(errors, "address.zipcode")}
                        size="small"
                        label="ZipCode"
                        fullWidth
                        name="incidentAddress.zipCode"
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography>Exact location where AED was used</Typography>
                    <TextField
                        name="exactLocation"
                        helperText={errorFieldMessage(errors, "exactLocation")}
                        error={errorFieldHandler(errors, "exactLocation")}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography>Location</Typography>
                    <Select
                        fullWidth
                        helperText={errorFieldMessage(errors, "locationId")}
                        error={errorFieldHandler(errors, "locationId")}
                        onChange={(e) => {
                            setSelectedLocation(e.target.value);
                            handleChange({ target: { value: e.target.value?.id, name: "locationId" } });
                        }}
                    >
                        {locationOptions.map((location) => (
                            <MenuItem value={location}>{location?.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography>Which AED was used in the rescue?</Typography>
                    <Select
                        fullWidth
                        helperText={errorFieldMessage(errors, "aedId")}
                        error={errorFieldHandler(errors, "aedId")}
                        onChange={(e) => {
                            handleChange({ target: { value: e.target.value?.id, name: "aedId" } });
                        }}
                    >
                        {aedOptions.map((aed) => (
                            <MenuItem value={aed}>
                                {aed?.aedModel?.manufacturer} | {aed?.aedModel?.model} | {aed?.serial}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={12} mt={4}>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            alignContent: "center",
                            paddingBottom: 10,
                        }}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            Patient Details
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography>Last Name</Typography>
                    <TextField
                        name="lastName"
                        helperText={errorFieldMessage(errors, "lastName")}
                        error={errorFieldHandler(errors, "lastName")}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography>First Name</Typography>
                    <TextField
                        name="firstName"
                        helperText={errorFieldMessage(errors, "firstName")}
                        error={errorFieldHandler(errors, "firstName")}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>Date of Birth</Typography>
                    <BasicDatePicker
                        InputProps={{ fullWidth: true, helperText: errorFieldMessage(errors, "dateBirth"), error: errorFieldHandler(errors, "dateBirth") }}
                        onChange={(date) => handleChange({ target: { value: date ? DateHelper.toLocalISOString(date).slice(0, 10) : "", name: "dateBirth" } })}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>Race</Typography>
                    <Select
                        fullWidth
                        name="race"
                        helperText={errorFieldMessage(errors, "race")}
                        error={errorFieldHandler(errors, "race")}
                        onChange={handleChange}
                    >
                        {["WHITE", "AFRICAN_AMERICAN", "AMERICAN_INDIAN", "HISPANIC", "ASIAN", "PACIFIC_ISLANDER", "OTHER"].map((opt) => (
                            <MenuItem value={opt}>{opt.replaceAll("_", " ")}</MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>Gender</Typography>
                    <Select
                        fullWidth
                        name="gender"
                        helperText={errorFieldMessage(errors, "gender")}
                        error={errorFieldHandler(errors, "gender")}
                        onChange={handleChange}
                    >
                        {["FEMALE", "MALE", "OTHER"].map((opt) => (
                            <MenuItem value={opt}>{opt.replaceAll("_", " ")}</MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={12} mt={4}>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            alignContent: "center",
                            paddingBottom: 10,
                        }}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            Aditional Information
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <RadioSelect
                        title="1. Did anyone see or hear the patient collapse?"
                        onChange={(opt) => handleChange({ target: { value: opt, name: "question1" } })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <RadioSelect
                        title="2. Initially, was the patient breathing when the AED personnel arrived?"
                        onChange={(opt) => handleChange({ target: { value: opt, name: "question2" } })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <RadioSelect
                        title="3. Initially, did the patient have a pulse when the AED personnel arrived?"
                        onChange={(opt) => handleChange({ target: { value: opt, name: "question3" } })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <RadioSelect
                        title="4. Was there CPR by anyone else before the AED personnel arrived?"
                        onChange={(opt) => handleChange({ target: { value: opt, name: "question4" } })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <RadioSelect
                        title="5. Was the cardiac arrest after AED personnel arrived?"
                        onChange={(opt) => handleChange({ target: { value: opt, name: "question5" } })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <RadioSelect
                        title="6. Did you or anyone else use a mouth-to-mouth barrier?"
                        onChange={(opt) => handleChange({ target: { value: opt, name: "question6" } })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <RadioSelect
                        title="7. Did patient receive oxygen during the rescue (prior to EMS arrival)?"
                        onChange={(opt) => handleChange({ target: { value: opt, name: "question7" } })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography>Description of the event</Typography>
                    <TextField
                        name="descriptionOfEvent"
                        helperText={errorFieldMessage(errors, "descriptionOfEvent")}
                        error={errorFieldHandler(errors, "descriptionOfEvent")}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>Defibrillator operator name</Typography>
                    <TextField
                        name="operatorName"
                        helperText={errorFieldMessage(errors, "operatorName")}
                        error={errorFieldHandler(errors, "operatorName")}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>Contact Phone</Typography>
                    <TextField
                        name="contactPhone"
                        helperText={errorFieldMessage(errors, "contactPhone")}
                        error={errorFieldHandler(errors, "contactPhone")}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>Date form completed</Typography>
                    <BasicDatePicker
                        InputProps={{
                            fullWidth: true,
                            helperText: errorFieldMessage(errors, "dateFormCompleted"),
                            error: errorFieldHandler(errors, "dateFormCompleted"),
                        }}
                        onChange={(date) =>
                            handleChange({ target: { value: date ? DateHelper.toLocalISOString(date).slice(0, 10) : "", name: "dateFormCompleted" } })
                        }
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} mt={4} pb={6}>
                    <Button variant="contained" onClick={() => sendRescueInformation()} fullWidth>
                        Send Info
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
};

function RadioSelect({ options = ["YES", "NO", "UNKNOWN"], onChange = () => {}, value, title = "SELECT_TITLE" }) {
    return (
        <FormControl>
            <FormLabel sx={{ fontWeight: "bold", fontSize: 14 }} id="demo-radio-buttons-group-label">
                {title}
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={(e) => onChange(e.target.value)}
            >
                {options.map((option) => (
                    <FormControlLabel sx={{ fontSize: 10 }} value={option} control={<Radio />} label={option} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

function getDate(date) {
    const currentTimeZoneOffsetInHours = new Date().getTimezoneOffset() / 60;
    const dateObj = new Date(date);
    dateObj.setHours(dateObj.getHours() - currentTimeZoneOffsetInHours);

    return dateObj.toISOString();
}

export default AfterTheRescueModal;
