import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, TextField, Grid, Typography } from "@mui/material";
import LogoHorizontal from "../../images/Logo_2.png";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorHandler, errorProps } from "../../helpers/errorHelper";

export default function Login() {
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState("");

    async function ForgetPassword() {
        await apiRequest
            .post(`/auth/forgot-password`, {
                email: email,
            })
            .then((res) => {
                notify("Check your email to change your password", "success");
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    const styleBody = {
        backgroundImage: "linear-gradient(to bottom, #22579b, #1f5190, #1d4b85, #1a457b, #183f70)",
        width: "100%",
        height: "100vh",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const styleBox = {
        backgroundColor: "#ffffff",
        width: "400px",
        p: 4,
        borderRadius: "0.3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const styleButton = {
        backgroundColor: "#5dc48f",
        mb: 5,
    };

    return (
        <Grid sx={styleBody}>
            <Grid sx={styleBox} container spacing={1}>
                <img src={LogoHorizontal} alt="" width={300} />
                <Grid item xs={12} mt={4}>
                    <TextField
                        id="outlined-basic"
                        fullWidth
                        type={"email"}
                        label="Email"
                        value={email}
                        variant="outlined"
                        {...errorProps(errors, "email")}
                        onChange={(evt) => setEmail(evt.target.value)}
                    />
                </Grid>

                <Grid marginTop={2} item xs={12} md={12}>
                    <Button sx={styleButton} onClick={() => ForgetPassword()} variant="contained" size="large" fullWidth>
                        Request password reset
                    </Button>
                </Grid>

                <Link to="/login">
                    <Typography sx={{ color: "#22579b" }}>Back to login</Typography>
                </Link>
            </Grid>
        </Grid>
    );
}
