import { Grid, Button, Collapse } from "@mui/material";
import { useState } from "react";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";

export default function InfoCollapse({ children, title }: any) {
    const [collapseOpen, setCollapseOpen] = useState(true);

    return (
        <Grid
            container
            marginTop={1}
            marginBottom={1}
            sx={{
                transition: "0.5s",
                boxShadow: "0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%)",
                "&:hover": {
                    boxShadow: "rgb(145 158 171 / 15%) 0px 0px 2px 0px, rgb(145 158 171 / 15%) 0px 16px 32px -4px;",
                },
            }}
        >
            <Button
                sx={{ textTransform: "capitalize", fontSize: "1rem", padding: 1.5, display: "flex", justifyContent: "space-between" }}
                onClick={() => setCollapseOpen(!collapseOpen)}
                fullWidth
                variant="contained"
                color="secondary"
            >
                {title}
                {collapseOpen ? <FiChevronDown /> : <FiChevronRight />}
            </Button>
            <Collapse in={collapseOpen} sx={{ width: "100%", backgroundColor: "#FFFF", borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                {children}
            </Collapse>
        </Grid>
    );
}
