import { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Circle } from "@mui/icons-material";
import DashboardLayout from "../../layouts/DashboardLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import AfterTheRescueModal from "./modal";

export default function AfterTheRescue() {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true, organization: { org: true } }} currentPage="After the rescue" />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">After the rescue</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Please provide as much information as possible</Typography>
                    <Typography variant="body2">
                        This page describes the steps that are necessary to take after your AED has been utilized in a rescue.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">What to do after you used your AED</Typography>
                    <Typography variant="body2">
                        If your AED has been utilized in a rescue, the steps below will help guide you in getting the AED back into order.
                    </Typography>
                    <ol style={{ marginTop: 4 }}>
                        <li>
                            <Typography variant="body2">Remove and discard the AED pads into the public trash (they are not a biohazard).</Typography>
                        </li>
                        <li>
                            <Typography variant="body2">Clean and disinfect the unit if necessary.</Typography>
                        </li>
                        <li>
                            <Typography variant="body2">Place new electrode pads into the AED, and if the pads have a cable plug them into the AED.</Typography>
                        </li>
                        <li>
                            <Typography variant="body2">Philips FRX AEDs only:</Typography>
                            <ol>
                                <li>
                                    <Typography variant="body2">Remove the AED battery and then replace it once again</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">The AED will automatically run a self-test</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">Press the Shock button and On/Off button when directed</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">The AED will report the result of the self-test, turn off and go into standby mode</Typography>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="body2">If your AED brand/model is listed below, remove the AED battery and replace with a new one:</Typography>
                            <ol>
                                <li>
                                    <Typography variant="body2">G3Pro, non-rechargeable battery</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">Heartsine AEDs (as the battery and pads are combined in a single unit)</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">LifePak 1000</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">ZOLL AED Plus</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">If the user manual of your AED instructs you to replace the battery</Typography>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="body2">Turn the AED on, let it perform the self-checks, and listen for the prompts:</Typography>
                            <ol>
                                <li>
                                    <Typography variant="body2">
                                        If the AED prompts you to replace the battery, turn the unit off, and replace the battery with your back-up battery
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">
                                        If the AED is prompting you to place electrodes on the patient, the AED has sufficient battery strength. Turn the AED
                                        off and ensure the status light indicates the AED is ready
                                    </Typography>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography variant="body2">Perform a maintenance check using the Maintenance Checklist.</Typography>
                        </li>
                        <li>
                            <Typography variant="body2">Order the following replacement supplies:</Typography>
                            <ol>
                                <li>
                                    <Typography variant="body2">Electrode pads</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">AED Kit (includes the gloves, razor, scissors, gauze towelettes, etc…)</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">Battery, if needed</Typography>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <Typography variant="body2">
                        Because you deployed the AED and used it during a CPR, the AED has stored information about the treatment rendered. To retrieve this
                        information, consult the owners manual for your AED.
                        <br />
                        The fee to process an after rescue report is $200.
                        <br />
                        To begin the process of generating an after rescue report, click on button below.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => setModalOpen(!modalOpen)}>
                        Generate report
                    </Button>
                </Grid>

                <AfterTheRescueModal open={modalOpen} onClose={() => setModalOpen(false)} />
            </Grid>
        </DashboardLayout>
    );
}
