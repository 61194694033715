import { notify } from "./notify";

export function errorHandler(err: any) {
    if (err?.response?.data?.errors) {
        if (err?.response?.data?.errors) {
            if (err?.response?.data?.errors[0].field == null) {
                notify(err?.response?.data?.errors[0]?.message, "error");
            }
        }
        return [...err.response.data.errors];
    } else {
        if (err.response.data.message) {
            notify(err?.response?.data?.message, "error");
        } else {
            notify(err?.response?.data?.error, "error");
        }
        return [];
    }
}

export function errorFieldHandler(errors: any, fieldName: string) {
    return errors.filter((err: any) => err.field === fieldName).length > 0;
}

export function errorFieldMessage(errors: any, fieldName: string): string {
    return errors.filter((err: any) => err.field == fieldName)[0]?.message;
}

export function errorProps(errors: any[], fieldName: string) {
    return {
        helperText: errorFieldMessage(errors, fieldName),
        error: errorFieldHandler(errors, fieldName),
    };
}
