import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CheckCircle, Close, InfoOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    Chip,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    TableContainer,
    Paper,
} from "@mui/material";
import { DateFormat } from "../../../helpers/DateFormat";
import { accessoriesForecastResponseDTO, forecastSubscriptionList } from "../../../services/forecast/getForecast";

type props = {
    forecastList: forecastSubscriptionList;
};

export function ForecastTable({ forecastList }: props) {
    return (
        <>
            <Box marginTop={4}>
                <Typography variant="h6">Overdue organizations</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Overdue organizations">
                        <TableHead style={{ backgroundColor: "#f35d2b" }}>
                            <TableRow>
                                <TableCell style={{ color: "#FFF" }}>Organization name</TableCell>
                                <TableCell style={{ color: "#FFF" }}>Subscription org.</TableCell>
                                <TableCell style={{ color: "#FFF" }}>Expiration date</TableCell>
                                <TableCell style={{ color: "#FFF" }}>Total AEDs</TableCell>
                                <TableCell style={{ color: "#FFF" }}>Program coordinators</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {forecastList.overDueOrganizations.length > 0 ? (
                                <>
                                    {forecastList.overDueOrganizations.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
                                        >
                                            <TableCell>
                                                <Link
                                                    to={`/organization/${row.organizationId}`}
                                                    style={{ textDecoration: "none", color: "rgba(0, 0, 0, .87)" }}
                                                >
                                                    <Typography sx={{ ":hover": { fontWeight: "bold", textDecorationLine: "underline", color: "black" } }}>
                                                        {row.organizationName}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell>{row.subscriptionOrg}</TableCell>
                                            <TableCell>{row.expiration}</TableCell>
                                            <TableCell>{row.totalAeds}</TableCell>
                                            {row.programCoordinators.length > 0 ? (
                                                <>
                                                    <TableCell>
                                                        {row.programCoordinators.map((rowCoordinator, cIndex) => {
                                                            return (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>Phone: {rowCoordinator.fone}</Typography>
                                                                            <Typography>Email: {rowCoordinator.email}</Typography>
                                                                        </>
                                                                    }
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: 3,
                                                                            alignItems: "center",
                                                                            cursor: "help",
                                                                        }}
                                                                    >
                                                                        <Typography key={cIndex} sx={{ ":hover": { textDecoration: "underline" } }}>
                                                                            {rowCoordinator.fullName}
                                                                        </Typography>
                                                                        <InfoOutlined fontSize="small" />
                                                                    </Box>
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell>
                                                        <Typography variant="caption">No program coordinator found</Typography>
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="overline">No overdue organization found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box marginTop={8}>
                <Typography variant="h6">Expiration upcoming</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Expiration upcoming">
                        <TableHead style={{ backgroundColor: "#FFA800" }}>
                            <TableRow>
                                <TableCell>Organization name</TableCell>
                                <TableCell>Subscription org.</TableCell>
                                <TableCell>Expiration date</TableCell>
                                <TableCell>Total AEDs</TableCell>
                                <TableCell>Program coordinators</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {forecastList.expirationUpcoming.length > 0 ? (
                                <>
                                    {forecastList.expirationUpcoming.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
                                        >
                                            <TableCell>
                                                <Link
                                                    to={`/organization/${row.organizationId}`}
                                                    style={{ textDecoration: "none", color: "rgba(0, 0, 0, .87)" }}
                                                >
                                                    <Typography sx={{ ":hover": { fontWeight: "bold", textDecorationLine: "underline", color: "black" } }}>
                                                        {row.organizationName}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell>{row.subscriptionOrg}</TableCell>
                                            <TableCell>{row.expiration}</TableCell>
                                            <TableCell>{row.totalAeds}</TableCell>
                                            {row.programCoordinators.length > 0 ? (
                                                <>
                                                    <TableCell>
                                                        {row.programCoordinators.map((rowCoordinator, cIndex) => {
                                                            return (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>Phone: {rowCoordinator.fone}</Typography>
                                                                            <Typography>Email: {rowCoordinator.email}</Typography>
                                                                        </>
                                                                    }
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: 3,
                                                                            alignItems: "center",
                                                                            cursor: "help",
                                                                        }}
                                                                    >
                                                                        <Typography key={cIndex} sx={{ ":hover": { textDecoration: "underline" } }}>
                                                                            {rowCoordinator.fullName}
                                                                        </Typography>
                                                                        <InfoOutlined fontSize="small" />
                                                                    </Box>
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell>
                                                        <Typography variant="caption">No program coordinator found</Typography>
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="overline">No expiration upcoming found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}

type AccessoryForecastTableRowProps = {
    accessoryForecast: accessoriesForecastResponseDTO;
    // editNote: (accessory: accessoriesForecastResponseDTO, note: string) => Promise<any>;
};

const AccessoryForecastTableRow = ({ accessoryForecast }: AccessoryForecastTableRowProps) => {
    const [accessoryNote, setAccessoryNote] = useState(accessoryForecast.note);
    const [editNoteMode, setEditNoteMode] = useState(false);
    const [error, setError] = useState(false);

    const handleCloseEditNoteMode = () => {
        setEditNoteMode(!editNoteMode);
        setAccessoryNote(accessoryForecast.note);
    };

    const handleEditNote = async (accessory: accessoriesForecastResponseDTO) => {
        setError(false);

        // await editNote(accessory, accessoryNote)
        //     .then(() => {
        //         setEditNoteMode(false);
        //         notify("Note successfully edited", "success");
        //     })
        //     .catch((err) => {
        //         setError(true);
        //         errorHandler(err);
        //     });
    };

    return (
        <TableRow key={accessoryForecast.id}>
            <TableCell
                sx={{
                    border: "none",
                    borderLeft: "1px solid #00000033",
                    maxWidth: 120,
                }}
            >
                {accessoryForecast.dueNow ? (
                    <Chip label="Due now" sx={{ backgroundColor: "#FFA800", color: "white", fontSize: 12 }} />
                ) : accessoryForecast.overdue ? (
                    <Chip label="Over due" sx={{ backgroundColor: "#FF0000", color: "white", fontSize: 12 }} />
                ) : (
                    <Chip label="OK" sx={{ backgroundColor: "#00BF41", color: "white" }} />
                )}
            </TableCell>
            <TableCell sx={{ border: "none", borderLeft: "1px solid #00000033", width: 140 }}>{accessoryForecast.type.replaceAll("_", " ")}</TableCell>
            <TableCell sx={{ border: "none", borderLeft: "1px solid #00000033" }}>{accessoryForecast.aedModel}</TableCell>
            <TableCell sx={{ border: "none", borderLeft: "1px solid #00000033" }}>{accessoryForecast.aedSerial}</TableCell>
            <TableCell sx={{ border: "none", borderLeft: "1px solid #00000033" }}>{accessoryForecast.aedPlacement}</TableCell>
            <TableCell sx={{ border: "none", borderLeft: "1px solid #00000033", width: 120 }}>{DateFormat(accessoryForecast.expires)}</TableCell>
            <TableCell
                sx={{
                    height: "100%",
                    border: "none",
                    borderLeft: "1px solid #00000033",
                    borderRight: "1px solid #00000033",
                }}
            >
                {editNoteMode ? (
                    <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                            <OutlinedInput
                                error={error}
                                size="small"
                                id="outlined-adornment-password"
                                value={accessoryNote}
                                onChange={(e) => setAccessoryNote(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => handleEditNote(accessoryForecast)} edge="end">
                                            <Tooltip title="Save note">
                                                <CheckCircle color="success" />
                                            </Tooltip>
                                        </IconButton>

                                        <IconButton aria-label="toggle password visibility" onClick={handleCloseEditNoteMode} edge="end">
                                            <Tooltip title="Save note">
                                                <Close />
                                            </Tooltip>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                        {" "}
                        <Typography>{accessoryNote}</Typography>{" "}
                        <Button onClick={() => setEditNoteMode(true)}>
                            <FaEdit />
                        </Button>{" "}
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};
