import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Login from "../pages/login";
import { notify } from "./notify";

export const PrivateRoute = ({
    children,
    allowedRoles = ["programCoordinator", "franchise", "programStaff", "siteCoordinator", "siteStaff", "sitesSupervisor", "systemAdmin"],
}) => {
    const location = useLocation();

    const { user } = useContext(AuthContext);

    if (user == undefined) return <Login />;

    if (user == null) return <Navigate to="/login" state={{ from: location }} replace />;

    for (let i = 0; i < allowedRoles.length; i++) {
        if (user?.userProfiles[allowedRoles[i]]) {
            return children;
        }
    }

    if (user.userProfiles?.systemAdmin || user.userProfiles?.franchise) {
        return <Navigate to="/organizations" state={{ from: location }} replace />;
    } else {
        return <Navigate to={"/organization/" + user?.organization?.id} replace />;
    }
};
