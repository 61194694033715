import { Grid, Drawer, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header/Header";
import RTILogo from "../assets/rescue-training-institute-logo.png";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { useParams } from "react-router-dom";
import { SmDrawer } from "./responsiveDrawer";
import { colors } from "../themes/main";

export default function DashboardLayout({ children }: any) {
    const { organizationId } = useParams();
    const matches = useMediaQuery("(min-width: 900px)");
    const drawerWidth = matches ? "13rem" : "6.5rem";

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Header />
            </AppBar>
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    ["& .MuiDrawer-paper"]: {
                        backgroundColor: colors.main.primary,
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
            >
                <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <SmDrawer params={{ organizationId: organizationId }} />
                    <Box sx={{ padding: 2 }}>
                        <Typography variant="caption" color="#FFFF">
                            Powered by
                        </Typography>
                        <img src={RTILogo} width={175} height={55} style={{ objectFit: "contain" }} />
                    </Box>
                </Box>
            </Drawer>

            <Grid container style={{ width: "100%" }}>
                <Box component="main" sx={{ display: "flex", flex: "1 1 auto", flexDirection: "column", width: "100%", padding: 3, minHeight: "100vh" }}>
                    <div style={{ height: "56px" }}></div>
                    {children}
                </Box>
            </Grid>
        </Box>
    );
}
