import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText, Typography, Grid, Button } from "@mui/material";
import aedsLogo from "../../../assets/aed_logo.png";
import apiRequest from "../../../helpers/apiRequest";
import { notify } from "../../../helpers/notify";
import { useModal } from "../../../hooks/useModal";
import SearchFilter from "../../../components/SearchFilter";
import MuiTable from "../../../components/MuiTable";
import customConfirmAlert from "../../../components/confirmAlert";
import EditAED from "../../../components/aeds/modal/edit/editAed";

export default function AdminAedsModal(props) {
    const modalName = "adminAED";
    const { open, close, SceneRender } = useModal({
        title: "Admin AEDs",
        scene: <AdminAedsModalBody modalName={modalName} onClose={() => close()} />,
    });

    const handleOnOpen = () => {
        // "modalName;lastPage"
        const lastPage = sessionStorage.getItem("last-modal");

        if (!lastPage || lastPage.split(";")[0] !== modalName) {
            sessionStorage.setItem("last-modal", `${modalName};0`);
        }

        open();
    };

    return (
        <>
            <SceneRender />
            <ListItemButton onClick={() => handleOnOpen()}>
                <ListItemIcon style={{ color: "white" }}>
                    <img
                        src={aedsLogo}
                        style={{
                            width: "1.32rem",
                            marginLeft: "3px",
                            filter: "brightness(1000%)",
                        }}
                    />
                </ListItemIcon>
                <ListItemText primary={props.title} />
            </ListItemButton>
        </>
    );
}

export function AdminAedsModalBody({ modalName, onClose }) {
    const [filter, setFilter] = useState("");
    const [showInactive, setShowInactive] = useState(false);
    const [aedToEdit, setAedToEdit] = useState(null);
    const [isUpdated, setIsUpdated] = useState(true);
    const [toggleReload, setToggleReload] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            label: "Manufacturer || Model",
            name: "aedModel_manufacturer",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => {
                            navigate(
                                `/organization/${tableMeta.tableData[tableMeta.rowIndex][7]}/location/${tableMeta.tableData[tableMeta.rowIndex][5]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][6]
                                }`
                            );
                            onClose();
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {value} | {tableMeta.tableData[tableMeta.rowIndex][8]}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "305px",
                    },
                }),
            },
        },
        {
            label: "Serial number",
            name: "serial",
        },
        {
            label: "Organization",
            name: "ownerInfo_organizationName",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => {
                            navigate(`/organization/${tableMeta.tableData[tableMeta.rowIndex][7]}`);
                            onClose();
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "315px",
                    },
                }),
            },
        },
        {
            label: "Location",
            customSortParam: "locationName",
            name: "ownerInfo_locationName",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => {
                            navigate(`/organization/${tableMeta.tableData[tableMeta.rowIndex][7]}/location/${tableMeta.tableData[tableMeta.rowIndex][5]}`);
                            onClose();
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "336px",
                    },
                }),
            },
        },
        {
            label: "Placement",
            name: "placement",
        },
        {
            label: "LocationId",
            name: "ownerInfo_locationId",
            options: {
                display: false,
            },
        },
        {
            label: "ID",
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "OrganizationId",
            name: "ownerInfo_organizationId",
            options: {
                display: false,
            },
        },
        {
            label: "Model",
            name: "aedModel_model",
            options: {
                display: false,
            },
        },
    ];

    function handleOnClose() {
        if (!isUpdated) {
            customConfirmAlert(() => {
                setIsUpdated(true);
                setAedToEdit(null);
            });
        } else {
            setAedToEdit(null);
        }
    }

    async function disableAED(id) {
        await apiRequest
            .put(`/aed/${id}/disable`)
            .then((res) => {
                notify("AED disabled", "success");
                setToggleReload(false);
                setToggleReload(true);
            })
            .catch((err) => {
                notify("Error disabling AED", "error");
            });
    }

    async function enableAED(id) {
        await apiRequest
            .put(`/aed/${id}/enable`)
            .then((res) => {
                notify("AED enabled", "success");
                setToggleReload(false);
                setToggleReload(true);
            })
            .catch((err) => {
                notify("Error enabling AED", "error");
            });
    }

    return (
        <>
            {aedToEdit ? (
                <EditAED aed={aedToEdit} onEdit={handleOnClose} onSave={{ isUpdated, setIsUpdated }} />
            ) : (
                <>
                    <SearchFilter onChangeFilter={(filter) => setFilter(filter)} />
                    <Grid item xs={12} marginBottom={2} marginTop={4}>
                        <Button
                            variant={!showInactive ? "contained" : "outlined"}
                            onClick={() => setShowInactive(false)}
                            color="secondary"
                            sx={{ marginRight: 1 }}
                        >
                            MANAGE ACTIVE
                        </Button>
                        <Button variant={showInactive ? "contained" : "outlined"} onClick={() => setShowInactive(true)} color="secondary">
                            MANAGE INACTIVE
                        </Button>
                    </Grid>
                    <MuiTable
                        noSelect
                        noView
                        pagination
                        paginationURL={showInactive ? "/aed/disabled/" : "/aed/"}
                        columns={columns}
                        search={filter}
                        noDelete={showInactive}
                        undo={showInactive}
                        onEdit={(aed) => setAedToEdit(aed)}
                        onDelete={(aed) => {
                            customConfirmAlert(
                                () => {
                                    disableAED(aed?.id);
                                },
                                () => {},
                                "CONFIRM"
                            );
                        }}
                        onUndo={(aed) => enableAED(aed.id)}
                        forceReload={toggleReload}
                        modalName={modalName}
                    />
                </>
            )}
        </>
    );
}
