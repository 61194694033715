import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { FaFlag, FaMedkit, FaBriefcase, FaMapMarker, FaCalendar, FaEdit } from "react-icons/fa";
import { RiDeleteBackFill } from "react-icons/ri";
import { Button, Grid, Typography, Card, CircularProgress, Box, Tooltip } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { motion } from "framer-motion";
import { AxiosError } from "axios";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { DateFormat } from "../../helpers/DateFormat";
import { useModal } from "../../hooks/useModal";
import { AuthContext } from "../../context/AuthContext";
import EditCompany from "./editCompany";
import LocationsModal from "./locations";
import RespondersModal from "./responders";
import NotesModal from "./notes";
import InformationModal from "./information";
import EditUser from "../users/editUser";
import getUserById from "../../services/organization/getUserById";
import { HelpModalBody } from "../../layouts/components/help-modal";
import DashboardLayout from "../../layouts/DashboardLayout";
import InfoCollapse from "../../components/InfoCollapse";
import InfoCard from "../../components/InfoCard";
import AedModal from "../../components/aeds/modal/index";
import NewAEDsModal from "../../components/new-aeds/modal/index";
import Breadcrumbs from "../../components/Breadcrumbs";
import AedsLogo from "../../assets/aed_logo.png";
import NewAedsLogo from "../../assets/new_aeds.png";
import responderIcon from "../../assets/responders_icon.png";
import { colors } from "../../themes/main";
import { InfoOutlined } from "@mui/icons-material";

export default function Organization() {
    const { authRedirect, profiles, user } = useContext<any>(AuthContext);

    const { open, close, SceneRender } = useModal({ title: "Order Supplies", scene: <HelpModalBody subjectTxt="Order Supplies" onClose={() => close()} /> });

    const { organizationId } = useParams();
    const id = organizationId;

    const [contentLoaded, setContentLoaded] = useState(false);
    const [organization, setOrganization] = useState<any | null>(null);
    const [locations, setLocations] = useState<any>(null);
    const [aedsInfo, setAEDsInfo] = useState<any>([]);
    const [responders, setResponders] = useState<any>([]);
    const [adminNotes, setAdminNotes] = useState<any>([]);
    const [newAeds, setNewAeds] = useState<any>([]);

    const [deviceInspections, setDeviceInspections] = useState<any>(null);
    const [deviceAccessories, setDeviceAccessories] = useState<any>(null);
    const [responderCertifications, setResponderCertifications] = useState<any>(null);

    const [userToEdit, setUserToEdit] = useState<any>(false);
    const [editUserModal, setEditUserModal] = useState(false);

    async function getOrganizationInfo() {
        const organizationInfo = await apiRequest
            .get(`/organization/${id}`)
            .then((res) => res.data)
            .catch((err: AxiosError) => {
                err.response?.status == 401 && authRedirect();
            });
        setOrganization(organizationInfo);
        setContentLoaded(true);

        const locationsInfo = await apiRequest.get(`/location?organizationId=${organizationId}`).then((res) => res.data);
        setLocations(locationsInfo);

        const aedsInfo = await apiRequest.get(`/aed/organization/${id}`).then((res) => res.data);
        setAEDsInfo(aedsInfo);

        const respondersInfo = await apiRequest.get(`/responder?organizationId=${id}`).then((res) => res.data);
        setResponders(respondersInfo);

        const newAedsInfo = await apiRequest
            .get(`/organization/${organizationId}/newaeds`)
            .then((res) => res.data)
            .catch((err) => console.log(err.message));
        setNewAeds(newAedsInfo);
    }

    async function getConditionalInfo() {
        if (user?.userProfiles?.systemAdmin) {
            const adminNotesInfo = await apiRequest
                .get(`/organization/${id}/admin-notes/`)
                .then((res) => res.data)
                .catch((err: AxiosError) => {
                    err.response?.status == 401 && authRedirect();
                });
            setAdminNotes([...adminNotesInfo]);
        }
    }

    async function getOtherInfo() {
        const deviceInspectionsInfo = await apiRequest
            .get(`/information/device-inspection?organizationId=${organizationId}`)
            .then((res) => res.data)
            .catch((err) => null);
        const deviceAccessoriesInfo = await apiRequest
            .get(`/information/device-accessories?organizationId=${organizationId}`)
            .then((res) => res.data)
            .catch((err) => null);
        const responderCertificationsInfo = await apiRequest
            .get(`/information/responder-certification?organizationId=${organizationId}`)
            .then((res) => res.data)
            .catch((err) => null);

        setDeviceInspections({ ...deviceInspectionsInfo });
        setDeviceAccessories(deviceAccessoriesInfo);
        setResponderCertifications(responderCertificationsInfo);
    }

    const [informationModal, setInformationModal] = useState<boolean>(false);
    const [informationModalType, setInformationModalType] = useState<any>(null);
    const [informationModalStatus, setInformationModalStatus] = useState<any>(null);
    const [informationModalName, setInformationModalName] = useState<String>("");

    const openInfoModal = (type: string, status: string) => {
        setInformationModalType(type);
        setInformationModalStatus(status);
        setInformationModalName("infoModal");
        setInformationModal(true);
    };

    const getLastModalOpen = () => {
        const modalName = sessionStorage.getItem("last-modal");

        if (!modalName) return;

        // "modalName;lastPage"
        switch (modalName.split(";")[0]) {
            case "locationsModal":
                setLocationsModal(true);
                break;
            case "respondersModal":
                setRespondersModal(true);
                break;
            case "aedDevicesModal":
                setAedDevicesModal(true);
                break;
            case "newAedsDevicesModal":
                setNewAedsDevicesModal(true);
                break;
            case "inspec-dueNow":
                openInfoModal("device-inspection", "dueNow");
                break;
            case "inspec-overDue":
                openInfoModal("device-inspection", "overDue");
                break;
            case "inspec-needsAttention":
                openInfoModal("device-inspection", "needsAttention");
                break;
            case "acc-dueNow":
                openInfoModal("device-accessories", "dueNow");
                break;
            case "acc-overDue":
                openInfoModal("device-accessories", "overDue");
                break;
            case "resp-dueNow":
                openInfoModal("responder-certification", "dueNow");
                break;
            case "resp-overDue":
                openInfoModal("responder-certification", "overDue");
                break;
        }
    };

    const setLastModalOpen = (modalName: string) => {
        sessionStorage.setItem("last-modal", modalName);
    };

    const [programProfiles, setProgramProfiles] = useState<any>(null);

    const getProgramProfiles = () => {
        apiRequest.get(`/user/users-position-organization/${organizationId}`).then((res) => setProgramProfiles(res.data));
    };

    useEffect(() => {
        getOrganizationInfo();
        getOtherInfo();
        getLastModalOpen();
        getProgramProfiles();
    }, [organizationId]);

    useEffect(() => {
        if (profiles !== null) {
            getConditionalInfo();
        }
    }, [profiles]);

    const [organizationModal, setOrganizationModal] = useState(false);
    const [locationsModal, setLocationsModal] = useState(false);
    const [aedDevicesModal, setAedDevicesModal] = useState(false);
    const [respondersModal, setRespondersModal] = useState(false);
    const [notesModal, setNotesModal] = useState(false);
    const [notesModalAdmin, setNotesModalAdmin] = useState(false);

    async function deleteNote(id: any, admin: boolean) {
        await apiRequest
            .delete(`/organization/${organizationId}/${admin ? "admin-notes" : "notes"}/${id}`)
            .then((res) => {
                notify("Note deleted", "success");
                admin ? getConditionalInfo() : getOrganizationInfo();
            })
            .catch((err) => {
                notify("Error deleting note", "error");
            });
    }

    const [newAedsDevicesModal, setNewAedsDevicesModal] = useState(false);

    const isFranchiseAdmin = user.userProfiles.systemAdmin || user.userProfiles.franchise;

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="Organization" />
            <NotesModal
                onNoteAdded={() => {
                    notesModalAdmin ? getConditionalInfo() : getOrganizationInfo();
                }}
                admin={notesModalAdmin}
                open={notesModal}
                onClose={() => setNotesModal(false)}
            />

            {informationModalType && informationModalStatus && (
                <InformationModal
                    status={informationModalStatus}
                    type={informationModalType}
                    open={informationModal}
                    modalName={informationModalName}
                    onClose={() => {
                        setInformationModal(false);
                        setLastModalOpen("none");
                    }}
                />
            )}

            {contentLoaded && (
                <motion.div key="Organization" initial={{ scale: 0.97, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.97, opacity: 0 }}>
                    <Grid container padding={2} paddingTop={3} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" textTransform="uppercase" color="#5a5c69" fontWeight="900">
                                {organization?.name || "Not found"}
                            </Typography>
                            <Grid item xs={12} gap={0.5} display="flex" flexDirection="row">
                                <Typography variant="body1" fontWeight="bold">
                                    Company Address:
                                </Typography>
                                <Typography variant="body1" marginLeft={1}>
                                    {`${organization?.mainAddress.address} - ${organization?.mainAddress.city}, ${organization?.mainAddress.state}  ${organization?.mainAddress.zipCode}` ||
                                        "Not found"}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6} display="flex" flexDirection="row" flexWrap="wrap">
                            <Grid item xs={12} display="flex" justifyContent="flex-end" gap={1}>
                                <Typography variant="body1" fontWeight="bold">
                                    Subscription Type:{" "}
                                </Typography>
                                <Typography variant="body1">{`${organization?.subscription}` || "Not found"}</Typography>
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="flex-end" gap={1}>
                                <Typography variant="body1" fontWeight="bold">
                                    Expiration:{" "}
                                </Typography>
                                <Typography variant="body1">{`${DateFormat(organization?.expiration)}` || "Not found"}</Typography>
                            </Grid>
                        </Grid>

                        {(user.userProfiles.programCoordinator || user.userProfiles.programStaff || isFranchiseAdmin) && (
                            <Grid item xs={12} display="flex" gap={2}>
                                <Button
                                    onClick={() => setOrganizationModal(true)}
                                    key="0232"
                                    sx={{ height: 35, padding: 2, paddingTop: 0.5, paddingBottom: 0.5 }}
                                    variant="contained"
                                >
                                    <FaEdit style={{ marginRight: 10 }} />
                                    Edit Company
                                </Button>

                                <EditCompany
                                    onCompanyUpdate={() => {
                                        setOrganizationModal(false);
                                        getOrganizationInfo();
                                    }}
                                    company={organization}
                                    open={organizationModal}
                                    onClose={() => setOrganizationModal(false)}
                                />
                            </Grid>
                        )}

                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InfoCollapse title="Program Coordinator">
                                    {programProfiles?.programCoordinator?.map((programCoordinator: any, index: number) => (
                                        <Grid key={index} container sx={{ padding: 2, width: "100%", flexDirection: "column" }}>
                                            <Grid sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {programCoordinator?.name}
                                                </Typography>
                                                {programCoordinator?.notificationProfile && (
                                                    <NotificationsActiveIcon color="primary" fontSize="small" style={{ marginBottom: 2 }} />
                                                )}
                                                {user.userProfiles.systemAdmin && (
                                                    <FaEdit
                                                        onClick={async () => {
                                                            const userInfo = await getUserById(programCoordinator.id);

                                                            setEditUserModal(true);
                                                            setUserToEdit(userInfo);
                                                        }}
                                                        title="Edit user"
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                )}
                                            </Grid>
                                            {userToEdit && <EditUser user={userToEdit} open={editUserModal} onClose={() => setEditUserModal(false)} />}
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Phone:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.phone}</Typography>
                                            </Grid>
                                            <Grid sx={{ display: "flex" }}>
                                                <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                    Email:{" "}
                                                </Typography>
                                                <Typography variant="body2">{programCoordinator?.email}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </InfoCollapse>

                                {programProfiles?.programStaff?.length > 0 && (
                                    <InfoCollapse title="Program Staff">
                                        {programProfiles?.programStaff?.map((programStaff: any, index: number) => (
                                            <Grid key={index} container sx={{ padding: 2, width: "100%", flexDirection: "column" }}>
                                                <Grid sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <Typography variant="body1" fontWeight="bold">
                                                        {programStaff?.name}
                                                    </Typography>
                                                    {programStaff?.notificationProfile && (
                                                        <NotificationsActiveIcon color="primary" fontSize="small" style={{ marginBottom: 2 }} />
                                                    )}
                                                </Grid>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                        Phone:{" "}
                                                    </Typography>
                                                    <Typography variant="body2">{programStaff?.phone}</Typography>
                                                </Grid>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Typography variant="body2" sx={{ width: 60 }} fontWeight="bold">
                                                        Email:{" "}
                                                    </Typography>
                                                    <Typography variant="body2">{programStaff?.email}</Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </InfoCollapse>
                                )}

                                {!user?.userProfiles.siteCoordinator && !user?.userProfiles.siteStaff && (
                                    <InfoCard
                                        add
                                        onAdd={() => {
                                            setNotesModalAdmin(false);
                                            setNotesModal(true);
                                        }}
                                        title="Notes"
                                    >
                                        <Grid container spacing={2} padding={2} paddingBottom={0}>
                                            {organization?.notes?.map((note: any, index: number) => (
                                                <Grid
                                                    key={note.id}
                                                    container
                                                    sx={{
                                                        borderBottom: index !== organization?.notes?.length - 1 ? "1px solid rgba(0,0,0,0.1)" : "none",
                                                        marginTop: 2,
                                                        padding: 2,
                                                    }}
                                                >
                                                    <Grid item xs={2}>
                                                        <Box sx={{ display: "flex", gap: 1 }}>
                                                            <FaCalendar size="1rem" color="black" />
                                                            <Typography variant="body2" fontWeight="bold">
                                                                {note.date}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={10} sx={{ display: "flex", gap: 1 }}>
                                                        <Typography variant="body2">
                                                            {note.text}
                                                            {note.createdBy && (
                                                                <>
                                                                    {" - "}
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <Typography>Phone: {note.createdBy?.fone}</Typography>
                                                                                <Typography>Email: {note.createdBy?.email}</Typography>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body2"
                                                                            component="span"
                                                                            sx={{ ":hover": { textDecoration: "underline" } }}
                                                                        >
                                                                            {note.createdBy?.fullName}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                            <RiDeleteBackFill
                                                                onClick={() => deleteNote(note.id, false)}
                                                                color="#e74a3b"
                                                                style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </InfoCard>
                                )}

                                {user?.userProfiles?.systemAdmin && (
                                    <InfoCard
                                        add
                                        onAdd={() => {
                                            setNotesModalAdmin(true);
                                            setNotesModal(true);
                                        }}
                                        title="Admin Notes"
                                    >
                                        <Grid container spacing={2} padding={2} paddingBottom={0}>
                                            {adminNotes?.map((note: any, index: number) => (
                                                <Grid
                                                    key={note.id}
                                                    container
                                                    sx={{
                                                        borderBottom: index !== adminNotes.length - 1 ? "1px solid rgba(0,0,0,0.1)" : "none",
                                                        marginTop: 2,
                                                        padding: 2,
                                                    }}
                                                >
                                                    <Grid item xs={2}>
                                                        <Box sx={{ display: "flex", gap: 1 }}>
                                                            <FaCalendar size="1rem" color="black" />
                                                            <Typography variant="body2" fontWeight="bold">
                                                                {note.date}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={10} sx={{ display: "flex", gap: 1 }}>
                                                        <Typography variant="body2">
                                                            {note.text}
                                                            {note.createdBy && (
                                                                <>
                                                                    {" - "}
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                <Typography>Phone: {note.createdBy?.fone}</Typography>
                                                                                <Typography>Email: {note.createdBy?.email}</Typography>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body2"
                                                                            component="span"
                                                                            sx={{ ":hover": { textDecoration: "underline" } }}
                                                                        >
                                                                            {note.createdBy?.fullName}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                            <RiDeleteBackFill
                                                                onClick={() => deleteNote(note.id, true)}
                                                                color="#e74a3b"
                                                                style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </InfoCard>
                                )}
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <InfoBallon
                                            onClick={() => {
                                                setLocationsModal(true);
                                                setLastModalOpen("locationsModal");
                                            }}
                                            type="Location"
                                            data={locations?.totalElements ?? <CircularProgress size={18} />}
                                            title="Locations"
                                        />
                                        <LocationsModal
                                            organizationId={organization?.id}
                                            onLocationUpdate={() => {
                                                // setLocationsModal(false);
                                                getOrganizationInfo();
                                            }}
                                            locations={locations}
                                            open={locationsModal}
                                            onClose={() => {
                                                setLocationsModal(false);
                                                setLastModalOpen("none");
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InfoBallon
                                            onClick={() => {
                                                setRespondersModal(true);
                                                setLastModalOpen("respondersModal");
                                            }}
                                            type="User"
                                            data={responders?.totalElements ?? <CircularProgress size={18} />}
                                            title="Responders"
                                        />
                                        <RespondersModal
                                            organizationId={organization?.id}
                                            onAEDUpdate={() => {
                                                setOrganizationModal(false);
                                                getOrganizationInfo();
                                            }}
                                            responders={responders}
                                            locations={locations}
                                            open={respondersModal}
                                            onClose={() => {
                                                setRespondersModal(false);
                                                setLastModalOpen("none");
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InfoBallon
                                            onClick={() => {
                                                setAedDevicesModal(true);
                                                setLastModalOpen("aedDevicesModal");
                                            }}
                                            type="Aeds"
                                            data={aedsInfo?.totalElements ?? <CircularProgress size={18} />}
                                            title="AED Devices"
                                        />
                                        <AedModal
                                            onListUpdate={() => getOrganizationInfo()}
                                            open={aedDevicesModal}
                                            editMode={true}
                                            onClose={() => {
                                                getOrganizationInfo();
                                                setAedDevicesModal(false);
                                                setLastModalOpen("none");
                                            }}
                                        />
                                        {/* <AedDevicesModal organizationId={organization?.id} onAEDUpdate={()=>getOrganizationInfo()} aeds={aeds} open={aedDevicesModal} onClose={()=>setAedDevicesModal(false)} /> */}
                                    </Grid>

                                    {!user.userProfiles.siteStaff && (
                                        <Grid item xs={12} sm={6}>
                                            <NewAEDsModal
                                                onUpdate={() => {
                                                    getOrganizationInfo();
                                                }}
                                                open={newAedsDevicesModal}
                                                onClose={() => {
                                                    setNewAedsDevicesModal(false);
                                                    setLastModalOpen("none");
                                                }}
                                            />
                                            <InfoBallon
                                                variant="danger"
                                                onClick={() => {
                                                    setNewAedsDevicesModal(true);
                                                    setLastModalOpen("newAedsDevicesModal");
                                                }}
                                                type="NewAeds"
                                                data={newAeds?.totalElements ?? <CircularProgress size={18} />}
                                                title="New AEDS"
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <InfoCard icon={<FaFlag size="1rem" />} data={2} title="Device Inspections">
                                    <Grid container spacing={2} padding={2}>
                                        <Grid item xs={12} md={4}>
                                            <Card
                                                onClick={() => {
                                                    if (deviceInspections?.dueNow.length > 0) {
                                                        openInfoModal("device-inspection", "dueNow");
                                                        setLastModalOpen("inspec-dueNow");
                                                    }
                                                }}
                                                sx={{
                                                    cursor: deviceInspections?.dueNow?.length > 0 ? "pointer" : "default",
                                                    padding: 2,
                                                    backgroundColor: colors.main.primary,
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight="800">
                                                    {deviceInspections?.dueNow?.length ?? <CircularProgress sx={{ color: "#FFFF" }} size={18} />}
                                                </Typography>
                                                <Typography variant="caption" fontWeight="bold">
                                                    Due now
                                                </Typography>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Card
                                                onClick={() => {
                                                    if (deviceInspections?.overDue.length > 0) {
                                                        openInfoModal("device-inspection", "overDue");
                                                        setLastModalOpen("inspec-overDue");
                                                    }
                                                }}
                                                sx={{
                                                    cursor: deviceInspections?.overDue?.length > 0 ? "pointer" : "default",
                                                    padding: 2,
                                                    backgroundColor: "#e74a3b",
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight="800">
                                                    {deviceInspections?.overDue?.length ?? <CircularProgress sx={{ color: "#FFFF" }} size={18} />}
                                                </Typography>
                                                <Typography variant="caption" fontWeight="bold">
                                                    Overdue
                                                </Typography>
                                            </Card>
                                        </Grid>

                                        <Grid
                                            onClick={() => {
                                                if (deviceInspections?.needsAttention.length > 0) {
                                                    openInfoModal("device-inspection", "needsAttention");
                                                    setLastModalOpen("inspec-needsAttention");
                                                }
                                            }}
                                            item
                                            xs={12}
                                            md={4}
                                        >
                                            <Card
                                                sx={{
                                                    cursor: deviceInspections?.needsAttention?.length > 0 ? "pointer" : "default",
                                                    padding: 2,
                                                    backgroundColor: "#f6c23e",
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight="800">
                                                    {deviceInspections?.needsAttention?.length ?? <CircularProgress sx={{ color: "#FFFF" }} size={18} />}
                                                </Typography>
                                                <Typography variant="caption" fontWeight="bold">
                                                    Needs Attention
                                                </Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </InfoCard>

                                <InfoCard icon={<FaMedkit size="1rem" />} data={2} title="Device Accessories">
                                    <Grid container spacing={2} padding={2}>
                                        <Grid item xs={12} md={4}>
                                            <Card
                                                onClick={() => {
                                                    if (deviceAccessories?.dueNow.length > 0) {
                                                        openInfoModal("device-accessories", "dueNow");
                                                        setLastModalOpen("acc-dueNow");
                                                    }
                                                }}
                                                sx={{
                                                    cursor: deviceAccessories?.dueNow?.length > 0 ? "pointer" : "default",
                                                    padding: 2,
                                                    backgroundColor: colors.main.primary,
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight="800">
                                                    {deviceAccessories?.dueNow?.length ?? <CircularProgress sx={{ color: "#FFFF" }} size={18} />}
                                                </Typography>
                                                <Typography variant="caption" fontWeight="bold">
                                                    Due now
                                                </Typography>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Card
                                                onClick={() => {
                                                    if (deviceAccessories?.overDue.length > 0) {
                                                        openInfoModal("device-accessories", "overDue");
                                                        setLastModalOpen("acc-overDue");
                                                    }
                                                }}
                                                sx={{
                                                    cursor: deviceAccessories?.overDue?.length > 0 ? "pointer" : "default",
                                                    padding: 2,
                                                    backgroundColor: "#e74a3b",
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight="800">
                                                    {deviceAccessories?.overDue?.length ?? <CircularProgress sx={{ color: "#FFFF" }} size={18} />}
                                                </Typography>
                                                <Typography variant="caption" fontWeight="bold">
                                                    Overdue
                                                </Typography>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <SceneRender />
                                            <Card
                                                onClick={() => {
                                                    open();
                                                }}
                                                sx={{
                                                    cursor: "pointer",
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 2,
                                                    backgroundColor: "#4DA167",
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="body2" fontWeight="800">
                                                    Order Supplies
                                                </Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </InfoCard>

                                <InfoCard icon={<FaBriefcase size="1rem" />} data={2} title="Responder Certifications">
                                    <Grid container spacing={2} padding={2}>
                                        <Grid item xs={12} md={4}>
                                            <Card
                                                onClick={() => {
                                                    if (responderCertifications?.dueNow.length > 0) {
                                                        openInfoModal("responder-certification", "dueNow");
                                                        setLastModalOpen("resp-dueNow");
                                                    }
                                                }}
                                                sx={{
                                                    cursor: responderCertifications?.dueNow.length > 0 ? "pointer" : "default",
                                                    padding: 2,
                                                    backgroundColor: colors.main.primary,
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight="800">
                                                    {responderCertifications?.dueNow.length ?? <CircularProgress sx={{ color: "#FFFF" }} size={18} />}
                                                </Typography>
                                                <Typography variant="caption" fontWeight="bold">
                                                    Due now
                                                </Typography>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Card
                                                onClick={() => {
                                                    if (responderCertifications?.overDue.length > 0) {
                                                        openInfoModal("responder-certification", "overDue");
                                                        setLastModalOpen("resp-overDue");
                                                    }
                                                }}
                                                sx={{
                                                    cursor: responderCertifications?.overDue.length > 0 ? "pointer" : "default",
                                                    padding: 2,
                                                    backgroundColor: "#e74a3b",
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="h6" fontWeight="800">
                                                    {responderCertifications?.overDue?.length ?? <CircularProgress sx={{ color: "#FFFF" }} size={18} />}
                                                </Typography>
                                                <Typography variant="caption" fontWeight="bold">
                                                    Overdue
                                                </Typography>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Card
                                                onClick={() => window.open("https://www.rescuetraininginstitute.com/")}
                                                sx={{
                                                    cursor: "pointer",
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 2,
                                                    backgroundColor: "#4DA167",
                                                    boxShadow: "none",
                                                    color: "white",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography variant="body2" fontWeight="800">
                                                    Schedule Class
                                                </Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </InfoCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </motion.div>
            )}
        </DashboardLayout>
    );
}

export function InfoBallon({ variant = "default", type, data, title = "Ballon", onClick, isAedLocation = false }: any) {
    return (
        <Card
            onClick={() => onClick()}
            sx={{
                backgroundColor: "#FFF",
                ...(variant == "danger" && { border: "1px solid #e74a3b" }),
                cursor: "pointer",
                borderLeft: `0.25rem solid ${variant == "danger" ? "#e74a3b" : colors.main.primary}!important`,
                padding: 2,
                marginTop: 1,
                marginBottom: 1,
                boxShadow: "0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%)",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="caption" fontWeight="bold" color={colors.main.primary}>
                        {title.toUpperCase()}
                    </Typography>
                    {isAedLocation ? (
                        data
                    ) : (
                        <Typography variant="h6" color={colors.main.primary}>
                            {data}
                        </Typography>
                    )}
                </Grid>

                <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {type == "User" && <img src={responderIcon} style={{ width: "2.5rem" }} />}
                    {type == "Location" && <FaMapMarker color={colors.main.primary} size={"2rem"} />}
                    {type == "Aeds" && <img src={AedsLogo} style={{ width: "2rem" }} />}
                    {type == "NewAeds" && <img src={NewAedsLogo} style={{ width: "2.5rem" }} />}
                </Grid>
            </Grid>
        </Card>
    );
}
