import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Button, CircularProgress, Grid, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { Edit, Delete, GridOn, Check, Lock, DragIndicator, Add } from "@mui/icons-material";
import DashboardLayout from "../../layouts/DashboardLayout";
import { AuthContext } from "../../context/AuthContext";
import CustomModal from "../../components/CustomModal/CustomModal";
import { errorFieldHandler, errorFieldMessage } from "../../helpers/errorHelper";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { IDocumentCategory, IDocumentFile, INewDocumentFile } from "../../entities/documentCenter";
import ConfirmDialog from "../../components/ConfirmDialog";
import PDFDocument from "../../components/PDFDocument";
import { colors } from "../../themes/main";
import "swiper/css";
import "./style.css";

type IModalOpenType = "none" | "cat-create" | "cat-delete" | "cat-rearrange" | "doc-create" | "doc-delete";

function DocumentCenter() {
    const { user } = useContext<any>(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState<IDocumentCategory[]>([]);
    const [categoryToModify, setCategoryToModify] = useState<IDocumentCategory>();
    const [documentToModify, setDocumentToModify] = useState<IDocumentFile>();
    const [categoryIdPositionUpdate, setCategoryIdPositionUpdate] = useState(-1);
    const [modalType, setModalType] = useState<IModalOpenType>("none");

    const getAllCategories = () => {
        setIsLoading(true);

        apiRequest
            .get("/document-center/category")
            .then((res) => setCategories(res.data))
            .finally(() => setIsLoading(false));
    };

    const updateCategories = (newCategory: IDocumentCategory) => {
        const updatedCategories = categories;
        const categoryIndex = updatedCategories.findIndex((category) => category.id === newCategory.id);

        if (categoryIndex === -1) {
            setCategories((prevState) => [...prevState, newCategory]);
        } else {
            setCategories((prevState) => prevState.map((oldCategory) => (oldCategory.id === newCategory.id ? newCategory : oldCategory)));
        }

        setCategoryToModify(undefined);
    };

    const deleteCategory = async (id: number) => {
        await apiRequest
            .delete("/document-center/category/" + id)
            .then((res) => {
                const updatedCategories = categories.filter((category) => category.id !== id);

                setCategories(updatedCategories);
                notify("Category removed successfully", "success");
            })
            .catch((err) => {
                notify(err.response.data?.errors[0]?.message || "Unexpected error", "error");
            });
    };

    const updateCategoryDocument = (newDocument: IDocumentFile) => {
        setCategories((prevState) =>
            prevState.map((category) => {
                category.documents = category.documents.filter((oldDocument) => oldDocument.id !== newDocument.id);
                category.id === newDocument.category.id && category.documents.push(newDocument);
                return category;
            })
        );
    };

    const deleteDocument = async (id: number, categoryId: number) => {
        await apiRequest
            .delete("/document-center/document/" + id)
            .then((res) => {
                const updatedCategories = categories.map((category) => {
                    if (category.id === categoryId) {
                        category.documents = category.documents.filter((document) => document.id !== id);
                    }

                    return category;
                });

                setCategories(updatedCategories);
                notify("Document removed successfully", "success");
            })
            .catch((err) => {
                notify(err.response.data?.errors[0]?.message || "Unexpected error", "error");
            });
    };

    const updateDocumentPosition = (target: IDocumentFile, source: IDocumentFile) => {
        let updatedCategories = categories;
        updatedCategories.map((category) => {
            if (category.id === target.category.id) {
                category.documents = category.documents.filter((doc) => doc.id !== target.id);
                target.position = source.position;
                category.documents.splice(source.position, 0, target);
            }

            return category;
        });

        apiRequest
            .put<IDocumentCategory>("/document-center/document/update-position", {
                documentId: target.id,
                newPosition: source.position,
            })
            .then((res) => {
                updateCategories(res.data);
            })
            .catch((err) => {
                notify(err.response.data?.errors[0]?.message || "Unexpected error", "error");
            });
    };

    useEffect(() => {
        getAllCategories();
    }, []);

    return (
        <DashboardLayout>
            <Typography sx={{ marginTop: 2, marginBottom: 2, color: "rgba(0,0,0,0.7)" }} variant="h4" fontWeight="bold">
                Documents
            </Typography>
            <Grid container>
                {user?.userProfiles?.systemAdmin && (
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={() => setModalType("cat-create")}>
                            New category
                        </Button>
                        <Button variant="outlined" color="primary" sx={{ marginLeft: 1 }} onClick={() => setModalType("cat-rearrange")}>
                            Rearrange categories
                        </Button>
                    </Grid>
                )}

                {isLoading && (
                    <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress size={32} color="primary" />
                    </Box>
                )}

                {!isLoading && categories.length < 1 && (
                    <Box sx={{ display: "fex", justifyContent: "center", margin: 4, width: "100%" }}>
                        <Typography variant="body1">No categories found.</Typography>
                    </Box>
                )}

                {!isLoading &&
                    categories.length > 0 &&
                    categories.map((category) => (
                        <Grid
                            item
                            xs={12}
                            key={"d_" + category.id}
                            sx={{
                                marginTop: 4,
                                padding: "24px",
                                borderRadius: "12px",
                                backgroundColor: "#fafafa",
                                boxShadow: "0 0 16px #ccc",
                                width: "calc(100vw - 256px)",
                            }}
                        >
                            <Box>
                                <Typography variant="h5">
                                    {category.categoryName}
                                    {user?.userProfiles?.systemAdmin && (
                                        <>
                                            <Tooltip title={categoryIdPositionUpdate === category.id ? "Confirm" : "Rearrange"} arrow>
                                                {categoryIdPositionUpdate === category.id ? (
                                                    <Check
                                                        onClick={() => {
                                                            setCategoryIdPositionUpdate(-1);
                                                            setDocumentToModify(undefined);
                                                        }}
                                                        fontSize="small"
                                                        sx={{ cursor: "pointer", marginLeft: "36px" }}
                                                    />
                                                ) : (
                                                    <GridOn
                                                        onClick={() => setCategoryIdPositionUpdate(category.id)}
                                                        fontSize="small"
                                                        sx={{ cursor: "pointer", marginLeft: "36px" }}
                                                    />
                                                )}
                                            </Tooltip>
                                            <Tooltip title="Edit" arrow>
                                                <Edit
                                                    onClick={() => {
                                                        setModalType("cat-create");
                                                        setCategoryToModify(category);
                                                    }}
                                                    fontSize="small"
                                                    sx={{ cursor: "pointer", marginLeft: "12px" }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Delete" arrow>
                                                <Delete
                                                    onClick={() => {
                                                        setCategoryToModify(category);
                                                        setModalType("cat-delete");
                                                    }}
                                                    fontSize="small"
                                                    sx={{ cursor: "pointer", marginLeft: "12px" }}
                                                />
                                            </Tooltip>
                                        </>
                                    )}
                                </Typography>
                            </Box>

                            <Swiper
                                slidesPerView="auto"
                                slidesPerGroupAuto
                                slidesOffsetAfter={96}
                                grabCursor
                                noSwipingClass="no-swiping"
                                wrapperClass="swiper-gap"
                            >
                                {category.documents.length > 0 &&
                                    category.documents
                                        .sort((a, b) => a.position - b.position)
                                        .map((file) => (
                                            <SwiperSlide
                                                key={"f_" + file.id}
                                                className={documentToModify?.id === file.id ? "no-swiping" : ""}
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={(e) => {
                                                    const targetDoc: IDocumentFile = JSON.parse(e.dataTransfer.getData("text"));
                                                    if (targetDoc.id === file.id || targetDoc.category.id !== file.category.id) return;
                                                    updateDocumentPosition(targetDoc, file);
                                                }}
                                            >
                                                <PDFDocument
                                                    document={file}
                                                    enableModification
                                                    onEdit={() => {
                                                        setDocumentToModify(file);
                                                        setModalType("doc-create");
                                                    }}
                                                    onDelete={() => {
                                                        setDocumentToModify(file);
                                                        setModalType("doc-delete");
                                                    }}
                                                />
                                                {categoryIdPositionUpdate === category.id && (
                                                    <Box
                                                        onClick={() => setDocumentToModify(file)}
                                                        draggable
                                                        onDragStart={(e) => e.dataTransfer.setData("text", JSON.stringify(file))}
                                                        onDragEnd={() => setDocumentToModify(undefined)}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            backgroundColor: "#eee",
                                                            width: "fit-content",
                                                            borderRadius: "50%",
                                                            padding: "8px",
                                                            position: "absolute",
                                                            bottom: "0",
                                                            right: "0",
                                                            cursor: documentToModify?.id === file.id ? "grab" : "pointer",
                                                        }}
                                                    >
                                                        <Tooltip arrow title={documentToModify?.id === file.id ? "Drag" : "Unlock"}>
                                                            {documentToModify?.id === file.id ? <DragIndicator /> : <Lock />}
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                            </SwiperSlide>
                                        ))}
                                {user?.userProfiles?.systemAdmin && (
                                    <SwiperSlide>
                                        <Box
                                            style={{
                                                width: "200px",
                                                minHeight: "300px",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                boxShadow: "0 0 12px #ccc",
                                                backgroundColor: "rgba(238, 238, 238, 0.5)",
                                            }}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setModalType("doc-create");
                                                    setCategoryToModify(category);
                                                }}
                                                style={{
                                                    display: "inherit",
                                                    padding: 24,
                                                    border: "0",
                                                    borderRadius: "50%",
                                                    backgroundColor: colors.main.primary,
                                                    color: "#fff",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Add style={{ fontSize: "3em" }} />
                                            </Button>
                                        </Box>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </Grid>
                    ))}
            </Grid>

            <CategoryModal
                isOpen={modalType === "cat-create"}
                onClose={() => {
                    setModalType("none");
                    setCategoryToModify(undefined);
                }}
                onSave={updateCategories}
                category={categoryToModify}
            />
            <ConfirmDialog
                open={modalType === "cat-delete"}
                onClose={() => {
                    setModalType("none");
                    setCategoryToModify(undefined);
                }}
                onConfirm={async () => {
                    if (categoryToModify) {
                        await deleteCategory(categoryToModify?.id);
                        setModalType("none");
                        setCategoryToModify(undefined);
                    }
                }}
                text={`Are you sure you want to delete category ${categoryToModify?.categoryName}?`}
            />
            <RearrangeCategoriesModal
                isOpen={modalType === "cat-rearrange"}
                onClose={() => setModalType("none")}
                onSave={(updatedCategories: IDocumentCategory[]) => setCategories(updatedCategories)}
                categories={categories}
            />
            <DocumentModal
                isOpen={modalType === "doc-create"}
                onClose={() => {
                    setModalType("none");
                    setDocumentToModify(undefined);
                    setCategoryToModify(undefined);
                }}
                onSave={(newDocument: IDocumentFile) => {
                    updateCategoryDocument(newDocument);
                    setModalType("none");
                    setDocumentToModify(undefined);
                    setCategoryToModify(undefined);
                }}
                categories={categories}
                document={createNewDocFile(documentToModify)}
                categoryId={categoryToModify?.id}
            />
            <ConfirmDialog
                open={modalType === "doc-delete"}
                onClose={() => {
                    setModalType("none");
                    setDocumentToModify(undefined);
                }}
                onConfirm={async () => {
                    if (documentToModify) {
                        await deleteDocument(documentToModify.id, documentToModify.category.id);
                        setModalType("none");
                        setDocumentToModify(undefined);
                    }
                }}
                text={`Are you sure you want to delete document ${documentToModify?.title}?`}
            />
        </DashboardLayout>
    );
}

function createNewDocFile(document?: IDocumentFile): INewDocumentFile {
    return {
        id: document?.id || undefined,
        title: document?.title || "",
        url: document?.url || "",
        categoryId: document?.category.id || -1,
    };
}

type CategoryModalProps = {
    isOpen: boolean;
    onClose: Function;
    onSave: Function;
    category?: IDocumentCategory;
};

function CategoryModal({ isOpen, onClose, onSave, category }: CategoryModalProps) {
    const [categoryName, setCategoryName] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);

    const handleOnSave = async () => {
        setIsProcessing(true);

        if (category === undefined) {
            await create();
        } else {
            await update();
        }

        setIsProcessing(false);
    };

    const handleOnClose = () => {
        onClose();
        setCategoryName("");
        setIsProcessing(false);
        setErrors([]);
    };

    const create = async () => {
        await apiRequest
            .post("/document-center/category", {
                categoryName: categoryName,
            })
            .then((res) => {
                notify("Document category created", "success");
                onSave(res.data);
                handleOnClose();
            })
            .catch((err) => {
                if (err.response.data?.errors) {
                    setErrors(err.response.data?.errors);
                }
            });
    };

    const update = async () => {
        await apiRequest
            .put("/document-center/category/" + category?.id, {
                categoryName: categoryName,
            })
            .then((res) => {
                notify("Document category updated", "success");
                onSave(res.data);
                handleOnClose();
            })
            .catch((err) => {
                if (err.response.data.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    notify("Error updating document category" + err.response.data.error, "error");
                }
            });
    };

    useEffect(() => {
        setCategoryName(category?.categoryName || "");
    }, [category]);

    return (
        <CustomModal title="Document category" padding={2} width={"32vw"} open={isOpen} onClose={handleOnClose}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        required
                        helperText={errorFieldMessage(errors, "categoryName")}
                        error={errorFieldHandler(errors, "categoryName")}
                        type="text"
                        value={categoryName}
                        label="Category name"
                        fullWidth
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
                    <Button variant="contained" color="primary" disabled={isProcessing} onClick={() => handleOnSave()}>
                        {category ? "Update" : "Create"}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => handleOnClose()}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}

type DocumentModalProps = {
    isOpen: boolean;
    onClose: Function;
    onSave: Function;
    categories?: IDocumentCategory[];
    document: INewDocumentFile;
    categoryId?: number;
};

function DocumentModal({ isOpen, onClose, onSave, categories = [], document, categoryId = -1 }: DocumentModalProps) {
    const cleanDocFile = createNewDocFile();

    const [newDocument, setNewDocument] = useState<INewDocumentFile>(cleanDocFile);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState<any[]>([]);

    const handleOnSave = async () => {
        setIsProcessing(true);

        if (document.id === undefined) {
            await create();
        } else {
            await update();
        }

        setIsProcessing(false);
    };

    const handleOnClose = () => {
        setNewDocument(cleanDocFile);
        setIsProcessing(false);
        setErrors([]);
        onClose();
    };

    const create = async () => {
        await apiRequest
            .post<IDocumentFile>("document-center/document", newDocument)
            .then((res) => {
                notify("Document created successfully", "success");
                onSave(res.data);
                handleOnClose();
            })
            .catch((err) => {
                if (err.response.data?.errors) {
                    setErrors(err.response.data?.errors);
                }
            });
    };

    const update = async () => {
        await apiRequest
            .put<IDocumentFile>("document-center/document/" + newDocument?.id, newDocument)
            .then((res) => {
                notify("Document updated successfully", "success");
                onSave(res.data);
                handleOnClose();
            })
            .catch((err) => {
                if (err.response.data?.errors) {
                    setErrors(err.response.data?.errors);
                }
            });
    };

    useEffect(() => {
        if (categoryId !== -1) {
            document.categoryId = categoryId;
        }

        setNewDocument(document);
    }, [document]);

    return (
        <CustomModal title="Document" padding={2} width={"32vw"} open={isOpen} onClose={handleOnClose}>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <TextField
                        required
                        helperText={errorFieldMessage(errors, "title")}
                        error={errorFieldHandler(errors, "title")}
                        type="text"
                        value={newDocument?.title}
                        label="Title"
                        fullWidth
                        onChange={(e) => setNewDocument((prevState) => ({ ...prevState, title: e.target.value }))}
                    />
                    <TextField
                        required
                        helperText={errorFieldMessage(errors, "url")}
                        error={errorFieldHandler(errors, "url")}
                        type="text"
                        value={newDocument?.url}
                        label="URL"
                        fullWidth
                        onChange={(e) => setNewDocument((prevState) => ({ ...prevState, url: e.target.value }))}
                    />
                    {categoryId === -1 && (
                        <TextField
                            label="Category"
                            value={newDocument?.categoryId === -1 ? null : newDocument?.categoryId}
                            onChange={(e) => setNewDocument((prevState) => ({ ...prevState, categoryId: parseInt(e.target.value) }))}
                            select
                        >
                            {categories &&
                                categories.map((category) => (
                                    <MenuItem key={"c_" + category.id} value={category.id}>
                                        {category.categoryName}
                                    </MenuItem>
                                ))}
                        </TextField>
                    )}
                </Grid>
                <Grid item sx={{ display: "flex", gap: 2, justifyContent: "center", width: "100%" }}>
                    <Button variant="contained" color="primary" disabled={isProcessing} onClick={() => handleOnSave()}>
                        {document.id ? "Update" : "Create"}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => handleOnClose()}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}

type RearrangeCategoriesModalProps = {
    isOpen: boolean;
    onClose: Function;
    onSave: Function;
    categories: IDocumentCategory[];
};

function RearrangeCategoriesModal({ isOpen, onClose, onSave, categories }: RearrangeCategoriesModalProps) {
    const [categoriesList, setCategoriesList] = useState<IDocumentCategory[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const handleOnClose = () => {
        if (hasChanges) {
            onSave(categoriesList);
        }

        setIsLoading(false);
        setHasChanges(false);
        onClose();
    };

    const handleOnDrop = (categoryId: number, newPosition: number) => {
        setIsLoading(true);

        apiRequest
            .put<IDocumentCategory[]>("/document-center/category/change-position", {
                categoryId,
                newPosition,
            })
            .then((res) => {
                onSave(res.data);

                setHasChanges(true);
                setCategoriesList(res.data);
            })
            .catch((err) => {
                notify("Invalid position " + newPosition, "error");
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setCategoriesList(categories);
    }, [categories]);

    return (
        <CustomModal title="Rearrange Categories" padding={2} width={"32vw"} open={isOpen} onClose={handleOnClose}>
            <Grid container direction="column" rowGap={2}>
                {categoriesList.map((category) => (
                    <Grid
                        key={"rc_" + category.id}
                        item
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                            const targetCat: IDocumentCategory = JSON.parse(e.dataTransfer.getData("text"));
                            if (targetCat.id === category.id) return;
                            handleOnDrop(targetCat.id, category.categoryPosition);
                        }}
                    >
                        <Box
                            draggable={!isLoading}
                            onDragStart={(e) => e.dataTransfer.setData("text", JSON.stringify(category))}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                                padding: 1,
                                backgroundColor: "#eee",
                                cursor: isLoading ? "default" : "grab",
                            }}
                        >
                            <DragIndicator />
                            <Typography>{category.categoryName}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </CustomModal>
    );
}

export default DocumentCenter;
