import { useState } from "react";
import { Button, Typography, Grid } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import LogsTable from "./logsTable";

import Breadcrumbs from "../../components/Breadcrumbs";

export default function SupportLogs() {
    const [manageMode, setManageMode] = useState("active");

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="Support Logs" />
            <Typography sx={{ marginTop: 2, marginBottom: 2, color: "rgba(0,0,0,0.7)" }} variant="h4" fontWeight="bold">
                Support Logs
            </Typography>
            <Grid container>
                <Grid item xs={12} marginTop={2}>
                    <Button
                        variant={manageMode == "active" ? "contained" : "outlined"}
                        onClick={() => setManageMode("active")}
                        color="secondary"
                        sx={{ marginRight: 1 }}
                    >
                        {" "}
                        Manager Active
                    </Button>
                    <Button variant={manageMode == "inactive" ? "contained" : "outlined"} onClick={() => setManageMode("inactive")} color="secondary">
                        Manage Inactive
                    </Button>
                </Grid>
                <Grid item xs={12} marginTop={4}>
                    {manageMode === "active" && <LogsTable title={"Active logs"} baseUrl={"/support-log/"} />}
                    {manageMode === "inactive" && <LogsTable title={"Inactive logs"} baseUrl={"/support-log/inactive"} />}
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}
