import { useEffect, useState } from "react";
import { Chip, Button, Grid, Typography, TextField, Select, MenuItem } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import NewClassTypeOther from "../class-types/newClassTypeOther";
import CustomModal from "../../components/CustomModal/CustomModal";
import BasicDatePicker from "../../components/DatePicker";

export default function AddTrainingModal({
    open,
    onClose,
    responders,
    onInspectionAdded = () => {},
    onInspectionAddError = () => {},
    organizationId,
    locationId,
}: any) {
    function handleOnClose(bool: boolean) {
        clearCamps();
        onClose(bool);
    }

    const [trainingExpirationDate, setTrainingExpirationDate] = useState<any>("");
    const [trainingNotes, setTrainingNotes] = useState<any>("");
    const [classTypes, setClassTypes] = useState<any>([]);
    const [currentResponders, setCurrentResponders] = useState(responders || []);
    const [currentClassType, setCurrentClassType] = useState<any>(null);

    async function getClassTypes(otherName: string | null = null) {
        const classTypesInfo = await apiRequest.get("/classtype").then((res) => res.data);
        setClassTypes([...classTypesInfo]);
        if (otherName) {
            setCurrentClassType(classTypesInfo.find((classType: any) => classType.type == otherName));
        } else {
            setCurrentClassType(classTypesInfo[0]);
        }
    }

    async function addTraining() {
        currentResponders?.map((responder: any) => {
            apiRequest
                .put("/responder/add-training/", {
                    responderId: responder.id,
                    classTypeId: currentClassType.id,
                    expirationDate: new Date(trainingExpirationDate).toISOString().slice(0, 10),
                    notes: trainingNotes,
                })
                .then((res) => {
                    onInspectionAdded();
                    onClose(true);
                    notify("Training added", "success");
                })
                .catch((err) => {
                    // notify(err.response.data?.errors[0]?.message, "error");
                    onInspectionAddError(err.response.data.error || `${err.response.data.errors[0].field || ""} ${err.response.data.errors[0].message}`);
                });
        });
    }

    function clearCamps() {
        setCurrentResponders([]);
        setTrainingExpirationDate("");
        setTrainingNotes("");
    }

    function removeResponderFromList(responder: any) {
        const newResponders = currentResponders;
        newResponders.splice(currentResponders.indexOf(responder), 1);
        setCurrentResponders([...newResponders]);
    }

    useEffect(() => {
        if (Array.isArray(responders) && open) {
            setCurrentResponders([...responders]);
        }
    }, [responders, open]);

    useEffect(() => {
        getClassTypes();
    }, []);

    const [newClassTypeOpen, setNewClassTypeOpen] = useState(false);

    return (
        <CustomModal padding={2} open={open} title="Add Training" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Add Training</Typography>
                </Grid>

                <Grid item marginTop={2} xs={12}>
                    <Typography variant="caption">You're adding the training to the following responders:</Typography>
                </Grid>

                <Grid item marginBottom={4} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }} xs={12}>
                    {currentResponders.map((responder: any) => (
                        <Chip label={responder?.name} onDelete={() => removeResponderFromList(responder)} />
                    ))}
                </Grid>

                <NewClassTypeOther
                    onUserAdded={(classTypeName: string) => getClassTypes(classTypeName)}
                    open={newClassTypeOpen}
                    onClose={() => {
                        setNewClassTypeOpen(false);
                    }}
                />

                <Grid item xs={12} md={6}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={currentClassType}
                        label="Class Type"
                        MenuProps={{
                            sx: { maxHeight: 250 },
                        }}
                        onChange={(evt) => {
                            if (evt.target.value === "other") {
                                setNewClassTypeOpen(true);
                            } else {
                                setCurrentClassType(evt.target.value);
                            }
                        }}
                    >
                        {classTypes.map((classType: any) => (
                            <MenuItem key={classType.id} value={classType}>
                                {classType.type}
                            </MenuItem>
                        ))}

                        <MenuItem key={"other"} value={"other"}>
                            Other
                        </MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                    <BasicDatePicker
                        PickerProps={{ label: "Expiration Date" }}
                        value={trainingExpirationDate}
                        onChange={(date) => {
                            setTrainingExpirationDate(date);
                        }}
                        InputProps={{ fullWidth: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField label="Notes" value={trainingNotes} fullWidth onChange={(evt) => setTrainingNotes(evt.target.value)} />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => addTraining()} fullWidth color="success">
                        ADD
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
