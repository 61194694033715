import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { aedModel } from "../../../entities/aedModel";
import apiRequest from "../../../helpers/apiRequest";
import { notify } from "../../../helpers/notify";
import { errorHandler, errorFieldHandler, errorFieldMessage } from "../../../helpers/errorHelper";
import BasicDatePicker from "../../DatePicker";
import AccessoriesList from "./accessoryList";

type AccessoriesProps = {
    accessories: any[];
    onAccessoriesListChange: (list: any) => any;
    editMode: boolean;
    aedId: number | string | null;
    aedModel: aedModel | null;
    error: boolean;
    helperText?: string;
};

export default function Accessories({
    aedModel,
    accessories = [],
    onAccessoriesListChange = (list) => {},
    editMode = false,
    aedId = null,
    error = false,
    helperText,
}: AccessoriesProps) {
    const [accessoriesList, setAccessoriesList] = useState<any>(accessories);

    const [addAccessoryOpen, setAddAccessoryOpen] = useState<any>(false);

    const [accessoryType, setAccessoryType] = useState<any>(null);
    const [accessoryOperationLevel, setAccessoryOperationLevel] = useState<any>(null);
    const [expirationDate, setExpirationDate] = useState<any>("");

    const [errors, setErrors] = useState<any>([]);

    function isInstallationDate() {
        return accessoryType === "BATTERY" && aedModel?.fixedBatteryExpirationDate === false;
    }

    function isBackupInstallationDate() {
        return isInstallationDate() && accessoryOperationLevel === "BACKUP";
    }

    async function addAccessoryHandler() {
        if (editMode) {
            await apiRequest
                .post(`/aed/${aedId}/accessory/`, {
                    type: accessoryType,
                    expiration: expirationDate ? new Date(expirationDate).toISOString().slice(0, 10) : null,
                    operationLevel: accessoryType === "PEDIATRIC_PAD" ? null : accessoryOperationLevel,
                })
                .then((res) => {
                    setAccessoriesList([...res.data?.accessories]);
                    setAddAccessoryOpen(false);

                    setAccessoryType("");
                    setAccessoryOperationLevel("");
                    setExpirationDate("");

                    notify("Accessory added", "success");
                })
                .catch((err) => {
                    setErrors(() => errorHandler(err));
                    notify(err.response?.data?.errors[0]?.message, "error");
                });
        } else {
            addAccessory();
        }
    }

    async function addAccessory(id: string = String(new Date().getTime()), operationLevel = null) {
        if (!aedModel) return notify("Select a model first", "error");

        let validExpirationDate = expirationDate ? format(expirationDate, "yyyy-MM-dd") : "";
        let isInvalidAccessory = false;

        await apiRequest
            .post("/aed/accessory-valid", {
                aedModelId: aedModel.id,
                type: accessoryType,
                operationLevel: accessoryType === "PEDIATRIC_PAD" ? null : accessoryOperationLevel,
                expiration: validExpirationDate,
            })
            .then((res) => {
                validExpirationDate = res.data.expiration;
            })
            .catch((err) => {
                isInvalidAccessory = true;
                setErrors(() => errorHandler(err));
            });

        if (isInvalidAccessory) return;

        let newList = Object.assign([], accessoriesList);
        newList.push({
            id: id ?? new Date().getTime(),
            type: accessoryType,
            operationLevel: operationLevel || accessoryOperationLevel || "N/A",
            expiration: validExpirationDate,
            expirationLabel: validExpirationDate,
            installationDate: expirationDate ? format(expirationDate, "yyyy-MM-dd") : "",
        });

        setAccessoriesList([...newList]);
        setAddAccessoryOpen(false);

        setAccessoryType("");
        setAccessoryOperationLevel("");
        setExpirationDate("");

        setErrors([]);
    }

    useEffect(() => {
        onAccessoriesListChange(accessoriesList);
    }, [accessoriesList]);

    return (
        <Grid item xs={12}>
            <Grid
                item
                xs={12}
                sx={{
                    paddingTop: 2,
                    paddingBottom: 1,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h6">Accessories</Typography>
                <Button onClick={() => setAddAccessoryOpen(!addAccessoryOpen)} variant="text">
                    Add supplies {!addAccessoryOpen ? <IoChevronForward style={{ marginLeft: 10 }} /> : <IoChevronDown style={{ marginLeft: 10 }} />}{" "}
                </Button>
            </Grid>

            {addAccessoryOpen && (
                <Grid
                    item
                    gap={2}
                    xs={12}
                    sx={{
                        paddingBottom: 1,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                error={errorFieldHandler(errors, "type")}
                                onChange={(evt) => setAccessoryType(evt.target.value)}
                                value={accessoryType}
                                fullWidth
                                label="Type"
                            >
                                {aedModel?.comboBatteryAndPads ? (
                                    <MenuItem value="BATTERY_PAD_COMBO">BATTERY PAD COMBO</MenuItem>
                                ) : (
                                    [
                                        <MenuItem value="ADULT_PAD">ADULT PAD</MenuItem>,
                                        aedModel?.adultPadiCPROption && <MenuItem value="ADULT_PAD_ICPR">ADULT PAD iCPR</MenuItem>,
                                        <MenuItem value="BATTERY">BATTERY</MenuItem>,
                                    ]
                                )}
                                <MenuItem disabled={aedModel !== null && !aedModel?.pediatricPadsOption} value="PEDIATRIC_PAD">
                                    PEDIATRIC PAD
                                </MenuItem>
                            </Select>
                            <FormHelperText error>{errorFieldMessage(errors, "type")}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-labelx">Operation Level</InputLabel>
                            <Select
                                error={errorFieldHandler(errors, "operationLevel")}
                                disabled={accessoryType === "PEDIATRIC_PAD" || !accessoryType}
                                onChange={(evt) => setAccessoryOperationLevel(evt.target.value)}
                                value={accessoryOperationLevel}
                                fullWidth
                                label="Operation Level"
                            >
                                <MenuItem value="PRIMARY">PRIMARY</MenuItem>
                                <MenuItem value="BACKUP">BACKUP</MenuItem>
                            </Select>
                            <FormHelperText error>{errorFieldMessage(errors, "operationLevel")}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <BasicDatePicker
                            error={errorFieldHandler(errors, "expiration")}
                            isDisabled={isBackupInstallationDate() || (!accessoryOperationLevel && accessoryType !== "PEDIATRIC_PAD")}
                            value={expirationDate}
                            onChange={(date) => setExpirationDate(date)}
                            PickerProps={{
                                size: "small",
                                label: isInstallationDate() ? "Installation Date" : "Expiration Date",
                            }}
                        />
                        <FormHelperText error>{errorFieldMessage(errors, "expiration")}</FormHelperText>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Button onClick={() => addAccessoryHandler()} sx={{ height: 55 }} fullWidth variant="contained">
                            Add
                        </Button>
                    </Grid>
                </Grid>
            )}

            <div style={error ? { border: "1px solid red", borderRadius: 3 } : {}}>
                <AccessoriesList
                    isFixedBatteryExpirationDate={aedModel?.fixedBatteryExpirationDate}
                    aedId={aedId}
                    aedModelId={aedModel?.id}
                    editMode={editMode}
                    onUpdate={(list) => setAccessoriesList([...list])}
                    accessories={accessoriesList}
                />
            </div>
            {error && (
                <Typography variant="caption" color="#e04">
                    {helperText}
                </Typography>
            )}
        </Grid>
    );
}
