import { useState, useEffect } from "react";
import { Button, Grid, TextField } from "@mui/material";
import CustomModal from "../../components/CustomModal/CustomModal";
import { notify } from "../../helpers/notify";
import customConfirmAlert from "../../components/confirmAlert";
import apiRequest from "../../helpers/apiRequest";

export default function EditClassType({ classDetails, open, onClose, onSave }) {
    const [className, setClassName] = useState("");
    const [updated, setUpdated] = useState(true);

    const handleOnClose = (bool) => {
        if (!updated) {
            customConfirmAlert(() => {
                setUpdated(true);
                onClose(bool);
            });
        } else {
            onClose(bool);
        }
    };

    const handleOnSave = () => {
        apiRequest
            .put(`/classtype/${classDetails.id}`, {
                type: className,
            })
            .then((res) => {
                setUpdated(true);
                onClose(false);
                onSave(res.data); // {id: Number, type: String}
                notify("Class type updated", "success");
            })
            .catch((err) => {
                const errorMessaage = err.response.data.errors[0]?.message ?? "Unexpected error, please try again soon";
                notify(errorMessaage, "error");
            });
    };

    useEffect(() => {
        setClassName(classDetails.type);
    }, [classDetails]);

    return (
        <CustomModal padding={2} open={open} title="Edit Class Type" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        value={className}
                        onChange={(e) => {
                            setUpdated(false);
                            setClassName(e.target.value);
                        }}
                        placeholder="Type the class type name"
                        label="Class Type"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={handleOnSave} fullWidth color="success">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
