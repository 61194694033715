import { Box, Typography } from "@mui/material";
import { SwapHoriz } from "@mui/icons-material";
import { useEffect, useState } from "react";
import "../../../App.css";
import CustomModal from "../../CustomModal/CustomModal";

export default function ModelSelect({ error = false, helperText = "", aed, onClickToChange = () => {}, isEdit = false }) {
    const [aedSelected, setAedSelected] = useState(null);

    useEffect(() => {
        setAedSelected(aed);
    }, [aed]);

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 1,
                    borderRadius: 1,
                    gap: 1,
                    border: error ? "1px solid #e04" : "1px solid rgba(0,0,0,0.1)",
                }}
            >
                {!isEdit && (
                    <Box
                        onClick={() => onClickToChange()}
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 35,
                            height: 35,
                            border: "1px solid #2a5886",
                            marginLeft: 1,
                            borderRadius: 40,
                        }}
                    >
                        <SwapHoriz color="primary" />
                    </Box>
                )}

                {aedSelected && (
                    <Box sx={{ display: "flex" }}>
                        <ImageModal
                            urlPhoto={aed?.urlPhoto || "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                            model={aed?.model}
                            manufacturer={aed?.manufacturer}
                        />
                        <Box
                            sx={{
                                justifyContent: "center",
                                paddingLeft: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="caption">{aed?.manufacturer}</Typography>
                            <Typography variant="h6">{aed?.model}</Typography>
                        </Box>
                    </Box>
                )}

                {!aedSelected && (
                    <Box sx={{ display: "flex", padding: 1 }}>
                        <Typography variant="body1">Select a model</Typography>
                    </Box>
                )}
            </Box>
            {error && (
                <Typography variant="caption" color="error">
                    {helperText}
                </Typography>
            )}
        </Box>
    );
}

function ImageModal({ urlPhoto, model, manufacturer }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <img
                src={urlPhoto}
                onClick={() => setOpen(true)}
                style={{
                    cursor: "pointer",
                    width: 60,
                    height: 60,
                }}
                alt=""
            />
            <CustomModal onClose={() => setOpen(false)} title={`${model} | ${manufacturer}`} open={open} width={"50vw"}>
                <img
                    alt=""
                    style={{
                        maxHeight: "40vh",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                    }}
                    src={urlPhoto || "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                />
            </CustomModal>
        </>
    );
}
