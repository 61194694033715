import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, TextField } from "@mui/material";
import { DateHelper } from "../../helpers/dateHelper";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorHandler, errorProps } from "../../helpers/errorHelper";
import CustomModal from "../../components/CustomModal/CustomModal";
import customConfirmAlert from "../../components/confirmAlert";

export default function NotesModal({ admin = false, open, onClose, onNoteAdded = () => {} }: any) {
    const { organizationId } = useParams();

    const [note, setNote] = useState("");

    function handleOnClose(bool: boolean) {
        if (!bool && note.length > 0) {
            customConfirmAlert(() => {
                setNote("");
                onClose(bool);
            });
        } else {
            setNote("");
            onClose(bool);
        }
    }

    const [errors, setErrors] = useState<any[]>([]);

    async function addNote() {
        const timezone = new Date().getTimezoneOffset();
        const date = new Date(Date.now() - timezone);

        await apiRequest
            .post(`organization/${organizationId}/${admin ? "admin-notes" : "notes"}/`, {
                date: DateHelper.toLocalISOString(date).slice(0, 10),
                text: note,
            })
            .then(async (res) => {
                handleOnClose(true);
                notify("Note created", "success");
                onNoteAdded();
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    return (
        <CustomModal padding={2} open={open} title={admin ? "Add admin note" : "Add note"} onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField {...errorProps(errors, "text")} onChange={(e) => setNote(e.target.value)} placeholder="Type a note here..." fullWidth />
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={() => addNote()} variant="contained" color="success" fullWidth>
                        Add
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
