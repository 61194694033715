import { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import CustomModal from "../../components/CustomModal/CustomModal";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import customConfirmAlert from "../../components/confirmAlert";
import { errorHandler, errorProps } from "../../helpers/errorHelper";

export default function NewClassTypeOther({ classType = null, open, onClose, onUserAdded = (createdTypeName) => {}, onUserAddError = () => {} }) {
    const [updated, setUpdated] = useState(true);
    const [errors, setErrors] = useState([]);

    function handleOnClose(bool) {
        if (!updated) {
            customConfirmAlert(() => {
                onClose(bool);
                setClassTypeName("");

                setTimeout(() => {
                    clearCamps();
                }, 250);
            });
        } else {
            setClassTypeName("");
            onClose(bool);
        }
    }

    const [classTypeName, setClassTypeName] = useState("");
    const [classTypeOther, setClassTypeOther] = useState(true);

    async function createClassTypeRequest() {
        if (classTypeName == "") {
            return notify("You need to define a name to the class type", "error");
        }

        await apiRequest
            .post(`/classtype/${classTypeOther ? "other" : ""}`, {
                type: classTypeName,
            })
            .then((res) => {
                onUserAdded(classTypeName);
                onClose(true);
                notify("Class type created", "success");
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    function clearCamps() {
        setClassTypeName("");
        setClassTypeOther(false);
    }

    useEffect(() => {
        if (classType !== null) {
            setClassTypeName(classType.type);
        }
    }, [classType]);

    return (
        <CustomModal padding={2} open={open} title="Create Other Class Type" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        value={classTypeName}
                        onChange={(e) => {
                            setUpdated(false);
                            setClassTypeName(e.target.value);
                        }}
                        placeholder="Type the class type name"
                        label="Other Class Type"
                        {...errorProps(errors, "type")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => createClassTypeRequest()} fullWidth color="success">
                        {classType !== null ? "SAVE" : "CREATE"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
