import { getForecastSubscription } from "../../services/forecast/getForecast";

const useForecastContainer = () => {
    const getForecastList = getForecastSubscription;

    return {
        getForecastList,
    };
};

export { useForecastContainer };
