import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, Box, Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { DateFormat } from "../../helpers/DateFormat";
import CreateOrganization from "./createOrganization";
import SendEmail from "./sendEmail";
import EditCompany from "./editCompany";
import DashboardLayout from "../../layouts/DashboardLayout";
import MuiTable from "../../components/MuiTable";
import ConfirmDialog from "../../components/ConfirmDialog";
import Breadcrumbs from "../../components/Breadcrumbs";
import SearchFilter from "../../components/SearchFilter";

export default function Home() {
    const columns = [
        {
            label: "id",
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Name",
            name: "name",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => navigate(`/organization/${tableMeta.tableData[tableMeta.rowIndex][0]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "500px",
                    },
                }),
            },
        },
        {
            label: "Address",
            name: "mainAddress_address",
        },
        {
            label: "Expiration",
            name: "expiration",
            options: {
                customBodyRender: (value: any) => <span>{DateFormat(value)}</span>,
            },
        },
        {
            label: "Subscription",
            name: "subscription",
        },
        {
            label: "Status",
            name: "isActive",
            options: {
                customBodyRender: (value: any) => <span>{value ? "Active" : "Inactive"}</span>,
            },
        },
    ];

    const [contentLoaded, setContentLoaded] = useState(false);
    const [contentUpdated, setContentUpdated] = useState(true);

    async function getOrganizationInfo() {
        setContentUpdated(false);
        setContentUpdated(true);
        setContentLoaded(true);
    }

    useEffect(() => {
        getOrganizationInfo();
    }, []);

    const [ModalOpen, setModalOpen] = useState(false);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [organizationToDelete, setOrganizationToDelete] = useState(null);

    function disableOrganization(id: any) {
        setConfirmOpen(true);
        setOrganizationToDelete(id);
    }

    async function disableOrganizationRequest() {
        apiRequest
            .put(`/organization/${organizationToDelete}/disable`)
            .then((res) => {
                if (res.status == 200) {
                    setConfirmOpen(false);
                    notify("Organization disabled", "success");
                    getOrganizationInfo();
                }
            })
            .catch((err) => {
                notify("Error deleting organization", "error");
                console.log(err);
            });
    }

    const [searchFilter, setSearchFilter] = useState("");

    function changeFilter(filter: string) {
        setSearchFilter(filter);
    }

    useEffect(() => {
        getOrganizationInfo();
    }, [searchFilter]);

    const navigate = useNavigate();

    const [organizationsSelected, setOrganizationsSelected] = useState<any>([]);

    const [sendEmailOpen, setSendEmailOpen] = useState(false);

    const [companyToEdit, setCompanyToEdit] = useState(null);

    function refreshContent() {
        setContentUpdated(false);
        setTimeout(() => {
            setContentUpdated(true);
        }, 75);
    }

    return (
        <DashboardLayout>
            <Breadcrumbs currentPage="Home" />
            {contentLoaded && (
                <motion.div style={{ flex: "1 1 auto", height: "100%", flexGrow: 1, display: "flex", flexDirection: "column", padding: 20, paddingTop: 5 }}>
                    <Grid container padding={1} paddingTop={3} display="flex" spacing={2} sx={{ overflowY: "auto", flex: 1 }}>
                        <ConfirmDialog open={confirmOpen} onConfirm={() => disableOrganizationRequest()} onClose={() => setConfirmOpen(false)} />

                        <Grid item xs={12}>
                            <Typography variant="h6" color="#5a5c69" fontWeight="600">
                                Organizations
                            </Typography>

                            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", paddingTop: 10 }}>
                                <SearchFilter onChangeFilter={(filter) => changeFilter(filter)} />

                                <Button
                                    sx={{ height: 40, minWidth: 130, marginLeft: 2 }}
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                    variant="contained"
                                    color="success"
                                >
                                    ADD NEW
                                </Button>
                                <SendEmail open={sendEmailOpen} onClose={() => setSendEmailOpen(false)} selectedOrganizations={organizationsSelected} />
                                <Tooltip disableHoverListener={organizationsSelected.length > 0} title="Select one or more organizations to cast a message">
                                    <Button
                                        sx={{ height: 40, minWidth: 120, marginLeft: 2 }}
                                        onClick={() => {
                                            organizationsSelected.length > 0 && setSendEmailOpen(true);
                                        }}
                                        variant={organizationsSelected.length > 0 ? "contained" : "outlined"}
                                    >
                                        SEND EMAIL
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Grid>

                        <CreateOrganization
                            onClose={() => setModalOpen(false)}
                            onOrganizationCreate={() => {
                                setModalOpen(false);
                                getOrganizationInfo();
                            }}
                            open={ModalOpen}
                        />

                        {companyToEdit !== null && (
                            <EditCompany
                                open={companyToEdit !== null}
                                onCompanyUpdate={() => refreshContent()}
                                company={companyToEdit}
                                onClose={() => setCompanyToEdit(null)}
                            />
                        )}

                        <Grid item xs={12} sx={{ height: "calc(100% - 180px)", display: "flex", width: "100%", paddingBottom: 5, flex: 1 }}>
                            {contentUpdated && (
                                <MuiTable
                                    noView
                                    onEdit={(data: any) => setCompanyToEdit(data)}
                                    onRowSelectionChangeWithData={(data: any) => {
                                        setOrganizationsSelected(data);
                                    }}
                                    pagination
                                    paginationURL={`/organization`}
                                    search={searchFilter}
                                    onDelete={(data: any) => disableOrganization(data.id)}
                                    onView={(location: any) => navigate("/organization/" + location.id)}
                                    key="tableOrganizationsList"
                                    columns={columns}
                                />
                            )}
                        </Grid>
                    </Grid>
                </motion.div>
            )}
        </DashboardLayout>
    );
}
