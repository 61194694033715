import { Button, Card, Grid, Modal, Typography } from "@mui/material";

export default function ConfirmDialog({ open, onClose, onConfirm, text = "Confirm?" }) {
    return (
        <Modal open={open} onClose={onClose} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
            <Card sx={{ height: 200, maxWidth: 600, width: "80%", borderRadius: 1 }}>
                <Grid container spacing={2} alignItems="center" height="100%" padding={2} justifyContent="space-between">
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <Typography variant="h6">{text}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item display="flex" justifyContent="center" gap={2}>
                            <Button variant="contained" onClick={() => onConfirm()}>
                                Yes
                            </Button>
                            <Button variant="outlined" onClick={() => onClose()}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Modal>
    );
}
