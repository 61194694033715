import { AxiosResponse, AxiosError } from "axios";
import { Location } from "../../entities/location";
import apiRequest from "../../helpers/apiRequest";
import { DateHelper } from "../../helpers/dateHelper";

type forecastResponseDTO = {
    forecastAccessoriesDto: forecastAccessoriesDTO[];
    accessoriesQuantities: forecastAccessoryQuantity[];
};

type forecastAccessoriesDTO = {
    locationDto: Location;
    size: number;
    accessoriesForecastDto: accessoriesForecastResponseDTO[];
};

export type forecastAccessoryQuantity = {
    aedModel: {
        id: number;
        model: string;
        manufacturer: string;
        urlPhoto: string;
        comboBatteryAndPads: boolean;
        pediatricPadsOption: boolean;
        fixedBatteryExpirationDate: boolean;
        batteryYearsExpiration: number;
        warrantyYears: number;
        adultPadiCPROption: boolean;
    };
    quantityByModel: {
        aedModelId: number;
        battery: number;
        adultPad: number;
        pediatricPad: number;
        batteryPadCombo: number;
        adultPadIcpr: number;
    };
};

export type accessoriesForecastResponseDTO = {
    id: number;
    type: string;
    expires: string;
    aedId: number;
    aedModelId: number;
    aedModel: string;
    aedPlacement: string;
    aedSerial: string;
    overdue: boolean;
    dueNow: boolean;
    note: string;
};

export type forecastByOrganization = {
    name: string;
    id: number;
    subscription: string;
    forecastList: forecastAccessoriesDTO[];
};

export type forecastSubscriptionList = {
    overDueOrganizations: [forecastSubscription];
    expirationUpcoming: [forecastSubscription];
};

type forecastSubscription = {
    organizationName: string;
    subscriptionOrg: string;
    expiration: string;
    totalAeds: number;
    organizationId: number;
    programCoordinators: [
        {
            id: number;
            fullName: string;
            fone: string;
            email: string;
            organizationId: number;
            profiles: [string];
        }
    ];
};

const getForecast = async (start: string, end: string) => {
    start = DateHelper.dateToString(start);
    end = DateHelper.dateToString(end);

    const forecastInfo = await apiRequest
        .post(`/forecast/accessories-bydate`, {
            start,
            end,
        })
        .then((res: AxiosResponse) => res.data);

    return forecastAdapter(forecastInfo);
};

export const forecastAdapter = (forecastList: forecastResponseDTO) => {
    const organizations: forecastByOrganization[] = [];
    const accessories: forecastAccessoryQuantity[] = forecastList.accessoriesQuantities;

    forecastList.forecastAccessoriesDto.forEach((forecast) => {
        const idx = organizations.map((org) => org.id).indexOf(forecast.locationDto.organization.id);

        if (idx === -1) {
            organizations.push({
                name: forecast.locationDto.organization.name,
                id: forecast.locationDto.organization.id,
                subscription: forecast.locationDto.organization.subscription,
                forecastList: [forecast],
            });
        } else {
            organizations[idx].forecastList.push(forecast);
        }
    });

    return { organizations, accessories };
};

const getForecastSubscription = async (start?: string, end?: string, isDefault?: boolean) => {
    const forecastInfo = await apiRequest
        .get("/forecast/organizations", isDefault ? {} : { params: { start, end } })
        .then((res: AxiosResponse) => res.data)
        .catch((err: AxiosError) => {
            return err.response?.data;
        });

    return forecastInfo;
};

export { getForecast, getForecastSubscription };
