import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomModal from "../../components/CustomModal/CustomModal";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorFieldHandler, errorFieldMessage, errorHandler } from "../../helpers/errorHelper";

export default function EditShipTo({ open, onClose, onEdit = () => {}, location }) {
    const [shipToName, setShipToName] = useState("");
    const [shipToAddress, setShipToAddress] = useState("");
    const [shipToCity, setShipToCity] = useState("");
    const [shipToState, setShipToState] = useState("");
    const [shipToCounty, setShipToCounty] = useState("");
    const [shipToPhone, setShipToPhone] = useState("");
    const [shipToZipCode, setShipToZipCode] = useState("");
    const [errors, setErrors] = useState([]);

    function editLocationHandler() {
        setShipToName(location?.shipTo?.name);
        setShipToPhone(location?.shipTo?.fone);
        setShipToAddress(location?.shipTo?.address?.address);
        setShipToCity(location?.shipTo?.address?.city);
        setShipToCounty(location?.shipTo?.address?.county);
        setShipToState(location?.shipTo?.address?.state);
        setShipToZipCode(location?.shipTo?.address?.zipCode);
    }

    useEffect(() => {
        editLocationHandler();
    }, [location]);

    async function updateLocation() {
        await apiRequest
            .put("/location/" + location.id + "/shipto", {
                shipTo: {
                    name: shipToName,
                    fone: shipToPhone,
                    address: {
                        address: shipToAddress,
                        city: shipToCity,
                        state: shipToState,
                        zipCode: shipToZipCode,
                        county: shipToCounty,
                    },
                },
            })
            .then(() => {
                notify("Location Updated", "success");
                onEdit();
            })
            .catch((err) => {
                setErrors(errorHandler(err));
                console.log(errors);

                notify("Error updating location", "error");
            });
    }

    return (
        <CustomModal title="Edit Location Ship To" open={open} onClose={() => onClose()}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="caption">Ship To</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "shipTo.name")}
                        error={errorFieldHandler(errors, "shipTo.name")}
                        size="small"
                        label="Name"
                        value={shipToName}
                        fullWidth
                        onChange={(evt) => setShipToName(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "shipTo.fone")}
                        error={errorFieldHandler(errors, "shipTo.fone")}
                        size="small"
                        label="Phone"
                        value={shipToPhone}
                        fullWidth
                        onChange={(evt) => setShipToPhone(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                        helperText={errorFieldMessage(errors, "shipTo.address.address")}
                        error={errorFieldHandler(errors, "shipTo.address.address")}
                        size="small"
                        label="Address"
                        value={shipToAddress}
                        fullWidth
                        onChange={(evt) => setShipToAddress(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "shipTo.address.city")}
                        error={errorFieldHandler(errors, "shipTo.address.city")}
                        size="small"
                        label="City"
                        value={shipToCity}
                        fullWidth
                        onChange={(evt) => setShipToCity(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "shipTo.address.state")}
                        error={errorFieldHandler(errors, "shipTo.address.state")}
                        size="small"
                        label="State"
                        value={shipToState}
                        fullWidth
                        onChange={(evt) => setShipToState(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "shipTo.address.county")}
                        error={errorFieldHandler(errors, "shipTo.address.county")}
                        size="small"
                        label="County"
                        value={shipToCounty}
                        fullWidth
                        onChange={(evt) => setShipToCounty(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "shipTo.address.zipCode")}
                        error={errorFieldHandler(errors, "shipTo.address.zipCode")}
                        size="small"
                        label="ZipCode"
                        value={shipToZipCode}
                        fullWidth
                        onChange={(evt) => setShipToZipCode(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => updateLocation()} fullWidth color="success">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
