import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Grid,
    Typography,
    TextField,
    Box,
    Switch,
    FormGroup,
    FormControlLabel,
    styled,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Autocomplete,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorHandler, errorProps } from "../../helpers/errorHelper";
import useAuth from "../../hooks/useAuth";
import CustomModal from "../../components/CustomModal/CustomModal";
import { FaTrash } from "react-icons/fa";
import ChangePassword from "./changePassword";

export default function EditUser({ onDelete = (user) => {}, user, open, onClose, onUserAdded = () => {}, onUserAddError = () => {} }) {
    const { user: myUser } = useAuth();
    const navigate = useNavigate();

    function handleOnClose(bool) {
        onClose(bool);
        clearCamps();
    }

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fone, setFone] = useState("");
    const [email, setEmail] = useState("");
    const [notifications, setNotifications] = useState(user?.notifications);
    const [userEnabled, setUserEnabled] = useState(user?.userStatus);

    const [errors, setErrors] = useState([]);

    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const [isProgramProfile, setIsProgramProfile] = useState(false);
    const [isAdminRole, setIsAdminRole] = useState(false);

    const [places, setPlaces] = useState([]);
    const [locations, setLocations] = useState([]);
    const [organizations, setOrganizations] = useState([]);

    const [profile, setProfile] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [placeId, setPlaceId] = useState(null);

    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

    function handleChangeUserStatus(enabled) {
        if (enabled) {
            enableUser();
        } else {
            disableUser();
        }
    }

    async function enableUser() {
        await apiRequest
            .put(`/user/${user.id}/enable`)
            .then((res) => {
                notify("User enabled", "success");
                onUserAdded();
                setUserEnabled(true);
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    async function disableUser() {
        await apiRequest
            .put(`/user/${user.id}/disable`)
            .then((res) => {
                notify("User disabled", "success");
                setUserEnabled(false);
                onUserAdded();
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    async function updateUser() {
        await apiRequest
            .put(`/user/${user.id}`, {
                firstName: firstName,
                lastName: lastName,
                fone: fone,
                email: email,
            })
            .then((res) => {
                onUserAdded();
                handleOnClose(true);
                notify("User updated", "success");
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    notify(err?.response?.data?.error, "error");
                }
            });
    }

    async function updateNotifications(enable) {
        if (enable) {
            await apiRequest.put(`/user/${user?.id}/enable-notifications`).then((res) => {
                notify("Notifications enabled!", "success");
                setNotifications(enable);
            });
        } else {
            await apiRequest.put(`/user/${user?.id}/disable-notifications`).then((res) => {
                notify("Notifications disabled!", "success");
                setNotifications(enable);
            });
        }
    }

    function getAllLocations() {
        if (!organization) return;

        apiRequest.get(`location/all/${organization}`).then((res) => {
            const locationList = res.data?.map((location) => {
                return { id: location.id, label: location.name };
            });

            setLocations(locationList);
        });
    }

    function getAllOrganizations() {
        apiRequest.get("organization/all").then((res) => {
            const organizationList = res.data?.map((organization) => {
                return { id: organization.id, label: organization.name };
            });

            setOrganizations(organizationList);
        });
    }

    function addNotification() {
        apiRequest
            .post("/user/notification-profile", {
                email: user?.email,
                profile: profile,
                placeId: placeId,
            })
            .then((res) => {
                setPlaces(res.data?.notificationProfiles);
                notify("Notification added", "success");
            })
            .catch((err) => {
                notify(err.response.data.errors[0].message, "error");
            });
    }

    function removeNotification(notificationId) {
        apiRequest
            .put(`/user/${user?.id}/notification-profile/${notificationId}/remove`)
            .then((res) => {
                setPlaces(res.data?.notificationProfiles);
                notify("Notification removed", "success");
            })
            .catch((err) => {
                notify(err.response.data.errors[0].message, "error");
            });
    }

    function clearCamps() {
        setFirstName("");
        setLastName("");
        setFone("");
        setEmail("");
        setErrors([]);
    }

    useEffect(() => {
        setFirstName(user?.firstName);
        setLastName(user?.lastName);
        setFone(user?.fone);
        setEmail(user?.email);

        setPlaces(user?.notificationProfiles);

        setProfile(null);
        setOrganization(null);
        setPlaceId(null);

        setIsAdminRole(user?.userProfiles.systemAdmin || user?.userProfiles.franchise);

        if (user?.userProfiles.systemAdmin) {
            getAllOrganizations();
        }

        if (user?.userProfiles.franchise) {
            const franchiseOrgs = user?.franchise.orgs.map((org) => {
                return {
                    id: org.id,
                    label: org.name,
                };
            });

            setOrganizations(franchiseOrgs);
        }

        if (!user?.userProfiles.systemAdmin && !user?.userProfiles.franchise) {
            setOrganization(user?.organization.id);
        }
    }, [open, user]);

    useEffect(() => {
        const checkProfile = profile === "ROLE_PROGRAM_COORDINATOR" || profile === "ROLE_PROGRAM_STAFF";

        if (checkProfile) setPlaceId(user?.organization?.id);
        setIsProgramProfile(checkProfile);

        if (isAdminRole && !checkProfile) setOrganization(null);
    }, [profile]);

    useEffect(() => {
        getAllLocations();
        setPlaceId(null);
    }, [organization]);

    const columns = [
        {
            name: "profile",
            label: "Profile",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const profileLabel = value?.replace("ROLE_", "").replaceAll("_", " ").toLowerCase();
                    return profileLabel.charAt(0).toUpperCase() + profileLabel.slice(1);
                },
            },
        },
        {
            name: "placeName",
            label: "Organization / Location",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => {
                            if (value === user?.organization.name) {
                                navigate(`/organization/${tableMeta.tableData[tableMeta.rowIndex][3]}`);
                            } else {
                                navigate(`/organization/${user?.organization.id}/location/${tableMeta.tableData[tableMeta.rowIndex][3]}`);
                            }
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                display: myUser.userProfiles.systemAdmin,
                customBodyRender: (value, tableMeta) => (
                    <FaTrash
                        style={{ marginLeft: 15, padding: 1, cursor: "pointer" }}
                        size={20}
                        color="rgba(0,0,0,0.65)"
                        onClick={() => removeNotification(value)}
                    />
                ),
            },
        },
        {
            name: "placeId",
            options: {
                display: false,
            },
        },
    ];

    const options = {
        selectableRowsHeader: false,
        selectableRows: "none",
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        pagination: false,
    };

    return (
        <CustomModal padding={2} open={open} title="Edit user" onClose={() => handleOnClose(false)}>
            <CustomModal
                open={isNotificationModalOpen}
                title="Notifications profiles"
                onClose={() => {
                    setIsNotificationModalOpen(false);
                }}
                width="60vw"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        defaultChecked={user?.notifications}
                                        onChange={(e) => updateNotifications(!notifications)}
                                        checked={notifications}
                                    />
                                }
                                labelPlacement="start"
                                label={<Typography variant="caption">Notifications</Typography>}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{ marginBottom: 1 }}>
                            NOTIFICATIONS
                        </Typography>
                        <MUIDataTable data={places} columns={columns} options={options} />
                    </Grid>

                    {myUser.userProfiles.systemAdmin && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>
                                    ADD PROFILE AND PLACE
                                </Typography>
                                <Grid xs={12} sx={{ display: "flex" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select a profile</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            label="Select a profile"
                                            value={profile}
                                            onChange={(e) => setProfile(e.target.value)}
                                        >
                                            <MenuItem value="ROLE_PROGRAM_COORDINATOR">Program Coordinator</MenuItem>
                                            <MenuItem value="ROLE_PROGRAM_STAFF">Program Staff</MenuItem>
                                            <MenuItem value="ROLE_SITES_SUPERVISOR">Sites Supervisor</MenuItem>
                                            <MenuItem value="ROLE_SITE_COORDINATOR">Site Coordinator</MenuItem>
                                            <MenuItem value="ROLE_SITE_STAFF">Site Staff</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {profile && !isProgramProfile && isAdminRole && (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                key={profile}
                                                options={organizations}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Select a organization" />}
                                                onChange={(_, value) => setOrganization(value.id)}
                                            />
                                        </FormControl>
                                    )}

                                    <FormControl fullWidth>
                                        {isProgramProfile ? (
                                            <>
                                                {isAdminRole ? (
                                                    <Autocomplete
                                                        key={profile}
                                                        options={organizations}
                                                        renderOption={(props, option) => (
                                                            <li {...props} key={option.id}>
                                                                {option.label}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => <TextField {...params} label="Select a organization" />}
                                                        onChange={(_, value) => setPlaceId(value.id)}
                                                    />
                                                ) : (
                                                    <Select value={placeId} disabled={true}>
                                                        <MenuItem value={user?.organization.id}>{user?.organization.name}</MenuItem>
                                                    </Select>
                                                )}
                                            </>
                                        ) : (
                                            <Autocomplete
                                                key={organization}
                                                disabled={!profile || !organization}
                                                options={locations}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Select a place" />}
                                                onChange={(_, value) => setPlaceId(value.id)}
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="success" fullWidth onClick={addNotification}>
                                    ADD NOTIFICATION
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CustomModal>

            <ChangePassword self={false} user={user} open={isChangePasswordModalOpen} onClose={() => setIsChangePasswordModalOpen(false)} />

            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Edit User</Typography>

                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <IOSSwitch defaultChecked={user?.userStatus} onChange={(e) => handleChangeUserStatus(!userEnabled)} checked={userEnabled} />
                                }
                                labelPlacement="start"
                                label={<Typography variant="caption">User {userEnabled ? "enabled" : "disabled"}</Typography>}
                            />
                        </FormGroup>
                        <Button variant="contained" onClick={() => setIsNotificationModalOpen(true)}>
                            Notifications profiles
                        </Button>
                        {myUser?.userProfiles.systemAdmin && (
                            <Button variant="contained" onClick={() => setIsChangePasswordModalOpen(true)}>
                                Set password
                            </Button>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        {...errorProps(errors, "firstName")}
                        label="First name"
                        value={firstName}
                        fullWidth
                        onChange={(evt) => setFirstName(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        {...errorProps(errors, "lastName")}
                        label="Last name"
                        value={lastName}
                        fullWidth
                        onChange={(evt) => setLastName(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField {...errorProps(errors, "email")} label="Email" value={email} fullWidth onChange={(evt) => setEmail(evt.target.value)} />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField {...errorProps(errors, "fone")} label="Phone" value={fone} fullWidth onChange={(evt) => setFone(evt.target.value)} />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" color="success" fullWidth onClick={() => updateUser()}>
                        Save
                    </Button>
                </Grid>
                {myUser?.userProfiles?.systemAdmin && (
                    <Grid item xs={12}>
                        <Button variant="contained" color="error" fullWidth onClick={() => onDelete(user)}>
                            Delete user
                        </Button>
                    </Grid>
                )}
            </Grid>
        </CustomModal>
    );
}

export const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    marginLeft: 5,
    marginRight: 10,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));
