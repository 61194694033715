import apiRequest from "../helpers/apiRequest";

export default function useAccessory() {
    const removePrimary = async (accessoriesList, currentAccessory, aedId, aedFixedBatteryExpirationDate, setAsyncModal) => {
        if (currentAccessory.operationLevel === "BACKUP" || currentAccessory.type === "PEDIATRIC PAD") {
            return;
        }

        let backupId = null;
        let installationDateBattery = null;
        let isBackupModalNeeded = false;
        let isInstallationDateModalNeeded = false;
        const adultPadRegex = new RegExp("ADULT PAD*");
        const sameAccTypeList = accessoriesList.filter(
            (accessory) =>
                (accessory.type == currentAccessory.type || (adultPadRegex.test(accessory.type) && adultPadRegex.test(currentAccessory.type))) &&
                accessory.operationLevel == "BACKUP"
        );

        if (sameAccTypeList.length > 1 && (currentAccessory.type !== "BATTERY" || (currentAccessory.type === "BATTERY" && aedFixedBatteryExpirationDate))) {
            isBackupModalNeeded = true;
        }

        if (sameAccTypeList.length > 0 && currentAccessory.type === "BATTERY" && !aedFixedBatteryExpirationDate) {
            isInstallationDateModalNeeded = true;
        }

        if (isBackupModalNeeded || isInstallationDateModalNeeded) {
            const componentName = isBackupModalNeeded ? "BACKUP_ID" : "INSTALLATION_DATE";

            await new Promise((resolve, reject) => {
                setAsyncModal(() => ({
                    title: isBackupModalNeeded
                        ? "Choose a backup accessory to convert as a primary"
                        : "Choose installation date for the backup battery that will be converted to primary",
                    isOpen: true,
                    onClose: () => reject(),
                    component: componentName,
                    componentData: isBackupModalNeeded ? sameAccTypeList : null,
                    asyncAction: (data) => resolve(data),
                }));
            }).then((res) => {
                componentName === "BACKUP_ID" ? (backupId = res) : (installationDateBattery = res);
            });
        }

        return apiRequest.delete("/aed/remove-primary-accessory", {
            data: {
                aedId: aedId,
                accessoryId: currentAccessory.id,
                backupId,
                installationDateBattery, // "yyyy-MM-dd"
            },
        });
    };

    return { removePrimary };
}
