import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, TableCell, TableRow, TableHead, TableBody, Button, Typography, Box, CircularProgress } from "@mui/material";
import apiRequest from "../../../helpers/apiRequest";
import { DateFormat } from "../../../helpers/DateFormat";
import AddInspection from "../addInspection";
import CustomModal from "../../../components/CustomModal/CustomModal";
import MuiTable from "../../../components/MuiTable";

export default function InformationModal({ open, onClose, type, status, modalName }) {
    const navigate = useNavigate();
    const { organizationId } = useParams();

    function handleOnClose(bool) {
        onClose(bool);
        setInformation([]);
        setSelectedRows([]);
    }

    const [information, setInformation] = useState([]);

    async function getInformation() {
        const informationRes = await apiRequest.get(`/information/${typeInformationReturn().baseURL}?organizationId=${organizationId}`).then((res) => res.data);
        setInformation([...informationRes[status]]);
    }

    useEffect(() => {
        if (open) {
            getInformation();
        }
    }, [type, status, open]);

    function returnStatusTitle(status) {
        switch (status) {
            case "needsAttention":
                return "Needs Attention";
            case "overDue":
                return "Over Due";
            case "dueNow":
                return "Due Now";

            default:
                break;
        }
    }

    function typeInformationReturn() {
        let typeColumns = [];
        let typeBaseURL = "";
        let modalTitle = "";
        let redirect = "";

        if (type == "device-inspection") {
            typeBaseURL = "device-inspection";
            typeColumns = status == "needsAttention" ? aedColumnsNeedsAttention : deviceInspectionColumns;
            modalTitle = "Device Inspection";
            redirect = (data) => `/organization/${data.ownerInfo.organizationId}/location/${data.ownerInfo.locationId}/aed/${data.id}`;
        }

        if (type == "device-accessories") {
            typeBaseURL = "device-accessories";
            typeColumns = accessoriesColumns;
            modalTitle = "Device Accessories";
            redirect = (data) => `/organization/${data.aed.ownerInfo.organizationId}/location/${data.aed.ownerInfo.locationId}/aed/${data.aed.id}`;
        }

        if (type == "responder-certification") {
            typeBaseURL = "responder-certification";
            typeColumns = respondersColumns;
            modalTitle = "Responder Certifications";
            redirect = (data) => `/organization/${data.responder.organizationId}/location/${data.responder.locationId}/responder/${data.responder.id}`;
        }

        return {
            columns: typeColumns,
            baseURL: typeBaseURL,
            title: modalTitle,
            redirect: redirect,
        };
    }

    const deviceInspectionColumns = [
        {
            name: "id",
            label: "ID",
            options: { display: false },
        },
        {
            name: "ownerInfo_locationId",
            label: "LocID",
            options: { display: false },
        },
        {
            label: "Location",
            name: "ownerInfo_locationAddress",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const address = JSON.parse(value);
                    const addressStr = `${address.address} - ${address.city}, ${address.state} ${address.zipCode}  `;
                    return (
                        <Typography
                            variant="body2"
                            sx={{
                                color: "black",
                                "&:hover": {
                                    fontWeight: 600,
                                    textDecorationLine: "underline",
                                },
                            }}
                            onClick={() => navigate(`/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}`)}
                            style={{ cursor: "pointer" }}
                        >
                            {addressStr}
                        </Typography>
                    );
                },
                setCellProps: () => ({
                    style: {
                        width: "500px",
                    },
                }),
            },
        },
        {
            label: "Placement",
            name: "placement",
        },
        {
            name: "aedModel_model",
            label: "Model",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            name: "aedModel_manufacturer",
            label: "Manufacturer",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            name: "inspectionDue",
            label: "Inspection Due",
            options: {
                customBodyRender: (value, tableMeta) => <span>{DateFormat(value)}</span>,
            },
        },
    ];

    const aedColumnsNeedsAttention = [
        {
            name: "id",
            label: "ID",
            options: { display: false },
        },
        {
            name: "ownerInfo_locationId",
            label: "ownerInfo_locationId",
            options: { display: false },
        },
        {
            name: "aedModel_model",
            label: "Model",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            name: "aedModel_manufacturer",
            label: "Manufacturer",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            name: "inspectionDue",
            label: "Inspection Date",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return <span>{DateFormat(information[tableMeta.rowIndex].inspections[0].inspectionDate)}</span>;
                },
            },
        },
        {
            label: "Inspection Notes",
            name: "note",
            options: {
                customBodyRender: (value, tableMeta) => <span>{information[tableMeta.rowIndex]?.inspections[0]?.notes}</span>,
            },
        },
        {
            label: "Placement",
            name: "placement",
        },
    ];

    const accessoriesColumns = [
        {
            name: "aed_id",
            label: "aed_id",
            options: { display: false },
        },
        {
            name: "aed_ownerInfo_locationId",
            label: "aed_ownerInfo_locationId",
            options: { display: false },
        },
        {
            name: "accessory_type",
            label: "Accessory",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value.replaceAll("_", " ")}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            name: "accessory_expiration",
            label: "Expiration",
            options: {
                customBodyRender: (value) => <span>{DateFormat(value)}</span>,
            },
        },
        {
            label: "AED Manufacturer",
            name: "aed_aedModel_manufacturer",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value.replaceAll("_", " ")}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            label: "AED Model",
            name: "aed_aedModel_model",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value.replaceAll("_", " ")}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            label: "Location",
            name: "aed_ownerInfo_locationName",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => navigate(`/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value.replaceAll("_", " ")}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
    ];

    const respondersColumns = [
        {
            name: "trainings",
            label: "trainings",
            options: { display: false },
        },
        {
            name: "responder_id",
            label: "ID",
            options: { display: false },
        },
        {
            name: "responder_locationId",
            label: "LocationId",
            options: { display: false },
        },
        {
            name: "responder_name",
            label: "Name",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][2]}/responder/${
                                    tableMeta.tableData[tableMeta.rowIndex][1]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value.replaceAll("_", " ")}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "500px",
                    },
                }),
            },
        },
        {
            name: "responder_locationName",
            label: "Location",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => navigate(`/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][2]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value.replaceAll("_", " ")}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "500px",
                    },
                }),
            },
        },
    ];

    const [selectedRows, setSelectedRows] = useState([]);

    const [inspectionModal, setInspectionModal] = useState(false);

    return (
        <CustomModal padding={2} open={open} title={typeInformationReturn().title} onClose={() => handleOnClose(false)}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1, alignItems: "center" }}>
                <Typography color="#2a5886" variant="h6" fontWeight="bold">
                    {returnStatusTitle(status)}
                </Typography>
                {type == "device-inspection" && (
                    <Button disabled={selectedRows == 0} onClick={() => setInspectionModal(true)} variant={selectedRows == 0 ? "outlined" : "contained"}>
                        Add Inspection
                    </Button>
                )}
            </Box>

            <AddInspection open={inspectionModal} onClose={() => setInspectionModal(false)} aeds={selectedRows} />

            <Box sx={{ minHeight: 175, transition: "0.3s", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {information.length > 0 ? (
                    <MuiTable
                        noSelect={type == "responder-certification" || type == "device-accessories"}
                        noActions
                        noView
                        noEdit
                        onRowSelectionChange={(rowIds) => setSelectedRows(rowIds.map((id) => information[id]))}
                        aditionalOptions={type == "responder-certification" ? aditionalOptionsForResponders : {}}
                        noDelete
                        onView={(data) => navigate(typeInformationReturn().redirect(data))}
                        columns={typeInformationReturn().columns}
                        data={[...information]}
                        modalName={modalName}
                    />
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </CustomModal>
    );
}

export const aditionalOptionsForResponders = {
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    isRowExpandable: (dataIndex, expandedRows) => {
        return true;
    },
    rowsExpanded: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    renderExpandableRow: (rowData, rowMeta) => {
        const colSpan = rowData.length + 1;
        return (
            <TableRow sx={{ backgroundColor: "rgba(244,244,244,0.5)" }}>
                <TableCell colSpan={colSpan}>
                    <Table>
                        <TableHead sx={{ backgroundColor: "transparent" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>Training</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Expiration</TableCell>
                        </TableHead>
                        <TableBody sx={{ backgroundColor: "transparent" }}>
                            {rowData[0]?.map((training) => {
                                return (
                                    <TableRow>
                                        <TableCell>{training?.type}</TableCell>
                                        <TableCell>{training?.expirationDate}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        );
    },
};
