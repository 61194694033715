import { Button, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import MuiTable from "../../MuiTable";

export default function NewAEDsList({ onSetLocation }) {
    const columns = [
        {
            label: "id",
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "ownerInfo_locationName",
            name: "ownerInfo_locationId",
            options: {
                display: false,
            },
        },
        {
            label: "Manufacturer",
            name: "aedModel_manufacturer",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1] || "no-location"}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            label: "Model",
            name: "aedModel_model",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1] || "no-location"}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            label: "Serial number",
            name: "serial",
        },
        {
            name: "warrantyDate",
            label: "Warranty Expiration",
            options: {
                customBodyRender: (value) => <span>{value ? format(parse(value, "yyyy-MM-dd", new Date()), "MM/dd/yyyy") : ""}</span>,
            },
        },
    ];

    function setLocationHandler(aed) {
        onSetLocation(aed);
    }

    const navigate = useNavigate();

    const { organizationId } = useParams();

    return (
        <MuiTable
            noView
            onAditionalAction={(aed) => setLocationHandler(aed)}
            aditionalAction={
                <Button sx={{ height: 20, width: 110, fontSize: 12, padding: 0, margin: 0, fontWeight: 500 }} variant="contained">
                    Set Location
                </Button>
            }
            noEdit
            onView={(aed) => navigate(`/organization/${aed?.ownerInfo?.organizationId}/location/${aed?.ownerInfo?.locationId || "no-location"}/aed/${aed?.id}`)}
            pagination
            paginationURL={`/organization/${organizationId}/newaeds`}
            noSelect
            noDelete
            columns={columns}
        />
    );
}
