import { useState, useEffect, useRef } from "react";
import { Box, Button, TextField, Typography, Grid, InputLabel, Select, MenuItem, FormControl, Chip, CircularProgress, Checkbox, Tooltip } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import { ForecastTable } from "./components/forecast-table";
import ForecastAccessoriesTable from "./components/forecast-acc-qty-table";
import BasicDatePicker from "../../components/DatePicker";
import { DateHelper } from "../../helpers/dateHelper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { forecastListContent, uniqueForecast } from "../../entities/forecastSheet";
import apiRequest from "../../helpers/apiRequest";
import { AxiosError, AxiosResponse } from "axios";
import { pagination } from "../../entities/pagination";
import { forecastAccessoryQuantity } from "../../services/forecast/getForecast";
import { Close } from "@mui/icons-material";

const ForecastPage = () => {
    const [forecastList, setforecastList] = useState<uniqueForecast[]>([]);
    const [forecastAccessories, setForecastAccessories] = useState<forecastAccessoryQuantity[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [manageMode, setManageMode] = useState<"forecast" | "accessories">("forecast");

    const [todayDateString, nextMonthDateString] = getDefaultDate();
    const [startDate, setStartDate] = useState(todayDateString);
    const [endDate, setEndDate] = useState(nextMonthDateString);
    const [subscriptions, setSubscriptions] = useState<string[]>([]);

    const searchRef = useRef<any>(null);

    const getForecastList = async () => {
        await apiRequest
            .post(
                "/forecast",
                {
                    start: DateHelper.dateToString(startDate),
                    end: DateHelper.dateToString(endDate),
                    subscriptions: subscriptions,
                },
                {
                    params: {
                        search: searchRef.current?.value === "" ? null : searchRef.current?.value,
                    },
                }
            )
            .then((res: AxiosResponse<pagination<forecastListContent>>) => {
                const newForecastList = filterForecast(res.data?.content);

                setforecastList(newForecastList);
            })
            .catch((err: AxiosError) => console.error(err));
    };

    const getAccessoriesQuantities = async () => {
        await apiRequest
            .post(
                "forecast/accessory-quantities",
                {
                    start: DateHelper.dateToString(startDate),
                    end: DateHelper.dateToString(endDate),
                    subscriptions: subscriptions,
                },
                {
                    params: {
                        search: searchRef.current?.value === "" ? null : searchRef.current?.value,
                    },
                }
            )
            .then((res) => setForecastAccessories(res.data))
            .catch((err) => console.error(err));
    };

    const handleGetForecast = async () => {
        setIsLoading(true);
        await Promise.all([getForecastList(), getAccessoriesQuantities()]).finally(() => {
            setIsLoading(false);
        });
    };

    // TEMP SOLUTION
    const filterForecast = (forecastListContent: forecastListContent[]) => {
        const uniqueForecastList: uniqueForecast[] = [];

        // Get forecast by organization
        for (const key in forecastListContent) {
            let isUnique = true;

            for (const key_2 in uniqueForecastList) {
                if (forecastListContent[key].organization.id === uniqueForecastList[key_2].organization.id) {
                    isUnique = false;
                    break;
                }
            }

            if (isUnique) {
                uniqueForecastList.push(uniqueForecastFactory(forecastListContent[key]));
            }
        }

        // Add missing locations and AEDs
        for (const key in forecastListContent) {
            if (forecastListContent[key].location === null) continue;

            for (const key_2 in uniqueForecastList) {
                if (forecastListContent[key].organization.id === uniqueForecastList[key_2].organization.id) {
                    let isUniqueLoc = true;

                    for (const key_3 in uniqueForecastList[key_2].locations) {
                        if (forecastListContent[key].location.id === uniqueForecastList[key_2].locations[key_3].id) {
                            let isUniqueAed = true;

                            for (const key_4 in uniqueForecastList[key_2].locations[key_3].aeds) {
                                if (forecastListContent[key].aed.id === uniqueForecastList[key_2].locations[key_3].aeds[key_4].id) {
                                    isUniqueAed = false;
                                    break;
                                }
                            }

                            if (isUniqueAed) {
                                uniqueForecastList[key_2].locations[key_3].aeds.push({
                                    ...forecastListContent[key].aed,
                                    accessories: forecastListContent[key].accessories,
                                });
                            }

                            isUniqueLoc = false;
                            break;
                        }
                    }

                    if (isUniqueLoc) {
                        uniqueForecastList[key_2].locations.push({
                            ...forecastListContent[key].location,
                            aeds: [
                                {
                                    ...forecastListContent[key].aed,
                                    accessories: [...forecastListContent[key].accessories],
                                },
                            ],
                        });
                    }
                }
            }
        }

        return uniqueForecastList;
    };

    const handleOnChangeSubscription = (subType: string[]) => {
        setSubscriptions(subType);
    };

    useEffect(() => {
        (async () => {
            if (manageMode === "forecast" && forecastList.length < 1) {
                await getForecastList();
            } else if (manageMode === "accessories" && forecastAccessories.length < 1) {
                await getAccessoriesQuantities();
            }

            setIsLoading(false);
        })();
    }, [manageMode]);

    useEffect(() => {
        const handleKeyDownEvent = (e: KeyboardEvent) => {
            if (e.code === "Enter" && searchRef.current?.value.length > 0) handleGetForecast();
        };

        document.addEventListener("keydown", handleKeyDownEvent);

        return () => document.removeEventListener("keydown", handleKeyDownEvent);
    }, []);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="Forecast Sheet" />
            <Typography sx={{ marginTop: 2, marginBottom: 2, color: "rgba(0,0,0,0.7)" }} variant="h4" fontWeight="bold">
                Forecast Sheet
            </Typography>

            <Box sx={{ display: "flex", gap: 2 }}>
                <BasicDatePicker
                    value={startDate}
                    onChange={(date: any) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                            setStartDate(DateHelper.stringToGMT(date.toString()));
                        }
                    }}
                    InputProps={{ size: "small" }}
                />
                <BasicDatePicker
                    value={endDate}
                    onChange={(date: any) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                            setEndDate(DateHelper.stringToGMT(date.toString()));
                        }
                    }}
                    InputProps={{ size: "small" }}
                />
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 0.5 }}>
                    <FormControl sx={{ minWidth: 128 }}>
                        <InputLabel id="subscription-label" variant="outlined" sx={{ top: "-6px" }}>
                            Subscription
                        </InputLabel>
                        <Select
                            labelId="subscription-label"
                            size="small"
                            label="Subscription"
                            multiple
                            value={subscriptions}
                            onChange={(e: any) => handleOnChangeSubscription(e.target.value)}
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", gap: 0.5 }}>
                                    <Chip label={selected[0].replaceAll("_", " ")} />
                                    {selected.length > 1 && <Chip label={"..."} />}
                                </Box>
                            )}
                        >
                            {[
                                "BASIC_Q",
                                "BASIC_I",
                                "BASIC_IS",
                                "STANDARD_Q",
                                "STANDARD_I",
                                "STANDARD_IS",
                                "BASIC_PLUS",
                                "STANDARD_PLUS",
                                "PREMIUM_PLUS",
                                "NO_SUBSCRIPTION",
                            ].map((subType, index) => (
                                <MenuItem key={index} value={subType}>
                                    <Checkbox sx={{ paddingLeft: 0 }} checked={subscriptions.indexOf(subType) > -1} />
                                    {subType.replaceAll("_", " ")}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {subscriptions.length > 0 && (
                        <Tooltip title="Clear subscription filter">
                            <Close fontSize="small" sx={{ "&:hover": { cursor: "pointer" } }} onClick={() => setSubscriptions([])} />
                        </Tooltip>
                    )}
                </Box>
                <Button variant="contained" onClick={handleGetForecast}>
                    GET FORECAST
                </Button>
            </Box>

            <Box sx={{ display: "flex", gap: 2, marginTop: 2, width: "100%" }}>
                <TextField
                    type="search"
                    label="Search"
                    size="small"
                    fullWidth
                    inputRef={searchRef}
                    onKeyPress={(e) => {
                        if (e.code === "Enter" && searchRef.current?.value.length < 1) handleGetForecast();
                    }}
                />
                <Button variant="contained" onClick={handleGetForecast}>
                    Search
                </Button>
            </Box>

            <Grid container>
                <Grid item xs={12} marginTop={2}>
                    <Button
                        variant={manageMode == "forecast" ? "contained" : "outlined"}
                        onClick={() => setManageMode("forecast")}
                        color="secondary"
                        sx={{ marginRight: 1 }}
                    >
                        Forecast Accessories
                    </Button>
                    <Button variant={manageMode == "accessories" ? "contained" : "outlined"} onClick={() => setManageMode("accessories")} color="secondary">
                        Quantity
                    </Button>
                </Grid>
                <Grid item xs={12} marginTop={4}>
                    {!isLoading ? (
                        <>
                            {manageMode == "forecast" ? (
                                <>
                                    {forecastList.length > 0 ? (
                                        <ForecastTable forecastList={forecastList} />
                                    ) : (
                                        <Typography align="center" padding={4}>
                                            No matching results found
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <ForecastAccessoriesTable forecastList={forecastAccessories} />
                            )}
                        </>
                    ) : (
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

function getDefaultDate() {
    const todayDate = new Date();
    const todayDateString = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
    const newMonthDate = new Date(todayDate.setMonth(todayDate.getMonth() + 1));
    const newMonthDateString = `${newMonthDate.getFullYear()}-${newMonthDate.getMonth() + 1}-${newMonthDate.getDate()}`;

    return [todayDateString, newMonthDateString];
}

function uniqueForecastFactory(forecast: forecastListContent): uniqueForecast {
    return {
        organization: forecast.organization,
        locations: [
            {
                ...forecast.location,
                aeds: [
                    {
                        ...forecast.aed,
                        accessories: [...forecast.accessories],
                    },
                ],
            },
        ],
    };
}

export default ForecastPage;
