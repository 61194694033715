import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaEdit, FaCircle, FaTrash } from "react-icons/fa";
import { Button, Grid, Typography, Box, Tooltip, CircularProgress } from "@mui/material";
import MUIDataTable, { SelectableRows } from "mui-datatables";
import { motion } from "framer-motion";
import DashboardLayout from "../../layouts/DashboardLayout";
import useAccessory from "../../hooks/useAccessory";
import apiRequest from "../../helpers/apiRequest";
import AddInspection from "./addInspection";
import EditInspection from "./editInspection";
import AddAccessory from "./addAccessory";
import EditAccessory from "./editAccessory";
import { DateFormat } from "../../helpers/DateFormat";
import { notify } from "../../helpers/notify";
import Breadcrumbs from "../../components/Breadcrumbs";
import { InfoBallon } from "../organization";
import EditAED from "../../components/aeds/modal/edit/editAed";
import CustomModal from "../../components/CustomModal/CustomModal";
import RemovePrimaryAccessory from "../../components/aeds/modal/removePrimaryAccessory";

export default function Organization() {
    const { aedId } = useParams();
    const navigate = useNavigate();

    const [inspectionToEdit, setInspectionToEdit] = useState<any>([]);
    const [editInspectionModalOpen, setEditInspectionModalOpen] = useState(false);

    const [accessoryToEdit, setAccessoryToEdit] = useState<any>(null);
    const [editAccessoryModalOpen, setAccessoryModalOpen] = useState(false);

    const [contentLoaded, setContentLoaded] = useState(false);
    const [aed, setAED] = useState<any>(null);

    const [aedInspectors, setAedInspectors] = useState<any>(null);

    const [editAedModal, setEditAedModal] = useState(false);

    const { removePrimary } = useAccessory();
    const [asyncModal, setAsyncModal] = useState<any>({
        title: "",
        isOpen: false,
        onClose: () => {},
        component: "",
        componentData: {},
        asyncAction: () => {},
    });

    const clearModalData = () => {
        setAsyncModal(() => ({
            title: "",
            isOpen: false,
            onClose: () => {},
            component: "",
            componentData: {},
            asyncAction: () => {},
        }));
    };

    async function getAccessoriesInfo() {
        await apiRequest.get(`/aed/${aedId}`).then((res) => {
            setAED(res.data);
            setPagination(() => ({
                totalElements: res.data?.accessories.length,
                currentPage: 0,
            }));
        });
    }

    async function getInspectionInfo() {
        await apiRequest
            .get(
                `/aed/${aedId}/inspection?page=${tableOptions.page}&size=${tableOptions.size}&sort=${tableOptions.sortColumn}${
                    tableOptions.sortColumn !== "" ? "," + tableOptions.sortOrder : ""
                }`
            )
            .then((res) => {
                setAedInspectors(res.data.content);
                setPagination(() => ({
                    totalElements: res.data?.totalElements,
                    currentPage: res.data?.pageable.pageNumber,
                }));
            });
    }

    async function getOrganizationInfo() {
        if (manageMode === "accessories") {
            await getAccessoriesInfo();
        } else {
            await getInspectionInfo();
        }
    }

    async function deleteInspections(id: any) {
        await apiRequest.delete(`/aed/${aedId}/inspection/${id}`).then((res) => {
            notify("Inspection removed", "success");
            setContentLoaded(false);
            setContentLoaded(true);
            getOrganizationInfo();
        });
    }

    async function deleteAccessories(currentAccessory: any) {
        if (currentAccessory.operationLevel === "BACKUP" || currentAccessory.type === "PEDIATRIC PAD") {
            await apiRequest
                .delete(`/aed/${aedId}/accessory/${currentAccessory.id}`)
                .then((res) => {
                    notify("Accessory deleted", "success");
                    getOrganizationInfo();
                })
                .catch((err) => {
                    notify("Error deleting accessory: " + err.message, "error");
                });

            return;
        }

        removePrimary(aed?.accessories, currentAccessory, aed.id, aed.fixedBatteryExpirationDate, setAsyncModal)
            .then((res: any) => {
                const updatedList = res.data?.accessories || [];
                let backupAccessory = null;

                for (const i in aed?.accessories) {
                    if (backupAccessory) break;

                    for (const j in updatedList) {
                        if (
                            aed?.accessories[i].id == updatedList[j].id &&
                            aed?.accessories[i].operationLevel == "BACKUP" &&
                            updatedList[j].operationLevel == "PRIMARY"
                        ) {
                            backupAccessory = aed?.accessories[i];
                            break;
                        }
                    }
                }

                getOrganizationInfo();

                notify(
                    <span>
                        {"Accessory Removed."}
                        <br />
                        {`Backup ${backupAccessory.type.replaceAll("_", " ")} converted to primary`}
                    </span>,
                    "success"
                );
            })
            .catch((err) => {
                notify(err.response.data?.errors[0].message || "Unexpected error", "error");
            })
            .finally(clearModalData);
    }

    const [manageMode, setManageMode] = useState("accessories");

    const [pagination, setPagination] = useState({
        totalElements: 0,
        currentPage: 0,
    });

    const [tableOptions, setTableOptions] = useState({
        page: 0,
        size: 10,
        sortColumn: "",
        sortOrder: "asc",
    });

    const columnsAccessories = [
        {
            name: "active",
            label: "Status",
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    return renderStatusAccessory({
                        dueNow: tableMeta.rowData[5],
                        overDue: tableMeta.rowData[6],
                        active: value,
                    });
                },
            },
        },
        {
            name: "type",
            label: "Type",
        },
        {
            name: "operationLevel",
            label: "Operation Level",
        },
        {
            name: "expiration",
            label: "Expiration",
            options: {
                customBodyRender: (value: any, tableMeta: any) => DateFormat(value),
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    const rowAccessory = aed?.accessories?.filter((accessory: any) => accessory.id === value)[0];

                    return (
                        <>
                            <FaEdit
                                onClick={() => {
                                    setAccessoryToEdit(rowAccessory);
                                    setAccessoryModalOpen(true);
                                }}
                                style={{ marginLeft: 20, padding: 1, cursor: "pointer" }}
                                size={20}
                                color="rgba(0,0,0,0.65)"
                            />
                            <FaTrash
                                onClick={() => deleteAccessories(rowAccessory)}
                                style={{ marginLeft: 20, padding: 1, cursor: "pointer" }}
                                size={20}
                                color="rgba(0,0,0,0.65)"
                            />
                        </>
                    );
                },
            },
        },
        {
            name: "dueNow",
            label: "Due Now",
            options: {
                display: false,
                filter: false,
            },
        },
        {
            name: "overDue",
            label: "Over Due",
            options: {
                display: false,
                filter: false,
            },
        },
    ];

    const columnInspections = [
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    return renderStatusInspection(value);
                },
            },
        },
        {
            name: "statusNeedsAttetion",
            label: "Status Needs Attention",
        },
        {
            name: "inspectionDate",
            label: "Inspection Date",
            options: {
                customBodyRender: (value: any, tableMeta: any) => DateFormat(value),
            },
        },
        {
            name: "inspectorName",
            label: "Inspector Name",
        },
        {
            name: "notes",
            label: "Notes",
        },
        {
            name: "id",
            label: "Actions",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <FaTrash
                            onClick={() => deleteInspections(value)}
                            style={{ marginLeft: 20, padding: 1, cursor: "pointer" }}
                            size={20}
                            color="rgba(0,0,0,0.65)"
                        />
                    );
                },
            },
        },
    ];

    const options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        selectableRowsHeader: false,
        selectableRows: "none" as SelectableRows,

        serverSide: true,
        count: pagination.totalElements,
        page: pagination.currentPage,
        pagination: manageMode !== "accessories",
        rowsPerPage: tableOptions.size,
        rowsPerPageOptions: [10, 25, 50, 100, { label: "ALL", value: pagination.totalElements }] as number[],
        onChangePage: (page: number) => {
            setTableOptions((prevState) => ({ ...prevState, page }));
        },
        onChangeRowsPerPage: (size: number) => {
            setTableOptions((prevState) => ({ ...prevState, size, page: 0 }));
        },
        onColumnSortChange: (changedColumn: string, direction: string) => {
            setTableOptions((prevState) => ({
                ...prevState,
                sortColumn: changedColumn,
                sortOrder: direction,
            }));
        },
    };

    const [AddInspectionModalOpen, setAddInspectionModalOpen] = useState(false);
    const [addAccessoryModalOpen, setAddAccessoryModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            await getOrganizationInfo();
            setContentLoaded(true);
        })();
    }, [manageMode, tableOptions]);

    function renderStatusInspection(status: any) {
        if (status == "OVER_DUE") {
            return (
                <>
                    <FaCircle size={20} color="#FF0000" />{" "}
                </>
            );
        }
        if (status == "NEEDS_ATTENTION") {
            return (
                <>
                    <FaCircle size={20} color="#FFA800" />{" "}
                </>
            );
        }
        if (status == "INACTIVE" || status == "DISABLED" || status == "NOT_ACTIVE") {
            return (
                <>
                    <FaCircle size={20} color="#454545" />{" "}
                </>
            );
        }
        return (
            <>
                <FaCircle size={20} color="#00BF41" />{" "}
            </>
        );
    }

    function renderStatusAccessory(row: any) {
        if (row.dueNow) {
            return (
                <Tooltip title="Due Now">
                    <Box sx={{ display: "flex" }}>
                        <FaCircle size={20} color="#0d5eaf" />
                    </Box>
                </Tooltip>
            );
        }

        if (row.overDue) {
            return (
                <Tooltip title="Overdue">
                    <Box sx={{ display: "flex" }}>
                        <FaCircle size={20} color="#FF0000" />
                    </Box>
                </Tooltip>
            );
        }

        if (!row.active) {
            return (
                <Tooltip title="Disabled/Inactive">
                    <Box sx={{ display: "flex" }}>
                        <FaCircle size={20} color="#454545" />
                    </Box>
                </Tooltip>
            );
        }

        return (
            <Tooltip title="Ok">
                <Box sx={{ display: "flex" }}>
                    <FaCircle size={20} color="#00BF41" />
                </Box>
            </Tooltip>
        );
    }

    function getInspectionStatus() {
        let status = "Ready";

        if (aed?.needsAttention) status = "Needs Attention";
        else if (aed?.dueNow) status = "Due Now";
        else if (aed?.pastDue) status = "Overdue";

        return status;
    }

    useEffect(() => {
        if (accessoryToEdit && aed?.accessories.length > 0) {
            const filtered = aed?.accessories?.filter((acessory: any) => {
                return acessory.id == accessoryToEdit?.id;
            });

            if (filtered?.length > 0) {
                setAccessoryToEdit(filtered[0]);
            }
        }

        if (inspectionToEdit) {
            const filtered = aed?.inspections?.filter((inspection: any) => {
                return inspection.id == inspectionToEdit?.id;
            });

            if (filtered?.length > 0) {
                setInspectionToEdit(filtered[0]);
            }
        }
    }, [aed]);

    useEffect(() => {
        getOrganizationInfo();
    }, [aedId]);

    return (
        <DashboardLayout>
            <RemovePrimaryAccessory
                title={asyncModal.title}
                isOpen={asyncModal.isOpen}
                onClose={() => {
                    asyncModal.onClose();
                    clearModalData();
                }}
                component={asyncModal.component}
                componentData={asyncModal.componentData}
                asyncAction={asyncModal.asyncAction}
            />

            <Breadcrumbs linksOption={{ home: true, organization: { org: true, location: true } }} currentPage="AED" />
            {contentLoaded && (
                <motion.div key="Location" initial={{ scale: 0.97, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.97, opacity: 0 }}>
                    <Grid container padding={1} spacing={2}>
                        <AddInspection
                            onInspectionAdded={() => {
                                getOrganizationInfo();
                            }}
                            aeds={[aed]}
                            open={AddInspectionModalOpen}
                            onClose={() => setAddInspectionModalOpen(false)}
                            locationId={aed?.id}
                            organizationId={aed?.organization?.id}
                        />

                        <EditInspection
                            onInspectionAdded={() => {
                                notify("Inspection added to the selected aeds!", "success");
                                getOrganizationInfo();
                            }}
                            onInspectionAddError={(message: any) => {
                                notify(message, "error");
                            }}
                            aeds={[inspectionToEdit]}
                            open={editInspectionModalOpen}
                            onClose={() => setEditInspectionModalOpen(false)}
                            locationId={aed?.id}
                            organizationId={aed?.organization?.id}
                        />

                        <AddAccessory
                            aedModel={aed?.aedModel}
                            accessories={[...aed?.accessories]}
                            onInspectionAdded={() => {
                                getOrganizationInfo();
                                setAddAccessoryModalOpen(false);
                            }}
                            aeds={[aed]}
                            open={addAccessoryModalOpen}
                            onClose={() => setAddAccessoryModalOpen(false)}
                        />

                        <EditAccessory
                            onInspectionAdded={() => {
                                setAccessoryModalOpen(false);
                                getOrganizationInfo();
                            }}
                            aedId={aed?.id}
                            onInspectionAddError={(message: any) => {
                                notify(message, "error");
                            }}
                            aeds={[{ ...accessoryToEdit }]}
                            open={editAccessoryModalOpen}
                            onClose={() => setAccessoryModalOpen(false)}
                            locationId={aed?.id}
                            organizationId={aed?.organization?.id}
                            aedModel={aed?.aedModel}
                        />

                        <CustomModal open={editAedModal} title="Edit AED" onClose={() => setEditAedModal(false)}>
                            <EditAED aed={aed} onEdit={() => setEditAedModal(false)} />
                        </CustomModal>

                        <Grid item xs={12} md={9} sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "flex-start", gap: 1 }}>
                            <Box>
                                <Typography variant="caption">{aed?.aedModel?.manufacturer}</Typography>
                                <Typography variant="h4" fontWeight="bold">
                                    {aed?.aedModel?.model}
                                    <FaEdit onClick={() => setEditAedModal(true)} size={25} style={{ marginLeft: 10, padding: 5, cursor: "pointer" }} />
                                </Typography>
                                <Typography variant="body1">Serial number: {aed?.serial}</Typography>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                                    <Typography variant="caption">Inspection Status</Typography>
                                    {getInspectionStatus()}
                                </Box>

                                <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                                    <Typography variant="caption">Inspection Due</Typography>
                                    {DateFormat(aed?.inspectionDue)}
                                </Box>

                                {!aed?.isActive && (
                                    <Box sx={{ color: "red" }}>
                                        <Typography variant="overline">Inactive</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>

                        {aed?.ownerInfo.locationId && (
                            <Grid item xs={12} md={3}>
                                <InfoBallon
                                    onClick={() => navigate(`/organization/${aed?.ownerInfo.organizationId}/location/${aed?.ownerInfo.locationId}`)}
                                    type="Location"
                                    data={
                                        <>
                                            <Typography variant="body2">{aed?.ownerInfo.locationName}</Typography>
                                            <Typography variant="body2">{aed?.ownerInfo.locationAddress?.address}</Typography>
                                            <Typography variant="body2">{aed?.placement}</Typography>
                                        </>
                                    }
                                    title="Location"
                                    isAedLocation={true}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} marginTop={4}>
                            <Button
                                variant={manageMode == "accessories" ? "contained" : "outlined"}
                                onClick={() => setManageMode("accessories")}
                                color="secondary"
                                sx={{ marginRight: 1 }}
                            >
                                Manage Accessories
                            </Button>
                            <Button
                                variant={manageMode == "inspections" ? "contained" : "outlined"}
                                onClick={() => setManageMode("inspections")}
                                color="secondary"
                            >
                                Manage Inspections
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {manageMode === "accessories" && (
                                <>
                                    {aed?.accessories ? (
                                        <MUIDataTable title="Accessories" data={aed?.accessories} columns={columnsAccessories} options={options} />
                                    ) : (
                                        <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                            <CircularProgress size={30} sx={{ margin: 4 }} color="primary" />
                                        </Box>
                                    )}
                                </>
                            )}

                            {manageMode === "inspections" && (
                                <>
                                    {aedInspectors ? (
                                        <MUIDataTable title="Inspections History" data={aedInspectors} columns={columnInspections} options={options} />
                                    ) : (
                                        <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                            <CircularProgress size={30} sx={{ margin: 4 }} color="primary" />
                                        </Box>
                                    )}
                                </>
                            )}

                            <Button
                                variant="contained"
                                onClick={() => (manageMode == "accessories" ? setAddAccessoryModalOpen(true) : setAddInspectionModalOpen(true))}
                                sx={{ height: 40 }}
                                style={{ width: "100%" }}
                            >
                                ADD NEW {manageMode == "accessories" ? "Accessory" : "Inspection"}
                            </Button>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 1,
                                    border: "1px solid rgba(0,0,0,0.1)",
                                    marginTop: 2,
                                    padding: 0.5,
                                }}
                            >
                                <FaCircle color="#00BF41" />{" "}
                                <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                    Ok
                                </Typography>
                                <FaCircle color="#0d5eaf" />{" "}
                                <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                    Due Now
                                </Typography>
                                <FaCircle color="#FFA800" />{" "}
                                <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                    Needs Attention
                                </Typography>
                                <FaCircle color="#FF0000" />{" "}
                                <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                    Overdue
                                </Typography>
                                <FaCircle color="#454545" />{" "}
                                <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                    Disabled/Inactive
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </motion.div>
            )}
        </DashboardLayout>
    );
}
