import { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, FormControlLabel, FormGroup } from "@mui/material";
import { IOSSwitch } from "../users/editUser";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorFieldHandler, errorProps } from "../../helpers/errorHelper";
import useAuth from "../../hooks/useAuth";
import CustomModal from "../../components/CustomModal/CustomModal";
import BasicDatePicker from "../../components/DatePicker";

export default function EditCompany({ open, onClose, company, onCompanyUpdate = () => null }: any) {
    const { user } = useAuth();
    const sa = user?.userProfiles?.systemAdmin;

    const [anchorCompany, setAnchorCompany] = useState(company);

    const [companyName, setCompanyName] = useState(company?.name);
    const [companyAddress, setCompanyAddress] = useState(company?.mainAddress?.address);
    const [companyCity, setCompanyCity] = useState(company?.mainAddress?.city);
    const [companyState, setCompanyState] = useState(company?.mainAddress?.state);
    const [companyCounty, setCompanyCounty] = useState(company?.mainAddress?.county);
    const [companyZipCode, setCompanyZipCode] = useState(company?.mainAddress?.zipCode);
    const [organizationRecurrence, setOrganizationRecurrence] = useState<any>(parseInt(company?.inspectionRecurrence[0]));
    const [organizationSubscription, setOrganizationSubscription] = useState<any>(
        company?.subscription?.toUpperCase().replaceAll("-", "_").replaceAll(" ", "_")
    );
    const [organizationSubscriptionExpiration, setOrganizationSubscriptionExpiration] = useState<any>(company?.expiration);
    const [isRequestBusy, setIsRequestBusy] = useState(false);

    const [states, setStates] = useState([]);
    async function getStates() {
        const statesFetched = await apiRequest.get("/information/states").then((res) => res.data);
        setStates(statesFetched);
    }

    const [errors, setErrors] = useState<any>([]);

    async function updateOrganizacao() {
        await updateSubscription();

        setIsRequestBusy(true);

        await apiRequest
            .put("/organization/" + company?.id, {
                name: companyName,
                mainAddress: {
                    address: companyAddress,
                    city: companyCity,
                    state: companyState,
                    zipCode: companyZipCode,
                    county: companyCounty,
                },
                inspectionRecurrence: organizationRecurrence,
                subscription: organizationSubscription,
            })
            .then((res) => {
                onCompanyUpdate();
                onClose(true);
                notify("Organization updated", "success");
            })
            .catch((err) => {
                setErrors([...err.response.data.errors]);
                notify(err.response.data.errors[0]?.message ?? "Unexpected error, please try again soon", "error");
            })
            .finally(() => setIsRequestBusy(false));
    }

    const updateSubscription = async () => {
        const isSameSubscription = organizationSubscription === anchorCompany?.subscription.toUpperCase().replaceAll("-", "_").replaceAll(" ", "_");
        const isSameSubscriptionExpiration = new Date(organizationSubscriptionExpiration).toISOString().slice(0, 10) === anchorCompany.expiration;

        if (isSameSubscription && isSameSubscriptionExpiration) {
            return;
        }

        await apiRequest
            .post(`/organization/${company?.id}/subscription`, {
                subscription: organizationSubscription,
                subscriptionExpiration: organizationSubscriptionExpiration ? new Date(organizationSubscriptionExpiration).toISOString().slice(0, 10) : null,
            })
            .then((res) => {
                setAnchorCompany(res.data);
                notify("Subscription updated", "success");
            })
            .catch((err) => {
                setErrors([...err.response.data.errors]);
                notify(err.response.data.errors[0]?.message ?? "Unexpected error, please try again soon", "error");
                throw new Error("Subscription not updated"); // Stop flow when thrown. Used in function "updateOrganizacao"
            });
    };

    function handleOnClose(bool: boolean) {
        onClose(bool);
        setErrors([]);
    }

    const [organizationEnabled, setOrganizationEnabled] = useState(company?.isActive);
    async function updateOrganizationEnabled(enable: boolean) {
        if (enable) {
            await apiRequest.put(`/organization/${company?.id}/enable`).then((res) => {
                notify("Organization enabled", "success");
                setOrganizationEnabled(true);
                onCompanyUpdate();
            });
        } else {
            await apiRequest.put(`/organization/${company?.id}/disable`).then((res) => {
                notify("Organization disabled", "success");
                setOrganizationEnabled(false);
                onCompanyUpdate();
            });
        }
    }

    useEffect(() => {
        if (states.length < 1) {
            getStates();
        }
    }, []);

    return (
        <CustomModal open={open} title="Edit Organization" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="caption">Info</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...errorProps(errors, "name")}
                        label="Name"
                        value={companyName}
                        onChange={(evt) => setCompanyName(evt.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">Address</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        {...errorProps(errors, "mainAddress.address")}
                        label="Address"
                        value={companyAddress}
                        fullWidth
                        onChange={(evt) => setCompanyAddress(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="City"
                        {...errorProps(errors, "mainAddress.city")}
                        value={companyCity}
                        fullWidth
                        onChange={(evt) => setCompanyCity(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    {states.length > 0 && (
                        <Autocomplete
                            value={
                                states.filter((stt: any) => {
                                    if (stt?.postalAbbreviation == companyState) return true;
                                    else if (stt?.name == companyState) {
                                        setCompanyState(stt?.postalAbbreviation);
                                        return true;
                                    } else return false;
                                })[0]
                            }
                            isOptionEqualToValue={(opt, val) => opt?.postalAbbreviation == val}
                            onChange={(event: any, newValue: any) => {
                                setCompanyState(newValue?.postalAbbreviation);
                            }}
                            options={states}
                            getOptionLabel={(opt: any) => opt?.name}
                            renderInput={(params: any) => <TextField {...errorProps(errors, "mainAddress.state")} {...params} label="State" />}
                        />
                    )}
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="County"
                        {...errorProps(errors, "mainAddress.county")}
                        value={companyCounty}
                        fullWidth
                        onChange={(evt) => setCompanyCounty(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="ZipCode"
                        {...errorProps(errors, "mainAddress.zipCode")}
                        value={companyZipCode}
                        fullWidth
                        onChange={(evt) => setCompanyZipCode(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} mb={2}>
                        <Typography variant="caption">Recurrence</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Inspection Recurrence</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select2"
                                defaultValue={organizationRecurrence}
                                value={organizationRecurrence}
                                {...errorProps(errors, "inspectionRecurrence")}
                                label="Inspection Recurrence"
                                placeholder="Inspection Recurrence"
                                onChange={(evt) => setOrganizationRecurrence(evt.target.value)}
                            >
                                <MenuItem value={1}>1 Month</MenuItem>
                                <MenuItem value={3}>3 Months</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {sa && (
                    <Grid item xs={12}>
                        <Grid item xs={12} mb={2}>
                            <Typography variant="caption">Subscription</Typography>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label1x">Subscription</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={organizationSubscription}
                                        label="Subscription"
                                        error={errorFieldHandler(errors, "subscription")}
                                        placeholder="Subscription"
                                        onChange={(evt) => setOrganizationSubscription(evt.target.value)}
                                    >
                                        <MenuItem value="BASIC_Q">Basic-Q</MenuItem>
                                        <MenuItem value="BASIC_I">Basic-I</MenuItem>
                                        <MenuItem value="BASIC_IS">Basic-IS</MenuItem>

                                        <MenuItem value="STANDARD_Q">Standard-Q</MenuItem>
                                        <MenuItem value="STANDARD_I">Standard-I</MenuItem>
                                        <MenuItem value="STANDARD_IS">Standard-IS</MenuItem>

                                        <MenuItem value="BASIC_PLUS">Basic Plus</MenuItem>
                                        <MenuItem value="STANDARD_PLUS">Standard Plus</MenuItem>
                                        <MenuItem value="PREMIUM_PLUS">Premium Plus</MenuItem>
                                        <MenuItem value="NO_SUBSCRIPTION">No Subscription</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <BasicDatePicker
                                    {...errorProps(errors, "subscriptionExpiration")}
                                    PickerProps={{ label: "Subscription Expiration" }}
                                    InputProps={{ fullWidth: true }}
                                    value={organizationSubscriptionExpiration}
                                    onChange={(date) => {
                                        date = date instanceof Date ? date : new Date(date);

                                        if (!isNaN(date.getTime())) {
                                            setOrganizationSubscriptionExpiration(date);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12} sx={{ display: "flex", padding: 0, marginLeft: -2 }}>
                    <FormGroup sx={{ padding: 0 }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    defaultChecked={organizationEnabled}
                                    onChange={(e: any) => updateOrganizationEnabled(!organizationEnabled)}
                                    checked={organizationEnabled}
                                />
                            }
                            labelPlacement="start"
                            label={
                                <Typography variant="caption" sx={{ padding: 0 }}>
                                    Organization {organizationEnabled ? "enabled" : "disabled"}
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => updateOrganizacao()} disabled={isRequestBusy} fullWidth style={{ height: 40 }} color="success">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
