import CustomModal from "../../components/CustomModal/CustomModal";
import { Button, Grid, Typography, TextField, Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import customConfirmAlert from "../../components/confirmAlert";
import { errorHandler, errorProps } from "../../helpers/errorHelper";
import { aedModel } from "../../entities/aedModel";

type NewAedModelProps = {
    aedModel: aedModel | null;
    editMode: boolean;
    open: boolean;
    onClose: () => any;
    onUserAdded: () => any;
};

export default function NewAedModel({ editMode = false, aedModel = null, open, onClose, onUserAdded = () => {} }: NewAedModelProps) {
    const [updated, setUpdated] = useState(true);

    function handleOnClose(forceExit = false) {
        if (!updated && !forceExit) {
            customConfirmAlert(() => {
                onClose();

                setTimeout(() => {
                    clearCamps();
                }, 250);
            });
        } else {
            clearCamps();
            onClose();
        }
    }

    const [manufacturer, setManufacturer] = useState<any>("");
    const [model, setModel] = useState<any>("");
    const [urlPhoto, setUrlPhoto] = useState<any>("");

    const [comboBatteryAndPads, setComboBatteryAndPads] = useState<boolean>(false);
    const [batteryFixedExpirationDate, setBatteryFixedExpirationDate] = useState<boolean>(false);
    const [batteryYearsExpiration, setBatteryYearsExpiration] = useState<number>(1);
    const [pediatricPadsOption, setPediatricPadsOption] = useState<boolean>(false);
    const [warrantyYears, setWarrantyYears] = useState<number>(0);

    const [errors, setErrors] = useState<any[]>([]);

    async function createModelRequest() {
        const aedModelObj: aedModel = {
            manufacturer: manufacturer,
            model: model,
            urlPhoto: urlPhoto,
            comboBatteryAndPads: comboBatteryAndPads,
            fixedBatteryExpirationDate: batteryFixedExpirationDate,
            batteryYearsExpiration: batteryYearsExpiration,
            pediatricPadsOption: pediatricPadsOption,
            warrantyYears: warrantyYears,
        };

        await apiRequest
            .post(`/aedmodels/`, aedModelObj)
            .then((res) => {
                setUpdated(true);
                handleOnClose(true);
                onUserAdded();
                notify("Aed Model created", "success");
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    async function editModelRequest() {
        const updatedData: aedModel = {
            manufacturer: manufacturer,
            model: model,
            urlPhoto: urlPhoto,
            comboBatteryAndPads: comboBatteryAndPads,
            fixedBatteryExpirationDate: batteryFixedExpirationDate,
            batteryYearsExpiration: batteryYearsExpiration,
            pediatricPadsOption: pediatricPadsOption,
            warrantyYears: warrantyYears,
        };

        await apiRequest
            .put(`/aedmodels/${aedModel?.id}`, updatedData)
            .then((res) => {
                setUpdated(true);
                handleOnClose(true);
                onUserAdded();
                notify("Aed Model Edited", "success");
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    function clearCamps() {
        setManufacturer("");
        setModel("");
        setUrlPhoto("");
        setBatteryFixedExpirationDate(false);
        setBatteryYearsExpiration(0);
        setPediatricPadsOption(false);
        setComboBatteryAndPads(false);
        setErrors([]);
    }

    function editHandler() {
        if (aedModel) {
            setManufacturer(aedModel?.manufacturer);
            setModel(aedModel?.model);
            setUrlPhoto(aedModel?.urlPhoto);
            setBatteryFixedExpirationDate(aedModel.fixedBatteryExpirationDate);
            setBatteryYearsExpiration(aedModel.batteryYearsExpiration);
            setPediatricPadsOption(aedModel.pediatricPadsOption);
            setComboBatteryAndPads(aedModel.comboBatteryAndPads);
            setWarrantyYears(aedModel.warrantyYears);
        }
    }

    useEffect(() => {
        if (aedModel !== null) {
            editHandler();
        }
    }, [aedModel]);

    return (
        <CustomModal padding={2} open={open} title={editMode ? "Edit AED Model" : "Create AED Model"} onClose={() => handleOnClose()}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        {...errorProps(errors, "manufacturer")}
                        value={manufacturer}
                        onChange={(e) => {
                            setUpdated(false);
                            setManufacturer(e.target.value);
                        }}
                        placeholder="Manufacturer"
                        label="Manufacturer"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        {...errorProps(errors, "model")}
                        value={model}
                        onChange={(e) => {
                            setUpdated(false);
                            setModel(e.target.value);
                        }}
                        placeholder="Model"
                        label="Model"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        {...errorProps(errors, "urlPhoto")}
                        value={urlPhoto}
                        onChange={(e) => {
                            setUpdated(false);
                            setUrlPhoto(e.target.value);
                        }}
                        placeholder="Photo URL"
                        label="Photo URL"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={comboBatteryAndPads} onChange={(e) => setComboBatteryAndPads(e.target.checked)} />}
                            label="Combo Battery and Pads"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={pediatricPadsOption} onChange={(e) => setPediatricPadsOption(e.target.checked)} />}
                            label="Pediatric Pads option"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={batteryFixedExpirationDate} onChange={(e) => setBatteryFixedExpirationDate(e.target.checked)} />}
                            label="Battery Fixed Expiration Date"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={6}>
                    <CounterSelector label="Warranty Years" value={warrantyYears} onChange={(val) => setWarrantyYears(val)} />
                </Grid>

                {!batteryFixedExpirationDate && (
                    <Grid item xs={12} md={6}>
                        <CounterSelector label="Battery Years Expiration" value={batteryYearsExpiration} onChange={(val) => setBatteryYearsExpiration(val)} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => (editMode ? editModelRequest() : createModelRequest())} fullWidth color="success">
                        {aedModel !== null ? "SAVE" : "CREATE"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}

type CounterSelectorProps = {
    value?: number;
    onChange?: (value: number) => any;
    label: string;
};

function CounterSelector({ value, onChange = (val) => {}, label }: CounterSelectorProps) {
    const [currentValue, setCurrentValue] = useState<number>(value || 0);

    useEffect(() => {
        if (currentValue !== value) {
            onChange(currentValue);
        }
    }, [currentValue]);

    return (
        <>
            <Typography>{label}</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
                <Button size="small" variant="outlined" sx={{ padding: 0, margin: 0 }} onClick={() => setCurrentValue((prevState) => prevState - 1)}>
                    -
                </Button>
                {currentValue}
                <Button size="small" variant="outlined" sx={{ padding: 0, margin: 0 }} onClick={() => setCurrentValue((prevState) => prevState + 1)}>
                    +
                </Button>
            </Box>
        </>
    );
}
