import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Typography, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import CustomModal from "../../components/CustomModal/CustomModal";

export default function SetLocation({ aed, open, onClose, onUserAdded = () => {}, onUserAddError = () => {} }) {
    const { organizationId } = useParams();

    function handleOnClose(bool) {
        onClose(bool);
        // clearCamps();
    }

    const [selectedLocation, setSelectedLocation] = useState(null);

    const [locations, setLocations] = useState([]);

    async function getLocations() {
        const locationsInfo = await apiRequest.get(`/location/all/${organizationId}`).then((res) => res.data);
        setLocations([...locationsInfo]);
    }

    async function setLocationRequest() {
        if (selectedLocation == null) {
            return notify("You need to select a location", "error");
        }

        await apiRequest
            .post(`/aed/addlocation`, {
                aedId: aed.id,
                locationId: selectedLocation?.id,
                organizationId: organizationId,
            })
            .then((res) => {
                handleOnClose(true);
                notify("Aed location set", "success");
            })
            .catch((err) => {
                notify("Error setting aed location", "error");
            });
    }

    // function clearCamps() {}

    useEffect(() => {
        getLocations();
    }, []);

    return (
        <CustomModal padding={2} open={open} title="Set AED location" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="body1">Select a location</Typography>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label2">Location</InputLabel>
                        <Select
                            MenuProps={{
                                sx: {
                                    maxHeight: 250,
                                },
                            }}
                            labelId="demo-simple-select-label2"
                            id="demo-simple-select2"
                            value={selectedLocation}
                            onChange={(evt) => setSelectedLocation(evt.target.value)}
                            label="Location"
                        >
                            {locations.map((org) => (
                                <MenuItem value={org}>{org.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => setLocationRequest()} fullWidth color="success">
                        {"SET LOCATION"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
