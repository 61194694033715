import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import apiRequest from "../../../helpers/apiRequest";
import MuiTable from "../../MuiTable";

export default function ModelSelectList({ onSelect = (model) => {} }) {
    const [models, setModels] = useState([]);

    async function getModels() {
        const modelsRes = await apiRequest.get("/aedmodels").then((res) => res.data);
        setModels(modelsRes);
    }

    useEffect(() => {
        getModels();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>Select AED Model</Typography>
            </Grid>

            <Grid item xs={12}>
                <MuiTable
                    defaultRowsPerPage={50}
                    data={models}
                    noSelect
                    noActions
                    columns={[
                        {
                            name: "urlPhoto",
                            label: "Image",
                            options: {
                                customBodyRender: (value) => (
                                    <img src={value || "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"} width={30} height={30} />
                                ),
                            },
                        },
                        {
                            name: "manufacturer",
                            label: "Manufacturer",
                        },
                        {
                            name: "model",
                            label: "Model",
                        },
                    ]}
                    aditionalOptions={{
                        onCellClick: (colData, cellMeta) => {
                            onSelect(models[cellMeta.rowIndex]);
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
}
