import CustomModal from "../../components/CustomModal/CustomModal";
import { Button, Grid, Typography, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import apiRequest from "../../helpers/apiRequest";
import BasicDatePicker from "../../components/DatePicker";
import { errorHandler, errorProps } from "../../helpers/errorHelper";
import { DateHelper } from "../../helpers/dateHelper";
import { notify } from "../../helpers/notify";

export default function DevicesModal({
    open,
    aedId,
    onClose,
    aeds,
    onInspectionAdded = () => {},
    onInspectionAddError = () => {},
    organizationId,
    locationId,
    aedModel,
}: any) {
    function handleOnClose(bool: boolean) {
        onClose(bool);
        clearCamps();
        setErrors([]);
    }

    const [operationLevel, setOperationLevel] = useState<any>(0);
    const [accessoryExpiration, setAccessoryExpiration] = useState<any>("");

    const [errors, setErrors] = useState<any>([]);

    async function updateAccessory() {
        await apiRequest
            .put(`/aed/${aedId}/accessory/${aeds[0].id}`, {
                lot: "",
                expiration: accessoryExpiration ? DateHelper.stringToGMT(accessoryExpiration) : null,
                operationLevel: operationLevel,
            })
            .then((res) => {
                notify("Accessory successfully edited", "success");
                onInspectionAdded();
            })
            .catch((err) => {
                setErrors([...err.response.data.errors]);
                notify(err.response.data.errors[0]?.message ?? "Unexpected error, please try again soon", "error");
            });
    }

    function clearCamps() {
        setOperationLevel("");
        setAccessoryExpiration("");
    }

    function isInstallationDateNeeded() {
        return aeds[0].type === "BATTERY" && aedModel?.fixedBatteryExpirationDate === false;
    }

    useEffect(() => {
        if (aeds[0]) {
            setOperationLevel(aeds[0].operationLevel);
            setAccessoryExpiration(isInstallationDateNeeded() ? aeds[0].installationDate : aeds[0].expiration);
        }
    }, [aeds, open]);

    return (
        <CustomModal padding={2} open={open} title="Edit accessory" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Edit accessory</Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl focused fullWidth>
                        <InputLabel id="demo-simple-select-label">Operation Level</InputLabel>
                        <Select
                            {...errorProps(errors, "accessory")}
                            disabled={aeds[0].type === "PEDIATRIC PAD"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={operationLevel}
                            onChange={(evt: any) => setOperationLevel(evt.target.value)}
                            label="Operation Level"
                        >
                            {aeds[0].type === "PEDIATRIC PAD" && <MenuItem value="N/A">N/A</MenuItem>}
                            <MenuItem value="PRIMARY">PRIMARY</MenuItem>
                            <MenuItem value="BACKUP">BACKUP</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <BasicDatePicker
                        {...errorProps(errors, "expiration")}
                        PickerProps={{
                            label: isInstallationDateNeeded() ? "Installation Date" : "Expiration Date",
                        }}
                        value={accessoryExpiration}
                        onChange={(date) => {
                            setAccessoryExpiration(date);
                        }}
                        InputProps={{ fullWidth: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => updateAccessory()} fullWidth color="success">
                        {"SAVE"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
