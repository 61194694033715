import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import apiRequest from "../../../helpers/apiRequest";
import { DateFormat } from "../../../helpers/DateFormat";
import { notify } from "../../../helpers/notify";
import customConfirmAlert from "../../confirmAlert";
import MuiTable from "../../MuiTable";
import SearchFilter from "../../SearchFilter";
import { AuthContext } from "../../../context/AuthContext";

export default function AEDList({ onEdit, onDelete = () => {} }) {
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const { user } = useContext(AuthContext);

    const [contentLoaded, setContentLoaded] = useState(true);
    const [filterFinal, setFilterFinal] = useState("");

    const columns = [
        {
            label: "LocationId",
            name: "ownerInfo_locationId",
            options: {
                display: false,
            },
        },
        {
            label: "ID",
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Manufacturer",
            name: "aedModel_manufacturer",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][0]}/aed/${
                                    tableMeta.tableData[tableMeta.rowIndex][1]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            label: "Model",
            name: "aedModel_model",
        },
        {
            label: "Serial number",
            name: "serial",
        },
        {
            label: "Location",
            customSortParam: "locationName",
            name: "ownerInfo_locationName",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => navigate(`/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][0]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "300px",
                    },
                }),
            },
        },
        {
            label: "Placement",
            name: "placement",
        },
        {
            label: "Warranty Expiration",
            name: "warrantyDate",
            options: {
                customBodyRender: (value) => <span>{DateFormat(value)}</span>,
            },
        },
    ];

    function editAEDsHandler(aed) {
        onEdit(aed);
    }

    async function disableAED(id) {
        await apiRequest
            .put(`/aed/${id}/disable`)
            .then((res) => {
                notify("AED disabled", "success");
                onDelete();
                setContentLoaded(false);
                setContentLoaded(true);
            })
            .catch((err) => {
                notify("Error disabling AED", "error");
            });
    }

    async function enableAED(id) {
        await apiRequest
            .put(`/aed/${id}/enable`)
            .then((res) => {
                notify("AED enabled", "success");
                onDelete();
                setContentLoaded(false);
                setContentLoaded(true);
            })
            .catch((err) => {
                notify("Error enabling AED", "error");
            });
    }

    const [showDisabled, setShowDisabled] = useState(false);

    return (
        <>
            {contentLoaded && (
                <>
                    <SearchFilter onChangeFilter={(filter) => setFilterFinal(filter)} />
                    {!user.userProfiles.siteCoordinator && !user.userProfiles.siteStaff && (
                        <Grid item xs={12} marginBottom={2} marginTop={4}>
                            <Button
                                variant={!showDisabled ? "contained" : "outlined"}
                                onClick={() => setShowDisabled(false)}
                                color="secondary"
                                sx={{ marginRight: 1 }}
                            >
                                MANAGE ACTIVE
                            </Button>
                            <Button variant={showDisabled ? "contained" : "outlined"} onClick={() => setShowDisabled(true)} color="secondary">
                                MANAGE INACTIVE
                            </Button>
                        </Grid>
                    )}
                    {showDisabled ? (
                        <>
                            <Typography fontWeight="medium" sx={{ mt: 2 }}>
                                Disabled aeds
                            </Typography>
                            <MuiTable
                                noDelete
                                undo
                                onUndo={(aed) => enableAED(aed.id)}
                                noView
                                onDelete={(aed) => {
                                    customConfirmAlert(
                                        () => {
                                            disableAED(aed?.id);
                                        },
                                        () => {},
                                        "CONFIRM"
                                    );
                                }}
                                onView={(aed) =>
                                    navigate(
                                        `/organization/${aed?.ownerInfo?.organizationId}/location/${aed?.ownerInfo?.locationId || "no-location"}/aed/${aed?.id}`
                                    )
                                }
                                pagination
                                paginationURL={`/aed/disabled/organization/${organizationId}`}
                                noSelect
                                onEdit={(aed) => editAEDsHandler(aed)}
                                columns={columns}
                                modalName="AED"
                            />
                        </>
                    ) : (
                        <MuiTable
                            noView
                            noDelete={!user.userProfiles.systemAdmin && !user.userProfiles.franchise && !user.userProfiles.programCoordinator}
                            onDelete={(aed) => {
                                customConfirmAlert(
                                    () => {
                                        disableAED(aed?.id);
                                    },
                                    () => {},
                                    "CONFIRM"
                                );
                            }}
                            onView={(aed) =>
                                navigate(
                                    `/organization/${aed?.ownerInfo?.organizationId}/location/${aed?.ownerInfo?.locationId || "no-location"}/aed/${aed?.id}`
                                )
                            }
                            pagination
                            paginationURL={`/aed/organization/${organizationId}`}
                            noSelect
                            onEdit={(aed) => editAEDsHandler(aed)}
                            columns={columns}
                            modalName="AED"
                            search={filterFinal}
                        />
                    )}
                </>
            )}
        </>
    );
}
