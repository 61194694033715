import CustomModal from "../../components/CustomModal/CustomModal";
import { Button, Grid, Typography, Select, FormControl, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import { useState } from "react";
import apiRequest from "../../helpers/apiRequest";
import BasicDatePicker from "../../components/DatePicker";
import { notify } from "../../helpers/notify";
import { aedModel } from "../../entities/aedModel";
import { errorHandler, errorFieldHandler, errorFieldMessage } from "../../helpers/errorHelper";

type AddAccessoryModalProps = {
    aedModel: aedModel | null;
    open: boolean;
    onClose: any;
    accessories?: any[] | null;
    aeds?: any;
    onInspectionAdded?: () => any;
    onInspectionAddError?: () => any;
};

export default function DevicesModal({
    aedModel,
    open,
    onClose,
    accessories = [],
    aeds,
    onInspectionAdded = () => {},
    onInspectionAddError = () => {},
}: AddAccessoryModalProps) {
    function handleOnClose(bool: boolean) {
        onClose(bool);
        clearCamps();
    }

    const [accessoryType, setAccessoryType] = useState<any>("");
    const [accessoryOperationLevel, setAccessoryOperationLevel] = useState<any>("");
    const [accessoryExpiration, setAccessoryExpiration] = useState<any>("");

    const [errors, setErrors] = useState<any>([]);

    async function addInspection() {
        await apiRequest
            .post(`/aed/${aeds[0].id}/accessory/`, {
                type: accessoryType,
                expiration: accessoryExpiration ? new Date(accessoryExpiration).toISOString().slice(0, 10) : null,
                operationLevel: accessoryOperationLevel,
            })
            .then((res) => {
                notify("Accessory added", "success");
                clearCamps();
                onInspectionAdded();
            })
            .catch((err) => {
                setErrors(() => errorHandler(err));
                notify(err.response.data.errors[0].message, "error");
            });
    }

    function clearCamps() {
        setAccessoryType("");
        setAccessoryOperationLevel("");
        setAccessoryExpiration("");
        setErrors([]);
    }

    function isInstallationDate() {
        return accessoryType === "BATTERY" && aedModel?.fixedBatteryExpirationDate === false;
    }

    function isBackupInstallationDate() {
        return isInstallationDate() && accessoryOperationLevel === "BACKUP";
    }

    return (
        <CustomModal padding={2} open={open} title="Add accessory" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Add accessory</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Accessory</InputLabel>
                        <Select
                            error={errorFieldHandler(errors, "type")}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(evt: any) => setAccessoryType(evt.target.value)}
                            label="Accessory"
                        >
                            {aedModel?.comboBatteryAndPads ? (
                                <MenuItem value="BATTERY_PAD_COMBO">BATTERY PAD COMBO</MenuItem>
                            ) : (
                                [
                                    <MenuItem value="ADULT_PAD">ADULT PAD</MenuItem>,
                                    aedModel?.adultPadiCPROption && <MenuItem value="ADULT_PAD_ICPR">ADULT PAD iCPR</MenuItem>,
                                    <MenuItem value="BATTERY">BATTERY</MenuItem>,
                                ]
                            )}
                            <MenuItem disabled={!aedModel?.pediatricPadsOption} value="PEDIATRIC_PAD">
                                PEDIATRIC PAD
                            </MenuItem>
                        </Select>
                        <FormHelperText error>{errorFieldMessage(errors, "type")}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Operation Level</InputLabel>
                        <Select
                            error={errorFieldHandler(errors, "operationLevel")}
                            disabled={accessoryType === "PEDIATRIC_PAD"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(evt: any) => setAccessoryOperationLevel(evt.target.value)}
                            label="Operation Level"
                        >
                            <MenuItem value="PRIMARY">PRIMARY</MenuItem>
                            <MenuItem value="BACKUP">BACKUP</MenuItem>
                        </Select>
                        <FormHelperText error>{errorFieldMessage(errors, "operationLevel")}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <BasicDatePicker
                        error={errorFieldHandler(errors, "expiration")}
                        isDisabled={isBackupInstallationDate()}
                        value={accessoryExpiration}
                        onChange={(date) => setAccessoryExpiration(date)}
                        PickerProps={{
                            size: "small",
                            label: isInstallationDate() ? "Installation Date" : "Expiration Date",
                        }}
                        InputProps={{ fullWidth: true }}
                    />
                    <FormHelperText error>{errorFieldMessage(errors, "expiration")}</FormHelperText>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => addInspection()} fullWidth color="success">
                        ADD
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
