import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCircle, FaEdit } from "react-icons/fa";
import { TableRow, Table, TableCell, TableHead, TableBody, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { DateFormat } from "../helpers/DateFormat";
import flattenObj from "../helpers/flattenObj";

export default function RespondersTable({
    onRowSelectionChange = (rows) => {},
    rows = [],
    organizationId,
    locationId,
    onAedEdit = (aedId) => {},
    respondersInfo = [],
    setRespondersTablePage = {},
    setRespondersTableSize = {},
    setSortColumn = {},
    setSortOrder = {},
    getRespondersInfo = {},
}) {
    const [serializedRows, setSerializedRows] = useState([...rows.map((row) => flattenObj(row))]);
    const navigate = useNavigate();

    useEffect(() => {
        setSerializedRows([...rows.map((row) => flattenObj(row))]);
    }, [rows]);

    // FIX: When MUIDataTable is sorted by name, row's index changes - so was needed to search by name instead of index value
    const getResponderObjByName = (name) => {
        return serializedRows.find((responder) => responder.name === name);
    };

    const getResponderStatusByTraining = (responder) => {
        const overdue = responder.trainings.find((training) => training.overdue);
        if (overdue) {
            return renderStatus(overdue);
        }

        const dueNow = responder.trainings.find((training) => training.dueNow);
        if (dueNow) {
            return renderStatus(dueNow);
        }

        return renderStatus(responder);
    };

    const columns = [
        {
            name: "trainings",
            label: "trainings",
            options: {
                display: "false",
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            fontWeight: 600,
                            "&:hover": {
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => {
                            navigate(`/organization/${organizationId}/location/${locationId}/responder/${getResponderObjByName(value).id}`);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (_, tableMeta) => getResponderStatusByTraining(getResponderObjByName(tableMeta.rowData[1])),
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <FaEdit
                            onClick={() => onAedEdit(getResponderObjByName(tableMeta.rowData[1]))}
                            size={25}
                            style={{ marginLeft: 10, padding: 5, cursor: "pointer" }}
                        />
                    </>
                ),
            },
        },
    ];

    function renderStatus(row) {
        if (row.overdue) {
            return (
                <>
                    <FaCircle size={20} color="#FF0000" />
                </>
            );
        }
        if (row.dueNow) {
            return (
                <>
                    <FaCircle size={20} color="#0d5eaf" />
                </>
            );
        }
        if (!row.isActive) {
            return (
                <>
                    <FaCircle size={20} color="#454545" />
                </>
            );
        }
        return (
            <>
                <FaCircle size={20} color="#00BF41" />
            </>
        );
    }

    const [rowsSelected, setRowsSelected] = useState([]);

    useEffect(() => {
        onRowSelectionChange(rowsSelected);
    }, [rowsSelected]);

    const options = {
        filterType: "checkbox",
        elevation: 0,
        expandableRows: true,
        expandableRowsHeader: true,
        expandableRowsOnClick: false,
        responsive: "scroll",
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setRowsSelected([...allRowsSelected.map((rowSelectedInfo) => rowSelectedInfo.dataIndex)]);
        },
        rowsSelected: rowsSelected,
        isRowExpandable: (dataIndex, expandedRows) => {
            return true;
        },
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
                <TableRow sx={{ backgroundColor: "rgba(244,244,244,0.5)" }}>
                    <TableCell colSpan={colSpan}>
                        <Table>
                            <TableHead sx={{ backgroundColor: "transparent" }}>
                                <TableCell sx={{ fontWeight: "bold" }}>Class Type</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Expiration</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Notes</TableCell>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: "transparent" }}>
                                {rowData[0]?.map((accessory) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{accessory?.type}</TableCell>
                                            <TableCell>{DateFormat(accessory?.expirationDate)}</TableCell>
                                            <TableCell>{renderStatus(accessory)}</TableCell>
                                            <TableCell sx={{ maxWidth: "360px" }}>{accessory?.notes}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            );
        },
        rowsPerPageOptions: [10, 25, 50, 100, { label: "ALL", value: respondersInfo?.totalElements }],
        rowsPerPage: respondersInfo.size ?? 10,
        count: respondersInfo.totalElements ?? 10,
        page: respondersInfo.pageable.pageNumber ?? 0,
        serverSide: true,
        onChangePage: (currentPage) => {
            setRespondersTablePage(currentPage);
            getRespondersInfo(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setRespondersTablePage(0);
            setRespondersTableSize(numberOfRows);
            getRespondersInfo(0, numberOfRows);
        },
        onColumnSortChange: (changedColumn, direction) => {
            const sortColumn = changedColumn?.replaceAll("_", ".")?.replaceAll("ownerInfo.locationName", "location.name").replaceAll("userStatus", "isActive");
            const sortOrder = direction;

            setSortColumn(sortColumn);
            setSortOrder(direction);
            getRespondersInfo(respondersInfo.pageable.pageNumber, respondersInfo.size, sortColumn, sortOrder);
        },
    };

    return (
        <div style={{ border: "1px solid rgba(224, 224, 224, 1)", borderRadius: 4, width: "100%" }}>
            <MUIDataTable
                title={"Employee List"}
                data={serializedRows}
                columns={columns}
                options={options}
                components={{
                    TableToolbarSelect: (props) => <></>,
                    TableToolbar: (props) => <div></div>,
                }}
            />
        </div>
    );
}
