import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, Box, CircularProgress, Tooltip, Switch } from "@mui/material";
import MUIDataTable from "mui-datatables";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { AuthContext } from "../../context/AuthContext";
import CustomModal from "../../components/CustomModal/CustomModal";
import ConfirmModal from "./ConfirmModal";
import { isSingular } from "./ConfirmModal";
import ConfirmDialog from "../../components/ConfirmDialog";

export default function Roles({ user: userProp, open, onClose, onUpdate }) {
    const { user: myUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const [user, setUser] = useState(userProp);

    const [organizations, setOrganizations] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false); // ID

    const [selectedProfile, setSelectedProfile] = useState(null);
    const [targetProfile, setTargetProfile] = useState(null);

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

    const [isSelf, setIsSelf] = useState(false);

    const isUserFranchise = (id) => {
        if (user.userProfiles.franchise) {
            for (const key in user.profiles) {
                if (id == user.profiles[key].placeId) return true;
            }
        }

        return false;
    };

    const isUserLocation = (id) => {
        if (user.locations && user.locations.length > 0) {
            for (const key in user.locations) {
                if (id == user.locations[key].id) return true;
            }
        }

        return false;
    };

    const columns = [
        {
            name: "name",
            label: hasProfile(["FRANCHISE_ADMIN"]) ? "Organization" : "Location",
            width: 1,
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            marginLeft: 2,
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => {
                            const link = hasProfile(["FRANCHISE_ADMIN"])
                                ? `/organization/${tableMeta.rowData[2]}`
                                : `/organization/${user?.organization?.id}/location/${tableMeta.rowData[2]}`;
                            navigate(link);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
            },
        },
        {
            name: "status",
            label: "Enable / Disable",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const id = tableMeta.rowData[2];
                    const isChecked = hasProfile(["FRANCHISE_ADMIN"]) ? isUserFranchise(id) : isUserLocation(id);

                    return (
                        <>
                            {isSwitchLoading == id ? (
                                <CircularProgress sx={{ marginLeft: 3 }} size={24} color="primary" />
                            ) : (
                                <Tooltip title={isChecked.toString().charAt(0).toUpperCase() + isChecked.toString().slice(1)}>
                                    <Switch
                                        checked={isChecked}
                                        disabled={isSelf}
                                        onChange={async () => {
                                            setIsSwitchLoading(id);
                                            hasProfile(["FRANCHISE_ADMIN"]) ? await setAsFranchise(id) : await setAsSiteProfile(selectedProfile, id);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
        },
        {
            name: "id",
            options: {
                display: false,
                filter: false,
            },
        },
    ];

    const options = {
        selectableRowsHeader: false,
        selectableRows: "none",
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        rowsPerPage: hasProfile(["FRANCHISE_ADMIN"]) ? organizations.length : locations.length,
        customFooter: () => {},
    };

    useEffect(() => {
        setUser(userProp);
        setLocations([]);
        setIsLoading(true);
        setSelectedProfile(null);
        setIsSelf(userProp?.id == myUser?.id);
    }, [userProp]);

    function handleOnClose(bool) {
        if (!hasAnyProfileActive()) {
            return setIsLeaveModalOpen(true);
        }

        onClose(bool);
        setIsConfirmModalOpen(false);
    }

    async function getUpdatedUser() {
        await apiRequest.get(`/user/${user?.id}`).then((res) => setUser({ ...res.data }));
    }

    async function addProfile(profile, id, add) {
        await apiRequest
            .put(`/user/${!add ? "removeprofile" : "addprofile"}`, {
                email: user?.email,
                userProfileType: profile,
                id: id,
            })
            .then((res) => {
                onUpdate();
                notify(`Profile successfully ${add ? "added" : "removed"}!`, "success");
                getUpdatedUser();
                if (profile === "PROGRAM_COORDINATOR" || profile === "PROGRAM_STAFF") {
                    !add && setSelectedProfile(null);
                }
            })
            .catch((err) => {
                notify(err.response.data.errors[0].message, "error");
            });
    }

    async function setAsFranchise(organizationId) {
        const alreadyHasProfile =
            user?.profiles.filter((profile) => profile.profileType == "ROLE_FRANCHISE_ADMIN" && profile.placeId === organizationId).length > 0;

        await apiRequest
            .put(`/user/franchise/${alreadyHasProfile ? "remove" : "add"}-org/`, {
                email: user?.email,
                organizationId: organizationId,
            })
            .then((res) => {
                getUpdatedUser();
                onUpdate();
                notify(`User ${alreadyHasProfile ? "un" : ""}set as Franchise`, "success");
            })
            .catch((err) => {
                notify(`Error ${alreadyHasProfile ? "un" : ""}setting user as Franchise`, "error");
            });
    }

    async function setAsSuperAdmin() {
        await apiRequest
            .put(`/user/systemadmin/${user?.userProfiles.systemAdmin ? "disable" : "enable"}`, {
                email: user?.email,
            })
            .then((res) => {
                getUpdatedUser();
                onUpdate();
                notify(`User ${user?.userProfiles.systemAdmin ? "un" : ""}set as System Admin`, "success");
            })
            .catch((err) => {
                notify(`${err.response.data.errors[0].message}`, "error");
            });
    }

    async function getOrganizationsAndLocations() {
        if (organizations.length < 1) {
            await apiRequest
                .get("/organization/all")
                .then((res) => setOrganizations(res.data))
                .catch((err) => []);
        }

        await apiRequest
            .get(`/location/all/${user?.organization?.id}`)
            .then((res) => setLocations(res.data))
            .catch((err) => []);

        setIsLoading(false);
        setIsSwitchLoading(false);
    }

    useEffect(() => {
        if (user) {
            setSelectedProfile(getUserActiveProfile()?.replace(" ", "_"));
            getOrganizationsAndLocations();
        }
    }, [user]);

    const hasAnyProfileActive = (allowedProfiles = []) => {
        if (user.profiles.length > 0) {
            for (let i = 0; i < allowedProfiles.length; i++) {
                if (user.profiles[0]?.profileType == allowedProfiles[i]) return false;
            }

            setIsConfirmModalOpen(true);
            return true;
        }

        return false;
    };

    const getUserActiveProfile = () => {
        return user.profiles.length > 0 ? user.profiles[0]?.profileType.replace("ROLE_", "").replace("_", " ") : null;
    };

    const removeAllActiveProfiles = async () => {
        await apiRequest
            .put("/user/remove-all-profiles", {
                email: user.email,
            })
            .then(async (res) => {
                notify("All profiles were removed", "success");
                await getUpdatedUser();
            })
            .catch((err) => []);
    };

    async function handleSubmit(profile = selectedProfile) {
        if (profile === "SYSTEM_ADMIN") {
            return await setAsSuperAdmin();
        }

        if (profile === "PROGRAM_COORDINATOR" || profile === "PROGRAM_STAFF") {
            return await setAsProgramProfile(profile);
        }
    }

    async function setAsProgramProfile(profile) {
        if (user.profiles.length > 0 && user.profiles[0]?.profileType === "ROLE_" + profile) {
            return await addProfile(profile, user?.organization?.id, false);
        }

        await addProfile(profile, user?.organization?.id, true);
    }

    async function setAsSiteProfile(profile, locationId) {
        if (isUserLocation(locationId)) {
            return await addProfile(profile, locationId, false);
        }

        await addProfile(profile, locationId, true);
    }

    function hasPermission(roles) {
        for (let i = 0; i < roles.length; i++) {
            if (myUser.userProfiles[roles[i]]) {
                return true;
            }
        }

        return false;
    }

    function hasProfile(profiles) {
        for (let i = 0; i < profiles.length; i++) {
            if (selectedProfile === profiles[i].replace(" ", "_")) {
                return true;
            }
        }

        return false;
    }

    const ProfileButton = (profileLabel) => (
        <Button
            onClick={() => {
                profileLabel = profileLabel.replace(" ", "_");

                setTargetProfile(profileLabel);

                if (hasAnyProfileActive(["ROLE_" + profileLabel])) return;

                setSelectedProfile(profileLabel);
                handleSubmit(profileLabel);
            }}
            variant={hasProfile([profileLabel]) ? "contained" : "outlined"}
        >
            {hasProfile([profileLabel]) ? `REMOVE ${profileLabel} PROFILE${!isSingular(profileLabel) ? "S" : ""}` : `SET AS ${profileLabel}`}
        </Button>
    );

    const getUserCompleteName = () => {
        return `${user?.firstName} ${user?.lastName}`;
    };

    return (
        <CustomModal padding={2} open={open} title="User roles" onClose={() => handleOnClose(false)}>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                sourceProfile={getUserActiveProfile()}
                targetProfile={targetProfile?.replace("_", " ")}
                onAccept={() => {
                    removeAllActiveProfiles().then(() => {
                        setIsConfirmModalOpen(false);

                        handleSubmit(targetProfile).then(() => {
                            setSelectedProfile(targetProfile);
                            setTargetProfile(null);
                        });
                    });
                }}
                onClose={() => setIsConfirmModalOpen(false)}
            />

            <ConfirmDialog
                open={isLeaveModalOpen}
                onClose={() => setIsLeaveModalOpen(false)}
                text="Are you sure you want to leave the user without a profile?"
                onConfirm={() => {
                    onClose(false);
                    setSelectedProfile(null);
                    setIsConfirmModalOpen(false);
                    setIsLeaveModalOpen(false);
                }}
            />

            <Grid container spacing={2} paddingBottom={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">{getUserCompleteName()}</Typography>
                    <Typography variant="caption">{user?.email}</Typography>
                    <br />
                    <Typography variant="caption">{user?.organization.name}</Typography>
                </Grid>

                {isSelf ? (
                    <Grid item xs={12}>
                        <Typography variant="body2">{`YOU'RE A ${getUserActiveProfile()}`}</Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="body2">PROFILES</Typography>
                        </Grid>

                        {hasPermission(["systemAdmin"]) && (
                            <Grid item xs={12} gap={2} display="flex">
                                {ProfileButton("SYSTEM ADMIN")}
                                {ProfileButton("FRANCHISE ADMIN")}
                            </Grid>
                        )}

                        <Grid item xs={12} gap={2} display="flex">
                            {hasPermission(["systemAdmin", "franchise"]) && ProfileButton("PROGRAM COORDINATOR")}
                            {hasPermission(["systemAdmin", "franchise", "programCoordinator"]) && ProfileButton("PROGRAM STAFF")}
                        </Grid>

                        <Grid item xs={12} gap={2} display="flex">
                            {hasPermission(["systemAdmin", "franchise", "programCoordinator", "programStaff"]) && ProfileButton("SITES SUPERVISOR")}
                            {hasPermission(["systemAdmin", "franchise", "programCoordinator", "programStaff", "sitesSupervisor"]) &&
                                ProfileButton("SITE COORDINATOR")}
                            {hasPermission(["systemAdmin", "franchise", "programCoordinator", "programStaff", "sitesSupervisor", "siteCoordinator"]) &&
                                ProfileButton("SITE STAFF")}
                        </Grid>
                    </>
                )}

                {hasProfile(["SYSTEM_ADMIN", "PROGRAM_COORDINATOR", "PROGRAM_STAFF"]) && (
                    <Typography variant="h6" m={"auto"} mt={4} mb={2}>
                        {`${getUserCompleteName().toUpperCase()} IS A ${
                            getUserActiveProfile() === "SYSTEM ADMIN"
                                ? "SYSTEM ADMIN"
                                : `${getUserActiveProfile() || selectedProfile} AT ${user?.organization.name.toUpperCase()}`
                        }`}
                    </Typography>
                )}

                {hasProfile(["FRANCHISE_ADMIN"]) && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="body2">ORGANIZATION</Typography>
                        </Grid>

                        <Grid item xs={12} gap={2}>
                            {isLoading ? (
                                <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress size={30} color="primary" />
                                </Box>
                            ) : (
                                <>
                                    {!user.franchise && (
                                        <Typography variant="caption" color="error">
                                            Select at least one organization
                                        </Typography>
                                    )}
                                    <MUIDataTable data={organizations} columns={columns} options={options} />
                                </>
                            )}
                        </Grid>
                    </>
                )}

                {hasProfile(["SITES_SUPERVISOR", "SITE_COORDINATOR", "SITE_STAFF"]) && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="body2">LOCATIONS</Typography>
                        </Grid>

                        <Grid item xs={12} gap={2}>
                            {isLoading ? (
                                <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress size={30} color="primary" />
                                </Box>
                            ) : (
                                <>
                                    {user.locations.length < 1 && (
                                        <Typography variant="caption" color="error">
                                            Select at least one location
                                        </Typography>
                                    )}
                                    <MUIDataTable data={locations} columns={columns} options={options} />
                                </>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </CustomModal>
    );
}
