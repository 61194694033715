import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCircle, FaEdit } from "react-icons/fa";
import { TableRow, Table, TableCell, TableHead, TableBody, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { DateFormat } from "../helpers/DateFormat";
import flattenObj from "../helpers/flattenObj";
import { format, parse } from "date-fns";

export default function AedsTable({
    onRowSelectionChange = (rows) => {},
    rows = [],
    organizationId,
    locationId,
    onAedEdit = (aedId) => {},
    aedsInfo = [],
    setAedsTablePage = {},
    setAedsTableSize = {},
    setSortColumn = {},
    setSortOrder = {},
}) {
    const [serializedRows, setSerializedRows] = useState([...rows.map((row) => flattenObj(row))]);
    useEffect(() => setSerializedRows([...rows.map((row) => flattenObj(row))]), [rows]);

    useEffect(() => {
        setSerializedRows([...rows.map((row) => flattenObj(row))]);
    }, [rows]);

    const navigate = useNavigate();

    const columns = [
        {
            name: "accessories",
            label: "accessories",
            options: {
                display: "false",
                filter: true,
                sort: true,
            },
        },
        {
            name: "aedModel_manufacturer",
            label: "Manufacturer",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                textDecorationLine: "underline",
                                fontWeight: 600,
                            },
                        }}
                        onClick={() => navigate(`/organization/${organizationId}/location/${locationId}/aed/${tableMeta.tableData[tableMeta.rowIndex][6]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value.replaceAll("_", " ")}
                    </Typography>
                ),
            },
        },
        {
            name: "aedModel_model",
            label: "Model",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "serial",
            label: "Serial Number",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "placement",
            label: "Placement",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "inspectionDue",
            label: "Inspection Due",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updatedValue) => (
                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                        {renderStatus(rows[tableMeta.rowIndex])}
                        <span>{value ? format(parse(value, "yyyy-MM-dd", new Date()), "MM/dd/yyyy") : ""}</span>
                    </div>
                ),
            },
        },
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <FaEdit onClick={() => onAedEdit(rows[tableMeta.rowIndex])} size={25} style={{ marginLeft: 10, padding: 5, cursor: "pointer" }} />
                    </>
                ),
            },
        },
    ];

    function renderStatus(row) {
        if (!row?.isActive) {
            return (
                <>
                    <FaCircle size={20} color="#454545" />{" "}
                </>
            );
        }

        if (row?.dueNow) {
            return (
                <>
                    <FaCircle size={20} color="#0d5eaf" />{" "}
                </>
            );
        }

        if (row?.needsAttention) {
            return (
                <>
                    <FaCircle size={20} color="#FFA800" />{" "}
                </>
            );
        }

        if (row?.overDue || row?.pastDue) {
            return (
                <>
                    <FaCircle size={20} color="#FF0000" />{" "}
                </>
            );
        }
        return (
            <>
                <FaCircle size={20} color="#00BF41" />{" "}
            </>
        );
    }

    function renderStatusAccessory(row) {
        if (!row?.active) {
            return (
                <>
                    <FaCircle size={20} color="#454545" />{" "}
                </>
            );
        }

        if (row?.dueNow) {
            return (
                <>
                    <FaCircle size={20} color="#0d5eaf" />{" "}
                </>
            );
        }

        if (row?.needsAttention) {
            return (
                <>
                    <FaCircle size={20} color="#FFA800" />{" "}
                </>
            );
        }
        if (row?.overDue) {
            return (
                <>
                    <FaCircle size={20} color="#FF0000" />{" "}
                </>
            );
        }

        return (
            <>
                <FaCircle size={20} color="#00BF41" />{" "}
            </>
        );
    }

    const [rowsSelected, setRowsSelected] = useState([]);

    const options = {
        filterType: "checkbox",
        elevation: 0,
        expandableRows: true,
        expandableRowsHeader: true,
        expandableRowsOnClick: false,
        rowsSelected: rowsSelected,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setRowsSelected([...allRowsSelected.map((rowSelectedInfo) => rowSelectedInfo.dataIndex)]);
            onRowSelectionChange(allRowsSelected.map((rowSelectedInfo) => rowSelectedInfo.dataIndex));
        },
        onRowsDelete: (rowsDeleted) => {
            // const aedsToDelete = rowsDeleted?.data.map((rowData) => rows[rowData?.index]);
            // TODO: Promise.all for removal

            return false;
        },
        isRowExpandable: (dataIndex, expandedRows) => {
            return true;
        },
        rowsExpanded: [],
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
                <TableRow sx={{ backgroundColor: "rgba(244,244,244,0.5)" }}>
                    <TableCell colSpan={colSpan}>
                        <Table>
                            <TableHead sx={{ backgroundColor: "transparent" }}>
                                <TableCell sx={{ fontWeight: "bold" }}>Accessory</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Expiration</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Operation Level</TableCell>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: "transparent" }}>
                                {rowData[0]?.map((accessory, index) => {
                                    return (
                                        <TableRow key={"acc_" + index}>
                                            <TableCell sx={{ width: "160px" }}>{accessory?.type?.replace("_", " ")}</TableCell>

                                            <TableCell sx={{ width: "160px" }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                    {renderStatusAccessory(accessory)}
                                                    {DateFormat(accessory?.expiration)}
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                {accessory?.operationLevel == "PRIMARY" ? (
                                                    <div
                                                        style={{
                                                            backgroundColor: "#0d5eaf",
                                                            borderRadius: 30,
                                                            padding: "0 12px",
                                                            width: "fit-content",
                                                            height: 25,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography sx={{ cursor: "default" }} color="#FFF">
                                                            Primary
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            backgroundColor: "#0d5eaf",
                                                            borderRadius: 30,
                                                            padding: "0 12px",
                                                            width: "fit-content",
                                                            height: 25,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography sx={{ cursor: "default" }} color="#FFF">
                                                            Backup
                                                        </Typography>
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            );
        },
        rowsPerPageOptions: [10, 25, 50, 100, { label: "ALL", value: aedsInfo?.totalElements }],
        rowsPerPage: aedsInfo.size ?? 10,
        count: aedsInfo.totalElements ?? 10,
        page: aedsInfo.pageable.pageNumber ?? 0,
        serverSide: true,
        onColumnSortChange: (changedColumn, direction) => {
            setSortColumn(changedColumn?.replaceAll("_", ".")?.replaceAll("ownerInfo.locationName", "location.name").replaceAll("userStatus", "isActive"));
            setSortOrder(direction);
        },
        onChangePage: (currentPage) => setAedsTablePage(currentPage),
        onChangeRowsPerPage: (numberOfRows) => {
            setAedsTablePage(0);
            setAedsTableSize(numberOfRows);
        },
        customToolbarSelect: () => {},
    };

    return (
        <MUIDataTable
            title={"Employee List"}
            data={serializedRows}
            columns={columns}
            options={options}
            components={{
                TableToolbar: (props) => <>&nbsp;</>,
            }}
        />
    );
}
