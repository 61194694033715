import CustomModal from "../../CustomModal/CustomModal";
import { useEffect, useState } from "react";
import AEDList from "./list";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditAED from "./edit/editAed";
import customConfirmAlert from "../../confirmAlert";

export default function AedsModal({ open, onClose, aed = null, editMode = false, onListUpdate = () => {}, closeAfterEdit = false }) {
    const [state, setState] = useState("list");
    const [aedToEdit, setAEDToEdit] = useState(aed);
    const [updated, setUpdated] = useState(true);

    function handleOnClose(bool, noModal) {
        if (noModal) {
            if (closeAfterEdit) {
                onClose();
            } else {
                setState("list");
            }
            // setUpdated(true);
            return;
        }

        if (!updated) {
            customConfirmAlert(() => {
                onClose(bool);
                setState("list");
                setUpdated(true);
            });
        } else {
            onClose(bool);
            setState("list");
        }
    }

    useEffect(() => {
        if (aedToEdit !== null) {
            setState("edit");
            // setAEDToEdit(aedToEdit);
        }
    }, [aedToEdit]);

    return (
        <CustomModal padding={2} open={open} title="AEDs" onClose={() => handleOnClose(false)}>
            {state == "list" && (
                <>
                    <AEDList onDelete={() => onListUpdate()} onEdit={(aed) => setAEDToEdit(aed)} />
                </>
            )}

            {state == "edit" && (
                <>
                    {editMode && (
                        <Button onClick={() => setState("list")}>
                            <ArrowBackIcon />
                            &nbsp;Back to aeds list
                        </Button>
                    )}
                    <EditAED aed={aedToEdit} onEdit={() => handleOnClose(false, true)} onSave={{ updated, setUpdated }} />
                </>
            )}
        </CustomModal>
    );
}
