import { useState, useEffect } from "react";
import { forecastAccessoryQuantity } from "../../../services/forecast/getForecast";
import { Avatar, Box, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography, Collapse } from "@mui/material";
import { ExpandMore, ExpandLess, UnfoldMore } from "@mui/icons-material";
import { colors } from "../../../themes/main";

type ForecastAccessoriesTableProps = {
    forecastList: forecastAccessoryQuantity[];
};

export default function ForecastAccessoriesTable({ forecastList }: ForecastAccessoriesTableProps) {
    const [expandedList, setExpandedList] = useState<boolean[]>([false]);
    const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);

    const handleExpandedList = (key: number) => {
        let newList = [...expandedList];
        newList[key] = !newList[key];

        setExpandedList(newList);
    };

    const handleExpandedAllList = () => {
        setIsAllExpanded(!isAllExpanded);

        const newList = expandedList.fill(isAllExpanded);
        setExpandedList(newList);
    };

    useEffect(() => {
        let initExpandedList: boolean[] = [];

        for (let i = 0; i < forecastList.length; i++) {
            initExpandedList[i] = false;
        }

        setExpandedList(initExpandedList);
    }, [forecastList]);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                backgroundColor: colors.main.primary,
                                border: "none",
                                padding: 1.5,
                                color: "white",
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                            }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center", marginLeft: 2 }}>
                                <Tooltip title="Expand/Collapse">
                                    <UnfoldMore sx={{ cursor: "pointer" }} onClick={handleExpandedAllList} />
                                </Tooltip>
                                <Typography>AED Model</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        border: "none",
                        borderLeft: "1px solid #0a4176",
                        borderRight: "1px solid #0a4176",
                        backgroundColor: "#FFF",
                        "& > *:nth-of-type(even)": { backgroundColor: "#e5f2fd" },
                    }}
                >
                    {forecastList.length > 0 ? (
                        <>
                            {forecastList.map((accessory, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Box
                                            onClick={() => handleExpandedList(index)}
                                            sx={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center", marginLeft: 2 }}
                                        >
                                            {expandedList[index] ? (
                                                <Tooltip title="Collapse">
                                                    <ExpandLess sx={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Expand">
                                                    <ExpandMore sx={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            )}
                                            <Avatar alt="AED model image" src={accessory.aedModel.urlPhoto} />
                                            <Box>
                                                <Typography variant="subtitle1">{accessory.aedModel.model}</Typography>
                                                <Typography variant="caption">{accessory.aedModel.manufacturer}</Typography>
                                            </Box>
                                        </Box>
                                        <Collapse in={expandedList[index]}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow sx={{ backgroundColor: "#CCC" }}>
                                                        {accessory.aedModel.comboBatteryAndPads ? (
                                                            <TableCell>
                                                                <Typography>Battery Pad Combo</Typography>
                                                            </TableCell>
                                                        ) : (
                                                            <>
                                                                <TableCell>
                                                                    <Typography>Battery</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>Adult Pad</Typography>
                                                                </TableCell>
                                                                {accessory.aedModel?.adultPadiCPROption && (
                                                                    <TableCell>
                                                                        <Typography>Adult Pad iCPR</Typography>
                                                                    </TableCell>
                                                                )}
                                                            </>
                                                        )}
                                                        {accessory.aedModel.pediatricPadsOption && (
                                                            <TableCell>
                                                                <Typography>Pediatric Pad</Typography>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow sx={{ backgroundColor: "#EEE" }}>
                                                        {accessory.aedModel.comboBatteryAndPads ? (
                                                            <TableCell>
                                                                <Typography>{accessory.quantityByModel.batteryPadCombo}</Typography>
                                                            </TableCell>
                                                        ) : (
                                                            <>
                                                                <TableCell>
                                                                    <Typography>{accessory.quantityByModel.battery}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>{accessory.quantityByModel.adultPad}</Typography>
                                                                </TableCell>
                                                                {accessory.aedModel?.adultPadiCPROption && (
                                                                    <TableCell>
                                                                        <Typography>{accessory.quantityByModel.adultPadIcpr}</Typography>
                                                                    </TableCell>
                                                                )}
                                                            </>
                                                        )}
                                                        {accessory.aedModel.pediatricPadsOption && (
                                                            <TableCell>
                                                                <Typography>{accessory.quantityByModel.pediatricPad}</Typography>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <Typography align="center" padding={4}>
                            No matching results found
                        </Typography>
                    )}
                </TableBody>
                <TableFooter>
                    <TableCell
                        colSpan={2}
                        sx={{
                            backgroundColor: colors.main.primary,
                            border: "none",
                            padding: 0.5,
                            color: "white",
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                        }}
                    ></TableCell>
                </TableFooter>
            </Table>
        </>
    );
}
