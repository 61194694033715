import { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { errorFieldHandler, errorFieldMessage } from "../../helpers/errorHelper";
import useAuth from "../../hooks/useAuth";
import CustomModal from "../../components/CustomModal/CustomModal";
import customConfirmAlert from "../../components/confirmAlert";

export default function AddUserModal({ open, onClose, onUserAdded = () => {}, onUserAddError = () => {} }) {
    function handleOnClose(bool) {
        if (bool) {
            clearCamps();
            return onClose();
        }

        customConfirmAlert(() => {
            clearCamps();
            onClose && onClose();
        });
    }

    const { user } = useAuth();

    const [organizationSelected, setOrganizationSelected] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fone, setFone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [organizations, setOrganizations] = useState([]);

    async function getOrganizations() {
        let organizationsInfo = await apiRequest.get(`/organization/all`).then((res) => res.data);
        setOrganizations([...organizationsInfo]);
    }

    const [errors, setErrors] = useState([]);

    async function addUser() {
        await apiRequest
            .post(`/user/`, {
                firstName: firstName,
                lastName: lastName,
                fone: fone,
                email: email,
                organizationId: organizationSelected?.id,
                password: password,
            })
            .then((res) => {
                onUserAdded();
                handleOnClose(true);
                notify("User created", "success");
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    notify("Error adding user " + err?.response?.data?.error, "error");
                }
            });
    }

    function clearCamps() {
        setErrors([]);
        setOrganizationSelected("");
        setFirstName("");
        setLastName("");
        setFone("");
        setEmail("");
        setPassword("");
    }

    useEffect(() => {
        getOrganizations();
    }, []);

    return (
        <CustomModal padding={2} open={open} title="Add user" onClose={() => handleOnClose()}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Add User</Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "firstName")}
                        error={errorFieldHandler(errors, "firstName")}
                        label="First name"
                        value={firstName}
                        fullWidth
                        onChange={(evt) => setFirstName(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "lastName")}
                        error={errorFieldHandler(errors, "lastName")}
                        label="Last name"
                        value={lastName}
                        fullWidth
                        onChange={(evt) => setLastName(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        helperText={errorFieldMessage(errors, "email")}
                        error={errorFieldHandler(errors, "email")}
                        label="Email"
                        value={email}
                        fullWidth
                        onChange={(evt) => setEmail(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        helperText={errorFieldMessage(errors, "fone")}
                        error={errorFieldHandler(errors, "fone")}
                        label="Phone"
                        value={fone}
                        fullWidth
                        onChange={(evt) => setFone(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                        <Select
                            helperText={errorFieldMessage(errors, "organizationId")}
                            error={errorFieldHandler(errors, "organizationId")}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(evt) => setOrganizationSelected(evt.target.value)}
                            label="Organization"
                            MenuProps={{ sx: { maxHeight: 250 } }}
                        >
                            {organizations?.map((org) => (
                                <MenuItem value={org}>{org.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {user?.userProfiles?.systemAdmin && (
                    <Grid item xs={12}>
                        <TextField
                            helperText={errorFieldMessage(errors, "password")}
                            error={errorFieldHandler(errors, "password")}
                            type="password"
                            label="Password"
                            value={password}
                            fullWidth
                            onChange={(evt) => setPassword(evt.target.value)}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => addUser()} fullWidth color="success">
                        {"ADD"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
