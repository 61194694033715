import { Grid, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../themes/main";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "30vw",
    borderRadius: "0.3rem",
};

const styleHeader = {
    backgroundColor: colors.main.primary,
    color: "white",
    padding: "10px 20px",
    borderTopLeftRadius: "0.3rem",
    borderTopRightRadius: "0.3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItens: "center",
};

export default function CustomModal({ open, title, children, onClose, padding = "10px 20px", width = "80vw" }: any) {
    const styleBody = {
        padding: padding,
        backgroundColor: "#ffff",
        borderBottomLeftRadius: "0.3rem",
        borderBottomRightRadius: "0.3rem",
        maxHeight: "80vh",
        overflowY: "auto",
        width: width,
        maxWidth: "90vw",
    };

    return (
        <Modal
            aria-labelledby="transition-modal-titlex"
            aria-describedby="transition-modal-descriptionx"
            open={open}
            onClose={() => onClose()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Grid sx={{ maxHeight: "80vh", maxWidth: "90vw", overflowY: "scroll", transition: "0.5s" }}>
                    <Grid sx={style}>
                        <Grid sx={styleHeader}>
                            <Typography variant="h6">{title}</Typography>
                            <IconButton aria-label="close" onClick={() => onClose()}>
                                <CloseIcon style={{ color: "#FFFF" }} />
                            </IconButton>
                        </Grid>
                        <Grid sx={styleBody}>{children}</Grid>
                    </Grid>
                </Grid>
            </Fade>
        </Modal>
    );
}
