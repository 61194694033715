import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaTrash, FaEdit, FaCircle } from "react-icons/fa";
import { Button, Grid, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import apiRequest from "../../helpers/apiRequest";
import { DateFormat } from "../../helpers/DateFormat";
import { notify } from "../../helpers/notify";
import AddTraining from "./addTraining";
import EditTraining from "./editTraining";
import DashboardLayout from "../../layouts/DashboardLayout";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function Organization() {
    const { responderId } = useParams();
    const [selectedTrainings, setCurrentSelectedTrainings] = useState<any>([]);

    const columnsTrainings = [
        {
            field: "active",
            headerName: "Status",
            width: 80,
            center: true,
            valueGetter: (params: any) => `${params.row || ""}`,
            renderCell: (params: any) => renderStatus(params.row),
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
        },
        {
            field: "notes",
            headerName: "Notes",
            flex: 1,
        },
        {
            field: "expirationDate",
            headerName: "Expiration",
            renderCell: (params: any) => <span>{DateFormat(params.row.expirationDate)}</span>,
        },
        {
            field: "status",
            headerName: "Actions",
            center: true,
            valueGetter: (params: any) => `${params.row || ""}`,
            renderCell: (params: any) => (
                <>
                    <FaEdit
                        onClick={() => {
                            setTrainingToEdit(params.row);
                            setEditTrainingModalOpen(true);
                        }}
                        style={{ marginLeft: 20, padding: 1, cursor: "pointer" }}
                        size={20}
                        color="rgba(0,0,0,0.65)"
                    />
                    <FaTrash
                        onClick={() => deleteTrainings(params.row)}
                        style={{ marginLeft: 20, padding: 1, cursor: "pointer" }}
                        size={20}
                        color="rgba(0,0,0,0.65)"
                    />
                </>
            ),
        },
    ];

    const [contentLoaded, setContentLoaded] = useState(false);
    const [responder, setResponder] = useState<any | null>(null);
    const [trainingToEdit, setTrainingToEdit] = useState<any>([]);

    async function getOrganizationInfo() {
        const locationInfo = await apiRequest.get(`/responder/${responderId}`).then((res) => res.data);
        setResponder(locationInfo);

        setContentLoaded(true);
    }

    useEffect(() => {
        getOrganizationInfo();
    }, []);

    async function deleteTrainings(training: any = null) {
        if (training) {
            await apiRequest
                .put(`/responder/${responder.id}/remove-training/${training.id}`)
                .then((res) => {
                    getOrganizationInfo();
                    notify("Training removed from Responder", "success");
                })
                .catch((err) => {
                    notify(`${err.response.data.error || err.response.data.errors[0].message}`, "success");
                });

            return;
        }

        const promises = selectedTrainings.map(async (training: any) => {
            return await apiRequest.put(`/responder/${responder.id}/remove-training/${training.id}`);
        });

        Promise.all(promises)
            .then((res) => {
                notify("Training removed from the inspectors", "success");
            })
            .catch((err) => {
                notify(err.response.data.error || err.response.data.errors[0].message, "error");
            })
            .finally(getOrganizationInfo);
    }

    const [EditTrainingModalOpen, setEditTrainingModalOpen] = useState(false);
    const [AddTrainingModalOpen, setAddTrainingModalOpen] = useState(false);

    const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<any[]>([]);

    function renderStatus(row: any) {
        if (row.overdue) {
            return (
                <>
                    <FaCircle size={20} color="#FF0000" />{" "}
                </>
            );
        }
        if (row.dueNow) {
            return (
                <>
                    <FaCircle size={20} color="#0d5eaf" />{" "}
                </>
            );
        }
        if (!row.isActive) {
            return (
                <>
                    <FaCircle size={20} color="#454545" />{" "}
                </>
            );
        }
        return (
            <>
                <FaCircle size={20} color="#00BF41" />
            </>
        );
    }

    function setSelectedTrainings() {
        const currentSelectedTrainings = responder?.trainings.filter((training: any) => selectedRowsIndexes.includes(training?.id));
        setCurrentSelectedTrainings(currentSelectedTrainings);
    }

    useEffect(() => {
        setSelectedTrainings();
    }, [selectedRowsIndexes]);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true, organization: { org: true, location: true } }} currentPage="Responder" />
            {contentLoaded && (
                <Grid container padding={1} spacing={2}>
                    <AddTraining
                        onInspectionAdded={() => {
                            getOrganizationInfo();
                        }}
                        responders={[responder]}
                        open={AddTrainingModalOpen}
                        onClose={() => setAddTrainingModalOpen(false)}
                        locationId={responder?.id}
                        organizationId={responder?.organization?.id}
                    />

                    <EditTraining
                        responderId={responder.id}
                        onInspectionAdded={() => {
                            getOrganizationInfo();
                        }}
                        trainings={[trainingToEdit]}
                        open={EditTrainingModalOpen}
                        onClose={() => setEditTrainingModalOpen(false)}
                        locationId={responder?.id}
                        organizationId={responder?.organization?.id}
                    />

                    <Grid item xs={12}>
                        <Typography variant="h4" fontWeight="bold">
                            {responder?.name}
                        </Typography>
                        <Typography variant="body1">Location: {responder?.locationName}</Typography>
                    </Grid>

                    <Grid item xs={12} marginTop={1}>
                        <Grid item xs={12} style={{ display: "flex", gap: 10 }}>
                            <Button variant="contained" onClick={() => setAddTrainingModalOpen(true)} sx={{ height: 40 }}>
                                ADD TRAINING
                            </Button>
                            {selectedRowsIndexes.length > 0 && (
                                <Button variant="contained" color="error" onClick={() => deleteTrainings()} sx={{ height: 40 }}>
                                    DELETE SELECTED
                                </Button>
                            )}
                        </Grid>

                        <div style={{ height: 400, width: "100%" }}>
                            <DataGrid
                                rows={[...responder?.trainings]}
                                columns={columnsTrainings}
                                checkboxSelection
                                onSelectionModelChange={(e) => setSelectedRowsIndexes(e)}
                                disableSelectionOnClick
                            />
                        </div>

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                                border: "1px solid rgba(0,0,0,0.1)",
                                marginTop: 2,
                                padding: 0.5,
                            }}
                        >
                            <FaCircle color="#00BF41" />{" "}
                            <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                Ok
                            </Typography>
                            <FaCircle color="#0d5eaf" />{" "}
                            <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                Due Now
                            </Typography>
                            <FaCircle color="#FF0000" />{" "}
                            <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                Past Due/Expired/Needs Attention
                            </Typography>
                            <FaCircle color="#454545" />{" "}
                            <Typography lineHeight={0} variant="caption" marginLeft={1} marginRight={2}>
                                Disabled/Inactive
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </DashboardLayout>
    );
}
