import CustomModal from "../../components/CustomModal/CustomModal";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import apiRequest from "../../helpers/apiRequest";
import BasicDatePicker from "../../components/DatePicker";

export default function DevicesModal({ open, onClose, aeds, onInspectionAdded = () => {}, onInspectionAddError = () => {}, organizationId, locationId }: any) {
    function handleOnClose(bool: boolean) {
        onClose(bool);
        clearCamps();
    }

    const [inspectorName, setInspectorName] = useState<any>("");
    const [inspectionDate, setInspectionDate] = useState<any>("");
    const [inspectionNotes, setInspectionNotes] = useState<any>("");
    const [inspectionStatus, setInspectionStatus] = useState<any>("");

    const [currentAeds, setCurrentAeds] = useState(aeds || []);

    async function updateInspection() {
        await apiRequest
            .post("/aed/inspection/", {
                aedIds: [...currentAeds.map((aed: any) => aed.id)],
                inspectorName: inspectorName,
                inspectionStatus: inspectionStatus,
                inspectionDate: new Date(inspectionDate).toISOString().slice(0, 10),
                notes: inspectionNotes,
            })
            .then((res) => {
                onInspectionAdded();
            })
            .catch((err) => {
                onInspectionAddError(JSON.stringify(err.response.data.error));
            });
    }

    function clearCamps() {
        setCurrentAeds([]);
    }

    useEffect(() => {
        if (Array.isArray(aeds) && open) {
            setCurrentAeds([...aeds]);
        }

        setInspectorName(aeds[0]?.inspectorName);
        setInspectionDate(aeds[0]?.inspectionDate);
        setInspectionNotes(aeds[0]?.notes);
        setInspectionStatus(aeds[0]?.status);
    }, [aeds, open]);

    return (
        <CustomModal padding={2} open={open} title="Edit inspection" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Edit Inspection</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField focused label="Inspector name" value={inspectorName} fullWidth onChange={(evt) => setInspectorName(evt.target.value)} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField focused label="Inspection Status" value={inspectionStatus} fullWidth onChange={(evt) => setInspectionStatus(evt.target.value)} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <BasicDatePicker
                        PickerProps={{ label: "Inspection Date" }}
                        value={inspectionDate}
                        onChange={(date) => {
                            setInspectionDate(date);
                        }}
                        InputProps={{ fullWidth: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField focused label="Notes" value={inspectionNotes} fullWidth onChange={(evt) => setInspectionNotes(evt.target.value)} />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => updateInspection()} fullWidth color="success">
                        {"ADD"}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
