import CustomModal from "../../CustomModal/CustomModal";
import { AddAED } from "./add/addAed";
import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import NewAEDsList from "./list";
import SetLocation from "./set-location";
import customConfirmAlert from "../../confirmAlert";

export default function AedsModal({ open, onClose, onUpdate = () => {}, addMode = false, location = null }) {
    function handleOnClose(bool) {
        onClose(bool);
        setState("list");
    }

    const [state, setState] = useState(addMode ? "add" : "list");

    const handleOnAddAed = () => {
        setState("list");
        onUpdate();
    };

    const [aedToEdit, setAEDToEdit] = useState(null);
    useEffect(() => {
        if (aedToEdit) {
            setState("edit");
        }
    }, [aedToEdit]);

    const [aedToLocate, setAEDToLocate] = useState(null);
    useEffect(() => {
        if (aedToLocate) {
            setState("set-location");
        }
    }, [aedToLocate]);

    return (
        <CustomModal
            padding={2}
            open={open}
            title="New AEDs"
            onClose={() => {
                if (state == "add" || state == "set-location") {
                    customConfirmAlert(() => {
                        handleOnClose(false);
                    });
                } else {
                    handleOnClose(false);
                }
            }}
        >
            {state == "list" && (
                <>
                    <NewAEDsList onSetLocation={(aed) => setAEDToLocate(aed)} />
                    <Grid marginTop={2} item xs={12}>
                        <Button fullWidth onClick={() => setState("add")} variant="contained">
                            Add Aed
                        </Button>
                    </Grid>
                </>
            )}

            {state == "add" && (
                <>
                    {!addMode && (
                        <Button
                            onClick={() => {
                                customConfirmAlert(() => {
                                    setState("list");
                                });
                            }}
                        >
                            Back to aeds list
                        </Button>
                    )}
                    <AddAED defaultLocation={location} onAdd={() => (addMode ? onClose() : handleOnAddAed())} />
                </>
            )}

            {state == "set-location" && (
                <>
                    <Button onClick={() => setState("list")}>Back to aeds list</Button>
                    {aedToLocate && (
                        <SetLocation
                            aed={aedToLocate}
                            onUpdate={() => {
                                onUpdate();
                                setAEDToLocate(null);
                                setState("list");
                            }}
                        />
                    )}
                </>
            )}
        </CustomModal>
    );
}
