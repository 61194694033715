import { useEffect, useState } from "react";
import { Button, Grid, Typography, TextField, Select, FormControl, MenuItem, InputLabel, Autocomplete } from "@mui/material";
import { AxiosError } from "axios";
import { notify } from "../../helpers/notify";
import apiRequest from "../../helpers/apiRequest";
import { errorFieldHandler, errorHandler, errorProps } from "../../helpers/errorHelper";
import CustomModal from "../../components/CustomModal/CustomModal";
import BasicDatePicker from "../../components/DatePicker";
import customConfirmAlert from "../../components/confirmAlert";
import { DateHelper } from "../../helpers/dateHelper";

export default function CreateOrganization({ open, onClose, onOrganizationCreate = () => null }: any) {
    function handleOnClose(bool: boolean) {
        customConfirmAlert(() => {
            onClose(bool);
            clearCamps();
        });
    }

    const [errors, setErrors] = useState<any>([]);

    const [organizationName, setOrganizationName] = useState<string | null>(null);
    const [organizationSubscription, setOrganizationSubscription] = useState<string | null>("NO_SUBSCRIPTION");
    const [organizationRecurrence, setOrganizationRecurrence] = useState<number>(1);
    const [organizationExpiration, setOrganizationExpiration] = useState<string | null>(null);

    const [address, setAddress] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);
    const [state, setState] = useState<string | null>(null);
    const [zipCode, setZipCode] = useState<string | null>(null);
    const [county, setCounty] = useState<string | null>(null);

    async function createOrganization() {
        if (organizationExpiration) {
            try {
                DateHelper.stringToGMT(organizationExpiration);
            } catch {
                setErrors((prevState: any) => [
                    ...prevState,
                    {
                        field: "expiration",
                        message: "Invalid date format",
                    },
                ]);
                return;
            }
        }

        await apiRequest
            .post("/organization", {
                name: organizationName,
                mainAddress: {
                    address: address,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    county: county,
                },
                subscription: organizationSubscription,
                expiration: organizationExpiration ? DateHelper.stringToGMT(organizationExpiration) : null,
                inspectionRecurrence: organizationRecurrence,
            })
            .then(async (res) => {
                clearCamps();
                onOrganizationCreate();
                notify("Organization created", "success");
            })
            .catch((err: AxiosError) => {
                setErrors(errorHandler(err));
            });
    }

    function clearCamps() {
        setOrganizationName("");
        setOrganizationSubscription("NO_SUBSCRIPTION");
        setOrganizationRecurrence(1);
        setOrganizationExpiration("");
        setAddress("");
        setCity("");
        setState("");
        setZipCode("");
        setCounty("");
        setErrors([]);
    }

    const [states, setStates] = useState<any>([]);
    async function getStates() {
        setStates(await apiRequest.get(`/information/states`).then((res) => res.data));
    }

    useEffect(() => {
        getStates();
    }, []);

    return (
        <CustomModal padding={2} open={open} title="Organization" onClose={() => handleOnClose(false)}>
            <Grid container spacing={2}>
                <Grid item marginTop={2} xs={12}>
                    <Typography variant="h6">Create Organization</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="caption">Info</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        {...errorProps(errors, "name")}
                        label="Name"
                        value={organizationName}
                        fullWidth
                        onChange={(evt) => setOrganizationName(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <BasicDatePicker
                        {...errorProps(errors, "expiration")}
                        PickerProps={{ label: "Expiration" }}
                        value={organizationExpiration ?? ""}
                        onChange={(date) => {
                            setOrganizationExpiration(date);
                        }}
                        InputProps={{ fullWidth: true, error: errorFieldHandler(errors, "expiration") }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label1x">Subscription</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={organizationSubscription}
                            label="Subscription"
                            {...errorProps(errors, "subscription")}
                            placeholder="Subscription"
                            onChange={(evt) => setOrganizationSubscription(evt.target.value)}
                        >
                            <MenuItem value="BASIC_Q">Basic-Q</MenuItem>
                            <MenuItem value="BASIC_I">Basic-I</MenuItem>
                            <MenuItem value="BASIC_IS">Basic-IS</MenuItem>

                            <MenuItem value="STANDARD_Q">Standard-Q</MenuItem>
                            <MenuItem value="STANDARD_I">Standard-I</MenuItem>
                            <MenuItem value="STANDARD_IS">Standard-IS</MenuItem>

                            <MenuItem value="BASIC_PLUS">Basic Plus</MenuItem>
                            <MenuItem value="STANDARD_PLUS">Standard Plus</MenuItem>
                            <MenuItem value="PREMIUM_PLUS">Premium Plus</MenuItem>
                            <MenuItem value="NO_SUBSCRIPTION">No Subscription</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Inspection Recurrence</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label2"
                            id="demo-simple-select2"
                            value={organizationRecurrence}
                            label="Inspection Recurrence"
                            placeholder="Inspection Recurrence"
                            {...errorProps(errors, "inspectionRecurrence")}
                            onChange={(evt) => setOrganizationRecurrence(evt.target.value as number)}
                        >
                            <MenuItem value={1}>1 Month</MenuItem>
                            <MenuItem value={3}>3 Months</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="caption">Address</Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                        {...errorProps(errors, "mainAddress.address")}
                        label="Address"
                        value={address}
                        fullWidth
                        onChange={(evt) => setAddress(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField {...errorProps(errors, "mainAddress.city")} label="City" value={city} fullWidth onChange={(evt) => setCity(evt.target.value)} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setState(newValue?.postalAbbreviation);
                        }}
                        options={states}
                        getOptionLabel={(opt: any) => opt?.name}
                        renderInput={(params) => <TextField {...errorProps(errors, "mainAddress.state")} {...params} label="State" />}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        {...errorProps(errors, "mainAddress.county")}
                        label="County"
                        value={county}
                        fullWidth
                        onChange={(evt) => setCounty(evt.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        {...errorProps(errors, "mainAddress.zipCode")}
                        label="ZipCode"
                        value={zipCode}
                        fullWidth
                        onChange={(evt) => {
                            if (evt.target.value.length < 6) {
                                setZipCode(evt.target.value);
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => createOrganization()} fullWidth color="success">
                        Create
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
