import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableFooter,
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    TextField,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Badge,
} from "@mui/material";
import { DateFormat } from "../../../helpers/DateFormat";
import { colors } from "../../../themes/main";
import { extendedAeds, extendedLocation, forecastAccessories, uniqueForecast } from "../../../entities/forecastSheet";
import { Add, ChatBubble, Delete, Edit, ExpandMore, OpenInNew } from "@mui/icons-material";
import CustomModal from "../../../components/CustomModal/CustomModal";
import BasicDatePicker from "../../../components/DatePicker";
import { DateHelper } from "../../../helpers/dateHelper";
import apiRequest from "../../../helpers/apiRequest";
import { notify } from "../../../helpers/notify";
import { errorFieldHandler, errorProps } from "../../../helpers/errorHelper";
import { FaCalendar } from "react-icons/fa";
import { RiDeleteBackFill } from "react-icons/ri";

type forecastAcc = {
    id: number;
    status: string;
    date: string;
    trackingNumber?: string;
};

type ForecastTableProps = {
    forecastList: uniqueForecast[];
};

export function ForecastTable({ forecastList: forecastRawList }: ForecastTableProps) {
    const [forecastList, setForecastList] = useState<uniqueForecast[]>([]);
    const [forecastAccessory, setForecastAccessory] = useState<forecastAcc>({ id: 0, status: "", date: "", trackingNumber: "" });
    const [modalType, setModalType] = useState<"none" | "edit" | "delete">("none");
    const navigate = useNavigate();

    const getShipToInfo = (forecastLocation: extendedLocation): string => {
        if (!forecastLocation.shipTo) return "";

        const recipient = [forecastLocation.shipTo?.name, forecastLocation.shipTo?.fone].filter((item) => item != null).join(" - ");
        const address = [forecastLocation.shipTo.address?.address, forecastLocation.shipTo.address?.city, forecastLocation.shipTo.address?.state]
            .filter((item) => item != null)
            .join(", ");

        return (recipient ? recipient + " | " : "") + address;
    };

    const handleStatusUpdate = (accessory: forecastAccessories) => {
        const forecastListCopy = forecastList;
        let found = false;

        forecastListCopy.forEach((forecast) => {
            if (found) return;

            forecast.locations.forEach((location) => {
                if (found) return;

                location.aeds.forEach((aed) => {
                    if (found) return;

                    aed.accessories.forEach((listAccessory, index) => {
                        if (listAccessory.id === accessory.id) {
                            aed.accessories[index] = { ...accessory };
                            found = true;
                            return;
                        }
                    });
                });
            });
        });

        setForecastList(forecastListCopy);
    };

    useEffect(() => {
        setForecastList(forecastRawList);
    }, [forecastRawList]);

    return (
        <>
            <UpdateStatusModal
                isOpen={modalType === "edit"}
                onClose={() => setModalType("none")}
                forecastAccessory={forecastAccessory}
                onUpdate={(accessory: forecastAccessories) => handleStatusUpdate(accessory)}
            />

            <DeleteStatusModal
                isOpen={modalType === "delete"}
                onClose={() => setModalType("none")}
                accessoryId={forecastAccessory.id}
                onUpdate={(accessory: forecastAccessories) => handleStatusUpdate(accessory)}
            />

            {forecastList.map((forecast) => (
                <Accordion key={forecast.organization.id} defaultExpanded disableGutters sx={{ marginBottom: 5 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{
                            backgroundColor: colors.main.primary,
                            border: "none",
                            padding: 1.5,
                            color: "white",
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography
                                    onClick={() => navigate(`/organization/${forecast.organization.id}`)}
                                    sx={{
                                        width: "fit-content",
                                        display: "flex",
                                        gap: 1,
                                        "&:hover": { cursor: "pointer", textDecoration: "underline" },
                                    }}
                                >
                                    {forecast.organization.name} <OpenInNew fontSize="small" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align="right" sx={{ marginRight: 3 }}>
                                    {forecast.organization.subscription}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OrganizationNotes organizationId={forecast.organization.id} />

                        {forecast.locations.map((forecastLocation, index) => (
                            <Box key={forecastLocation.id} sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                                {forecast.locations.length > 1 && index > 0 && <Divider sx={{ marginTop: 3, backgroundColor: colors.main.primary }} />}

                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="overline" fontWeight="bold">
                                            Location
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: "fit-content",
                                                display: "flex",
                                                gap: 1,
                                                "&:hover": {
                                                    textDecorationLine: "underline",
                                                },
                                            }}
                                            onClick={() => navigate(`/organization/${forecast.organization.id}/location/${forecastLocation.id}`)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {forecastLocation.name} <OpenInNew fontSize="small" />
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="overline" fontWeight="bold">
                                            Ship To
                                        </Typography>
                                        <Typography>{getShipToInfo(forecastLocation)}</Typography>
                                    </Grid>
                                </Grid>

                                <Box>
                                    <Typography variant="overline" fontWeight="bold">
                                        AED
                                    </Typography>
                                    {forecastLocation.aeds.map((aed) => (
                                        <Fragment key={aed.id}>
                                            {/* AED */}
                                            <Table>
                                                <TableHead>
                                                    <TableRow sx={{ backgroundColor: "#CCC", border: "none" }}>
                                                        <TableCell width={384} sx={{ borderTopLeftRadius: 10 }}>
                                                            AED Model
                                                        </TableCell>
                                                        <TableCell width={256}>Serial</TableCell>
                                                        <TableCell sx={{ borderTopRightRadius: 10 }}>Placement</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow sx={{ borderLeft: "1px solid #CCC", borderRight: "1px solid #CCC" }}>
                                                        <TableCell sx={{ borderRight: "1px solid #CCC" }}>
                                                            <Typography
                                                                sx={{
                                                                    width: "fit-content",
                                                                    display: "flex",
                                                                    gap: 1,
                                                                    "&:hover": {
                                                                        textDecorationLine: "underline",
                                                                    },
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/organization/${forecast.organization.id}/location/${forecastLocation.id}/aed/${aed.id}`
                                                                    )
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                {aed.aedModel} <OpenInNew fontSize="small" />
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderRight: "1px solid #CCC" }}>
                                                            <Typography>{aed.serial}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{aed.placement}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>

                                            {/* ACCESSORIES */}
                                            <Table
                                                sx={{
                                                    marginBottom: forecastLocation.aeds.length > 1 ? 6 : 0,
                                                }}
                                            >
                                                <TableHead>
                                                    <TableRow sx={{ backgroundColor: "#CCC", border: "none" }}>
                                                        <TableCell width={75}>Status</TableCell>
                                                        <TableCell width={128}>Type</TableCell>
                                                        <TableCell width={128}>Expires</TableCell>
                                                        <TableCell>Forecast status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <AccessoryForecastTableRow
                                                        forecastAed={aed}
                                                        setForecastAccessory={setForecastAccessory}
                                                        openModal={(type: "edit" | "delete") => setModalType(type)}
                                                    />
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={12}
                                                            sx={{
                                                                backgroundColor: "#CCC",
                                                                border: "none",
                                                                padding: 0.5,
                                                                borderBottomLeftRadius: 10,
                                                                borderBottomRightRadius: 10,
                                                            }}
                                                        ></TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </Fragment>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
}

type AccessoryForecastTableRowProps = {
    forecastAed: extendedAeds;
    setForecastAccessory: Function;
    openModal: Function;
};

const AccessoryForecastTableRow = ({ forecastAed, setForecastAccessory, openModal }: AccessoryForecastTableRowProps) => {
    return (
        <>
            {forecastAed.accessories.map((accessory) => (
                <TableRow key={accessory.accessory.id}>
                    <TableCell
                        sx={{
                            border: "none",
                            borderLeft: "1px solid #CCC",
                        }}
                    >
                        {accessory.accessory.dueNow ? (
                            <Chip label="Due now" sx={{ backgroundColor: "#FFA800", color: "white", fontSize: 12 }} />
                        ) : accessory.accessory.overDue ? (
                            <Chip label="Over due" sx={{ backgroundColor: "#FF0000", color: "white", fontSize: 12 }} />
                        ) : (
                            <Chip label="OK" sx={{ backgroundColor: "#00BF41", color: "white" }} />
                        )}
                    </TableCell>
                    <TableCell sx={{ border: "none", borderLeft: "1px solid #CCC" }}>{accessory.accessory.type.replaceAll("_", " ")}</TableCell>
                    <TableCell sx={{ border: "none", borderLeft: "1px solid #CCC" }}>{DateFormat(accessory.accessory.expiration)}</TableCell>
                    <TableCell sx={{ border: "none", borderLeft: "1px solid #CCC", borderRight: "1px solid #CCC" }}>
                        <Typography sx={{ display: "flex", alignItems: "center" }}>
                            {accessory.status ? (
                                <>
                                    {`${accessory.status?.replaceAll("_", " ")} ${DateFormat(accessory.date)} ${
                                        accessory.trackingNumber ? `(${accessory.trackingNumber})` : ""
                                    }`}
                                    <Tooltip title="Edit" arrow>
                                        <Edit
                                            fontSize="small"
                                            sx={{
                                                marginLeft: 2,
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                            onClick={() => {
                                                openModal("edit");
                                                setForecastAccessory({
                                                    id: accessory.id,
                                                    date: accessory.date,
                                                    status: accessory.status,
                                                    trackingNumber: accessory.trackingNumber,
                                                });
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Delete" arrow>
                                        <Delete
                                            fontSize="small"
                                            sx={{
                                                marginLeft: 2,
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                            }}
                                            onClick={() => {
                                                openModal("delete");
                                                setForecastAccessory({
                                                    id: accessory.id,
                                                    date: accessory.date,
                                                    status: accessory.status,
                                                });
                                            }}
                                        />
                                    </Tooltip>
                                </>
                            ) : (
                                <Tooltip title="Add" arrow>
                                    <Add
                                        fontSize="small"
                                        sx={{
                                            marginLeft: 2,
                                            "&:hover": {
                                                cursor: "pointer",
                                            },
                                        }}
                                        onClick={() => {
                                            openModal("edit");
                                            setForecastAccessory({
                                                id: accessory.id,
                                                date: accessory.date,
                                                status: accessory.status,
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Typography>
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

type UpdateStatusModalProps = {
    isOpen: boolean;
    onClose: Function;
    forecastAccessory: forecastAcc;
    onUpdate: Function;
};

const UpdateStatusModal = ({ isOpen, onClose, forecastAccessory, onUpdate }: UpdateStatusModalProps) => {
    const [date, setDate] = useState("");
    const [status, setStatus] = useState("");
    const [trackingNumber, setTrackingNumber] = useState("");
    const [errors, setErrors] = useState<any>([]);

    const handleOnClose = () => {
        setDate("");
        setStatus("");
        setTrackingNumber("");
        setErrors([]);
        onClose();
    };

    const updateStatus = () => {
        try {
            DateHelper.stringToGMT(date);
        } catch {
            setErrors((prevState: any) => [
                ...prevState,
                {
                    field: "date",
                    message: "",
                },
            ]);
            return;
        }

        apiRequest
            .put(`/forecast/accessory/${forecastAccessory.id}`, {
                status,
                date: DateHelper.stringToGMT(date),
                trackingNumber: status === "SHIPPED" ? trackingNumber : undefined,
            })
            .then((res) => {
                notify("Forecast Accessory updated", "success");
                onUpdate(res.data);
                handleOnClose();
            })
            .catch((err) => {
                setErrors([...err.response.data.errors]);
                notify(err.response.data.errors[0]?.message ?? "Unexpected error, please try again soon", "error");
            });
    };

    useEffect(() => {
        setDate(forecastAccessory.date);
        setStatus(forecastAccessory.status);

        if (forecastAccessory?.trackingNumber) {
            setTrackingNumber(forecastAccessory.trackingNumber);
        }
    }, [forecastAccessory]);

    return (
        <CustomModal open={isOpen} title="Edit status" width="40vw" onClose={handleOnClose}>
            <Box sx={{ padding: 3, display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
                <Box sx={{ display: "flex", gap: 3 }}>
                    <FormControl sx={{ minWidth: 192 }}>
                        <InputLabel id="status-label" variant="outlined">
                            Status
                        </InputLabel>
                        <Select
                            label="Status"
                            labelId="status-label"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            {...errorProps(errors, "status")}
                        >
                            <MenuItem value="SHIPPED">SHIPPED</MenuItem>
                            <MenuItem value="DO_NOT_SHIP">DO NOT SHIP</MenuItem>
                            <MenuItem value="INVOICE_SENT">INVOICE SENT</MenuItem>
                            <MenuItem value="QUOTE_SENT">QUOTE SENT</MenuItem>
                        </Select>
                    </FormControl>
                    <BasicDatePicker
                        {...errorProps(errors, "date")}
                        PickerProps={{ label: "Date" }}
                        value={date}
                        onChange={(date: any) => setDate(date)}
                        InputProps={{ size: "medium", error: errorFieldHandler(errors, "date") }}
                    />
                    {status === "SHIPPED" && <TextField value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} label="Tracking Number" />}
                </Box>
                <Box>
                    <Button variant="contained" size="large" color="success" sx={{ marginRight: 1 }} onClick={updateStatus}>
                        SAVE
                    </Button>
                    <Button variant="outlined" size="large" color="error" onClick={() => handleOnClose()}>
                        CANCEL
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
};

type DeleteStatusModalProps = {
    isOpen: boolean;
    onClose: Function;
    accessoryId: number;
    onUpdate: Function;
};

const DeleteStatusModal = ({ isOpen, onClose, accessoryId, onUpdate }: DeleteStatusModalProps) => {
    const deleteStatus = () => {
        apiRequest
            .put(`/forecast/accessory/${accessoryId}/remove`)
            .then((res) => {
                notify("Forecast Accessory deleted", "success");
                onUpdate(res.data);
                onClose();
            })
            .catch((err) => {
                notify(err.response.data.errors[0]?.message ?? "Unexpected error, please try again soon", "error");
            });
    };

    return (
        <CustomModal open={isOpen} title="Delete status" width="32vw" onClose={() => onClose()}>
            <Box sx={{ padding: 3, display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
                <Box sx={{ display: "flex", gap: 3 }}>
                    <Typography variant="body1">Are you sure you want to delete the forecast status?</Typography>
                </Box>
                <Box>
                    <Button variant="contained" size="large" color="success" sx={{ marginRight: 1 }} onClick={() => deleteStatus()}>
                        YES
                    </Button>
                    <Button variant="outlined" size="large" color="error" onClick={() => onClose()}>
                        CANCEL
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
};

type OrganizationNotesProps = {
    organizationId: number;
};

const OrganizationNotes = ({ organizationId }: OrganizationNotesProps) => {
    type note = {
        id: number;
        text: string;
        date: string;
        createdBy: {
            id: number;
            fullName: string;
            fone: string;
            email: string;
            profiles: string[];
            organizationId: number;
        } | null;
    };

    const [notes, setNotes] = useState<note[]>([]);
    const [adminNotes, setAdminNotes] = useState<note[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [addModalType, setAddModalType] = useState<"" | "notes" | "admin-notes">("");
    const inputNoteRef = useRef<any>(null);

    const fetchNotes = async () => {
        setIsLoading(true);

        await apiRequest
            .get(`/organization/${organizationId}/notes/`)
            .then((res) => setNotes(res.data))
            .catch((err) => console.error(err));

        await apiRequest
            .get(`/organization/${organizationId}/admin-notes/`)
            .then((res) => setAdminNotes(res.data))
            .catch((err) => console.error(err));

        setIsLoading(false);
    };

    const addNote = () => {
        const timezone = new Date().getTimezoneOffset();
        const date = new Date(Date.now() - timezone);

        apiRequest
            .post(`organization/${organizationId}/${addModalType}/`, {
                date: DateHelper.toLocalISOString(date).slice(0, 10),
                text: inputNoteRef.current?.value,
            })
            .then((res) => {
                if (addModalType === "notes") {
                    setNotes(() => [...res.data]);
                } else {
                    setAdminNotes(() => [...res.data]);
                }

                setAddModalType("");
                notify("Note created", "success");
            })
            .catch((err) => console.error(err));
    };

    const deleteNote = (noteId: number, isAdminNote: boolean) => {
        apiRequest
            .delete(`/organization/${organizationId}/${isAdminNote ? "admin-notes" : "notes"}/${noteId}`)
            .then(() => {
                let notesCopy = isAdminNote ? adminNotes : notes;
                notesCopy = notesCopy.filter((note) => note.id !== noteId);

                if (isAdminNote) {
                    setAdminNotes(notesCopy);
                } else {
                    setNotes(notesCopy);
                }

                notify("Note deleted", "success");
            })
            .catch(() => {
                notify("Error deleting note", "error");
            });
    };

    useEffect(() => {
        fetchNotes();
    }, [organizationId]);

    return (
        <>
            <CustomModal
                title={`Add ${addModalType === "admin-notes" ? "admin" : ""} notes`}
                width="50vw"
                open={addModalType !== ""}
                onClose={() => setAddModalType("")}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField inputRef={inputNoteRef} placeholder="Type a note here..." fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={addNote} variant="contained" color="success" fullWidth>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </CustomModal>

            <Accordion sx={{ marginBottom: 1 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="overline" fontWeight="bold">
                                <Badge badgeContent={notes.length} color="primary" sx={{ marginRight: 2 }}>
                                    <ChatBubble fontSize="small" color="action" />
                                </Badge>
                                Notes
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="overline" fontWeight="bold">
                                <Badge badgeContent={adminNotes.length} color="primary" sx={{ marginRight: 2 }}>
                                    <ChatBubble fontSize="small" color="action" />
                                </Badge>
                                Admin notes
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {isLoading ? (
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 4 }}>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end", marginRight: 1 }}>
                                        <Button variant="contained" size="small" onClick={() => setAddModalType("notes")}>
                                            Add note
                                        </Button>
                                    </Grid>
                                    {notes?.map((note, index: number) => (
                                        <Grid
                                            key={note.id}
                                            container
                                            sx={{ borderBottom: index !== notes.length - 1 ? "1px solid rgba(0,0,0,0.1)" : "none", marginTop: 2 }}
                                        >
                                            <Grid item xs={2}>
                                                <Box sx={{ display: "flex", gap: 1 }}>
                                                    <FaCalendar size="1rem" color="black" />
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {note.date}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={10} sx={{ display: "flex", gap: 1 }}>
                                                <Typography variant="body2">
                                                    {note.text}
                                                    {note.createdBy && (
                                                        <>
                                                            {" - "}
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        <Typography>Phone: {note.createdBy?.fone}</Typography>
                                                                        <Typography>Email: {note.createdBy?.email}</Typography>
                                                                    </>
                                                                }
                                                            >
                                                                <Typography variant="body2" component="span" sx={{ ":hover": { textDecoration: "underline" } }}>
                                                                    {note.createdBy?.fullName}
                                                                </Typography>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                    <RiDeleteBackFill
                                                        onClick={() => deleteNote(note.id, false)}
                                                        color="#e74a3b"
                                                        style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
                                                    />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ borderLeft: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end", marginRight: 1 }}>
                                        <Button variant="contained" size="small" onClick={() => setAddModalType("admin-notes")}>
                                            Add note
                                        </Button>
                                    </Grid>
                                    {adminNotes?.map((note, index: number) => (
                                        <Grid
                                            key={note.id}
                                            container
                                            sx={{ borderBottom: index !== adminNotes.length - 1 ? "1px solid rgba(0,0,0,0.1)" : "none", marginTop: 2 }}
                                        >
                                            <Grid item xs={2}>
                                                <Box sx={{ display: "flex", gap: 1 }}>
                                                    <FaCalendar size="1rem" color="black" />
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {note.date}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={10} sx={{ display: "flex", gap: 1 }}>
                                                <Typography variant="body2">
                                                    {note.text}
                                                    {note.createdBy && (
                                                        <>
                                                            {" - "}
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        <Typography>Phone: {note.createdBy?.fone}</Typography>
                                                                        <Typography>Email: {note.createdBy?.email}</Typography>
                                                                    </>
                                                                }
                                                            >
                                                                <Typography variant="body2" component="span" sx={{ ":hover": { textDecoration: "underline" } }}>
                                                                    {note.createdBy?.fullName}
                                                                </Typography>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                    <RiDeleteBackFill
                                                        onClick={() => deleteNote(note.id, true)}
                                                        color="#e74a3b"
                                                        style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
                                                    />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
