import { createTheme } from "@mui/material/styles";

export const colors = {
    main: {
        primary: "#3670ab",
        light: "#3a78b6",
        dark: "#2f6193",
    },
};

export const theme = createTheme({
    typography: {
        fontFamily: ["Nunito"].join(","),
    },
    components: {
        MUIDataTable: {
            styleOverrides: {
                paper: {
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.1)",
                    boxShadow: "rgb(145 158 171 / 15%) 0px 0px 2px 0px, rgb(145 158 171 / 15%) 0px 16px 32px -4px;",
                },
            },
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    paddingLeft: 4,
                    paddingTop: 5,
                    paddingBottom: 5,
                },
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    paddingLeft: 8,
                    paddingTop: 5,
                    paddingBottom: 5,
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.main.primary,
                },
            },
        },
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    style: {
                        fontSize: "0.65rem",
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    ":first-letter": {
                        textTransform: "capitalize",
                    },
                    marginLeft: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        pointerEvents: "auto",
                    },
                },
                contained: {
                    boxShadow: "none",
                    fontWeight: "bold",
                    "&:hover": {
                        boxShadow: "none",
                        backgroundColor: colors.main.light,
                    },
                },
                secondary: {
                    backgroundColor: colors.main.primary,
                    fontWeight: "bold",
                    "&:hover": {
                        backgroundColor: colors.main.primary,
                    },
                },
                containedSuccess: {
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#4DA167",
                    },
                },
                containedSecondary: {
                    borderRadius: 5,
                    fontSize: 12,
                },
                outlinedSecondary: {
                    borderRadius: 5,
                    fontSize: 12,
                },
            },
        },
    },
    palette: {
        primary: {
            main: colors.main.primary,
            light: colors.main.light,
        },
        secondary: {
            main: colors.main.primary,
        },
        success: {
            main: "#4DA167",
        },
    },
});
