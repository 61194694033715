import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import MuiTable from "../../components/MuiTable";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import DashboardLayout from "../../layouts/DashboardLayout";
import NewClassType from "./newClassType";
import EditClassType from "./editClassType";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function ClassTypes() {
    const [classTypes, setClassTypes] = useState([]);

    async function getClassTypes() {
        const classTypesInfo = await apiRequest.get(`/classtype/`).then((res) => res.data);
        setClassTypes(classTypesInfo);
    }

    useEffect(() => getClassTypes(), []);

    const [newClassTypeOpen, setNewClassTypeOpen] = useState(false);
    const [selectedClassType, setSelectedClassType] = useState(null);

    useEffect(() => {
        if (selectedClassType !== null) {
            setNewClassTypeOpen(true);
        }
    }, [selectedClassType]);

    async function deleteClassType(id) {
        await apiRequest.delete(`/classtype/${id}`).then((res) => {
            notify("Class type deleted", "success");
            getClassTypes();
        });
    }

    const [other, setOther] = useState(false);

    const [editClassTypeOpen, setEditClassTypeOpen] = useState(false);
    const [editClassDetail, setEditClassDetail] = useState([]);

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="Class Types" />
            {!other && (
                <>
                    <NewClassType
                        classType={selectedClassType}
                        onUserAdded={() => getClassTypes()}
                        open={newClassTypeOpen}
                        onClose={() => {
                            setSelectedClassType(null);
                            setNewClassTypeOpen(false);
                        }}
                    />

                    <EditClassType
                        classDetails={editClassDetail}
                        open={editClassTypeOpen}
                        onClose={() => {
                            setSelectedClassType(null);
                            setEditClassTypeOpen(false);
                        }}
                        onSave={(updatedClassType) => {
                            const updatedClassTypes = classTypes.map((classType) => (classType.id === updatedClassType.id ? updatedClassType : classType));
                            setClassTypes(updatedClassTypes);
                        }}
                    />
                </>
            )}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            alignContent: "center",
                            paddingBottom: 10,
                        }}
                    >
                        <Typography variant="h6">Class Types</Typography>
                        <Button
                            size="small"
                            sx={{ height: 40, width: 150, marginLeft: 2 }}
                            onClick={() => {
                                setNewClassTypeOpen(true);
                            }}
                            variant="contained"
                            color="success"
                        >
                            ADD NEW
                        </Button>
                    </Box>
                </Grid>

                <Grid item marginTop={-1} xs={12}>
                    <MuiTable
                        noSelect
                        noView
                        onDelete={(data) => deleteClassType(data.id)}
                        onEdit={(details) => {
                            setEditClassDetail(details);
                            setEditClassTypeOpen(true);
                        }}
                        data={classTypes}
                        columns={[
                            {
                                name: "type",
                                label: "Type",
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}
