import axios from "axios";
import { parseCookies, destroyCookie } from "nookies";

const apiRequest = axios.create({ baseURL: "https://rescuemanager.com/api/", headers: {} });
const { "rescue-manager": token } = parseCookies();

// Verify on each response if the token is valid by the server
apiRequest.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            destroyCookie({}, "rescue-manager");
            localStorage.removeItem("user");
            apiRequest.defaults.headers["Authorization"] = "Bearer ";
            window.location = "/login";
        }

        return Promise.reject({ ...error });
    }
);

if (token) {
    apiRequest.defaults.headers["Authorization"] = `Bearer ${token}`;
}

export default apiRequest;
