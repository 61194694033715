import { Button, CircularProgress, Grid, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { BsQuestionCircle } from "react-icons/bs";
import { useModal } from "../../../hooks/useModal";
import { useState } from "react";
import { errorFieldHandler, errorFieldMessage, errorHandler } from "../../../helpers/errorHelper";
import apiRequest from "../../../helpers/apiRequest";
import { notify } from "../../../helpers/notify";

type props = {
    title: string;
    fixedSubject?: String;
};

export default function HelpModal(props: props) {
    const { open, close, SceneRender } = useModal({ title: props.title, scene: <HelpModalBody onClose={() => close()} /> });

    return (
        <>
            <SceneRender />
            <ListItemButton onClick={() => open()}>
                <ListItemIcon style={{ color: "white" }}>
                    <BsQuestionCircle />
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
            </ListItemButton>
        </>
    );
}

type HelpModalBodyProps = {
    onClose: Function;
    subjectTxt?: string;
};

export function HelpModalBody({ onClose, subjectTxt }: HelpModalBodyProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState<any>([]);

    const handleSubmit = () => {
        setIsLoading(true);

        apiRequest
            .post(`/mail/help`, {
                subject: subject || subjectTxt,
                message: message,
            })
            .then((res) => {
                notify("message sent", "success");
                onClose();
            })
            .catch((err) => {
                setErrors(errorHandler(err));
                notify(err?.response.data?.errors[0].message || "message not sent", "error");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {subjectTxt ? (
                    <TextField
                        size="small"
                        helperText={errorFieldMessage(errors, "subject")}
                        error={errorFieldHandler(errors, "subject")}
                        fullWidth
                        value={subjectTxt}
                        disabled
                    />
                ) : (
                    <TextField
                        size="small"
                        helperText={errorFieldMessage(errors, "subject")}
                        error={errorFieldHandler(errors, "subject")}
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        placeholder="Subject"
                    />
                )}
            </Grid>

            <Grid item xs={12}>
                <TextField
                    helperText={errorFieldMessage(errors, "message")}
                    error={errorFieldHandler(errors, "message")}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                    placeholder="Message"
                    multiline
                    minRows={5}
                />
            </Grid>

            <Grid item xs={12}>
                <Button onClick={() => handleSubmit()} variant="contained" fullWidth>
                    {isLoading ? <CircularProgress size={18} sx={{ color: "#FFFF" }} /> : `SEND`}
                </Button>
            </Grid>
        </Grid>
    );
}
