import { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Search, Close } from "@mui/icons-material";

export default function SearchFilter({ onChangeFilter = (filter: string) => {} }) {
    const [filter, setFilter] = useState("");

    function handleFilter() {
        onChangeFilter(filter);
    }

    function handleClearFilter() {
        setFilter("");
        onChangeFilter("");
    }

    return (
        <TextField
            onKeyDown={(evt) => evt.key === "Enter" && handleFilter()}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        {filter === "" ? (
                            <Search color="primary" onClick={() => handleFilter()} style={{ cursor: "pointer" }} />
                        ) : (
                            <Close color="primary" onClick={() => handleClearFilter()} style={{ cursor: "pointer" }} />
                        )}
                    </InputAdornment>
                ),
            }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search"
            size="small"
            fullWidth
        />
    );
}
