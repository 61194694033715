import { useEffect, useState, useContext } from "react";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, TextField, Autocomplete, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { AuthContext } from "../../context/AuthContext";
import { errorFieldHandler, errorFieldMessage, errorHandler, errorProps } from "../../helpers/errorHelper";
import MuiTable from "../../components/MuiTable";
import CustomModal from "../../components/CustomModal/CustomModal";
import customConfirmAlert from "../../components/confirmAlert";
import SearchFilter from "../../components/SearchFilter";

export default function LocationsModal({ open, onClose, locations, onLocationUpdate = () => null, organizationId }: any) {
    const [contentLoaded, setContentLoaded] = useState(true);

    const { user } = useContext<any>(AuthContext);

    const navigate = useNavigate();

    function handleOnClose(bool: boolean) {
        onClose(bool);
        setCreateMode(false);
        setEditMode(false);
        // setUpdated(false);
        clearCamps();
    }

    const columns = [
        {
            label: "Address",
            name: "address_address",
            options: { display: false },
        },
        {
            label: "Address",
            name: "address_city",
            options: { display: false },
        },
        {
            label: "Address",
            name: "address_state",
            options: { display: false },
        },
        {
            label: "Address",
            name: "address_zipCode",
            options: { display: false },
        },
        {
            label: "id",
            name: "id",
            options: { display: false },
        },
        {
            label: "Name",
            name: "name",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            marginRight: "16px",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                                marginRight: 0,
                            },
                        }}
                        onClick={() => navigate(`/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][4]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "500px",
                    },
                }),
            },
        },
        {
            label: "Address",
            name: "address_address",
            options: {
                customBodyRender: (value: any, tableMeta: any, updatedValue: any) => (
                    <span>{`${tableMeta.tableData[tableMeta.rowIndex][0] || ""} - ${tableMeta.tableData[tableMeta.rowIndex][1] || ""}, ${
                        tableMeta.tableData[tableMeta.rowIndex][2] || ""
                    } ${tableMeta.tableData[tableMeta.rowIndex][3] || ""}`}</span>
                ),
            },
        },
        {
            label: "AEDs Quantity",
            name: "aedsQuantity",
        },
    ];

    const [createMode, setCreateMode] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState<any>(null);

    const [locationName, setLocationName] = useState("");
    const [locationAddress, setLocationAddress] = useState("");
    const [locationCity, setLocationCity] = useState("");
    const [locationState, setLocationState] = useState<any>("");
    const [locationCounty, setLocationCounty] = useState("");
    const [locationZipCode, setLocationZipCode] = useState("");

    const [shipToName, setShipToName] = useState("");
    const [shipToAddress, setShipToAddress] = useState("");
    const [shipToCity, setShipToCity] = useState("");
    const [shipToState, setShipToState] = useState<any>("");
    const [shipToCounty, setShipToCounty] = useState("");
    const [shipToPhone, setShipToPhone] = useState("");
    const [shipToZipCode, setShipToZipCode] = useState("");

    const [errors, setErrors] = useState<any>([]);

    async function createLocation() {
        await apiRequest
            .post("/location", {
                name: locationName,
                address: {
                    address: locationAddress,
                    city: locationCity,
                    state: locationState,
                    zipCode: locationZipCode,
                    county: locationCounty,
                },
                shipTo: {
                    name: shipToName,
                    fone: shipToPhone,
                    address: {
                        address: shipToAddress,
                        city: shipToCity,
                        state: shipToState,
                        zipCode: shipToZipCode,
                        county: shipToCounty,
                    },
                },
                organizationId: organizationId,
            })
            .then((res) => {
                setStagedChanges(false);
                setCreateMode(false);
                notify("Location created", "success");
                onLocationUpdate();
                // setSaved(true);

                navigate(`/organization/${organizationId}/location/${res.data.id}`);
            })
            .catch((err) => {
                if (err?.response?.data?.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    notify("Error adding user " + err?.response?.data?.error, "error");
                }
                notify("Error creating location", "error");
            });
    }

    async function disableLocation(locId: any) {
        await apiRequest
            .put(`/location/${locId}/disable`)
            .then((res) => {
                notify("Location disabled!", "success");
                setContentLoaded(false);
                onLocationUpdate();
                setContentLoaded(true);
            })
            .catch((err) => {});
    }

    async function enableLocation(locId: any) {
        await apiRequest
            .put(`/location/${locId}/enable`)
            .then((res) => {
                notify("Location enabled!", "success");
                setContentLoaded(false);
                onLocationUpdate();
                setContentLoaded(true);
            })
            .catch((err) => {});
    }

    async function updateLocation() {
        if (selectedLocation == null) return;
        let isSuccessful = true;
        setErrors([]);

        await apiRequest
            .put("/location/" + selectedLocation.id + "", {
                name: locationName,
            })
            .catch((err) => {
                isSuccessful = false;
                setErrors((prevState: any) => [...prevState, ...errorHandler(err)]);
                // console.log(err);
            });

        await apiRequest
            .put("/location/" + selectedLocation.id + "/address", {
                address: {
                    address: locationAddress,
                    city: locationCity,
                    state: locationState,
                    zipCode: locationZipCode,
                    county: locationCounty,
                },
            })
            .catch((err) => {
                isSuccessful = false;
                setErrors((prevState: any) => [...prevState, ...errorHandler(err)]);
                // return;
            });

        await apiRequest
            .put("/location/" + selectedLocation.id + "/shipto", {
                shipTo: {
                    name: shipToName,
                    fone: shipToPhone,
                    address: {
                        address: shipToAddress,
                        city: shipToCity,
                        state: shipToState,
                        zipCode: shipToZipCode,
                        county: shipToCounty,
                    },
                },
            })
            .catch((err) => {
                isSuccessful = false;
                setErrors((prevState: any) => [...prevState, ...errorHandler(err)]);
                // console.log(errors);
            });

        if (isSuccessful) {
            notify("Location Updated", "success");
            // setUpdated(true);
            onLocationUpdate();
            // setSaved(true);

            setStagedChanges(false);
            setEditMode(false);
            setCreateMode(false);
            clearCamps();
            setErrors([]);
        } else {
            notify("Error updating location", "error");
        }
    }

    function editLocationHandler(location: any) {
        setSelectedLocation(location);
        setLocationName(location?.name);
        setLocationAddress(location?.address?.address);
        setLocationCity(location?.address?.city);
        setLocationCounty(location?.address?.county);
        setLocationState(location?.address?.state);
        setLocationZipCode(location?.address?.zipCode);
        setShipToName(location?.shipTo?.name);
        setShipToPhone(location?.shipTo?.fone);
        setShipToAddress(location?.shipTo?.address?.address);
        setShipToCity(location?.shipTo?.address?.city);
        setShipToCounty(location?.shipTo?.address?.county);
        setShipToState(location?.shipTo?.address?.state);
        setShipToZipCode(location?.shipTo?.address?.zipCode);
        setEditMode(true);
    }

    function clearCamps() {
        setSelectedLocation("");
        setLocationName("");
        setLocationAddress("");
        setLocationCity("");
        setLocationCounty("");
        setLocationState("");
        setLocationZipCode("");
        setShipToName("");
        setShipToPhone("");
        setShipToAddress("");
        setShipToCity("");
        setShipToCounty("");
        setShipToState("");
        setShipToZipCode("");
        setErrors([]);

        setFilterFinal("");
    }

    const [states, setStates] = useState<any>([]);
    async function getStates() {
        setStates(await apiRequest.get(`/information/states`).then((res) => res.data));
    }

    useEffect(() => {
        getStates();
    }, []);

    const [shipToSameAsAbove, setShipToSameAsAbove] = useState(false);

    useEffect(() => {
        if (shipToSameAsAbove) {
            setShipToAddress(locationAddress);
            setShipToCity(locationCity);
            setShipToCounty(locationCounty);
            setShipToState(locationState);
            setShipToZipCode(locationZipCode);
        } else {
            setShipToAddress("");
            setShipToCity("");
            setShipToCounty("");
            setShipToState("");
            setShipToZipCode("");
        }
    }, [shipToSameAsAbove]);

    const [filterFinal, setFilterFinal] = useState("");

    const [showDisabled, setShowDisabled] = useState(false);

    // Detects if user made any changes - if so, a confirm modal will popup if changes aren't saved
    const [stagedChanges, setStagedChanges] = useState(false);

    const isFranchiseAdmin = user.userProfiles.systemAdmin || user.userProfiles.franchise;

    return (
        <CustomModal
            padding={2}
            open={open}
            title="Locations"
            onClose={() => {
                if ((editMode || createMode) && stagedChanges) {
                    customConfirmAlert(() => {
                        setStagedChanges(false);
                        handleOnClose(true);
                    });
                } else {
                    handleOnClose(false);
                }
            }}
        >
            {!createMode && !editMode && (
                <>
                    <SearchFilter onChangeFilter={(filter) => setFilterFinal(filter)} />
                    {contentLoaded && (
                        <>
                            {!user.userProfiles.siteCoordinator && !user.userProfiles.siteStaff && (
                                <Grid item xs={12} marginBottom={2} marginTop={4}>
                                    <Button
                                        variant={!showDisabled ? "contained" : "outlined"}
                                        onClick={() => setShowDisabled(false)}
                                        color="secondary"
                                        sx={{ marginRight: 1 }}
                                    >
                                        MANAGE ACTIVE
                                    </Button>
                                    <Button variant={showDisabled ? "contained" : "outlined"} onClick={() => setShowDisabled(true)} color="secondary">
                                        MANAGE INACTIVE
                                    </Button>
                                </Grid>
                            )}
                            {showDisabled ? (
                                <>
                                    <Typography fontWeight="medium" sx={{ mt: 2 }}>
                                        Disabled locations
                                    </Typography>
                                    <MuiTable
                                        undo
                                        onUndo={(row: any) => enableLocation(row.id)}
                                        search={filterFinal}
                                        noView
                                        pagination
                                        noDelete
                                        paginationURL={`/location/disabled?organizationId=${organizationId}`}
                                        noSelect
                                        onView={(loc: any) => navigate("/organization/" + organizationId + "/location/" + loc.id)}
                                        onEdit={(location: any) => editLocationHandler(location)}
                                        columns={columns}
                                        modalName="loc"
                                    />
                                </>
                            ) : (
                                <MuiTable
                                    search={filterFinal}
                                    noView
                                    pagination
                                    onDelete={(loc: any) => {
                                        customConfirmAlert(
                                            () => {
                                                disableLocation(loc?.id);
                                            },
                                            () => {},
                                            "CONFIRM"
                                        );
                                    }}
                                    paginationURL={`/location?organizationId=${organizationId}`}
                                    noSelect
                                    onView={(loc: any) => navigate("/organization/" + organizationId + "/location/" + loc.id)}
                                    onEdit={(location: any) => editLocationHandler(location)}
                                    columns={columns}
                                    modalName="loc"
                                />
                            )}
                        </>
                    )}

                    {(user.userProfiles.programCoordinator || user.userProfiles.programStaff || isFranchiseAdmin) && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                clearCamps();
                                setCreateMode(true);
                            }}
                            fullWidth
                            color="success"
                            style={{ marginTop: 20 }}
                        >
                            Create
                        </Button>
                    )}
                </>
            )}

            {(createMode || editMode) && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (stagedChanges) {
                                    customConfirmAlert(() => {
                                        setStagedChanges(false);
                                        setEditMode(false);
                                        setCreateMode(false);
                                        clearCamps();
                                    });
                                } else {
                                    setEditMode(false);
                                    setCreateMode(false);
                                    clearCamps();
                                }
                            }}
                            color="primary"
                        >
                            <BsArrowLeftSquareFill style={{ marginRight: 10 }} /> Locations list
                        </Button>
                    </Grid>

                    <Grid item marginTop={2} xs={12}>
                        <Typography variant="h6">{createMode ? "Create location" : "Edit location"}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="caption">Info</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            helperText={errorFieldMessage(errors, "name")}
                            error={errorFieldHandler(errors, "name")}
                            size="small"
                            label="Location Name"
                            value={locationName}
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setLocationName(evt.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="caption">Address</Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextField
                            helperText={errorFieldMessage(errors, "address.address")}
                            error={errorFieldHandler(errors, "address.address")}
                            size="small"
                            label="Address"
                            value={locationAddress}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setLocationAddress(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            helperText={errorFieldMessage(errors, "address.city")}
                            error={errorFieldHandler(errors, "address.city")}
                            size="small"
                            label="City"
                            value={locationCity}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setLocationCity(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            key="2"
                            id="2"
                            size="small"
                            value={states.filter((stt: any) => stt?.postalAbbreviation == locationState)[0]}
                            onChange={(event, newValue) => {
                                setStagedChanges(true);
                                setLocationState(newValue?.postalAbbreviation);
                            }}
                            options={states}
                            getOptionLabel={(opt: any) => opt?.name}
                            renderInput={(params) => (
                                <TextField
                                    helperText={errorFieldMessage(errors, "address.state")}
                                    error={errorFieldHandler(errors, "address.state")}
                                    {...params}
                                    label="State"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            helperText={errorFieldMessage(errors, "address.county")}
                            error={errorFieldHandler(errors, "address.county")}
                            size="small"
                            label="County"
                            value={locationCounty}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setLocationCounty(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            {...errorProps(errors, "address.zipCode")}
                            size="small"
                            label="ZipCode"
                            value={locationZipCode}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);

                                if (evt.target.value.length < 6) {
                                    setLocationZipCode(evt.target.value);
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} spacing={5}>
                        <Typography variant="caption">Ship To</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            helperText={errorFieldMessage(errors, "shipTo.name")}
                            error={errorFieldHandler(errors, "shipTo.name")}
                            size="small"
                            label="Name"
                            value={shipToName}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setShipToName(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            helperText={errorFieldMessage(errors, "shipTo.fone")}
                            error={errorFieldHandler(errors, "shipTo.fone")}
                            size="small"
                            label="Phone"
                            value={shipToPhone}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setShipToPhone(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            setStagedChanges(true);
                                            setShipToSameAsAbove(e.target.checked);
                                        }}
                                    />
                                }
                                label="Same as above"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextField
                            helperText={errorFieldMessage(errors, "shipTo.address.address")}
                            error={errorFieldHandler(errors, "shipTo.address.address")}
                            size="small"
                            label="Address"
                            value={shipToAddress}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setShipToAddress(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            helperText={errorFieldMessage(errors, "shipTo.address.city")}
                            error={errorFieldHandler(errors, "shipTo.address.city")}
                            size="small"
                            label="City"
                            value={shipToCity}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setShipToCity(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            size="small"
                            key={shipToState}
                            options={states}
                            value={states.filter((state: any) => state?.postalAbbreviation == shipToState)[0]}
                            onChange={(event, newValue) => {
                                setStagedChanges(true);
                                setShipToState(newValue?.postalAbbreviation);
                            }}
                            getOptionLabel={(opt: any) => opt?.name}
                            renderInput={(params) => (
                                <TextField
                                    helperText={errorFieldMessage(errors, "shipTo.address.state")}
                                    error={errorFieldHandler(errors, "shipTo.address.state")}
                                    {...params}
                                    label="State"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            helperText={errorFieldMessage(errors, "shipTo.address.county")}
                            error={errorFieldHandler(errors, "shipTo.address.county")}
                            size="small"
                            label="County"
                            value={shipToCounty}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);
                                setShipToCounty(evt.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            helperText={errorFieldMessage(errors, "shipTo.address.zipCode")}
                            error={errorFieldHandler(errors, "shipTo.address.zipCode")}
                            size="small"
                            label="ZipCode"
                            value={shipToZipCode}
                            fullWidth
                            onChange={(evt) => {
                                setStagedChanges(true);

                                if (evt.target.value.length < 6) {
                                    setShipToZipCode(evt.target.value);
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => (createMode ? createLocation() : updateLocation())} fullWidth color="success">
                            {createMode ? "Create" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </CustomModal>
    );
}
