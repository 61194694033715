import { useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SupportIcon from "@mui/icons-material/Support";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Button, Collapse } from "@mui/material";
import { FaDownload } from "react-icons/fa";
import apiRequest from "../helpers/apiRequest";
import HelpModal from "./components/help-modal";
import { parseCookies } from "nookies";
import AdminAedsModal from "./components/admin-aeds-modal";
import aedsLogo from "../assets/aed_logo.png";
import classTypesIcon from "../assets/classtypes_icon.png";
import organizationsIcon from "../assets/organizations-icon.png";
import usersIcon from "../assets/users_icon.png";

export const SmDrawer = ({ params }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { organizationId } = useParams();
    const [reportsOpen, setReportsOpen] = useState(false);

    const [annualReportsDownload, setAnnualReportsDownload] = useState(null);
    const [aedReportsDownload, setAedReportsDownload] = useState(null);
    const [annualReportsFileName, setAnnualReportsFileName] = useState("annual-report.xlsx");
    const [aedReportsFileName, setAedReportsFileName] = useState("aed-report.xlsx");

    const clearLastModal = () => {
        sessionStorage.removeItem("last-modal");
    };

    function checkUserAuth(reportType) {
        const cookies = parseCookies();

        if (cookies["rescue-manager"] == null) {
            window.location.reload();
            return;
        }

        if (reportType === "annual") {
            let blob = new Blob([annualReportsDownload], { type: "octet-stream" });
            downloadFile(blob, annualReportsFileName);
        } else if (reportType === "aed") {
            let blob = new Blob([aedReportsDownload], { type: "octet-stream" });
            downloadFile(blob, aedReportsFileName);
        } else {
            console.error("Invalid report type");
        }
    }

    function downloadFile(file, fileName) {
        const link = document.createElement("a");

        link.style.display = "none";
        link.href = window.URL.createObjectURL(file);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
        }, 100);
    }

    function getAnnualReport() {
        if (annualReportsDownload === null) {
            apiRequest.get(`/reports/annual-report/${organizationId}/`, { responseType: "blob" }).then((res) => {
                if (res.headers?.filename) {
                    setAnnualReportsFileName(res.headers?.filename);
                }

                setAnnualReportsDownload(res.data);
            });
        }
    }

    function getAedReport() {
        if (aedReportsDownload === null) {
            apiRequest.get(`/reports/aed-report/${organizationId}/`, { responseType: "blob" }).then((res) => {
                if (res.headers?.filename) {
                    setAedReportsFileName(res.headers?.filename);
                }

                setAedReportsDownload(res.data);
            });
        }
    }

    function getReports() {
        setReportsOpen(!reportsOpen);
        getAnnualReport();
        getAedReport();
    }

    return (
        <List
            key="list2"
            sx={{ width: "100%", maxWidth: 360, color: "white", textTransform: "uppercase", fontWeight: "bold", marginTop: 8 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {(user?.userProfiles?.systemAdmin || user?.userProfiles?.franchise) && (
                <>
                    <Typography
                        fontWeight="bold"
                        color="rgba(255,255,255,0.5)"
                        sx={{ padding: 2, fontSize: "0.65rem", alignSelf: "center", textAlign: "left" }}
                    >
                        ADMIN
                    </Typography>
                    <ListItemButton
                        onClick={() => {
                            clearLastModal();
                            navigate("/organizations/");
                        }}
                    >
                        <ListItemIcon style={{ color: "white" }}>
                            <img
                                src={organizationsIcon}
                                style={{
                                    width: "1.32rem",
                                    marginLeft: "3px",
                                    filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(89deg) brightness(104%) contrast(102%)",
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Organizations" />
                    </ListItemButton>
                </>
            )}

            {!user?.userProfiles?.systemAdmin && !user?.userProfiles?.franchise && (
                <>
                    <Typography fontWeight="bold" color="rgba(255,255,255,0.5)" sx={{ padding: 2, fontSize: "0.65rem", alignSelf: "left", textAlign: "left" }}>
                        YOUR ORGANIZATION
                    </Typography>

                    <ListItemButton onClick={() => navigate("/organization/" + user?.organization?.id)}>
                        <ListItemIcon style={{ color: "white" }}>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </>
            )}

            <ListItemButton onClick={() => navigate("/users/")}>
                <ListItemIcon style={{ color: "white" }}>
                    <img
                        src={usersIcon}
                        style={{
                            width: "1.32rem",
                            marginLeft: "3px",
                            filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(89deg) brightness(104%) contrast(102%)",
                        }}
                    />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>

            {user?.userProfiles?.systemAdmin && (
                <>
                    <AdminAedsModal title="AEDs" />

                    <ListItemButton onClick={() => navigate("/aed-models/")}>
                        <ListItemIcon>
                            <img
                                src={aedsLogo}
                                style={{
                                    width: "1.32rem",
                                    marginLeft: "3px",
                                    filter: "brightness(1000%)",
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Aed Models" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate("/class-types/")}>
                        <ListItemIcon style={{ color: "white" }}>
                            <img
                                src={classTypesIcon}
                                style={{
                                    width: "1.32rem",
                                    marginLeft: "3px",
                                    filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(89deg) brightness(104%) contrast(102%)",
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Class Types" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate("/forecast-sheet/")}>
                        <ListItemIcon style={{ color: "white" }}>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Forecast Sheet" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate("/forecast-subscription/")}>
                        <ListItemIcon style={{ color: "white" }}>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Forecast Subscription" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate("/support-logs/")}>
                        <ListItemIcon style={{ color: "white" }}>
                            <SupportIcon />
                        </ListItemIcon>
                        <ListItemText primary="Support Logs" />
                    </ListItemButton>
                </>
            )}

            <ListItemButton onClick={() => navigate("/documents/")}>
                <ListItemIcon style={{ color: "white" }}>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Documents" />
            </ListItemButton>

            <Divider style={{ backgroundColor: "rgba(240, 240, 240, 0.5)" }} variant="middle" />

            <Typography fontWeight="bold" color="rgba(255,255,255,0.5)" sx={{ padding: 2, fontSize: "0.65rem", alignSelf: "center", textAlign: "left" }}>
                AED PROGRAM
            </Typography>

            {organizationId !== undefined && !user?.userProfiles?.sitesSupervisor && !user?.userProfiles?.siteCoordinator && !user?.userProfiles?.siteStaff && (
                <ListItemButton onClick={getReports}>
                    <ListItemIcon style={{ color: "white" }}>
                        <EqualizerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItemButton>
            )}

            <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
                <List sx={{ marginBottom: 1, display: "flex", alignItems: "center", flexDirection: "column" }} component="div" disablePadding>
                    <Button
                        onClick={() => checkUserAuth("annual")}
                        variant="contained"
                        sx={{ pl: 2, display: "flex", justifyContent: "space-between", borderRadius: 1, height: 35, width: 170 }}
                    >
                        <Typography variant="caption" color="#FFF" fontWeight="bold">
                            Annual Report
                        </Typography>
                        <FaDownload color="#FFF" />
                    </Button>
                    <Button
                        onClick={() => checkUserAuth("aed")}
                        variant="contained"
                        sx={{ pl: 2, mt: 1, display: "flex", justifyContent: "space-between", borderRadius: 1, height: 35, width: 170 }}
                    >
                        <Typography variant="caption" color="#FFF" fontWeight="bold">
                            AED Report
                        </Typography>
                        <FaDownload color="#FFF" />
                    </Button>
                </List>
            </Collapse>

            {organizationId !== undefined && (
                <ListItemButton
                    onClick={() => navigate(`/organization/${organizationId !== undefined ? organizationId : user?.organization?.id}/after-the-rescue`)}
                >
                    <ListItemIcon style={{ color: "white" }}>
                        <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="After the Rescue" />
                </ListItemButton>
            )}
            <HelpModal title="Contact Us" />
        </List>
    );
};
