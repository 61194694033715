import { createContext, useEffect, useState } from "react";
import apiRequest from "../helpers/apiRequest";
import { parseCookies, setCookie } from "nookies";
import { useNavigate } from "react-router-dom";
import { notify } from "../helpers/notify";

const cookies = parseCookies();

export const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
    const navigate = useNavigate();

    const checkToken = () => {
        if (cookies["rescue-manager"] !== null) {
            return cookies["rescue-manager"];
        } else {
            return null;
        }
    };

    const [authToken, setAuthToken] = useState(undefined);

    useEffect(() => {
        setAuthToken(checkToken());
    }, []);

    async function signIn(email, password) {
        await apiRequest
            .post("/auth", {
                email: email,
                password: password,
            })
            .then(async (res) => {
                await setCookie(undefined, "rescue-manager", res.data.token, {
                    maxAge: 60 * 60 * 6, //O cookie fica armazenado por 6 horas (Tempo de expiração)
                    path: "/",
                });
                setAuthToken(res.data.token);
                apiRequest.defaults.headers["Authorization"] = `Bearer ${res.data.token}`;
            })
            .catch((err) => {
                notify(err.response.data.message, "error");
            });
    }

    const [user, setUser] = useState(null);
    const [profiles, setProfiles] = useState(null);

    async function logOut() {
        setCookie(undefined, "rescue-manager", undefined, {
            maxAge: 60 * 60 * 6, //O cookie fica armazenado por 6 horas (Tempo de expiração)
            path: "/",
        });
        localStorage.removeItem("user");
        apiRequest.defaults.headers["Authorization"] = `Bearer `;
        setAuthToken(undefined);
        setUser(undefined);
        setProfiles(null);
        window.location = "/login";
    }

    async function getUser() {
        const user = localStorage.getItem("user");
        if (user) {
            setUser(JSON.parse(user));
        }

        await apiRequest
            .get("/user/this")
            .then(async (res) => {
                if (res.status == 401) {
                    logOut();
                }
                setUser(res.data);
                setProfiles(res.data.userProfiles);
                localStorage.setItem("user", JSON.stringify(res.data));
            })
            .catch((err) => {
                if (window.location.pathname != "/login") {
                    logOut();
                }
            });
    }

    function authRedirect() {
        if (user == null) {
            return null;
        }

        if (user?.userProfiles?.systemAdmin || user?.userProfiles?.franchise) {
            navigate("/organizations");
        } else {
            navigate("/organization/" + user?.organization?.id);
        }
    }

    useEffect(() => {
        if (authToken !== null && authToken !== undefined && authToken !== "undefined") {
            getUser();
        }
    }, [authToken]);

    return <AuthContext.Provider value={{ authRedirect, signIn, checkToken, authToken, logOut, user, profiles }}>{children}</AuthContext.Provider>;
}
