import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { Button, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { errorFieldHandler, errorFieldMessage, errorHandler } from "../../helpers/errorHelper";
import { notify } from "../../helpers/notify";
import CustomModal from "../../components/CustomModal/CustomModal";

export default function SendEmail({ selectedOrganizations = [], open = false, onClose = () => {} }) {
    const [modalOpen, setModalOpen] = useState(true);

    const [selectedRoles, setSelectedRoles] = useState(null);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [errors, setErrors] = useState([]);

    const [attatchmentFileServerName, setAttatchmentFileServerName] = useState("");
    const [attatchmentFileServerType, setAttatchmentFileServerType] = useState("");

    async function sendEmail() {
        await apiRequest
            .post(`/mail/organization`, {
                subject: subject,
                message: message,
                organizationIds: [...selectedOrganizations.map((org) => org.id)],
                profilesMail: selectedRoles,
                ...(attatchmentFileServerName && { fileName: attatchmentFileServerName, fileType: attatchmentFileServerType }),
            })
            .then((res) => {
                onClose();
                notify("Message sent!", "success");
                setAttatchmentFile(null);
            })
            .catch((err) => {
                setErrors(errorHandler(err));
                setAttatchmentFile(null);
            });
    }

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    const [all, setAll] = useState(false);
    const [franchise, setFranchise] = useState(false);
    const [programCoordinator, setProgramCoordinator] = useState(false);
    const [programStaff, setProgramStaff] = useState(false);
    const [sitesSupervisor, setSitesSupervisor] = useState(false);
    const [siteCoordinator, setSiteCoordinator] = useState(false);
    const [siteStaff, setSiteStaff] = useState(false);

    useEffect(() => {
        if (all == true) {
            setFranchise(true);
            setProgramCoordinator(true);
            setProgramStaff(true);
            setSitesSupervisor(true);
            setSiteCoordinator(true);
            setSiteStaff(true);
        } else {
            setFranchise(false);
            setProgramCoordinator(false);
            setProgramStaff(false);
            setSitesSupervisor(false);
            setSiteCoordinator(false);
            setSiteStaff(false);
        }
    }, [all]);

    useEffect(() => {
        if (franchise && programCoordinator && programStaff && sitesSupervisor && siteCoordinator && siteStaff) {
            setAll(true);
        }

        rolesArrayGetter();
    }, [franchise, programCoordinator, programStaff, sitesSupervisor, siteCoordinator, siteStaff]);

    function rolesArrayGetter() {
        const newArr = [];

        if (franchise) {
            newArr.push("FRANCHISE");
        }
        if (programCoordinator) {
            newArr.push("PROGRAM_COORDINATOR");
        }
        if (programStaff) {
            newArr.push("PROGRAM_STAFF");
        }
        if (sitesSupervisor) {
            newArr.push("SITES_SUPERVISOR");
        }
        if (siteCoordinator) {
            newArr.push("SITE_COORDINATOR");
        }
        if (siteStaff) {
            newArr.push("SITE_STAFF");
        }

        setSelectedRoles(newArr);
    }

    const [attatchmentFile, setAttatchmentFile] = useState(null);
    const [attatchmentFileLoading, setAttatchmentFileLoading] = useState(false);

    useEffect(() => {
        if (!attatchmentFile) return null;

        const form = new FormData();

        if (attatchmentFile.size > 10000000) {
            setAttatchmentFile(null);
            return notify("The file must have a size smaller than 10mb");
        }

        form.append("file", attatchmentFile);

        setAttatchmentFileLoading(true);
        apiRequest
            .post(`/mail/uploadfile`, form)
            .then((res) => {
                setAttatchmentFileServerName(res.data.fileName);
                setAttatchmentFileServerType(res.data.fileType);
                setAttatchmentFileLoading(false);
            })
            .catch((err) => {
                notify(err.message);
                setAttatchmentFileLoading(false);
            });
    }, [attatchmentFile]);

    function removeFile() {
        setAttatchmentFile(null);
        setAttatchmentFileLoading(false);
        setAttatchmentFileServerName("");
        setAttatchmentFileServerType("");
    }

    function clearCamps() {
        setAll(false);
        setSelectedRoles(null);
        setSubject("");
        setMessage("");
        setErrors([]);
        setAttatchmentFileServerName("");
        setAttatchmentFileServerType("");
        setFranchise(false);
        setProgramCoordinator(false);
        setProgramStaff(false);
        setSitesSupervisor(false);
        setSiteCoordinator(false);
        setSiteStaff(false);
    }

    return (
        <CustomModal
            title="Send email"
            open={modalOpen}
            onClose={() => {
                clearCamps();
                onClose(false);
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Sending email to the following organizations</Typography>
                </Grid>
                <Grid item xs={12} display="flex" flexWrap="wrap" gap={1}>
                    {selectedOrganizations.map((org, idx) => {
                        return <Chip label={org?.name} />;
                    })}
                </Grid>

                <Grid item xs={12} marginTop={2}>
                    <Typography>Send to</Typography>
                </Grid>
                <Grid item xs={12} marginTop={-1}>
                    <FormGroup sx={{ display: "flex", flexDirection: "row" }} fullWidth>
                        <FormControlLabel control={<Checkbox onChange={() => setAll(!all)} checked={all} />} label="ALL" />
                        <FormControlLabel control={<Checkbox onChange={() => setFranchise(!franchise)} checked={franchise} />} label="FRANCHISE" />
                        <FormControlLabel
                            control={<Checkbox onChange={() => setProgramCoordinator(!programCoordinator)} checked={programCoordinator} />}
                            label="PROGRAM COORDINATOR"
                        />
                        <FormControlLabel control={<Checkbox onChange={() => setProgramStaff(!programStaff)} checked={programStaff} />} label="PROGRAM STAFF" />
                        <FormControlLabel
                            control={<Checkbox onChange={() => setSitesSupervisor(!sitesSupervisor)} checked={sitesSupervisor} />}
                            label="SITES SUPERVISOR"
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={() => setSiteCoordinator(!siteCoordinator)} checked={siteCoordinator} />}
                            label="SITE COORDINATOR"
                        />
                        <FormControlLabel control={<Checkbox onChange={() => setSiteStaff(!siteStaff)} checked={siteStaff} />} label="SITE STAFF" />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        size="small"
                        helperText={errorFieldMessage(errors, "subject")}
                        error={errorFieldHandler(errors, "subject")}
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        placeholder="Subject"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        helperText={errorFieldMessage(errors, "message")}
                        error={errorFieldHandler(errors, "message")}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        placeholder="Message"
                        multiline
                        minRows={5}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Button variant="contained" component="label">
                        Upload File
                        <input
                            type="file"
                            hidden
                            {...(attatchmentFile !== null ? {} : { value: "" })}
                            onChange={(e) => setAttatchmentFile(e.target.files[0])}
                        />
                    </Button>

                    <Typography>{attatchmentFile?.name}</Typography>

                    {attatchmentFileLoading && <CircularProgress />}

                    {attatchmentFile && <BsTrash style={{ cursor: "pointer" }} onClick={() => removeFile()} />}
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <Button disabled={attatchmentFileLoading} onClick={() => sendEmail()} variant="contained" fullWidth>
                        Send email
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
