import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AuthContext } from "../context/AuthContext";

export default function Breadcrumbs({ linksOption = {}, currentPage = "" }) {
    const { user } = useContext(AuthContext);
    const params = useParams();
    const isProgramProfile = user.userProfiles.programStaff || user.userProfiles.programCoordinator;
    const isSiteProfile = user.userProfiles.siteCoordinator || user.userProfiles.siteStaff;

    // Only set to 'true' in 'linksOption' prop what you want to be shown
    let optionsTemplate = {
        home: false,
        forecastSheet: false,
        forecastSub: false,
        classTypes: false,
        aedModels: false,
        coordinatorView: false,
        users: false,
        supportLogs: false,
        organization: {
            org: false,
            newAed: false,
            location: false,
            aed: false,
            responder: false,
            afterTheRescue: false,
        },
    };

    optionsTemplate = { ...optionsTemplate, ...linksOption };

    return (
        <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            {optionsTemplate.home && !isProgramProfile && !isSiteProfile && !user.userProfiles.sitesSupervisor && (
                <Link to={"/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    {user.userProfiles.franchise ? "Organizations" : "Home"}
                </Link>
            )}

            {optionsTemplate.forecastSheet && (
                <Link to={"/forecast-sheet/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Forecast Sheet
                </Link>
            )}

            {optionsTemplate.forecastSub && (
                <Link to={"/forecast-subscription/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Forecast Subscription
                </Link>
            )}

            {optionsTemplate.classTypes && (
                <Link to={"/class-types/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Class Types
                </Link>
            )}

            {optionsTemplate.aedModels && (
                <Link to={"/aed-models/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    AED Models
                </Link>
            )}

            {optionsTemplate.coordinatorView && (
                <Link to={"/coordinator-view/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Coordinator View
                </Link>
            )}

            {optionsTemplate.users && (
                <Link to={"/users/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Users
                </Link>
            )}

            {optionsTemplate.supportLogs && (
                <Link to={"/support-logs/"} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Support Logs
                </Link>
            )}

            {optionsTemplate.organization.org && (
                <Link to={`/organization/${params.organizationId}`} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Organization
                </Link>
            )}

            {optionsTemplate.organization.newAed && (
                <Link to={`/organization/${params.organizationId}/new-aeds`} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    New AED
                </Link>
            )}

            {optionsTemplate.organization.location && (
                <Link to={`/organization/${params.organizationId}/location/${params.id}`} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    Location
                </Link>
            )}

            {optionsTemplate.organization.aed && (
                <Link
                    to={`/organization/${params.organizationId}/location/${params.id}/aed/${params.aedId}`}
                    style={{ padding: "6px", textDecoration: "none", color: "gray" }}
                >
                    AED
                </Link>
            )}

            {optionsTemplate.organization.responder && (
                <Link
                    to={`/organization/${params.organizationId}/location/${params.id}/responder/${params.responderId}`}
                    style={{ padding: "6px", textDecoration: "none", color: "gray" }}
                >
                    Responder
                </Link>
            )}

            {optionsTemplate.organization.afterTheRescue && (
                <Link to={`/organization/${params.organizationId}/after-the-rescue`} style={{ padding: "6px", textDecoration: "none", color: "gray" }}>
                    After the rescue
                </Link>
            )}

            <Typography color="black" style={{ padding: "8px" }}>
                {currentPage === "Home" && user.userProfiles.franchise ? "Organizations" : currentPage}
            </Typography>
        </MUIBreadcrumbs>
    );
}
