import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FaPlus, FaRegStickyNote } from "react-icons/fa";

export default function InfoCard({ add, onAdd = () => {}, children, title = "Notes", icon = <FaRegStickyNote size="1rem" /> }: any) {
    return (
        <Grid
            container
            marginTop={1}
            marginBottom={3}
            sx={{
                boxShadow: "rgb(145 158 171 / 25%) 0px 0px 2px 0px, rgb(145 158 171 / 20%) 0px 16px 32px -4px;",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: 1,
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    padding: 2,
                    backgroundColor: "#f8f9fc !important",
                    height: 50,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography fontSize="1rem" fontWeight="bold" style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    {" "}
                    {icon} {title}
                </Typography>

                {add && (
                    <Box
                        onClick={() => onAdd()}
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 0.6,
                            backgroundColor: "#0a4176",
                            borderRadius: 50,
                            width: 20,
                            height: 20,
                        }}
                    >
                        <FaPlus style={{ padding: 0, margin: 0, color: "white" }} />
                    </Box>
                )}
            </Grid>

            <Grid item xs={12} sx={{ backgroundColor: "#FFFF", borderRadius: 1 }}>
                {children}
            </Grid>
        </Grid>
    );
}
