import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MuiTable from "../../components/MuiTable";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import DashboardLayout from "../../layouts/DashboardLayout";
import NewAedModel from "./newAedModel.tsx";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function ClassTypes() {
    const [classTypes, setClassTypes] = useState([]);
    const [modelToEdit, setModelToEdit] = useState(null);

    async function getClassTypes() {
        const classTypesInfo = await apiRequest.get(`/aedmodels/`).then((res) => res.data);
        setClassTypes(classTypesInfo);
    }

    useEffect(() => getClassTypes(), []);

    const [modelModalOpen, setModelModalOpen] = useState(false);

    useEffect(() => {
        if (modelToEdit !== null) {
            setModelModalOpen(true);
        }
    }, [modelToEdit]);

    async function deleteAEDModel(id) {
        await apiRequest.delete(`/aedmodels/${id}`).then((res) => {
            notify("Model deleted", "success");
            getClassTypes();
        });
    }

    async function editAEDModel(model) {
        setModelToEdit(model);
    }

    return (
        <DashboardLayout>
            <Breadcrumbs linksOption={{ home: true }} currentPage="AED Models" />

            <NewAedModel
                editMode={modelToEdit !== null}
                aedModel={modelToEdit}
                onUserAdded={() => getClassTypes()}
                open={modelModalOpen}
                onClose={() => {
                    setModelToEdit(null);
                    setModelModalOpen(false);
                }}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            alignContent: "center",
                            paddingBottom: 10,
                        }}
                    >
                        <Typography variant="h6">AED Models</Typography>
                        <Button
                            size="small"
                            sx={{ height: 40, width: 150, marginLeft: 2 }}
                            onClick={() => {
                                setModelModalOpen(true);
                            }}
                            variant="contained"
                            color="success"
                        >
                            ADD NEW
                        </Button>
                    </Box>
                </Grid>

                <Grid item marginTop={-1} xs={12}>
                    <MuiTable
                        noSelect
                        noView
                        onDelete={(data) => deleteAEDModel(data.id)}
                        onEdit={(data) => editAEDModel(data)}
                        data={classTypes}
                        columns={[
                            {
                                name: "urlPhoto",
                                label: " ",
                                options: {
                                    customBodyRender: (value, tableMeta) => (
                                        <img
                                            src={value || "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                                            width="60"
                                            height="60"
                                        />
                                    ),
                                },
                            },
                            {
                                name: "manufacturer",
                                label: "Manufacturer",
                            },
                            {
                                name: "model",
                                label: "Model",
                            },
                            {
                                name: "comboBatteryAndPads",
                                label: "Combo battery and pads",
                                options: {
                                    customBodyRender: (value, tableMeta) => <Typography sx={{ textAlign: "center" }}>{value ? "Yes" : "No"}</Typography>,
                                },
                            },
                            {
                                name: "pediatricPadsOption",
                                label: "Pediatric Pads Option",
                                options: {
                                    customBodyRender: (value, tableMeta) => <Typography sx={{ textAlign: "center" }}>{value ? "Yes" : "No"}</Typography>,
                                },
                            },
                            {
                                name: "fixedBatteryExpirationDate",
                                label: "Fixed Battery Expiration Date",
                                options: {
                                    customBodyRender: (value, tableMeta) => <Typography sx={{ textAlign: "center" }}>{value ? "Yes" : "No"}</Typography>,
                                },
                            },
                            {
                                name: "batteryYearsExpiration",
                                label: "Battery Years Expiration",
                                options: {
                                    customBodyRender: (value, tableMeta) => <Typography sx={{ textAlign: "center" }}>{value}</Typography>,
                                },
                            },
                            {
                                name: "warrantyYears",
                                label: "Warranty Years",
                                options: {
                                    customBodyRender: (value, tableMeta) => <Typography sx={{ textAlign: "center" }}>{value}</Typography>,
                                },
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}
