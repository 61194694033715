import { useContext } from "react";
import { Document, Thumbnail, pdfjs } from "react-pdf";
import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Typography } from "@mui/material";
import { IDocumentFile } from "../entities/documentCenter";
import pdfFallback from "../assets/pdf-document.svg";
import docFallback from "../assets/word-document.svg";
import { AuthContext } from "../context/AuthContext";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

type PDFDocumentProps = {
    document: IDocumentFile;
    enableModification?: boolean;
    onEdit: Function;
    onDelete: Function;
};

export default function PDFDocument({ document, enableModification = false, onEdit, onDelete }: PDFDocumentProps) {
    const { user } = useContext<any>(AuthContext);
    const DOC_URL_REGEX = /^(https?:\/\/)?(www\.)?[^\/]*?\/?([^$]*?)?(\.docx?)$/;

    const a = "abc";
    a.endsWith(".pdf");

    return (
        <Card
            sx={{
                width: "200px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: "0 0 12px #ccc",
                backgroundColor: "#fff",
            }}
        >
            {document.dynamicURL && (document.dynamicURL?.endsWith(".pdf") || document.dynamicURL?.endsWith(".docx")) ? (
                <Document
                    file={document.dynamicURL}
                    loading={
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                aspectRatio: "1/1",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress size={32} color="primary" />
                        </Box>
                    }
                    error={
                        <Box sx={{ width: "fit-content", margin: "auto" }}>
                            <img
                                src={document.dynamicURL?.match(DOC_URL_REGEX) ? docFallback : pdfFallback}
                                style={{ width: "150px", height: "100%", aspectRatio: "1/1.25", cursor: "pointer" }}
                            />
                        </Box>
                    }
                    onClick={() => window.open(document.url, "_blank")}
                >
                    <Box sx={{ width: "fit-content", margin: "auto" }}>
                        <Thumbnail pageNumber={1} width={150} />
                    </Box>
                </Document>
            ) : (
                <Box sx={{ width: "fit-content", margin: "auto" }}>
                    <img src={pdfFallback} style={{ width: "150px", height: "100%", aspectRatio: "1/1.25", cursor: "pointer" }} />
                </Box>
            )}

            <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="overline" component="span" sx={{ fontWeight: 700, lineHeight: 0 }}>
                    {document.title}
                </Typography>
            </CardContent>
            {enableModification && user?.userProfiles?.systemAdmin && (
                <CardActions sx={{ justifyContent: "flex-start" }}>
                    <Button size="small" onClick={() => onEdit()}>
                        <Edit fontSize="small" sx={{ marginRight: "8px" }} />
                        Edit
                    </Button>
                    <Button size="small" onClick={() => onDelete()}>
                        <Delete fontSize="small" sx={{ marginRight: "8px" }} />
                        Delete
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}
