import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { TableRow, TableCell, Tooltip, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import MUIDataTable, { SelectableRows, FilterType, Responsive } from "mui-datatables";
import apiRequest from "../../../helpers/apiRequest";
import { notify } from "../../../helpers/notify";
import { SupportLog, SupportLogContent } from "../../../entities/supportLog";
import { Box, CircularProgress } from "@mui/material";

type Props = {
    title: string;
    baseUrl: string;
};

export default function LogsTable({ title, baseUrl }: Props) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState<Array<SupportLogContent>>([]);
    const [logsPagination, setLogsPagination] = useState({
        totalElements: 0,
        totalPages: 0,
    });
    const [tableOptions, setTableOptions] = useState({
        page: 0,
        size: 10,
        sortColumn: "",
        sortOrder: "asc",
    });

    const toggleSolvedStatus = (logId: number) => {
        return logs.map((log: SupportLogContent) => {
            if (log.id === logId) {
                return {
                    ...log,
                    wasSolved: !log.wasSolved,
                };
            } else {
                return log;
            }
        });
    };

    const handleSolvedOnChange = (logData: any) => {
        apiRequest
            .put(`/support-log/change-status/${logData[5]}`)
            .then((res) => {
                const updatedLogs = toggleSolvedStatus(logData[5]);
                setLogs(updatedLogs);
            })
            .catch((err: AxiosError) => {
                notify(err.response?.data.errors[0]?.message ?? "Unexpected error, please try again soon", "error");
            });
    };

    useEffect(() => {
        apiRequest
            .get<SupportLog>(
                `${baseUrl}?page=${tableOptions.page}&size=${tableOptions.size}&sort=${tableOptions.sortColumn}${
                    tableOptions.sortColumn !== "" ? "," + tableOptions.sortOrder : ""
                }`
            )
            .then((res) => {
                setLogs(res.data.content);
                setLogsPagination({
                    totalElements: res.data.totalElements,
                    totalPages: res.data.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err: AxiosError) => console.error(err.message));
    }, [tableOptions]);

    const columns = [
        {
            name: "subject",
            label: "Subject",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "organizationName",
            label: "Organization name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <Typography
                            variant="body2"
                            sx={{
                                color: "black",
                                marginRight: "16px",
                                "&:hover": {
                                    fontWeight: 600,
                                    textDecorationLine: "underline",
                                    marginRight: 0,
                                },
                            }}
                            onClick={() => navigate(`/organization/${tableMeta.rowData[7]}`)}
                            style={{ cursor: "pointer" }}
                        >
                            {value}
                        </Typography>
                    );
                },
            },
        },
        {
            name: "userEmail",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sendAt",
            label: "Send at",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value: string) => {
                    return formatData(value);
                },
            },
        },
        {
            name: "wasSolved",
            label: "Was solved",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: boolean, tableMeta: any) => (
                    <Tooltip title={value.toString().charAt(0).toUpperCase() + value.toString().slice(1)}>
                        <Switch checked={value} onChange={() => handleSolvedOnChange(tableMeta.rowData)} />
                    </Tooltip>
                ),
            },
        },
        {
            name: "id",
            options: {
                display: false,
                filter: false,
            },
        },
        {
            name: "message",
            options: {
                display: false,
                filter: false,
            },
        },
        {
            name: "organizationId",
            options: {
                display: false,
                filter: false,
            },
        },
    ];

    const options = {
        download: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRowsHeader: false,
        selectableRows: "none" as SelectableRows,
        filterType: "dropdown" as FilterType,
        serverSide: true,
        count: logsPagination.totalElements,
        responsive: "scroll" as Responsive,
        onChangePage: (page: number) => {
            setTableOptions((prevState) => {
                return {
                    ...prevState,
                    page,
                };
            });
        },
        rowsPerPageOptions: [10, 25, 50, 100, { label: "ALL", value: logsPagination.totalElements }] as number[],
        rowsPerPage: tableOptions.size,
        onChangeRowsPerPage: (size: number) => {
            setTableOptions((prevState) => {
                return {
                    ...prevState,
                    size,
                };
            });
        },
        onColumnSortChange: (changedColumn: string, direction: string) => {
            changedColumn = changedColumn?.replaceAll("_", ".")?.replaceAll("ownerInfo.locationName", "location.name").replaceAll("userStatus", "isActive");
            setTableOptions((prevState) => {
                return {
                    ...prevState,
                    sortColumn: changedColumn,
                    sortOrder: direction,
                };
            });
        },
        expandableRows: true,
        renderExpandableRow: (rowData: any) => {
            return (
                <TableRow>
                    <TableCell colSpan={rowData.length}>
                        <Typography variant="body1">Message</Typography>
                        <Typography variant="body2">{rowData[6]}</Typography>
                    </TableCell>
                </TableRow>
            );
        },
    };

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size={30} color="primary" />
                </Box>
            ) : (
                <MUIDataTable title={title} data={logs} columns={columns} options={options} />
            )}
        </>
    );
}

function formatData(data: string) {
    const dateObj = new Date(data);
    const day = dateObj.toLocaleDateString("en-US"); // MM/DD/YYYY Ex.: 2/6/2023
    const hour = dateObj.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }); // Ex.: 3:50 PM

    return `${day} - ${hour}`;
}
