import MUIDataTable from "mui-datatables";
import { useEffect, useState, cloneElement } from "react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { createTheme, ThemeProvider, CircularProgress, Checkbox, Typography } from "@mui/material";
import apiRequest from "../helpers/apiRequest";
import { Box } from "@mui/system";
import useAuth from "../hooks/useAuth";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { parseCookies, setCookie } from "nookies";

const themeObj: any = {
    components: {
        MUIDataTable: {
            styleOverrides: {
                root: {
                    width: "100%",
                    borderRadius: 5,
                    padding: 1,
                },
                paper: {
                    borderTop: "1px solid rgba(0,0,0,0.1)",
                    borderBottom: "none",
                    paddingBottom: 15,
                },
                responsiveScroll: {
                    width: "100%",
                    minHeight: "92%",
                    height: "100%",
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderLeft: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: 5,
                },
            },
        },
    },
};
const theme = createTheme({ ...themeObj });

export default function MuiTable({
    defaultRowsPerPage = 10,
    onUndo = (row: any) => {},
    undo = false,
    customComponents = {},
    onRowSelectionChange = (currentRowsSelected: any) => {},
    noSelect = false,
    aditionalOptions = {},
    noActions = false,
    noFooter = false,
    onView = (row: any) => {},
    onDelete = (row: any) => {},
    onSecondAditionalAction = (row: any) => {},
    onAditionalAction = (row: any) => {},
    onEdit = (details: any) => {},
    noView = false,
    noEdit = false,
    noDelete = false,
    aditionalAction = <></>,
    secondAditionalAction = <></>,
    columns = [],
    search = "",
    data = [],
    paginationURL = "",
    pagination = false,
    forceReload = false,
    onRowSelectionChangeWithData = (data: any) => {},
    modalName = null,
}: any) {
    const [unserializedRows, setUnserializedRows] = useState<any>([...data]);
    const [serializedRows, setSerializedRows] = useState<any>([...data.map((row: any) => flattenObj(row))]);
    useEffect(() => setSerializedRows([...data.map((row: any) => flattenObj(row))]), []);

    const { authRedirect } = useAuth();

    useEffect(() => {
        if (!pagination) {
            setUnserializedRows([...data]);
            setSerializedRows([...data.map((row: any) => flattenObj(row))]);
        }
    }, [data]);

    const [loading, setLoading] = useState(pagination);

    const cookies = parseCookies();

    const getModalPref = () => {
        if (!modalName) return defaultRowsPerPage;

        if (cookies[`modal-${modalName}-pref`]) {
            const prefPageSize = parseInt(cookies[`modal-${modalName}-pref`]);
            return isNaN(prefPageSize) ? defaultRowsPerPage : prefPageSize;
        } else {
            setModalPref(defaultRowsPerPage);
            return defaultRowsPerPage;
        }
    };

    const setModalPref = (size: string) => {
        if (!modalName) return;

        setCookie(undefined, `modal-${modalName}-pref`, size, {
            maxAge: 60 * 60 * 24 * 30, // O cookie fica armazenado por 30 Dias
            path: "/",
        });
    };

    const getLastPage = () => {
        if (!modalName) return 0;

        // "modalName;lastPage"
        const lastPage = sessionStorage.getItem("last-modal");

        if (!lastPage) return 0;

        const pageNum = parseInt(lastPage.split(";")[1]);
        return isNaN(pageNum) ? 0 : pageNum;
    };

    const setLastPage = (page: number = 0) => {
        if (!modalName) return;

        const lastModal = sessionStorage.getItem("last-modal");

        if (!lastModal) return;

        const lastModalArr = lastModal.split(";");
        lastModalArr[1] = page.toString();

        sessionStorage.setItem("last-modal", lastModalArr.join(";"));
    };

    const [page, setPage] = useState(getLastPage());
    const [pageSize, setPageSize] = useState(getModalPref());
    const [searchFilter, setSearchFilter] = useState(search);
    const [rowCount, setRowCount] = useState(0);
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");

    const [rowsSelected, setRowsSelected] = useState<any>([]);

    async function getPaginatedData(page = 0) {
        setLoading(true);

        if (!pagination) return setLoading(false);

        try {
            const newData = await apiRequest
                .get(
                    `${paginationURL}${paginationURL.indexOf("?") !== -1 ? "&" : "?"}page=${page}&size=${pageSize}${
                        searchFilter !== "" ? `&search=${searchFilter}` : ""
                    }&sort=${sortColumn}${sortColumn !== "" ? "," + sortOrder : ""}`
                )
                .then((res) => res.data)
                .catch((err) => {
                    if (err.response.status == 403 || err.response.status == 401) {
                        return authRedirect();
                    }
                    console.log("Error on MuiTable:: ", JSON.stringify(err.response.data));
                });

            setRowCount(newData?.totalElements);
            setUnserializedRows([...newData?.content]);
            setSerializedRows([...newData?.content]);
            setLoading(false);
        } catch (e) {}
    }

    useEffect(() => {
        if (typeof document !== "undefined") {
            document.getElementsByTagName("body")[0].style.overflow = "auto";
            setModalPref(pageSize);
        }
    }, [pageSize]);

    const options = {
        selectableRowsHeader: !noSelect,
        selectableRowsHideCheckboxes: noSelect,
        filterType: "checkbox",
        elevation: 0,
        stickyHeader: true,
        serverSide: pagination,
        responsive: "scroll",
        page: page,
        rowsSelected: rowsSelected,
        rowsPerPage: pageSize, // Cookie
        rowsPerPageOptions: [10, 25, 50, 100, { label: "ALL", value: rowCount || serializedRows?.length || 1000 }],
        onRowSelectionChange: (currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => {
            setRowsSelected(rowsSelected);
            onRowSelectionChange(rowsSelected);
            onRowSelectionChangeWithData([
                ...unserializedRows.filter((row: any, index: any) => {
                    if (rowsSelected.indexOf(index) !== -1) {
                        return row;
                    }
                }),
            ]);
        },
        onColumnSortChange: (changedColumn: any, direction: any) => {
            setSortColumn(changedColumn?.replaceAll("_", ".")?.replaceAll("ownerInfo.locationName", "location.name").replaceAll("userStatus", "isActive"));
            setSortOrder(direction);
            setPage(0);
        },
        onChangePage: (currentPage: any) => {
            if (pagination) {
                setPage(currentPage);
                getPaginatedData(currentPage);
            }

            setLastPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows: any) => {
            setPageSize(numberOfRows);
            setPage(0);
        },
        count: rowCount,
        ...aditionalOptions,
    };

    function aditionalActionRender(info: any) {
        const newComponent = cloneElement(aditionalAction, {
            onClick: () => onAditionalAction(info),
        });
        const newSecondComponent = cloneElement(secondAditionalAction, {
            onClick: () => onSecondAditionalAction(info),
        });
        return (
            <>
                {newComponent}
                {newSecondComponent}
            </>
        );
    }

    useEffect(() => {
        setLastPage(page);
    }, [page]);

    useEffect(() => {
        if (search !== "") setPage(0);

        setSearchFilter(search);
    }, [search]);

    useEffect(() => {
        getPaginatedData(getLastPage());
    }, [pageSize, searchFilter, sortColumn, sortOrder, paginationURL]);

    useEffect(() => {
        if (forceReload) getPaginatedData(page);
    }, [forceReload]);

    return (
        <ThemeProvider theme={theme}>
            {typeof window !== "undefined" && (
                <>
                    {loading ? (
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: 2, minHeight: 250 }}>
                            <CircularProgress size={40} />
                        </Box>
                    ) : (
                        <MUIDataTable
                            title=""
                            data={serializedRows.map((res: any) => flattenObj(res))}
                            columns={
                                noActions
                                    ? columns
                                    : [
                                          ...columns.concat({
                                              label: "Actions",
                                              name: "id",
                                              options: {
                                                  customBodyRender: (value: any, tableMeta: any, updatedValue: any) => (
                                                      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                                          {!noView && (
                                                              <FaEye
                                                                  onClick={() => onView(unserializedRows[tableMeta.rowIndex])}
                                                                  style={{ cursor: "pointer" }}
                                                              />
                                                          )}
                                                          {!noEdit && (
                                                              <FaEdit
                                                                  onClick={() => onEdit(unserializedRows[tableMeta.rowIndex])}
                                                                  style={{ cursor: "pointer" }}
                                                              />
                                                          )}
                                                          {aditionalActionRender(unserializedRows[tableMeta.rowIndex])}
                                                          {!noDelete && (
                                                              <FaTrash
                                                                  onClick={() => onDelete(unserializedRows[tableMeta.rowIndex])}
                                                                  style={{ cursor: "pointer" }}
                                                              />
                                                          )}
                                                          {undo && (
                                                              <RestartAltIcon
                                                                  onClick={() => onUndo(unserializedRows[tableMeta.rowIndex])}
                                                                  style={{ cursor: "pointer" }}
                                                              />
                                                          )}
                                                      </Box>
                                                  ),
                                                  filter: false,
                                                  sort: false,
                                              },
                                          }),
                                      ]
                            }
                            options={options}
                            components={{
                                Checkbox: (props: any) => {
                                    let newProps = Object.assign({}, props);

                                    if (props["data-description"] == "row-select-header") {
                                        return (
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                <Typography variant="caption" sx={{ width: 45, fontSize: 10, padding: 0, margin: 0 }}>
                                                    Select All
                                                </Typography>
                                                <Checkbox {...newProps} />
                                            </Box>
                                        );
                                    } else {
                                        return <Checkbox {...newProps} />;
                                    }
                                },
                                ...(noFooter && { TableFooter: (props: any) => <Box sx={{ width: "100%", height: 0 }}></Box> }),
                                TableToolbarSelect: (props: any) => <></>,
                                TableToolbar: (props: any) => <div></div>,
                                ...customComponents,
                            }}
                        />
                    )}
                </>
            )}
        </ThemeProvider>
    );
}

const flattenObj = (ob: any) => {
    let result: any = {};
    for (const i in ob) {
        if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
            if (Object.getOwnPropertyNames(ob).indexOf("profiles") !== -1) {
                result["profiles"] = ob["profiles"];
                const temp = flattenObj(ob[i]);
                for (const j in temp) {
                    result[i + "_" + j] = temp[j];
                }
            } else if (Object.getOwnPropertyNames(ob).indexOf("locationAddress") !== -1) {
                result["locationAddress"] = JSON.stringify(ob["locationAddress"]);
                const temp = flattenObj(ob[i]);
                for (const j in temp) {
                    result[i + "_" + j] = temp[j];
                }
            } else {
                const temp = flattenObj(ob[i]);
                for (const j in temp) {
                    result[i + "_" + j] = temp[j];
                }
            }
        } else {
            result[i] = ob[i];
        }
    }
    return result;
};
