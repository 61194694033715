import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import { Button, Grid, Typography, TextField, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import apiRequest from "../../helpers/apiRequest";
import { notify } from "../../helpers/notify";
import { AuthContext } from "../../context/AuthContext";
import { errorHandler, errorProps } from "../../helpers/errorHelper";
import MuiTable from "../../components/MuiTable";
import CustomModal from "../../components/CustomModal/CustomModal";
import customConfirmAlert from "../../components/confirmAlert";

export default function AedsModal({ open, onClose, responders, onAEDUpdate = () => null, organizationId }: any) {
    const [contentLoaded, setContentLoaded] = useState(true);
    const [locations, setLocations] = useState([]);

    const { user } = useContext<any>(AuthContext);

    const getLocations = async () => {
        const locs = await apiRequest.get(`/location/all/${organizationId}`).then((res) => res.data);
        setLocations(locs);
    };

    useEffect(() => {
        getLocations();
    }, []);

    function handleOnClose(bool: boolean) {
        onClose(bool);
        setCreateMode(false);
        setEditMode(false);
        // setUpdated(false);
    }

    const columns = [
        {
            label: "id",
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "locId",
            name: "locationId",
            options: {
                display: false,
            },
        },
        {
            label: "active",
            name: "isActive",
            options: {
                display: false,
            },
        },
        {
            label: "Name",
            name: "name",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() =>
                            navigate(
                                `/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}/responder/${
                                    tableMeta.tableData[tableMeta.rowIndex][0]
                                }`
                            )
                        }
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "500px",
                    },
                }),
            },
        },
        {
            label: "Location",
            name: "locationName",
            options: {
                customBodyRender: (value: any, tableMeta: any) => (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "black",
                            "&:hover": {
                                fontWeight: 600,
                                textDecorationLine: "underline",
                            },
                        }}
                        onClick={() => navigate(`/organization/${organizationId}/location/${tableMeta.tableData[tableMeta.rowIndex][1]}`)}
                        style={{ cursor: "pointer" }}
                    >
                        {value}
                    </Typography>
                ),
                setCellProps: () => ({
                    style: {
                        width: "500px",
                    },
                }),
            },
        },
    ];

    const [createMode, setCreateMode] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [selectedResponder, setSelectedResponder] = useState<any>(null);

    const [responderName, setResponderName] = useState("");
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [selectedLocationId, setSelectedLocationId] = useState<any>(null);

    async function updateResponder() {
        if (responderName == null || responderName == "") {
            return notify("The responder name is required.", "error");
        }

        if (selectedLocation == null) {
            return notify("You need to select a location", "error");
        }

        await apiRequest
            .put(`/responder/${selectedResponder.id}`, {
                name: responderName,
                locationId: selectedLocation?.id || selectedResponder.locationId,
            })
            .then((res) => {
                setEditMode(false);
                notify("Responder updated!", "success");
            })
            .catch((err) => console.log(err));

        // setUpdated(true);
        onAEDUpdate();
    }

    async function disableResponder(responder: any) {
        await apiRequest
            .put(`/responder/${responder.id}/disable`)
            .then((res) => {
                // setUpdated(true);
                setContentLoaded(false);
                setContentLoaded(true);
                notify("Responder disabled", "success");
            })
            .catch((err) => {
                notify(err.response.data.error, "error");
            });
    }

    async function enableResponder(responder: any) {
        await apiRequest
            .put(`/responder/${responder.id}/enable`)
            .then((res) => {
                // setUpdated(true);
                setContentLoaded(false);
                setContentLoaded(true);
                notify("Responder enabled", "success");
            })
            .catch((err) => {
                notify(err.response.data.error, "error");
            });
    }

    const [errors, setErrors] = useState<any>([]);

    async function addResponder() {
        await apiRequest
            .post("/responder", {
                locationId: selectedLocation?.id,
                name: responderName,
            })
            .then(async (res) => {
                // setUpdated(true);
                onAEDUpdate();
                notify("Responder created", "success");
                setCreateMode(false);
            })
            .catch((err) => {
                setErrors(errorHandler(err));
            });
    }

    function clearCamps() {
        setSelectedResponder("");
        setResponderName("");
        setSelectedLocationId("");
        setSelectedLocation(null);
        setErrors([]);
    }

    function editResponderHandler(responder: any) {
        setSelectedResponder(responder);
        setResponderName(responder?.name);
        setSelectedLocationId(responder?.locationId);

        setSelectedLocation(
            locations.map((loc: any) => {
                if (loc.id == responder?.locationId) {
                    return loc;
                }
            })[0]
        );

        setCreateMode(false);
        setEditMode(true);
    }

    useEffect(() => {
        if (!createMode && !editMode) {
            clearCamps();
        }
    }, [createMode, editMode]);

    const navigate = useNavigate();

    const [showDisabled, setShowDisabled] = useState(false);

    return (
        <CustomModal
            padding={2}
            open={open}
            title="Responders"
            onClose={() => {
                createMode || editMode
                    ? customConfirmAlert(() => {
                          handleOnClose(false);
                      })
                    : handleOnClose(false);
            }}
        >
            {!createMode && !editMode && (
                <>
                    {contentLoaded && (
                        <>
                            {!user.userProfiles.siteCoordinator && !user.userProfiles.siteStaff && (
                                <Grid item xs={12} marginBottom={2} marginTop={4}>
                                    <Button
                                        variant={!showDisabled ? "contained" : "outlined"}
                                        onClick={() => setShowDisabled(false)}
                                        color="secondary"
                                        sx={{ marginRight: 1 }}
                                    >
                                        MANAGE ACTIVE
                                    </Button>
                                    <Button variant={showDisabled ? "contained" : "outlined"} onClick={() => setShowDisabled(true)} color="secondary">
                                        MANAGE INACTIVE
                                    </Button>
                                </Grid>
                            )}
                            {showDisabled ? (
                                <>
                                    <Typography fontWeight="medium" sx={{ mt: 2 }}>
                                        Disabled responders
                                    </Typography>
                                    <MuiTable
                                        undo
                                        onUndo={(row: any) => {
                                            enableResponder(row);
                                        }}
                                        noDelete
                                        noView
                                        pagination
                                        paginationURL={`/responder/disabled?organizationId=${organizationId}`}
                                        noSelect
                                        onView={(data: any) => navigate(`/organization/${organizationId}/location/${data.locationId}/responder/${data.id}`)}
                                        onDelete={(responder: any) => disableResponder(responder)}
                                        onEdit={(responder: any) => editResponderHandler(responder)}
                                        columns={columns}
                                        modalName="responder"
                                    />
                                </>
                            ) : (
                                <MuiTable
                                    noView
                                    pagination
                                    paginationURL={`/responder?organizationId=${organizationId}`}
                                    noSelect
                                    onView={(data: any) => navigate(`/organization/${organizationId}/location/${data.locationId}/responder/${data.id}`)}
                                    onDelete={(responder: any) => disableResponder(responder)}
                                    onEdit={(responder: any) => editResponderHandler(responder)}
                                    columns={columns}
                                    modalName="responder"
                                />
                            )}
                        </>
                    )}
                    <Button variant="contained" onClick={() => setCreateMode(true)} fullWidth color="success" style={{ marginTop: 20 }}>
                        ADD
                    </Button>
                </>
            )}

            {(createMode || editMode) && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                customConfirmAlert(() => {
                                    clearCamps();
                                    setEditMode(false);
                                    setCreateMode(false);
                                });
                            }}
                            color="primary"
                        >
                            <BsArrowLeftSquareFill style={{ marginRight: 10 }} /> Responders list
                        </Button>
                    </Grid>

                    <Grid item marginTop={2} xs={12}>
                        <Typography variant="h6">{createMode ? "Add " : "Edit "} responder</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="caption">Info</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            {...errorProps(errors, "name")}
                            label="Name"
                            value={responderName}
                            fullWidth
                            onChange={(evt) => setResponderName(evt.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Location</InputLabel>
                            <Select
                                MenuProps={{
                                    sx: {
                                        maxHeight: 250,
                                    },
                                }}
                                {...errorProps(errors, "locationId")}
                                defaultValue={locations.filter((loc: any) => loc?.id === selectedLocationId)[0]}
                                value={selectedLocation}
                                onChange={(evt: any) => setSelectedLocation(evt.target.value)}
                                label="Location"
                            >
                                {locations.map((location: any) => (
                                    <MenuItem key={location} value={location} style={{ display: "flex", alignItems: "center", flexWrap: "wrap", margin: 0 }}>
                                        {location.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => (createMode ? addResponder() : updateResponder())} fullWidth color="success">
                            {createMode ? "ADD" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </CustomModal>
    );
}
